import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Backdrop,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
} from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import AppsIcon from '@material-ui/icons/Apps';
import { CircularProgress, Divider } from '@material-ui/core';
import Image from './Image';
import axios from 'axios';
import DownloadDialog from '../DownloadDialog';
import UploadBox from '../UploadBox';
import Lightbox from 'react-image-lightbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import 'react-image-lightbox/style.css';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteDialog from '../DeleteDialog';
import StartAtDialog from '../StartAtDialog';
// import MenuItem from '@material-ui/core/MenuItem';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom';

const GET_BRACKETS_ORGENIZED_IMAGES =
  'https://py.wesnapp.co.il/server/get-images-orgenized/';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyle = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 24,
    position: 'sticky',
    top: 0,
    zIndex: 999,
    background: '#F9F8FD',
    paddingBottom: 24,
    alignItems: 'center',
  },
  toolbarSelection: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 24,
    position: 'sticky',
    top: 80,
    height: 80,
    zIndex: 999,
    background: '#F9F8FD',
    paddingBottom: 24,
    alignItems: 'center',
  },
  type: {
    fontSize: 26,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  toolbarRightSide: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  toolbarRightSideIconButton: {
    marginTop: 4,
    marginLeft: 4,
  },
  button: {
    background: theme.palette.primary.main,
    color: 'white',
    textTransform: 'none',
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 12,
    boxShadow: `0px 6px 10px ${theme.palette.primary.main}60`,
    height: 42,
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  exposureButton: {
    background: theme.palette.primary.main,
    color: 'white',
    textTransform: 'none',
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 12,
    boxShadow: `0px 6px 10px ${theme.palette.primary.main}60`,
    height: 42,
    maxWidth: 200,
    marginTop: 16,
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  deleteButton: {
    background: '#eb4034',
    color: 'white',
    textTransform: 'none',
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 12,
    boxShadow: `0px 6px 10px #eb403460`,
    height: 42,
    fontWeight: 'bold',
    '&:hover': {
      background: '#eb4034',
    },
  },
  downloadButton: {
    background: '#34eb7d',
    color: 'white',
    textTransform: 'none',
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 12,
    boxShadow: `0px 6px 10px #34eb7d60`,
    height: 42,
    fontWeight: 'bold',
    '&:hover': {
      background: '#34eb7d',
    },
  },
  selectedView: {
    color: theme.palette.primary.main,
  },
  imagesContainerRow: {
    marginTop: 32,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    gap: 12,
    justifyContent: 'space-between',
  },
  imagesContainerColumn: {
    marginTop: 32,
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    gap: 12,
  },
  imagesGridContainer: {
    marginTop: 32,
    flexWrap: 'wrap',
  },
}));

const Original = (props) => {
  const classes = useStyle();

  let { id } = useParams();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [images, setImages] = useState({});
  const [selectedExposure, setSelectedExposure] = useState(null);
  const [view, setView] = useState('row');
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [selectionOn, setSelectionOn] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [foundImages, setFoundImages] = useState(false);

  // Download
  const [downloadPercenteges, setDownloadPercenteges] = useState(0);
  const [prepearingImages, setPrepearingImages] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  const [openMoveToMenu, setOpenMoveToMenu] = useState(null);

  // Delete
  const [deleting, setDeleting] = useState(false);

  const [uploadIsOpen, setUploadIsOpen] = useState(false);

  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');

  const [openExposuresMenu, setOpenExposuresMenu] = useState(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAtDialog, setOpenAtDialog] = useState(false);

  const handleCloseAlert = () => {
    setShowAlert(false);
    setAlertMessage('');
  };

  const showAlertMessage = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const handleChange = (event) => {
    setSelectedExposure(event.target.value);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleOpenImagesMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenExposuresMenu = (event) => {
    setOpenExposuresMenu(event.currentTarget);
  };

  const handleCloseExposuresMenu = () => {
    setUploadIsOpen(false);
    setOpenExposuresMenu(null);
  };

  const handleCloseImagesMenu = () => {
    setAnchorEl(null);
  };

  const fetchImages = async () => {
    let promiseFetchImages = [
      await getOriginalImagesForExposureType('1'),
      await getOriginalImagesForExposureType('3'),
      await getOriginalImagesForExposureType('4'),
      await getOriginalImagesForExposureType('5'),
      await getOriginalImagesForExposureType('6'),
      await getOriginalImagesForExposureType('7'),
      await getOriginalImagesForExposureType('8'),
      await getOriginalImagesForExposureType('9'),
    ];
    await Promise.all(promiseFetchImages);
    let newImages = {
      ...promiseFetchImages[0],
      ...promiseFetchImages[1],
      ...promiseFetchImages[2],
      ...promiseFetchImages[3],
      ...promiseFetchImages[4],
      ...promiseFetchImages[5],
      ...promiseFetchImages[6],
      ...promiseFetchImages[7],
      ...promiseFetchImages[8],
    };
    setImages(newImages);
    setLoading(false);
  };

  const uploadDownloadProgress = () => {
    return (progress) => {
      var percentCompleted = Math.round(
        (progress.loaded * 100) / progress.total
      );
      setDownloadPercenteges(percentCompleted);
      setPrepearingImages(false);
    };
  };

  const getDownloadUrl = (selectedImage) => {
    return selectedImage
      .replace('https://storage.googleapis.com/wesnapp/', '')
      .replace('/compressed', '')
      .replace('_reduced', '');
  };

  const downloadImages = () => {
    let zipname = `Wesnapp_${selectedExposure}`;
    if (downloadPercenteges == 0) {
      var lineOfFiles = '';
      for (var i = 0; i < selectedImages.length; i++) {
        let path = getDownloadUrl(selectedImages[i]);
        if (lineOfFiles == '') {
          lineOfFiles = path;
        } else {
          lineOfFiles = lineOfFiles + ',' + path;
        }
      }
      setDownloadPercenteges(1);
      setPrepearingImages(true);
      var config = {
        responseType: 'blob',
        onDownloadProgress: uploadDownloadProgress(),
      };
      var filesOld = lineOfFiles.split(',');
      var files = [];
      var folder = filesOld[0].split(
        '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
      )[0];
      for (var j = 0; j < filesOld.length; j++) {
        files.push(filesOld[j].split(folder + '/')[1]);
      }
      setOpenDownloadDialog(true);
      axios
        .post(
          'https://py.wesnapp.co.il/server/zipfiles-images',
          { folder: folder, files: files, zipname: zipname },
          config
        )
        .then((response) => {
          var headers = response.headers;
          var blob = new Blob([response.data], {
            type: headers['content-type'],
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = zipname;
          link.click();
          link.remove();
          setDownloadPercenteges(0);
          setOpenDownloadDialog(false);
          setSelectedImages([]);
          setSelectionOn(false);
          showAlertMessage('success', 'Images successfully downloaded');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getNewImagesAfterUpload = async () => {
    setDeleting(true);
    let originalImages = images;
    let newImagesFromServer = await getOriginalImagesForExposureType(
      selectedExposure
    );
    originalImages[selectedExposure] = newImagesFromServer[selectedExposure];
    setImages(originalImages);
    setDeleting(false);
    setSelectedImages([]);
    setSelectionOn(false);
    setUploadIsOpen(false);
  };

  const deleteImages = async () => {
    setDeleting(true);
    let promiseDelete = [];
    for (var i = 0; i < selectedImages.length; i++) {
      let path = getDownloadUrl(selectedImages[i]);
      // promiseDelete.push(axios.post("http://localhost:8080/delete-file" ,{path: path}, {headers: {'Content-Type': 'application/json'}}))
      promiseDelete.push(
        axios.post(
          'https://py.wesnapp.co.il/delete-file',
          { path: path },
          { headers: { 'Content-Type': 'application/json' } }
        )
      );
    }
    await Promise.all(promiseDelete);
    let originalImages = images;
    let newImagesFromServer = await getOriginalImagesForExposureType(
      selectedExposure
    );
    originalImages[selectedExposure] = newImagesFromServer[selectedExposure];
    setImages(originalImages);
    setDeleting(false);
    setSelectedImages([]);
    setSelectionOn(false);
    showAlertMessage('success', 'Images successfully deleted');
  };

  const moveToExposuresFolder = async (exposure) => {
    setDeleting(true);
    let promiseDelete = [];
    for (var i = 0; i < selectedImages.length; i++) {
      let path = getDownloadUrl(selectedImages[i]);
      // promiseDelete.push(axios.post("http://localhost:8080/move-file" ,{path: path, moveFrom: `${selectedExposure}Exposures`, moveTo: `${exposure}Exposures`}, {headers: {'Content-Type': 'application/json'}}))
      promiseDelete.push(
        axios.post(
          'https://py.wesnapp.co.il/move-file',
          {
            path: path,
            moveFrom: `${selectedExposure}Exposures`,
            moveTo: `${exposure}Exposures`,
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
      );
    }
    await Promise.all(promiseDelete);
    let originalImages = images;
    setSelectedExposure(exposure);
    // original folder
    let newImagesFromServer1 = await getOriginalImagesForExposureType(
      selectedExposure
    );
    originalImages[selectedExposure] = newImagesFromServer1[selectedExposure];
    setImages(originalImages);
    // new folder
    let newImagesFromServer2 = await getOriginalImagesForExposureType(exposure);
    originalImages[exposure] = newImagesFromServer2[exposure];
    setImages(originalImages);
    setDeleting(false);
    setSelectedImages([]);
    setSelectionOn(false);
    showAlertMessage('success', 'Images successfully moved');
  };

  const getOriginalImagesForExposureType = async (exposure) => {
    const response = await axios.get(
      `${GET_BRACKETS_ORGENIZED_IMAGES}${id}-originals-bracketing-${exposure}Exposures`,
      {},
      { headers: { 'Content-Type': 'application/json' } }
    );
    const gallery = [];
    for (var i = 0; i < response.data.originals.length; i++) {
      gallery.push({
        src: response.data.originals[i],
        thumbnail: response.data.thumbnail[i],
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        caption: 'Image ' + String(i),
      });
    }
    const newImages = {
      [String(exposure)]: {
        images: response.data,
        gallery: gallery,
      },
    };
    if (!selectedExposure) {
      if (response.data.originals.length > 0) {
        setFoundImages(true);
        setSelectedExposure(exposure);
      }
    }
    return newImages;
  };

  const openGallery = (index) => {
    setGalleryIndex(index);
    setGalleryOpen(true);
  };

  const canSelectAll = () => {
    if (
      images[selectedExposure] &&
      images[selectedExposure].images &&
      images[selectedExposure].images.originals
    ) {
      if (
        images[selectedExposure].images.originals.length ===
        selectedImages.length
      )
        return false;
      return true;
    }
    return false;
  };

  const selectAll = () => {
    setSelectedImages(images[selectedExposure].images.originals);
  };

  const diselectAll = () => {
    setSelectedImages([]);
  };

  useEffect(() => {
    if (!loading) {
      if (!foundImages) {
        setUploadIsOpen(true);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (uploadIsOpen) setSelectionOn(false);
  }, [uploadIsOpen]);

  useEffect(() => {
    if (selectionOn) setUploadIsOpen(false);
  }, [selectionOn]);

  const startAT = (withLoading = true) => {
    if (withLoading) setLoading(true);
    var data = { requestId: id, exposures: selectedExposure };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/editingRequest/create',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        if (withLoading) setLoading(false);
        showAlertMessage('success', 'Editing request successfully accepted');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGridSizeBaseOnExposures = () => {
    let items = 1;
    if (parseInt(selectedExposure) === 1) {
      items = 4;
      let spaces = (items - 1) * 12;
      return { width: `calc((100% - ${String(spaces)}px) / ${String(items)})` };
    }
    if (parseInt(selectedExposure) === 3) {
      items = 3;
      let spaces = (items - 1) * 12;
      return { width: `calc((100% - ${String(spaces)}px) / ${String(items)})` };
    }
    if (parseInt(selectedExposure) === 4) {
      items = 4;
      let spaces = (items - 1) * 12;
      return { width: `calc((100% - ${String(spaces)}px) / ${String(items)})` };
    }
    if (parseInt(selectedExposure) === 5) {
      items = 5;
      let spaces = (items - 1) * 12;
      return { width: `calc((100% - ${String(spaces)}px) / ${String(items)})` };
    }
    if (parseInt(selectedExposure) === 6) {
      items = 6;
      let spaces = (items - 1) * 12;
      return { width: `calc((100% - ${String(spaces)}px) / ${String(items)})` };
    }
    if (parseInt(selectedExposure) === 7) {
      items = 7;
      let spaces = (items - 1) * 12;
      return { width: `calc((100% - ${String(spaces)}px) / ${String(items)})` };
    }
    if (parseInt(selectedExposure) === 8) {
      items = 8;
      let spaces = (items - 1) * 12;
      return { width: `calc((100% - ${String(spaces)}px) / ${String(items)})` };
    }
    if (parseInt(selectedExposure) === 9) {
      items = 9;
      let spaces = (items - 1) * 12;
      return { width: `calc((100% - ${String(spaces)}px) / ${String(items)})` };
    }
  };

  const handleCloseMoveMenu = () => {
    setOpenMoveToMenu(null);
  };

  const handleMoveToMenu = (event) => {
    setOpenMoveToMenu(event.currentTarget);
  };

  return (
    <Container maxWidth='xl' disableGutters={true}>
      <StartAtDialog
        open={openAtDialog}
        setOpen={setOpenAtDialog}
        handleDelete={startAT}
      />
      <DeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        handleDelete={deleteImages}
      />
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Backdrop open={deleting || loading} style={{ zIndex: 999999 }}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <DownloadDialog
        open={openDownloadDialog}
        setOpen={setOpenDownloadDialog}
        downloadPercenteges={downloadPercenteges}
      />
      {galleryOpen && (
        <Lightbox
          mainSrc={images[selectedExposure].images.compressed[galleryIndex]}
          nextSrc={
            images[selectedExposure].images.compressed[
              (galleryIndex + 1) %
                images[selectedExposure].images.compressed.length
            ]
          }
          prevSrc={
            images[selectedExposure].images.compressed[
              (galleryIndex +
                images[selectedExposure].images.compressed.length -
                1) %
                images[selectedExposure].images.compressed.length
            ]
          }
          onCloseRequest={() => setGalleryOpen(false)}
          onMovePrevRequest={() =>
            setGalleryIndex(
              (galleryIndex +
                images[selectedExposure].images.compressed.length -
                1) %
                images[selectedExposure].images.compressed.length
            )
          }
          onMoveNextRequest={() =>
            setGalleryIndex(
              (galleryIndex + 1) %
                images[selectedExposure].images.compressed.length
            )
          }
        />
      )}
      <div className={classes.toolbar}>
        <div className={classes.toolbarRightSide}>
          {images['1'] &&
            images['3'] &&
            images['4'] &&
            images['5'] &&
            images['6'] &&
            images['7'] &&
            images['8'] &&
            images['9'] && (
              <Menu
                id='simple-menu'
                anchorEl={openExposuresMenu}
                keepMounted
                open={Boolean(openExposuresMenu)}
                onClose={handleCloseExposuresMenu}
              >
                <MenuItem
                  disabled={images['1'].images.originals.length === 0}
                  onClick={() => {
                    setSelectedExposure('1');
                    handleCloseExposuresMenu();
                  }}
                >
                  1 Exposure
                </MenuItem>
                <MenuItem
                  disabled={images['3'].images.originals.length === 0}
                  onClick={() => {
                    setSelectedExposure('3');
                    handleCloseExposuresMenu();
                  }}
                >
                  3 Exposure
                </MenuItem>
                <MenuItem
                  disabled={images['4'].images.originals.length === 0}
                  onClick={() => {
                    setSelectedExposure('4');
                    handleCloseExposuresMenu();
                  }}
                >
                  4 Exposure
                </MenuItem>
                <MenuItem
                  disabled={images['5'].images.originals.length === 0}
                  onClick={() => {
                    setSelectedExposure('5');
                    handleCloseExposuresMenu();
                  }}
                >
                  5 Exposure
                </MenuItem>
                <MenuItem
                  disabled={images['6'].images.originals.length === 0}
                  onClick={() => {
                    setSelectedExposure('6');
                    handleCloseExposuresMenu();
                  }}
                >
                  6 Exposure
                </MenuItem>
                <MenuItem
                  disabled={images['7'].images.originals.length === 0}
                  onClick={() => {
                    setSelectedExposure('7');
                    handleCloseExposuresMenu();
                  }}
                >
                  7 Exposure
                </MenuItem>
                <MenuItem
                  disabled={images['8'].images.originals.length === 0}
                  onClick={() => {
                    setSelectedExposure('8');
                    handleCloseExposuresMenu();
                  }}
                >
                  8 Exposure
                </MenuItem>
                <MenuItem
                  disabled={images['9'].images.originals.length === 0}
                  onClick={() => {
                    setSelectedExposure('9');
                    handleCloseExposuresMenu();
                  }}
                >
                  9 Exposure
                </MenuItem>
              </Menu>
            )}
          <div className={classes.type} onClick={handleOpenExposuresMenu}>
            Images
            {selectedExposure && <> {selectedExposure} exposures </>}(
            {images[selectedExposure] &&
            images[selectedExposure].images &&
            images[selectedExposure].images.originals
              ? images[selectedExposure].images.originals.length
              : 0}
            )
            <ArrowDropDownIcon
              style={{ marginLeft: 4, width: 32, height: 32, marginTop: 4 }}
            />
          </div>
          <IconButton
            onClick={() => setView('column')}
            className={classes.toolbarRightSideIconButton}
          >
            {view === 'column' ? (
              <ViewListIcon className={classes.selectedView} />
            ) : (
              <ViewListIcon />
            )}
          </IconButton>
          <IconButton
            onClick={() => setView('row')}
            className={classes.toolbarRightSideIconButton}
          >
            {view === 'row' ? (
              <AppsIcon className={classes.selectedView} />
            ) : (
              <AppsIcon />
            )}
          </IconButton>
        </div>
        <div>
          {/* <Button onClick={()=> setOpenAtDialog(true)} className={classes.button} style={{marginRight: 12, opacity: selectionOn ? 0.5 : 1}} disabled={selectionOn}>
            Start AT
          </Button> */}
          <Button
            onClick={() => setSelectionOn(true)}
            className={classes.button}
            style={{ marginRight: 12, opacity: selectionOn ? 0.5 : 1 }}
            disabled={selectionOn}
          >
            Select
          </Button>
          {!uploadIsOpen &&
            images['1'] &&
            images['3'] &&
            images['4'] &&
            images['5'] &&
            images['6'] &&
            images['7'] &&
            images['8'] &&
            images['9'] && (
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseImagesMenu}
              >
                <MenuItem
                  onClick={() => {
                    setSelectedExposure('1');
                    setUploadIsOpen(true);
                    handleCloseImagesMenu();
                  }}
                >
                  1 Exposure
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedExposure('3');
                    setUploadIsOpen(true);
                    handleCloseImagesMenu();
                  }}
                >
                  3 Exposure
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedExposure('4');
                    setUploadIsOpen(true);
                    handleCloseImagesMenu();
                  }}
                >
                  4 Exposure
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedExposure('5');
                    setUploadIsOpen(true);
                    handleCloseImagesMenu();
                  }}
                >
                  5 Exposure
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedExposure('6');
                    setUploadIsOpen(true);
                    handleCloseImagesMenu();
                  }}
                >
                  6 Exposure
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedExposure('7');
                    setUploadIsOpen(true);
                    handleCloseImagesMenu();
                  }}
                >
                  7 Exposure
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedExposure('8');
                    setUploadIsOpen(true);
                    handleCloseImagesMenu();
                  }}
                >
                  8 Exposure
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedExposure('9');
                    setUploadIsOpen(true);
                    handleCloseImagesMenu();
                  }}
                >
                  9 Exposure
                </MenuItem>
              </Menu>
            )}
          {!uploadIsOpen ? (
            <Button onClick={handleOpenImagesMenu} className={classes.button}>
              + Add Photos
            </Button>
          ) : (
            <Button
              onClick={() => setUploadIsOpen(false)}
              className={classes.button}
            >
              Cancel Upload
            </Button>
          )}
        </div>
      </div>
      <Divider />
      {selectionOn && (
        <div className={classes.toolbarSelection} style={{ paddingBottom: 0 }}>
          <div className={classes.toolbarRightSide}>
            <Button
              onClick={() => setSelectionOn(false)}
              className={classes.button}
              disabled={!selectionOn}
            >
              Cancel Selection
            </Button>
            <Button
              onClick={selectAll}
              className={classes.button}
              style={{ marginLeft: 12 }}
              disabled={!canSelectAll()}
            >
              Select All
            </Button>
            <Button
              onClick={diselectAll}
              className={classes.button}
              style={{ marginLeft: 12 }}
              disabled={selectedImages.length === 0}
            >
              Diselect All
            </Button>
          </div>
          <div>
            <Menu
              id='simple-menu'
              anchorEl={openMoveToMenu}
              keepMounted
              open={Boolean(openMoveToMenu)}
              onClose={handleCloseMoveMenu}
            >
              <MenuItem
                onClick={() => {
                  moveToExposuresFolder('1');
                  handleCloseMoveMenu();
                }}
              >
                1 Exposure
              </MenuItem>
              <MenuItem
                onClick={() => {
                  moveToExposuresFolder('3');
                  handleCloseMoveMenu();
                }}
              >
                3 Exposure
              </MenuItem>
              <MenuItem
                onClick={() => {
                  moveToExposuresFolder('4');
                  handleCloseMoveMenu();
                }}
              >
                4 Exposure
              </MenuItem>
              <MenuItem
                onClick={() => {
                  moveToExposuresFolder('5');
                  handleCloseMoveMenu();
                }}
              >
                5 Exposure
              </MenuItem>
              <MenuItem
                onClick={() => {
                  moveToExposuresFolder('6');
                  handleCloseMoveMenu();
                }}
              >
                6 Exposure
              </MenuItem>
              <MenuItem
                onClick={() => {
                  moveToExposuresFolder('7');
                  handleCloseMoveMenu();
                }}
              >
                7 Exposure
              </MenuItem>
              <MenuItem
                onClick={() => {
                  moveToExposuresFolder('8');
                  handleCloseMoveMenu();
                }}
              >
                8 Exposure
              </MenuItem>
              <MenuItem
                onClick={() => {
                  moveToExposuresFolder('9');
                  handleCloseMoveMenu();
                }}
              >
                9 Exposure
              </MenuItem>
            </Menu>
            <Button
              onClick={handleMoveToMenu}
              className={classes.button}
              disabled={selectedImages.length === 0}
              style={{ marginRight: 12 }}
            >
              Move To ({selectedImages.length})
            </Button>
            <Button
              onClick={() => downloadImages()}
              className={classes.downloadButton}
              disabled={selectedImages.length === 0}
              style={{ marginRight: 12 }}
            >
              Download ({selectedImages.length})
            </Button>
            <Button
              onClick={() => setOpenDeleteDialog(true)}
              className={classes.deleteButton}
              disabled={selectedImages.length === 0}
            >
              Delete ({selectedImages.length})
            </Button>
          </div>
        </div>
      )}
      {uploadIsOpen && selectedExposure && (
        <UploadBox
          fetchImages={fetchImages}
          request={props.request}
          startAT={startAT}
          showAlertMessage={showAlertMessage}
          exposure={selectedExposure}
          requestId={id}
          getNewImagesAfterUpload={getNewImagesAfterUpload}
        />
      )}
      {uploadIsOpen && !selectedExposure && (
        <div style={{ marginTop: 24 }}>
          <div style={{ fontSize: 22 }}>
            What kind of exposure you need to upload?
          </div>
          <div
            style={{
              marginTop: 24,
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              className={classes.exposureButton}
              onClick={() => setSelectedExposure('1')}
            >
              1 Exposure
            </Button>
            <Button
              className={classes.exposureButton}
              onClick={() => setSelectedExposure('3')}
            >
              3 Exposure
            </Button>
            <Button
              className={classes.exposureButton}
              onClick={() => setSelectedExposure('4')}
            >
              4 Exposure
            </Button>
            <Button
              className={classes.exposureButton}
              onClick={() => setSelectedExposure('5')}
            >
              5 Exposure
            </Button>
            <Button
              className={classes.exposureButton}
              onClick={() => setSelectedExposure('6')}
            >
              6 Exposure
            </Button>
            <Button
              className={classes.exposureButton}
              onClick={() => setSelectedExposure('7')}
            >
              7 Exposure
            </Button>
            <Button
              className={classes.exposureButton}
              onClick={() => setSelectedExposure('8')}
            >
              8 Exposure
            </Button>
            <Button
              className={classes.exposureButton}
              onClick={() => setSelectedExposure('9')}
            >
              9 Exposure
            </Button>
          </div>
        </div>
      )}
      {images[selectedExposure] &&
        images[selectedExposure].images &&
        images[selectedExposure].images.originals && (
          <div
            className={classes.imagesGridContainer}
            style={{ display: 'flex', gap: 12, flexWrap: 'wrap' }}
          >
            {images[selectedExposure].images.originals.map((image, index) => {
              if (view === 'column') {
                return (
                  <div style={{ width: '100%' }}>
                    <Image
                      selectionOn={selectionOn}
                      setSelectedImages={setSelectedImages}
                      selectedImages={selectedImages}
                      openGallery={openGallery}
                      key={index}
                      index={index}
                      image={image}
                      view={view}
                    />
                  </div>
                );
              } else {
                return (
                  <div style={getGridSizeBaseOnExposures()}>
                    <Image
                      selectionOn={selectionOn}
                      setSelectedImages={setSelectedImages}
                      selectedImages={selectedImages}
                      openGallery={openGallery}
                      key={index}
                      index={index}
                      image={image}
                      view={view}
                    />
                  </div>
                );
              }
            })}
            {/* </Grid> */}
          </div>
        )}
    </Container>
  );
};

export default Original;
