import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

function AuthContextProvider(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [token, setToken] = useState(null);
  const [accountData, setAccountData] = useState(null);

  useEffect(() => {
    if (accountId !== null && accountId !== '') {
      getUserDetails();
    }
  }, [accountId]);

  const getUserDetails = () => {
    var data = { id: accountId };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/account/get-one',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log('userDetails', response.data[0]);
        var firstName = response.data[0].FirstName;
        var lastName = response.data[0].LastName;
        setAccountData(response.data[0]);
      })
      .catch((error) => {});
    // Last visit
    axios
      .post(
        'https://us-central1-wesnapp-d136a.cloudfunctions.net/updateContact',
        {
          id: accountId,
          data: { Last_Login_Date_Time__c: new Date().toISOString() },
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {})
      .catch((error) => {});
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        setIsLoggedIn: setIsLoggedIn,
        accountId: accountId,
        setAccountId: setAccountId,
        contactId: contactId,
        setContactId: setContactId,
        accountType: accountType,
        setAccountType: setAccountType,
        token: token,
        setToken: setToken,
        accountData,
        setAccountData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
