import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from 'react-google-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Map from '../Map';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Location from './Location';

import { TimePicker, DatePicker } from 'antd';
import moment from 'moment';
import '../../../../../node_modules/antd/dist/antd.css';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const format = 'HH:mm';

const photographerImage = require('../../../../Assets/photographerImage2.png');

var mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#6C65FF',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#6C65FF',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#6C65FF',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#6C65FF',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: 'rgb(94,44,237)',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100vw',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 28,
      borderColor: '#DAE2FD',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#ecf1fe',
      borderRadius: 28,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      border: '2px solid',
      borderColor: 'yellow',
    },
  },
  button: {
    marginRight: theme.spacing(1),
    height: 50,
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#6C65FF',
    },
  },
  selectedButton: {
    marginRight: theme.spacing(1),
    height: 50,
    borderRadius: 25,
    backgroundColor: '#6C65FF',
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    color: 'white',
    hover: {
      backgroundColor: '#6C65FF',
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(landingPage) {
  if (landingPage) {
    return ['General Details', 'Shooting Details', 'Summary and Payment'];
  } else {
    return ['Shooting Details', 'Summary and Payment'];
  }
}

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const steps = getSteps(props.landingPage);
  const [value, setValue] = React.useState(0);
  const [paymentOption, setPaymentOption] = React.useState(1);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const getCoordinates = async (address) => {
    try {
      var link =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        encodeURI(address) +
        '&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s';
      const response = await axios.get(link);
      // console.log(response.data.results[0]);
      console.log(response.data.results);
      if (response.data.results.length > 0) {
        if (
          response.data.results[0].types.includes('street_address') ||
          response.data.results[0].types.includes('premise')
        ) {
          console.log('yes');
          props.main.setState({
            errorAddress: false,
            lat: response.data.results[0].geometry.location.lat,
            lng: response.data.results[0].geometry.location.lng,
            formattedAddress: response.data.results[0].formatted_address,
          });
        } else {
          console.log(response.data.results[0]);
          console.log('no');
          props.main.setState({ errorAddress: true });
        }
      } else {
        props.main.setState({ errorAddress: true });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleNext = () => {
    // setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (props.main.state.activeStep === 1 && props.main.state.useNow !== null) {
      props.main.setState({ useNow: null });
    } else {
      props.main.setState({ activeStep: props.main.state.activeStep - 1 });
    }
  };

  const handleReset = () => {
    props.main.setState({ activeStep: 0 });
  };

  const handleTimeChangeFrom = (date) => {
    var dateObj = new Date(date);
    var newTime = String(dateObj.getHours() + ':' + dateObj.getMinutes());
    props.main.setState({ time: newTime, formatedTime: new Date(date) });
  };

  const handleDateChangeFrom = (date) => {
    var dateObj = new Date(date);
    var m = String(dateObj.getDate());
    var d = String(dateObj.getMonth() + 1);
    var y = String(dateObj.getFullYear());
    if (m.length == 1) {
      m = '0' + m;
    }
    if (d.length == 1) {
      d = '0' + d;
    }
    var newDate = y + '-' + d + '-' + m;
    props.main.setState({ date: newDate, formatedDate: new Date(date) });
  };

  const handleChange = (name) => (event) => {
    props.main.setState({ [name]: event.target.checked });
  };

  const handleChangeText = (name) => (event) => {
    if (name === 'phone') {
      console.log(name);
      props.main.setState({ [name]: event.target.value.replace('-', '') });
    } else if (name === 'firstName') {
      props.main.setState({
        nameForInvoice: event.target.value + ' ' + props.main.state.lastName,
      });
      props.main.setState({ [name]: event.target.value });
    } else if (name === 'lastName') {
      props.main.setState({
        nameForInvoice: props.main.state.firstName + ' ' + event.target.value,
      });
      props.main.setState({ [name]: event.target.value });
    } else {
      console.log(name);
      props.main.setState({ [name]: event.target.value });
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'days').endOf('day');
  };

  const getDateTimeToDisplay = () => {
    var dateAndTime = '';
    var splitedDate = props.main.state.date.split('-');
    dateAndTime =
      splitedDate[1] +
      '/' +
      splitedDate[2] +
      '/' +
      splitedDate[0] +
      ' ' +
      props.main.state.time;
    return dateAndTime;
  };

  const onchangeDateTime = (e) => {
    console.log(e._d);
    handleDateChangeFrom(e._d);
    handleTimeChangeFrom(e._d);
  };

  const getBalance = (data) => {
    console.log('@#!@#');
    var packages = props.main.state.openPackages;
    console.log(packages);
    for (var i = 0; i < packages.length; i++) {
      console.log(packages[i].orderId);
      console.log(data);
      if (packages[i].orderId == data.WS_Order__c) {
        console.log(packages[i].balance);
        return packages[i].balance;
      }
    }
  };

  const getProductData = (id, whatType) => {
    var products = props.main.state.productsFromPackages;
    // console.log("!@#!@#!@#!@#");
    // console.log(products);
    for (var i = 0; i < products.length; i++) {
      // console.log(products[i].Id.slice(0, -3));
      // console.log(id);
      if (products[i].Id.slice(0, -3) === id) {
        if (whatType == 'all') {
          return products[i];
        }
        // console.log("!!!!");
        // console.log(products[i][whatType]);
        return products[i][whatType];
      }
    }
    return '';
  };

  const handleChangePaymentOption = (event, newValue) => {
    setPaymentOption(newValue);
  };

  const getPaymentIframe = () => {
    var maxpay = '2';
    var sum = props.main.state.selectedProductData.Total_Cost__c * 1.17;
    var cred_type = null;
    if (paymentOption === 1) {
      cred_type = '1';
    } else {
      cred_type = '6';
    }
    if (sum >= 1000 && sum < 2000) {
      maxpay = '2';
    } else if (sum >= 2000 && sum < 3000) {
      maxpay = '3';
    } else if (sum >= 3000 && sum < 4000) {
      maxpay = '4';
    }
    if (sum >= 5000) {
      maxpay = '5';
    }
    if (
      props.main.state.useNow &&
      props.main.state.selectedProductData.Product_Type__c === 'Package'
    ) {
      return (
        <div>
          <iframe
            style={{
              width: '100%',
              height: 340,
              marginTop: 0,
              frameBorder: 0,
              borderRadius: 0,
              border: 0,
            }}
            src={
              'https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&u71=1&trButtonColor=000000&buttonLabel=Payment&type=charge-package&nologo=1&sum=' +
              (
                props.main.state.selectedProductData.Total_Cost__c * 1.17
              ).toFixed(2) +
              '&price=' +
              (
                props.main.state.selectedProductData.Total_Cost__c * 1.17
              ).toFixed(2) +
              '&accountId=' +
              props.main.state.accountId +
              '&contact=' +
              props.main.state.contactId +
              '&mobile=' +
              props.main.state.phone +
              '&name=' +
              props.main.state.nameForInvoice +
              '&orderId=' +
              props.main.state.orderId +
              '-' +
              props.main.state.requestId +
              '&vatType=' +
              '0' +
              '&email=' +
              props.main.state.email +
              '&companyNumber=' +
              '' +
              '&address=' +
              props.main.state.formattedAddress +
              '&countryCode=' +
              'IL' +
              '&maxpay=' +
              maxpay +
              '&cred_type=' +
              cred_type +
              '&currencyCode=' +
              'ILS'
            }
          ></iframe>
        </div>
      );
    } else if (
      !props.main.state.useNow &&
      props.main.state.selectedProductData.Product_Type__c === 'Package'
    ) {
      return (
        <div>
          <iframe
            style={{
              width: '100%',
              height: 340,
              marginTop: 0,
              frameBorder: 0,
              borderRadius: 0,
              border: 0,
            }}
            src={
              'https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&cred_type=8&u71=1&trButtonColor=000000&buttonLabel=Payment&type=charge-many&nologo=1&sum=' +
              (
                props.main.state.selectedProductData.Total_Cost__c * 1.17
              ).toFixed(2) +
              '&price=' +
              (
                props.main.state.selectedProductData.Total_Cost__c * 1.17
              ).toFixed(2) +
              '&accountId=' +
              props.main.state.accountId +
              '&contact=' +
              props.main.state.contactId +
              '&mobile=' +
              props.main.state.phone +
              '&name=' +
              props.main.state.nameForInvoice +
              '&orderId=' +
              props.main.state.requestId +
              '&vatType=' +
              '0' +
              '&email=' +
              props.main.state.email +
              '&companyNumber=' +
              '' +
              '&address=' +
              props.main.state.formattedAddress +
              '&countryCode=' +
              'IL' +
              '&maxpay=' +
              maxpay +
              '&cred_type=' +
              cred_type +
              '&currencyCode=' +
              'ILS'
            }
          ></iframe>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const getTotalCost = () => {
    let toPayFor = props.main.state.selectedProductData.Total_Cost__c;
    let total = 0;
    if (props.main.state.couponIsValid) {
      if (props.main.state.couponType === 'cash') {
        total = toPayFor - props.main.state.couponDiscount;
      } else {
        total = toPayFor * ((100 - props.main.state.couponDiscount) / 100);
      }
    } else {
      total = toPayFor;
    }
    return (total * 1.17).toFixed(2);
  };

  const getPaymentURL = async () => {
    var link = 'https://wesnapp-node-server.appspot.com/api/get-payment-url';
    let data = {
      // Account
      contactId: '',
      accountId: '',
      // Product
      productId: '',
      // Request and Order
      requestId: '',
      orderId: '',
      // Amount
      productTotalAmount: '', // Before coupon discount
      totalAmountToPay: '',
      // Coupon
      couponCode: '',
      couponType: '',
      couponAmount: '',
      // Payment For
      paymentFor: 'product',
    };
    const response = await axios.post(link, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log(response);
  };

  return (
    <div className={classes.root}>
      {/* {getPaymentURL()} */}
      <Stepper
        style={{ marginTop: -30 }}
        alternativeLabel
        activeStep={props.main.state.activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {props.main.state.activeStep === steps.length + 1 ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {props.main.state.activeStep === 2 && (
                <div
                  style={{
                    marginTop: mobile ? '-20px' : '20px',
                    paddingLeft: mobile ? 10 : 0,
                    paddingRight: mobile ? 10 : 0,
                  }}
                >
                  <Grid container spacing={1} dir='ltr' justify='center'>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Grid container spacing={1} dir='ltr' justify='center'>
                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                          <div
                            style={{
                              padding: mobile ? 20 : 24,
                              borderRadius: 20,
                              border: '1px solid #544cf8',
                              width: mobile ? '90%' : '80%',
                              marginLeft: mobile ? '5%' : '10%',
                              backgroundColor: 'rgba(239,242,249, 0)',
                            }}
                          >
                            <div
                              className={classes.text}
                              style={{
                                fontSize: 20,
                                marginTop: 0,
                                fontFamily: "'Montserrat', sans-serif",
                                textAlign: 'left',
                                width: '100%',
                                marginLeft: mobile ? 8 : 30,
                              }}
                            >
                              <b>Summary</b>
                            </div>
                            <Divider
                              style={{
                                width: mobile
                                  ? 'calc(100% - 8px)'
                                  : 'calc(100% - 70px)',
                                marginTop: 20,
                                marginLeft: mobile ? 8 : 30,
                              }}
                            />
                            {props.main.state.useNow && (
                              <div
                                style={{
                                  width: '100%',
                                  marginTop: 20,
                                  fontSize: 15,
                                  marginLeft: mobile ? 8 : 30,
                                  color: '#6C65FF',
                                }}
                              >
                                <div
                                  style={{ textAlign: 'left', width: '100%' }}
                                >
                                  <b>Location:</b>{' '}
                                  {props.main.state.formattedAddress}
                                </div>
                                <Divider
                                  style={{
                                    width: mobile
                                      ? 'calc(100% - 8px)'
                                      : 'calc(100% - 70px)',
                                    marginTop: 20,
                                  }}
                                />
                              </div>
                            )}
                            {props.main.state.useNow && (
                              <div
                                style={{
                                  width: '100%',
                                  marginTop: 20,
                                  fontSize: 15,
                                  marginLeft: mobile ? 8 : 30,
                                  color: '#6C65FF',
                                }}
                              >
                                <div
                                  style={{ textAlign: 'left', width: '100%' }}
                                >
                                  <b>Date & Time:</b> {getDateTimeToDisplay()}
                                </div>
                                <Divider
                                  style={{
                                    width: mobile
                                      ? 'calc(100% - 8px)'
                                      : 'calc(100% - 70px)',
                                    marginTop: 20,
                                  }}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                width: '100%',
                                marginTop: 20,
                                fontSize: 15,
                                marginLeft: mobile ? 8 : 30,
                                color: '#6C65FF',
                              }}
                            >
                              <div style={{ textAlign: 'left', width: '100%' }}>
                                <b>Product:</b>{' '}
                                {
                                  props.main.state.selectedProductData
                                    .Public_Product_Name__c
                                }
                              </div>
                              <Divider
                                style={{
                                  width: mobile
                                    ? 'calc(100% - 8px)'
                                    : 'calc(100% - 70px)',
                                  marginTop: 20,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                width: '100%',
                                marginTop: 20,
                                fontSize: 15,
                                marginLeft: mobile ? 8 : 30,
                                color: '#6C65FF',
                              }}
                            >
                              {props.main.state.willBeOnSite && (
                                <div
                                  style={{ textAlign: 'left', width: '100%' }}
                                >
                                  <b>Contact on site:</b>{' '}
                                  {props.main.state.firstName +
                                    ' ' +
                                    props.main.state.lastName}
                                </div>
                              )}
                              {!props.main.state.willBeOnSite && (
                                <div
                                  style={{ textAlign: 'left', width: '100%' }}
                                >
                                  <b>Contact on site:</b>{' '}
                                  {props.main.state.whoWillBeOnSiteName}
                                </div>
                              )}
                              <Divider
                                style={{
                                  width: mobile
                                    ? 'calc(100% - 8px)'
                                    : 'calc(100% - 70px)',
                                  marginTop: 20,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                width: '100%',
                                marginTop: 20,
                                fontSize: 15,
                                marginLeft: mobile ? 8 : 30,
                                color: '#6C65FF',
                              }}
                            >
                              {props.main.state.willBeOnSite && (
                                <div
                                  style={{ textAlign: 'left', width: '100%' }}
                                >
                                  <b>Contact phone:</b> {props.main.state.phone}
                                </div>
                              )}
                              {!props.main.state.willBeOnSite && (
                                <div
                                  style={{ textAlign: 'left', width: '100%' }}
                                >
                                  <b>Contact phone:</b>{' '}
                                  {props.main.state.whoWillBeOnSitePhone}
                                </div>
                              )}
                            </div>
                          </div>
                        </Grid>
                        {!props.main.state.isPackage && (
                          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                              {/* Coupon */}
                              <center>
                                <div
                                  style={{
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontSize: 16,
                                    color: '#333',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    marginBottom: 0,
                                    marginTop: 0,
                                    color: 'rgb(40,40,53)',
                                    width: '50%',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      verticalAlign: 'bottom',
                                      width: 'calc(100% - 80px',
                                    }}
                                  >
                                    <TextField
                                      id='standard-basic'
                                      label='Coupon'
                                      error={props.main.state.errorCoupon}
                                      onChange={props.main.handleChangeCoupon}
                                      fullWidth
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      verticalAlign: 'bottom',
                                    }}
                                  >
                                    {props.main.state.checkingCoupon ? (
                                      <CircularProgress
                                        size={40}
                                        style={{ marginLeft: 15 }}
                                      />
                                    ) : (
                                      <Button
                                        helper
                                        helperText='Error coupon'
                                        variant='primary'
                                        style={{
                                          marginLeft: 10,
                                          width: 70,
                                          height: 40,
                                          backgroundColor: '#6C65FF',
                                          color: 'white',
                                          textTransform: 'none',
                                          fontWeight: 'bold',
                                          borderRadius: 18,
                                          opacity: props.main.state.canApply
                                            ? 1
                                            : 0.5,
                                        }}
                                        disabled={!props.main.state.canApply}
                                        onClick={() =>
                                          props.main.checkIfCouponIsValid()
                                        }
                                      >
                                        Apply
                                      </Button>
                                    )}
                                  </div>
                                </div>
                                {props.main.state.errorCoupon && (
                                  <div
                                    style={{
                                      fontFamily: "'Montserrat', sans-serif",
                                      fontSize: 14,
                                      color: '#333',
                                      marginTop: 4,
                                      textAlign: 'left',
                                      marginBottom: 0,
                                      color: 'red',
                                      width: '100%',
                                    }}
                                  >
                                    {props.main.state.errorCouponText}
                                  </div>
                                )}
                                {props.main.state.errorCouponText ===
                                  'Coupon is valid' && (
                                  <div
                                    style={{
                                      fontFamily: "'Montserrat', sans-serif",
                                      fontSize: 14,
                                      color: '#333',
                                      marginTop: 4,
                                      textAlign: 'left',
                                      marginBottom: 0,
                                      color: 'green',
                                      width: '100%',
                                    }}
                                  >
                                    {props.main.state.errorCouponText}
                                  </div>
                                )}
                                {/* End Coupon */}
                              </center>
                              <div
                                className={classes.text}
                                style={{
                                  fontSize: 20,
                                  marginTop: 5,
                                  marginBottom: 0,
                                  fontFamily: "'Montserrat', sans-serif",
                                }}
                              >
                                <b>
                                  <div>
                                    Total payment -{' '}
                                    {
                                      props.main.state.selectedProductData
                                        .Total_Cost__c
                                    }{' '}
                                    ILS (
                                    {(
                                      props.main.state.selectedProductData
                                        .Total_Cost__c * 1.17
                                    ).toFixed(2)}{' '}
                                    + VAT)
                                  </div>
                                  <br />
                                  {/* <div style={{color: '#544cf8', marginTop: -20}}>
                                You will be charged automatically after the shoot
                              </div> */}
                                </b>
                              </div>
                              {props.main.state.couponIsValid && (
                                <div
                                  className={classes.text}
                                  style={{
                                    fontSize: 20,
                                    marginTop: 15,
                                    marginBottom: 20,
                                    fontFamily: "'Montserrat', sans-serif",
                                  }}
                                >
                                  <b>
                                    {props.main.state.couponType === 'cash' ? (
                                      <span>
                                        Coupon off{' '}
                                        {props.main.state.couponDiscount} ILS
                                        added
                                      </span>
                                    ) : (
                                      <span>
                                        Coupon off{' '}
                                        {props.main.state.couponDiscount} %
                                        added
                                      </span>
                                    )}
                                    <br />
                                  </b>
                                </div>
                              )}
                              {props.main.state.useNow &&
                                props.main.state.selectedProductData
                                  .Product_Type__c === 'Single' && (
                                  // eslint-disable-next-line jsx-a11y/iframe-has-title
                                  <iframe
                                    style={{
                                      width: '100%',
                                      height: 290,
                                      marginTop: 0,
                                      frameBorder: 0,
                                      borderRadius: 0,
                                      border: 0,
                                    }}
                                    src={
                                      'https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&cred_type=1&u71=1&trButtonColor=000000&buttonLabel=Payment&type=charge-many&nologo=1&sum=' +
                                      getTotalCost() +
                                      '&price=' +
                                      getTotalCost() +
                                      '&accountId=' +
                                      props.main.state.accountId +
                                      '&contact=' +
                                      props.main.state.contactId +
                                      '&mobile=' +
                                      props.main.state.phone +
                                      '&name=' +
                                      props.main.state.nameForInvoice +
                                      '&orderId=' +
                                      props.main.state.requestId +
                                      '&vatType=' +
                                      '0' +
                                      '&email=' +
                                      props.main.state.email +
                                      '&companyNumber=' +
                                      '' +
                                      '&address=' +
                                      props.main.state.formattedAddress +
                                      '&countryCode=' +
                                      'IL' +
                                      '&currencyCode=' +
                                      'ILS'
                                    }
                                  ></iframe>
                                )}
                              {!props.main.state.useNow &&
                                props.main.state.selectedProductData
                                  .Product_Type__c === 'Single' && (
                                  // eslint-disable-next-line jsx-a11y/iframe-has-title
                                  // <iframe style={{width: '100%', height: 290, marginTop: 0, frameBorder: 0, borderRadius: 0, border: 0}} src={
                                  //   "https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&cred_type=1&u71=1&trButtonColor=000000&buttonLabel=Payment&type=charge-many&nologo=1&sum="
                                  //   + getTotalCost()
                                  //   + "&price=" + getTotalCost()
                                  //   + "&accountId=" + props.main.state.accountId
                                  //   + "&contact=" + props.main.state.contactId
                                  //   + "&mobile=" + props.main.state.phone
                                  //   + "&name=" + props.main.state.nameForInvoice
                                  //   + "&orderId=" + props.main.state.requestId
                                  //   + "&vatType=" + '0'
                                  //   + "&email=" + props.main.state.email
                                  //   + "&companyNumber=" + ''
                                  //   + "&address=" + props.main.state.formattedAddress
                                  //   + "&countryCode=" + "IL"
                                  //   + "&currencyCode=" + "ILS"}
                                  // >
                                  // </iframe>
                                  <iframe
                                    style={{
                                      width: '100%',
                                      height: 290,
                                      marginTop: 0,
                                      frameBorder: 0,
                                      borderRadius: 0,
                                      border: 0,
                                    }}
                                    src={
                                      'https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&cred_type=1&u71=1&trButtonColor=000000&buttonLabel=Payment&type=charge-many&nologo=1&sum=' +
                                      getTotalCost() +
                                      '&price=' +
                                      getTotalCost() +
                                      '&accountId=' +
                                      props.main.state.accountId +
                                      '&contact=' +
                                      props.main.state.contactId +
                                      '&mobile=' +
                                      props.main.state.phone +
                                      '&name=' +
                                      props.main.state.nameForInvoice +
                                      '&orderId=' +
                                      props.main.state.requestId +
                                      '&vatType=' +
                                      '0' +
                                      '&email=' +
                                      props.main.state.email +
                                      '&companyNumber=' +
                                      '' +
                                      '&address=' +
                                      props.main.state.formattedAddress +
                                      '&countryCode=' +
                                      'IL' +
                                      '&currencyCode=' +
                                      'ILS'
                                    }
                                  ></iframe>
                                )}
                              <div>
                                {props.main.state.selectedProductData
                                  .Product_Type__c === 'Package' && (
                                  <Paper elevation={0} style={{ flexGrow: 1 }}>
                                    <Tabs
                                      value={paymentOption}
                                      onChange={handleChangePaymentOption}
                                      indicatorColor='primary'
                                      textColor='primary'
                                      centered
                                    >
                                      <Tab label='תשלומים' />
                                      <Tab label='תשלום אחד' />
                                    </Tabs>
                                  </Paper>
                                )}
                                {/* Coupon
                              <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 20, marginTop: 20, color: 'rgb(40,40,53)', width: '100%'}}>
                                <div style={{display: 'inline-block', verticalAlign: 'bottom', width: 'calc(100% - 80px'}}><TextField id="standard-basic" label="Coupon" error={props.main.state.errorCoupon} onChange={props.main.handleChangeCoupon} fullWidth/></div> 
                                <div style={{display: 'inline-block', verticalAlign: 'bottom'}}>
                                  {props.main.state.checkingCoupon ? (
                                    <CircularProgress size={40} style={{marginLeft: 15}}/>
                                  ) : (
                                    <Button helper helperText="Error coupon" variant="primary" style={{marginLeft: 10, width: 70, height: 40, backgroundColor: '#6C65FF', color: 'white', textTransform: 'none', fontWeight: 'bold', borderRadius: 18, opacity: props.main.state.canApply ? 1 : 0.5}} disabled={!props.main.state.canApply} onClick={()=> props.main.checkIfCouponIsValid()}>Apply</Button>
                                  )}
                                </div>
                              </div>
                              {props.main.state.errorCoupon && (
                                <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 14, color: '#333', marginTop: 4, textAlign: 'left', marginBottom: 0, color: 'red', width: '100%'}}>
                                  {props.main.state.errorCouponText}
                                </div>
                              )}
                              {props.main.state.errorCouponText === 'Coupon is valid' && (
                                <div style={{fontFamily: "'Montserrat', sans-serif", fontSize: 14, color: '#333', marginTop: 4, textAlign: 'left', marginBottom: 0, color: 'green', width: '100%'}}>
                                  {props.main.state.errorCouponText}
                                </div>
                              )} */}
                                <div style={{ marginTop: 50 }}>
                                  {getPaymentIframe()}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
              {props.main.state.activeStep === 0 && (
                <div
                  style={{
                    marginTop: mobile ? '-20px' : '20px',
                    paddingLeft: mobile ? 10 : 0,
                    paddingRight: mobile ? 10 : 0,
                  }}
                >
                  <Grid container spacing={24} dir='ltr' justify='center'>
                    <Grid item xs={12} sm={12} md={6} xl={6}>
                      <div style={{ marginTop: 0 }}>
                        <TextField
                          id='first-name'
                          label='First Name*'
                          fullWidth
                          variant='outlined'
                          onChange={handleChangeText('firstName')}
                          value={props.main.state.firstName}
                          style={{ marginTop: 30 }}
                        />
                        {props.main.state.errorFirstName && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: 15,
                              marginLeft: 14,
                              fontFamily: "'Montserrat', sans-serif",
                              float: 'left',
                              marginTop: 8,
                            }}
                          >
                            Error first name
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6}>
                      <div
                        style={{
                          marginTop: 0,
                          marginLeft: mobile ? '0%' : '5%',
                        }}
                      >
                        <TextField
                          id='last-name'
                          label='Last Name*'
                          fullWidth
                          variant='outlined'
                          onChange={handleChangeText('lastName')}
                          value={props.main.state.lastName}
                          style={{ marginTop: 30 }}
                        />
                        {props.main.state.errorLastName && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: 15,
                              marginLeft: 14,
                              fontFamily: "'Montserrat', sans-serif",
                              float: 'left',
                              marginTop: 8,
                            }}
                          >
                            Error last name
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6}>
                      <div style={{ marginTop: 0 }}>
                        <TextField
                          id='email'
                          type='email'
                          label='Email*'
                          fullWidth
                          variant='outlined'
                          onChange={handleChangeText('email')}
                          value={props.main.state.email}
                          style={{ marginTop: 30 }}
                        />
                        {props.main.state.errorEmail && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: 15,
                              marginLeft: 14,
                              fontFamily: "'Montserrat', sans-serif",
                              float: 'left',
                              marginTop: 8,
                            }}
                          >
                            Error email address
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6}>
                      <div
                        style={{
                          marginTop: 0,
                          marginLeft: mobile ? '0%' : '5%',
                        }}
                      >
                        <TextField
                          id='phone'
                          type='tel'
                          label='Phone Number*'
                          fullWidth
                          variant='outlined'
                          onChange={handleChangeText('phone')}
                          value={props.main.state.phone}
                          style={{ marginTop: 30 }}
                        />
                        {props.main.state.errorPhoneNumber && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: 15,
                              marginLeft: 14,
                              fontFamily: "'Montserrat', sans-serif",
                              float: 'left',
                              marginTop: 8,
                            }}
                          >
                            Error phone number
                          </div>
                        )}
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6} xl={6}>
                        <div style={{marginTop: 0, marginLeft: mobile ? '0%' : '5%'}}>
                          <TextField id="outlined-basic" label="Office Name" fullWidth variant="outlined" onChange={handleChangeText('officeName')} value={props.main.state.officeName} style={{marginTop: 30}}/>
                          {props.main.state.errorOfficeName && (
                            <div style={{color: 'red', fontSize: 15, marginLeft: 14,fontFamily: "'Montserrat', sans-serif", float: 'left', marginTop: 8}}>Error last name</div>
                          )}
                        </div>
                      </Grid> */}
                  </Grid>
                </div>
              )}
              {props.main.state.activeStep === 1 &&
                props.main.state.useNow === null && (
                  <div
                    style={{
                      marginTop: mobile ? '-20px' : '20px',
                      paddingLeft: mobile ? 10 : 0,
                      paddingRight: mobile ? 10 : 0,
                    }}
                  >
                    <Grid container spacing={4} dir='ltr'>
                      <Grid item xs={12} sm={8} md={6} xl={6}>
                        <Button
                          onClick={() => props.main.setState({ useNow: true })}
                          style={{
                            display: 'inline-block',
                            fontSize: 20,
                            width: mobile ? '100%' : '100%',
                            border: '1px solid #544cf8',
                            borderRadius: 20,
                            padding: 15,
                            backgroundColor: '#F7F7F7',
                            minHeight: 180,
                            borderColor: '#DAE2FD',
                          }}
                        >
                          <div>
                            <img
                              alt=''
                              height={80}
                              src={require('../../../../Assets/userOrderNow.jpeg')}
                            />
                          </div>
                          <div>אני רוצה לרכוש צילום ולממש עכשיו</div>
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={8} md={6} xl={6}>
                        <Button
                          onClick={() => props.main.setState({ useNow: false })}
                          style={{
                            display: 'inline-block',
                            fontSize: 20,
                            width: mobile ? '100%' : '100%',
                            border: '1px solid #544cf8',
                            borderRadius: 20,
                            padding: 15,
                            backgroundColor: '#F7F7F7',
                            minHeight: 180,
                            borderColor: '#DAE2FD',
                          }}
                        >
                          <div>
                            <img
                              alt=''
                              height={80}
                              src={require('../../../../Assets/userOrderLater.jpeg')}
                            />
                          </div>
                          <div>אני רוצה לרכוש צילום ולממש מאוחר יותר</div>
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )}
              {props.main.state.activeStep === 1 &&
                props.main.state.useNow !== null && (
                  <div
                    style={{
                      marginTop: mobile ? '-20px' : '20px',
                      paddingLeft: mobile ? 10 : 0,
                      paddingRight: mobile ? 10 : 0,
                    }}
                  >
                    {/* <Grid container spacing={8} dir="ltr" justify="center">
                  <Grid item xs={12} sm={6} md={6} xl={6}> */}
                    <Grid container spacing={1} dir='ltr'>
                      {props.main.state.useNow !== null &&
                        props.main.state.useNow && (
                          <Grid item xs={12} sm={12} md={6} xl={6}>
                            <div style={{ marginTop: 0, marginTop: 18 }}>
                              <Location main={props.main} />
                              {/* <Autocomplete
                          style={{width: 'calc(70% - 30px)', textAlign: 'left', direction: 'ltr'}}
                          onPlaceSelected={(place) => {
                              console.log(place)
                              console.log(place.geometry.location.lat())
                              console.log(place.geometry.location.lng())
                              getCoordinates(place.formatted_address)
                          }}
                          placeholder="Choose specific address"
                          className="OrderInput"
                          style={{display: 'inline-block', float: 'left', width: '100%'}}
                          types={['address']}
                          region='il'
                          id='location'
                          componentRestrictions={{country: "il"}}
                          /> */}
                            </div>
                            {props.main.state.errorAddress && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: 12,
                                  fontFamily: "'Montserrat', sans-serif",
                                  float: 'left',
                                  marginTop: 8,
                                  marginLeft: 12,
                                }}
                              >
                                Address needs to containe a specific address
                              </div>
                            )}
                          </Grid>
                        )}
                      {props.main.state.useNow !== null &&
                        props.main.state.useNow && (
                          <Grid item xs={12} sm={12} md={6} xl={6}>
                            <div style={{ marginTop: 18 }}>
                              <div style={{ marginTop: 18 }}>
                                <DatePicker
                                  renderExtraFooter={() => (
                                    <div style={{ height: 0 }}>
                                      <div
                                        style={{
                                          backgroundColor: 'white',
                                          width: '50%',
                                          position: 'relative',
                                          top: 10,
                                          height: 50,
                                        }}
                                      ></div>
                                    </div>
                                  )}
                                  minuteStep={15}
                                  // bordered={false}
                                  format={'DD/MM/YYYY HH:mm'}
                                  hideDisabledOptions={true}
                                  placeholder='Select time for photo shoot'
                                  onChange={onchangeDateTime}
                                  clearIcon={<div></div>}
                                  disabledDate={disabledDate}
                                  showToday={false}
                                  showNow={false}
                                  id='date-and-time'
                                  disabledHours={() => [0, 1, 2, 3, 4, 5]}
                                  style={{
                                    backgroundColor: '#ecf1fe',
                                    outline: 'none',
                                    fontSize: 20,
                                    width: '100%',
                                    paddingTop: 7,
                                    borderRadius: 28,
                                    height: 56,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    border: '1px solid #DAE2FD',
                                    'antd-input:hover:focus:select': {
                                      border: '1px solid #DAE2FD',
                                      outline: 'none',
                                    },
                                  }}
                                  showTime
                                />
                              </div>
                            </div>
                            {props.main.state.errorDate && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: 12,
                                  fontFamily: "'Montserrat', sans-serif",
                                  float: 'left',
                                  marginTop: 8,
                                  marginLeft: 12,
                                }}
                              >
                                Error date and time. Choose minutes from
                                selection
                              </div>
                            )}
                          </Grid>
                        )}
                      {props.main.state.useNow !== null &&
                        props.main.state.useNow && (
                          <Grid item xs={12} sm={12} md={12} xl={12}>
                            <div style={{ float: 'left', marginTop: 14 }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={props.main.state.willBeOnSite}
                                    onChange={handleChange('willBeOnSite')}
                                    value='willBeOnSite'
                                    color='primary'
                                  />
                                }
                                label='You will be on site?'
                              />
                            </div>
                          </Grid>
                        )}
                      {/* {alert(props.main.state.admin)} */}
                      {console.log(props.admin)}
                      {props.main.state.useNow !== null &&
                        props.main.state.useNow &&
                        props.main.state.admin && (
                          <Grid item xs={12} sm={12} md={12} xl={12}>
                            <div style={{ float: 'left', marginTop: 14 }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={props.main.state.startMatch}
                                    onChange={handleChange('startMatch')}
                                    value='startMatch'
                                    color='primary'
                                  />
                                }
                                label='Start matching?'
                              />
                            </div>
                          </Grid>
                        )}
                      {props.main.state.useNow !== null &&
                        props.main.state.useNow &&
                        props.isBusinessAccount && (
                          <Grid item xs={12} sm={12} md={12} xl={12}>
                            <div style={{ float: 'left', marginTop: 14 }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={props.main.state.connectToBusiness}
                                    onChange={handleChange('connectToBusiness')}
                                    value='connectToBusiness'
                                    color='primary'
                                  />
                                }
                                label='Connect request to my business'
                              />
                            </div>
                          </Grid>
                        )}
                      {props.main.state.useNow !== null &&
                        props.main.state.useNow &&
                        !props.main.state.willBeOnSite && (
                          <Grid item xs={12} sm={12} md={6} xl={6}>
                            <div style={{ marginTop: 20 }}>
                              <TextField
                                id='contact-on-site'
                                className={classes.root}
                                label='Contact on site'
                                fullWidth
                                variant='outlined'
                                onChange={handleChangeText(
                                  'whoWillBeOnSiteName'
                                )}
                                value={props.main.state.whoWillBeOnSiteName}
                              />
                            </div>
                            {props.main.state.errorWillBeOnSiteName && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: 12,
                                  marginLeft: 12,
                                  fontFamily: "'Montserrat', sans-serif",
                                  float: 'left',
                                  marginTop: 8,
                                }}
                              >
                                Error name
                              </div>
                            )}
                          </Grid>
                        )}
                      {props.main.state.useNow !== null &&
                        props.main.state.useNow &&
                        !props.main.state.willBeOnSite && (
                          <Grid item xs={12} sm={6} md={6} xl={6}>
                            <div
                              style={{
                                marginTop: 20,
                                marginLeft: mobile ? '0%' : '5%',
                              }}
                            >
                              <TextField
                                id='contact-phone-number-on-site'
                                className={classes.root}
                                label='Phone Number'
                                fullWidth
                                variant='outlined'
                                onChange={handleChangeText(
                                  'whoWillBeOnSitePhone'
                                )}
                                value={props.main.state.whoWillBeOnSitePhone}
                              />
                            </div>
                            {props.main.state.errorWillBeOnSitePhone && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: 12,
                                  marginLeft: 12,
                                  fontFamily: "'Montserrat', sans-serif",
                                  float: 'left',
                                  marginTop: 8,
                                }}
                              >
                                Error phone
                              </div>
                            )}
                          </Grid>
                        )}
                      {props.main.state.useNow !== null &&
                        props.main.state.useNow && (
                          <Grid
                            container
                            spacing={1}
                            dir='ltr'
                            justify='center'
                          >
                            {props.main.state.openPackages.length !== 0 &&
                              props.main.state.searchForOpenPackages ===
                                false && (
                                <Grid item xs={12} sm={12} md={12} xl={12}>
                                  <div
                                    style={{
                                      textAlign: 'left',
                                      marginBottom: 5,
                                      marginTop: 5,
                                      color: 'rgb(129,139,166)',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Use From Package
                                  </div>
                                </Grid>
                              )}
                            {props.main.state.searchForOpenPackages ? (
                              <div style={{ marginTop: 12 }}>
                                <center>
                                  <div>
                                    <CircularProgress />
                                  </div>
                                  <div style={{ marginTop: 8 }}>
                                    Searching for open packages...
                                  </div>
                                </center>
                              </div>
                            ) : (
                              <Grid item xs={12} sm={12} md={12} xl={12}>
                                {props.main.state.useNow !== null &&
                                  props.main.state.useNow && (
                                    <Grid container spacing={1} dir='ltr'>
                                      {props.main.state.openPackages.map(
                                        (item, i) => (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            xl={4}
                                            lg={4}
                                          >
                                            <div
                                              style={{
                                                display: 'inline-block',
                                                width: mobile ? '100%' : '100%',
                                                border: '1px solid #544cf8',
                                                borderRadius: 20,
                                                padding: 15,
                                                backgroundColor: '#ecf1fe',
                                                borderColor: '#DAE2FD',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  textAlign: 'center',
                                                  width: '100%',
                                                  marginTop: mobile ? 15 : 0,
                                                  borderRadius: 15,
                                                  fontSize: 18,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginTop: 15,
                                                    fontSize: 20,
                                                  }}
                                                >
                                                  <b>
                                                    {getProductData(
                                                      item.singleProductId,
                                                      'Public_Product_Name__c'
                                                    )}
                                                  </b>
                                                </div>
                                                <div
                                                  style={{
                                                    marginTop: 0,
                                                    fontWeight: 'bold',
                                                  }}
                                                >
                                                  {getProductData(
                                                    item.singleProductId,
                                                    'Number_of_Images_for_Customer__c'
                                                  )}{' '}
                                                  Images
                                                </div>
                                                {props.main.props.admin && (
                                                  <div style={{ marginTop: 5 }}>
                                                    {getProductData(
                                                      item.singleProductId,
                                                      'Number_of_Images_for_Customer__c'
                                                    )}{' '}
                                                    Images
                                                  </div>
                                                )}
                                              </div>
                                              <div
                                                style={{
                                                  marginTop: 10,
                                                  fontSize: 18,
                                                }}
                                              >
                                                {
                                                  // <b>{item.Total_Cost__c} ILS + VAT</b>
                                                }
                                                <div>
                                                  <b>Balance: {item.balance}</b>
                                                </div>
                                                <div>
                                                  <b>Already Paid</b>
                                                </div>
                                              </div>
                                              <div>
                                                {props.main.state
                                                  .selectedPackage == item ? (
                                                  <button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={handleNext}
                                                    className={
                                                      'selectedPackageButton'
                                                    }
                                                  >
                                                    SELECTED
                                                  </button>
                                                ) : (
                                                  <button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={handleNext}
                                                    id='choose-product'
                                                    className={
                                                      'selectPackageButton'
                                                    }
                                                    onClick={() =>
                                                      props.main.setState({
                                                        selectedProduct:
                                                          item.singleProductId,
                                                        selectedProductData:
                                                          getProductData(
                                                            item.singleProductId,
                                                            'all'
                                                          ),
                                                        isPackage: true,
                                                        selectedPackage: item,
                                                      })
                                                    }
                                                  >
                                                    CHOOSE
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  )}
                              </Grid>
                            )}
                          </Grid>
                        )}
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div
                          style={{
                            textAlign: 'left',
                            marginBottom: 5,
                            marginTop: 15,
                            color: '#6C65FF',
                            fontWeight: 'bold',
                          }}
                        >
                          Choose Product
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <Paper
                          className={classes.root}
                          elevation={0}
                          style={{ marginTop: 0, marginBottom: 30 }}
                        >
                          <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            indicatorColor='primary'
                            textColor='primary'
                            centered
                          >
                            <Tab label='Products' />
                            <Tab label='Packages' />
                          </Tabs>
                        </Paper>
                      </Grid>
                      {value === 0 &&
                        props.main.state.products.map((item, i) =>
                          item.Product_Type__c === 'Single' ? (
                            <Grid item xs={12} sm={6} md={6} xl={4} lg={4}>
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: mobile ? '100%' : '100%',
                                  border: '1px solid #544cf8',
                                  borderRadius: 20,
                                  padding: 15,
                                  backgroundColor: '#ecf1fe',
                                  borderColor: '#DAE2FD',
                                }}
                              >
                                <div
                                  style={{
                                    textAlign: 'left',
                                    width: '100%',
                                    marginTop: mobile ? 15 : 0,
                                    borderRadius: 15,
                                    fontSize: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      marginTop: 20,
                                      fontSize: 20,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <b>{item.Public_Product_Name__c}</b>
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 4,
                                      fontSize: 12,
                                      fontWeight: 200,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <b>{item.Shooting_Category__c}</b>
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 20,
                                      textAlign: 'center',
                                      fontWeight: 700,
                                    }}
                                  >
                                    {item.Number_of_Images_for_Customer__c}{' '}
                                    Images
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 4,
                                      fontSize: 12,
                                      fontWeight: 200,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <b>Up to {item.Shooting_Duration__c} min</b>
                                  </div>
                                  {props.main.props.admin && (
                                    <div
                                      style={{
                                        marginTop: 30,
                                        textAlign: 'center',
                                      }}
                                    >
                                      {item.Number_of_Images_for_Customer__c}{' '}
                                      Images
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{
                                    marginTop: 30,
                                    fontSize: 18,
                                    textAlign: 'center',
                                  }}
                                >
                                  <b>{item.Total_Cost__c} ILS + VAT</b>
                                </div>
                                {item.Discount__c !== null && (
                                  <div
                                    style={{
                                      marginTop: 4,
                                      fontSize: 14,
                                      fontWeight: 'bold',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <b>Discount of {item.Discount__c} ILS</b>
                                  </div>
                                )}
                                <div>
                                  {props.main.state.selectedProduct ==
                                  item.Id ? (
                                    <button
                                      variant='contained'
                                      color='primary'
                                      onClick={handleNext}
                                      className={'selectedPackageButton'}
                                    >
                                      SELECTED
                                    </button>
                                  ) : (
                                    <button
                                      variant='contained'
                                      color='primary'
                                      onClick={handleNext}
                                      className={'selectPackageButton'}
                                      onClick={() =>
                                        props.main.setState({
                                          selectedProduct: item.Id,
                                          selectedProductData: item,
                                        })
                                      }
                                    >
                                      CHOOSE
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Grid>
                          ) : (
                            <div></div>
                          )
                        )}
                      {value === 1 &&
                        props.main.state.products.map((item, i) =>
                          item.Product_Type__c !== 'Single' ? (
                            <Grid item xs={12} sm={6} md={6} xl={4} lg={4}>
                              <div
                                style={{
                                  display: 'inline-block',
                                  width: mobile ? '100%' : '100%',
                                  border: '1px solid #544cf8',
                                  borderRadius: 20,
                                  padding: 15,
                                  backgroundColor: '#ecf1fe',
                                  borderColor: '#DAE2FD',
                                }}
                              >
                                <div
                                  style={{
                                    textAlign: 'left',
                                    width: '100%',
                                    marginTop: mobile ? 15 : 0,
                                    borderRadius: 15,
                                    fontSize: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      marginTop: 20,
                                      fontSize: 20,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <b>{item.Public_Product_Name__c}</b>
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 4,
                                      fontSize: 12,
                                      fontWeight: 200,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <b>{item.Shooting_Category__c}</b>
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 20,
                                      textAlign: 'center',
                                      fontWeight: 700,
                                    }}
                                  >
                                    {item.Quantity__c} Photo Shoots
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 4,
                                      textAlign: 'center',
                                      fontWeight: 700,
                                    }}
                                  >
                                    Of {item.Number_of_Images_for_Customer__c}{' '}
                                    Images
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 4,
                                      fontSize: 12,
                                      fontWeight: 200,
                                      textAlign: 'center',
                                    }}
                                  >
                                    <b>Up to {item.Shooting_Duration__c} min</b>
                                  </div>
                                  {props.main.props.admin && (
                                    <div
                                      style={{
                                        marginTop: 30,
                                        textAlign: 'center',
                                      }}
                                    >
                                      {item.Number_of_Images_for_Customer__c}{' '}
                                      Images
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{
                                    marginTop: 30,
                                    fontSize: 18,
                                    textAlign: 'center',
                                  }}
                                >
                                  <b>{item.Total_Cost__c} ILS + VAT</b>
                                </div>
                                {item.Discount__c !== null && (
                                  <div
                                    style={{
                                      marginTop: 4,
                                      fontSize: 14,
                                      fontWeight: 'bold',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <b>Discount of {item.Discount__c} ILS</b>
                                  </div>
                                )}
                                {/* {alert(item.Package_Total_Cost__c)} */}
                                <div>
                                  {props.main.state.selectedProduct ==
                                  item.Id ? (
                                    <button
                                      variant='contained'
                                      color='primary'
                                      onClick={handleNext}
                                      className={'selectedPackageButton'}
                                    >
                                      SELECTED
                                    </button>
                                  ) : (
                                    <button
                                      variant='contained'
                                      color='primary'
                                      onClick={handleNext}
                                      className={'selectPackageButton'}
                                      onClick={() =>
                                        props.main.setState({
                                          selectedProduct: item.Id,
                                          selectedProductData: item,
                                        })
                                      }
                                    >
                                      CHOOSE
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Grid>
                          ) : (
                            <div></div>
                          )
                        )}
                    </Grid>
                    {props.main.state.errorSelectProduct && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: 15,
                          fontFamily: "'Montserrat', sans-serif",
                          float: 'left',
                          marginTop: 8,
                        }}
                      >
                        Select a product
                      </div>
                    )}
                    {/* </Grid> */}
                    {/* </Grid> */}
                    {!mobile && (
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <div
                          style={{
                            width: '90%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            borderRadius: 30,
                            height: 'calc(100% - 5px)',
                            position: 'relative',
                            marginTop: 5,
                          }}
                        >
                          <Map
                            google={window.google}
                            lat={props.main.state.lat}
                            lng={props.main.state.lng}
                          />
                        </div>
                      </Grid>
                    )}
                    {/* </Grid> */}
                  </div>
                )}
            </Typography>
            <br />
            <br />
            <Grid
              container
              spacing={24}
              dir='ltr'
              justify='center'
              style={{ marginTop: mobile ? -15 : 0, marginBottom: 35 }}
            >
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <Button
                  disabled={
                    props.main.state.activeStep === 0 ||
                    (props.main.state.activeStep === 2 &&
                      props.main.state.selectedProductData.Product_Type__c ===
                        'Package')
                  }
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
              </Grid>
              {props.main.state.activeStep != 2 &&
              !(
                props.main.state.useNow === null &&
                props.main.state.activeStep === 1
              ) ? (
                <Grid item xs={6} sm={6} md={6} xl={6}>
                  <Button
                    variant='contained'
                    color='primary'
                    id={'active-step-' + (props.main.state.activeStep + 1)}
                    onClick={() => props.main.nextStep()}
                    className={classes.button}
                  >
                    {props.main.state.activeStep === steps.length
                      ? 'Finish'
                      : 'Next'}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={6} sm={6} md={6} xl={6}></Grid>
              )}
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}
