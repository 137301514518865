import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import AppBar from './AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@material-ui/core';
import SadFace from '@material-ui/icons/SentimentVeryDissatisfied';

const history = createBrowserHistory();

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#FFF',
    // background: 'linear-gradient(90deg, #6C65FF 50%, #FFF 50%)',
    position: 'fixed',
    top: 0,
    left: 0,
  },
});

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

class Template extends React.Component {
  state = {
    approving: false,
    rejecting: false,
    rejected: false,
    approved: false,
    loading: true,
    status: '',
    groupName: '',
  };

  componentDidMount() {
    console.log(this.props.match.params.id);
    this.getContact(this.props.match.params.id);
  }

  getContact = async (id) => {
    var data = { id: id };
    console.log(data);
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/contact/get-one',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        var contact = response.data[0];
        var accountId = contact.AccountId;
        this.setState({ id: accountId });
        this.getRequestData(accountId);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  getRequestData = (id) => {
    var data = { id: id };
    console.log(data);
    // axios.post('https://wesnapp-node-server.appspot.com' + '/api/accountcontactrelation/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/account/get-one',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);

        var account = response.data[0];
        var status = account.Unsubscribe_Marketing_Emails__c;
        this.setState({ status: status, loading: false });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  unsubscribe = () => {
    this.setState({ loading: true });
    var data = {
      accountId: this.state.id,
      data: { Unsubscribe_Marketing_Emails__c: true },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/update-account',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        this.setState({ loading: false, status: true });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <AppBar />
        <Grid
          container
          spacing={1}
          style={{ padding: 20, paddingTop: 40 }}
          justifyContent='center'
          justify='center'
        >
          <Grid item xs={12} sm={12} md={7} xl={7}>
            <SadFace
              style={{
                width: 80,
                height: 80,
                marginTop: 'calc(50vh - 50px - 210px)',
                color: '#FF3366',
              }}
            />
            <div
              style={{
                width: '100%',
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              We're sad to see you go!
            </div>
            <div
              style={{
                width: '100%',
                fontSize: 18,
                marginTop: 20,
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              If you don't want to receive any more sales or gifts from us
              please unsubscribe by clicking here:
            </div>
          </Grid>
          {/* <div style={{position: 'fixed', bottom: 20, width: '100%'}}> */}
          {/* <Grid container spacing={1} style={{padding: 20, paddingTop: 40}} justifyContent="center" justify="center"> */}
          {!this.state.loading && !this.state.status && (
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Button
                onClick={() => this.unsubscribe()}
                style={{
                  width: '100%',
                  maxWidth: 400,
                  height: 50,
                  marginTop: 30,
                  float: 'left',
                  borderRadius: 8,
                  backgroundColor: '#FF3366',
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Unsubscribe
              </Button>
            </Grid>
          )}
          {!this.state.loading && this.state.status && (
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Button
                style={{
                  width: '100%',
                  maxWidth: 400,
                  height: 50,
                  marginTop: 30,
                  float: 'left',
                  borderRadius: 8,
                  backgroundColor: 'none',
                  color: '#23CE6B',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Your change have been noted.
              </Button>
            </Grid>
          )}
          {this.state.loading && (
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <CircularProgress
                style={{
                  width: 40,
                  height: 40,
                  marginTop: 5,
                  color: '#FF3366',
                  marginTop: 35,
                }}
              />
            </Grid>
          )}
          {/* </Grid> */}
          {/* </div> */}
        </Grid>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
