import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import { withSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/ShoppingCart';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Lightbox from 'react-image-lightbox';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Map from '../Map';
import SendByEmailDialog from './SendByEmailDialog.js';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import PersonIcon from '@material-ui/icons/Person';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CalendarTodayIcon from '@material-ui/icons/DateRange';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import EmailDialog from './EmailDialog';
import axios from 'axios';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const zoomInIcon = require('../../../../Assets/zoomin_icon.png');
const black_logo = require('../../../../Assets/black_logo.png');
const drawerWidth = 380;
const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;
const GET_EDITED_ORGENIZED_IMAGES =
  'https://py.wesnapp.co.il/server/get-edited-images-orgenized/';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'rgb(255,255,255)',
  },
  formControl: {
    margin: theme.spacing(3),
    textAlign: 'left',
    float: 'left',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'clear',
  },
  appBarShift: {
    width: mobile ? '0px' : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  text: {
    color: 'rgb(40,40,53)',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    borderRadius: 0,
    zIndex: 9999,
    borderColor: 'rgb(246,247,251)',
    // backgroundColor: 'white'
  },
  drawerPaper: {
    width: mobile ? '100vw' : drawerWidth,
    height: mobile ? 'calc(100vh - 0px)' : '100vh',
    borderColor: 'rgb(246,247,251)',
    borderRadius: !mobile ? '35px 0px 0px 35px' : '35px 35px 0px 0px',
    backgroundColor: 'rgb(238,242,249)',
  },
  drawerHeader: {
    display: 'flex',
    // alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    borderColor: 'rgb(246,247,251)',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
});

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 20,
    backgroundColor: 'rgb(249,250,252)',
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#04e259',
  },
})(LinearProgress);

// export default function PersistentDrawerRight() {

var lng = 0;
var lat = 0;

class ShootingPage extends React.Component {
  constructor(props) {
    super(props);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log("!@#!@#!@#");
  //   console.log(nextProps);
  //   console.log(nextState);
  //     if (this.state == null)
  //       return true;
  //
  //     if (this.state.open != nextState.open)
  //       return false;
  //
  //     return true;
  // }

  state = {
    prepearingImages: false,
    paymentWithProduct: false,
    amountToCharge: 0,
    extraImagePrice: 0,
    extraImageDiscount: null,
    updateInProgress: false,
    requestPaid: null,
    loading: false,
    requestData: null,
    loadingData: true,
    open: mobile ? false : true,
    requestId: '',
    contactName: '',
    shootingLocation: '',
    dateAndTime: '',
    productName: '',
    firstSelection: false,
    avatar: '',
    mobilePhone: '',
    userComment: '',
    photographerName: '',
    numberOfImages: 0,
    downloadPercenteges: 0,
    confirmSelect: false,
    selectedImages: [],
    pricePerExtra: [],
    galleryIsOpen: false,
    galleryIndex: 0,
    openEmailDialog: false,
    imageHash: Date.now(),
    openConfirmDialog: false,
    openPaymentDialog: false,
    addingImagesToSalesforce: false,
    openReviewDialog: false,
    addingComment: false,
    openSnack: false,
    photographerRate: 0,
    photosRate: 0,
    imagesInPackage: 0,
    confirmSelect: false,
    selectedImages: [],
    selectedImagesString: '',
    avatar: '',
    selectedInThePast: [],
    imagesForGallery: [],
    openPaymentDialog: false,
    openConfirmDialog: false,
    addingImagesToSalesforce: false,
    editedImages: {
      thumbnail: [],
      originals: [],
      compressed: [],
      watermarked: [],
      loading: true,
      imagesForGallery: [],
    },
    imagesToPayFor: '',
    // lat: 32.146611,
    // lng: 34.8519761
    lat: 0,
    lng: 0,

    emailType: '',

    freeImages: null,

    openEmailInputDialog: false,

    sideMenuTab: 0,
    uploadingWatermark: false,

    extraImagesData: '',

    logo_preferences: {
      opacity: 100,
      logoPosition: 'top_right',
      url: '',
      logoSize: 66,
    },
    productId: '',
    coupon: '',
    checkingCoupon: '',
    couponIsValid: false,
    appliedCoupon: false,
    canApply: false,
    errorCoupon: false,
    errorCouponText: '',
    couponType: '',
    couponDiscount: '',
  };

  handleChangeLogoPosition = (event) => {
    this.setState(
      {
        logo_preferences: {
          opacity: this.state.logo_preferences.opacity,
          logoSize: this.state.logo_preferences.logoSize,
          logoPosition: event.target.value,
          url: this.state.logo_preferences.url,
        },
      },
      () => {
        this.updateLogoPreferences();
      }
    );
  };

  //  handleChangeOpacity = value => {
  //   this.setState({opacity: value})
  // };

  handleChangeOpacity = (event, newValue) => {
    this.setState(
      {
        logo_preferences: {
          opacity: newValue,
          logoSize: this.state.logo_preferences.logoSize,
          logoPosition: this.state.logo_preferences.logoPosition,
          url: this.state.logo_preferences.url,
        },
      },
      () => {
        this.updateLogoPreferences();
      }
    );
  };

  handleChangeSize = (event, newValue) => {
    this.setState(
      {
        logo_preferences: {
          opacity: this.state.logo_preferences.opacity,
          logoSize: newValue,
          logoPosition: this.state.logo_preferences.logoPosition,
          url: this.state.logo_preferences.url,
        },
      },
      () => {
        this.updateLogoPreferences();
      }
    );
  };

  handleChangeSideMenuTab = (event, newValue) => {
    // setValue(newValue);
    this.setState({ sideMenuTab: newValue });
  };

  addReview = () => {
    this.setState({ addingComment: true });

    var data = {
      requestId: this.state.requestId,
      data: { Comment_user__c: this.state.userComment },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/request/update-request',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        this.setState({ openSnack: true, addingComment: false });
        this.handleCloseReviewDialog();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getLinkAfterReplacement = (link) => {
    link = link.replace('_reduced_watermarked', '');
    link = link.replace('watermarked/', '');
    return link;
  };

  setRatingForPhotographer = (value) => {
    this.setState({ photographerRate: value });
    var data = {
      requestId: this.state.requestId,
      data: { Rating_Photographer__c: value },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/request/update-request',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setRatingForPhotos = (value) => {
    this.setState({ photosRate: value });
    var data = {
      requestId: this.state.requestId,
      data: { Rating_Shooting__c: value },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/request/update-request',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleOpenPaymentDialog = () => {
    this.setState({ openPaymentDialog: true });
  };

  handleCloseReviewDialog = () => {
    this.setState({ openReviewDialog: false });
  };

  handleCloseConfirmDialog = () => {
    this.setState({ openConfirmDialog: false });
  };

  handleClosePaymentDialog = () => {
    this.getDataOfRequest();
    this.setState({ openConfirmDialog: false });
    this.setState({ openPaymentDialog: false });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.getDataOfRequest();
  }

  getParameterByName = (name) => {
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  getDateAndTime = async (dateAndTime) => {
    var dateObj = new Date(dateAndTime);
    var stringToReturn = '';
    stringToReturn += dateObj.getDate() + '/';
    stringToReturn += dateObj.getMonth() + 1 + '/';
    stringToReturn += dateObj.getFullYear() + ' ';
    var hour = dateObj.getHours();
    if (String(hour).length < 2) {
      hour = '0' + hour;
    }
    var min = dateObj.getMinutes();
    if (String(min).length < 2) {
      min = '0' + min;
    }
    stringToReturn += hour + ':';
    stringToReturn += min;
    this.setState({ dateAndTime: stringToReturn });
  };

  getCoordinates = async (address) => {
    try {
      var link =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        encodeURI(address) +
        '&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s';
      const response = await axios.get(link);
      console.log(response.data.results[0].geometry.location);
      // this.setState({lat: response.data.results[0].geometry.location.lat, lng: response.data.results[0].geometry.location.lng})
      lat = response.data.results[0].geometry.location.lat;
      lng = response.data.results[0].geometry.location.lng;
    } catch (err) {
      console.error(err);
    }
  };

  getDataOfRequest = async () => {
    try {
      var id = this.getParameterByName('id');
      if (id != null && id != undefined && id != '') {
        this.setState({ requestId: id });
        var data = { id: id };
        const response = await axios.post(
          'https://wesnapp-node-server.appspot.com/api/request/get-full-data',
          data
        );
        console.log('@@#!@#');
        console.log(response.data);
        if (!response.data.request.hasOwnProperty('Name')) {
          return 'error';
        }
        var dateAndTime = await this.getDateAndTime(
          response.data.request.Shooting_Date_Time__c
        );
        this.setState({ loadingData: false });
        this.getCoordinates(
          response.data.request.ShootingStreet__c +
            ' ' +
            response.data.request.ShootingCity__c
        );
        var cn = response.data.contact.Name;
        var avatar = '';
        if (cn.split(' ').length == 2) {
          avatar = cn.split(' ')[0][0] + cn.split(' ')[1][0];
          console.log(cn);
          console.log(cn.split(' ')[0]);
          console.log(cn.split(' ')[0][0]);
          console.log(cn.split(' ')[1][0]);
          console.log(avatar);
        }
        this.setState({ avatar: avatar });
        var data = { id: response.data.contact.AccountId };
        const account_response = await axios.post(
          'https://wesnapp-node-server.appspot.com/api/account/get-one-account',
          data
        );
        console.log('!@#');
        // console.log(account_response.data[0].Logo_Preference__c);
        var logo_pre = JSON.parse(account_response.data[0].Logo_Preference__c);
        if (logo_pre != null && logo_pre != '') {
          this.setState({ logo_preferences: logo_pre });
        }
        var extraImagePrice = response.data.product.Price_Per_Extra_Product__c;
        var extraImageDiscount =
          account_response.data[0].Extra_Product_Discount__c;
        if (extraImageDiscount != null) {
          console.log('discount');
          var old = response.data.product.Price_Per_Extra_Product__c;
          var discount = 100 - extraImageDiscount;
          console.log('old price', old);
          var extraImagePrice = (old * discount) / 100;
          console.log(extraImagePrice);
        }
        var requestPaid = response.data.request.Paid__c;
        // alert(response.data.request.Order_Record_Type__c)
        // console.log(response.data.request)
        if (
          response.data.request.Order_Record_Type__c === '0121t0000015F5WAAU'
        ) {
          requestPaid = true;
        }
        this.setState(
          {
            requestData: response.data,
            extraImagePrice: extraImagePrice,
            photographerName: response.data.request.Photographer_Name__c,
            requestName: response.data.request.Name,
            contactName: response.data.contact.Name,
            productId: response.data.product.Id,
            accountId: response.data.contact.AccountId,
            requestPaid: requestPaid,
            // requestPaid: false,
            extraImageDiscount:
              account_response.data[0].Extra_Product_Discount__c,
            numberOfImages:
              response.data.request.Number_of_Images_for_Customer__c,
            freeImages: response.data.request.Number__c,
            mobilePhone: response.data.contact.MobilePhone,
            shootingLocation:
              response.data.request.ShootingStreet__c +
              ' ' +
              response.data.request.ShootingCity__c,
            productName: response.data.product.Public_Product_Name__c,
          },
          () => {
            this.setState({
              imagesInPackage:
                response.data.request.Number_of_Images_for_Customer__c,
            });
            var selectedImages = [];
            var selectedInThePastString = [];
            var selectedInThePast = [];
            if (response.data.request.Rating_Shooting__c != null) {
              this.setState({
                photosRate: response.data.request.Rating_Shooting__c,
              });
            }
            if (response.data.request.Rating_Photographer__c != null) {
              this.setState({
                photographerRate: response.data.request.Rating_Photographer__c,
              });
            }
            if (response.data.request.Comment_user__c != null) {
              this.setState({
                userComment: response.data.request.Comment_user__c,
              });
            }
            if (response.data.request.Selected_Images__c != null) {
              selectedInThePastString =
                response.data.request.Selected_Images__c.replace(
                  new RegExp('<p>', 'g'),
                  ''
                )
                  .replace(new RegExp('</p>', 'g'), '')
                  .replace(new RegExp('<br>', 'g'), '');
              console.log(selectedInThePastString);
              selectedInThePast = selectedInThePastString.split(',');
              selectedImages = selectedInThePastString.split(',');
              if (selectedImages.length == 0) {
                this.setState({ firstSelection: true });
              }
              this.setState({ selected: selectedImages.length });
            }
            // pricePerExtra: response.data.product.Price_Per_Extra_Product__c
            this.setState(
              {
                selectedImages: selectedImages,
                selectedInThePast: selectedInThePast,
                confirmSelect: false,
              },
              () => {
                this.getEditedImages();
              }
            );
          }
        );
      } else {
        this.setState({ loading: false });
        alert('Shooting ID is not valid.');
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  async getEditedImages() {
    console.log('DOWNLOADING EDITED');
    const response = await axios.get(
      GET_EDITED_ORGENIZED_IMAGES + this.state.requestId + '-edited',
      {},
      { headers: { 'Content-Type': 'application/json' } }
    );
    console.log(response.data);
    var data = response.data;
    var imagesForGallery = [];
    console.log(this.state.selectedInThePast);
    var counter = 0;
    for (var i = 0; i < data.watermarked.length; i++) {
      // console.log(this.state.selectedInThePast);
      console.log(data.originals[i]);
      if (this.state.selectedInThePast.includes(data.originals[i])) {
        console.log('yes');
        counter += 1;
        console.log(counter);
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth: 320,
          thumbnailHeight: 174,
          isSelected: false,
          caption: 'Image ' + String(i),
        });
      } else {
        console.log('no');
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth: 320,
          thumbnailHeight: 174,
          isSelected: false,
          caption: 'Image ' + String(i),
        });
      }
    }
    data['loading'] = false;
    data['imagesForGallery'] = imagesForGallery;
    this.setState({
      addingImagesToSalesforce: false,
      openConfirmDialog: false,
    });
    this.setState({
      editedImages: data,
      addingImagesToSalesforce: false,
      openConfirmDialog: false,
    });
    return '';
  }

  onChangeLogo = (e) => {
    this.setState({
      file: e.target.files[0],
      uploadingWatermark: true,
      updateInProgress: true,
    });
    console.log(e.target.files[0]);
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    // https://py.wesnapp.co.il/server/upload/
    axios
      .post(
        'https://py.wesnapp.co.il/server/uploadwatermark/' +
          this.state.requestData.contact.AccountId,
        formData,
        config
      )
      .then((response) => {
        this.setState({ uploadingWatermark: false }, () => {
          console.log('!@#!@#');
          console.log(response.data.imageUrl);

          this.setState(
            {
              logo_preferences: {
                opacity: this.state.logo_preferences.opacity,
                logoSize: this.state.logo_preferences.logoSize,
                logoPosition: this.state.logo_preferences.logoPosition,
                url: response.data.imageUrl,
              },
            },
            () => {
              console.log(this.state.logo_preferences.url);
              this.updateLogoPreferences();
            }
          );
          this.handleSuccess();
        });
      })
      .catch((error) => {});
  };

  updateLogoPreferences = () => {
    var formData = {
      data: {
        Logo_Preference__c:
          '{"url": "' +
          this.state.logo_preferences.url +
          '", "logoSize": ' +
          this.state.logo_preferences.logoSize +
          ', "logoPosition": "' +
          this.state.logo_preferences.logoPosition +
          '", "opacity": ' +
          this.state.logo_preferences.opacity +
          '}',
      },
      accountId: this.state.requestData.contact.AccountId,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/account/update-account',
        formData,
        {}
      )
      .then((response) => {
        console.log(response.data);
        console.log(this.state.logo_preferences.url);
        this.setState({ updateInProgress: false, imageHash: Date.now() });
      })
      .catch((error) => {});
  };

  getImagesInPackage = () => {
    var imagesInPackage = this.state.imagesInPackage;
    if (this.state.freeImages != null) {
      var imagesInPackage = this.state.imagesInPackage + this.state.freeImages;
    }
    return imagesInPackage;
  };

  confirmSelection = () => {
    var images = this.state.selectedImages;
    var imagesString = '';
    var amountToCharge = 0;

    if (images.length >= 1) {
      imagesString = images[0]
        .replace('watermarked/', '')
        .replace('_reduced_watermarked', '');
      if (images.length > 1) {
        for (var i = 1; i < images.length; i++) {
          imagesString +=
            ',' +
            images[i]
              .replace('watermarked/', '')
              .replace('_reduced_watermarked', '');
        }
      }
    }

    var paymentWithProduct = false;

    this.setState({ selectedImagesString: imagesString }, () => {
      var imagesInPackage = this.state.imagesInPackage;
      if (this.state.freeImages != null) {
        var imagesInPackage =
          this.state.imagesInPackage + this.state.freeImages;
      }
      if (
        this.state.selectedImages.length > imagesInPackage ||
        this.state.requestPaid == false
      ) {
        var imagesToPayFor = '';
        if (this.state.selectedImages.length > imagesInPackage) {
          var counter = this.state.selectedImages.length - imagesInPackage;
          for (var i = 0; i < this.state.selectedImages.length; i++) {
            if (
              !this.state.selectedInThePast.includes(
                this.state.selectedImages[i]
                  .replace('watermarked/', '')
                  .replace('_reduced_watermarked', '')
              )
            ) {
              if (counter != 0) {
                if (imagesToPayFor == '') {
                  imagesToPayFor = this.state.selectedImages[i]
                    .replace('watermarked/', '')
                    .replace('_reduced_watermarked', '');
                } else {
                  var imageString = this.state.selectedImages[i]
                    .replace('watermarked/', '')
                    .replace('_reduced_watermarked', '');
                  imagesToPayFor = imagesToPayFor + ',' + imageString;
                }
                counter -= 1;
              }
            }
          }
          amountToCharge +=
            imagesToPayFor.split(',').length * this.state.extraImagePrice;
          console.log(
            String(imagesToPayFor.split(',').length) +
              '|' +
              String(this.state.extraImagePrice) +
              '|' +
              String(this.state.requestData.product.Price_Per_Extra_Product__c)
          );
          this.setState(
            {
              extraImagesData:
                String(imagesToPayFor.split(',').length) +
                '-' +
                String(this.state.extraImagePrice) +
                '-' +
                String(
                  this.state.requestData.product.Price_Per_Extra_Product__c
                ),
            },
            () => {
              console.log(this.state.extraImagesData);
            }
          );
        }

        console.log('requestPaid', this.state.requestPaid);
        if (this.state.requestPaid == false) {
          paymentWithProduct = true;
          amountToCharge += this.state.requestData.request.Total_Cost__c;
        }

        if (this.getSelectedImagesToPayFor() === 0 && this.state.requestPaid) {
          this.setState({ selectedImagesString: imagesString }, () => {
            this.setState({ openConfirmDialog: true });
          });
        } else {
          this.setState(
            {
              imagesToPayFor: imagesToPayFor,
              amountToCharge: amountToCharge,
              paymentWithProduct: paymentWithProduct,
            },
            () => {
              this.setState({ openPaymentDialog: true });
            }
          );
        }
      } else {
        this.setState({ selectedImagesString: imagesString }, () => {
          this.setState({ openConfirmDialog: true });
        });
      }
    });
  };

  onSelectImage = (index) => {
    // console.log("ALREADY SELECTED");
    // console.log(this.state.selectedInThePast);
    // console.log("@@@@@@@@@@@@@@@@@");
    var inThePast = this.state.selectedInThePast;
    var inThePastSave = this.state.selectedInThePast;

    var images = this.state.editedImages;
    var img = images.imagesForGallery[index];

    var selectedImages = this.state.selectedImages;

    // console.log(this.state.editedImages.imagesForGallery[index]);

    if (!inThePast.includes(this.removeDraft(img.src))) {
      // console.log("in the past1");
      // console.log(inThePastSave);
      if (img.hasOwnProperty('isSelected')) {
        if (
          !inThePast.includes(
            this.removeDraft(images.imagesForGallery[index].src)
          )
        ) {
          // console.log("in the past2");
          // console.log(inThePastSave);
          images.imagesForGallery[index].isSelected = !img.isSelected;
          if (img.isSelected == true) {
            // console.log("in the past3");
            // console.log(inThePastSave);
            selectedImages.push(
              this.removeDraft(
                this.state.editedImages.imagesForGallery[index].src
              )
            );
            // console.log("in the past4");
            // console.log(inThePastSave);
          } else {
            var index = selectedImages.indexOf(
              this.removeDraft(images.imagesForGallery[index].src)
            );
            if (index > -1) {
              selectedImages.splice(index, 1);
            }
          }
          // console.log("selected images");
          // console.log(selectedImages);
          // console.log("in the past1");
          // console.log(this.state.selectedInThePast);
          // console.log("in the past2");
          // console.log(inThePastSave);
          if (selectedImages.length > this.state.selectedInThePast.length) {
            this.setState({
              selectedImages: selectedImages,
              confirmSelect: true,
              editedImages: images,
            });
          } else {
            this.setState({
              selectedImages: selectedImages,
              confirmSelect: false,
              editedImages: images,
            });
          }
        }
      }
      // this.setState({
      //     editedImages: images
      // });
    }
  };

  addImagesToSelection = () => {
    console.log('!@#!@#!@#!@#!@#!@#!@#!@#!@#!@#!@#');
    this.setState({ addingImagesToSalesforce: true });
    var data = {
      requestId: this.state.requestId,
      data: {
        Selected_Images__c: this.state.selectedImagesString,
        Status__c: 'Process Completed',
      },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/request/update-request',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        // var newSelectedImages = this.props.main.state.selectedImages
        //selectedInThePast: this.props.main.state.selectedImages,
        this.setState(
          { openConfirmDialog: false, addingImagesToSalesforce: false },
          () => {
            console.log('SELECTED IN THE PAST');
            console.log(this.state.selectedInThePast);
          }
        );
        this.getNewSelectedImages();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  downloadZipFileWatermark = (array, zipname) => {
    console.log(array);
    if (this.state.firstSelection) {
      this.setState({ openReviewDialog: true });
    }
    if (this.state.downloadPercenteges == 0) {
      var lineOfFiles = '';
      for (var i = 0; i < array.length; i++) {
        var path = '';
        console.log(array[i]);
        path = array[i].replace('https://storage.googleapis.com/wesnapp/', '');
        path.replace('/', '-');
        if (lineOfFiles == '') {
          lineOfFiles = path;
        } else {
          lineOfFiles = lineOfFiles + ',' + path;
        }
      }
      this.setState({ downloadPercenteges: 1, prepearingImages: true });
      var config = {
        responseType: 'blob',
        onDownloadProgress: this.uploadDownloadProgress(),
        timeout: 2000000000000000000000000,
      };
      var filesOld = lineOfFiles.split(',');
      var files = [];
      var folder = filesOld[0].split(
        '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
      )[0];
      for (var j = 0; j < filesOld.length; j++) {
        files.push(filesOld[j].split(folder + '/')[1]);
      }
      console.log('folder');
      console.log(folder);
      console.log(files);
      var data = {
        folder: folder,
        files: files,
        zipname: zipname,
        overlay_path:
          'watermarks/' +
          this.state.requestData.contact.AccountId +
          '/overlay.png',
        opacity: this.state.logo_preferences.opacity,
        size: this.state.logo_preferences.logoSize,
        position: this.state.logo_preferences.logoPosition,
      };
      console.log(data);
      axios
        .post(
          'https://py.wesnapp.co.il/server/zipfiles-watermarks',
          data,
          config
        )
        .then((response) => {
          console.log(response.data);
          var headers = response.headers;
          var blob = new Blob([response.data], {
            type: headers['content-type'],
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = zipname;
          link.click();
          link.remove();
          this.setState({ downloadPercenteges: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  sendImagesViaEmail = (array, zipname, email) => {
    this.setState({ openEmailDialog: true });
    console.log(array);
    var lineOfFiles = '';
    for (var i = 0; i < array.length; i++) {
      var path = '';
      console.log(array[i]);
      path = array[i].replace('https://storage.googleapis.com/wesnapp/', '');
      path.replace('/', '-');
      if (lineOfFiles == '') {
        lineOfFiles = path;
      } else {
        lineOfFiles = lineOfFiles + ',' + path;
      }
    }
    // this.setState({downloadPercenteges: 1, prepearingImages: true})
    var config = {
      responseType: 'blob',
      onDownloadProgress: this.uploadDownloadProgress(),
      timeout: 2000000000000000000000000,
    };
    var filesOld = lineOfFiles.split(',');
    var files = [];
    var folder = filesOld[0].split(
      '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
    )[0];
    for (var j = 0; j < filesOld.length; j++) {
      files.push(filesOld[j].split(folder + '/')[1]);
    }
    folder = folder.replace('edited', 'edited/compressed');
    console.log('folder');
    console.log(folder);
    console.log(files);
    axios
      .post(
        'https://py.wesnapp.co.il/server/email-images',
        {
          folder: folder,
          files: files,
          zipname: zipname,
          email: email,
          address:
            this.state.requestData.request.ShootingStreet__c +
            ', ' +
            this.state.requestData.request.ShootingCity__c,
        },
        config
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  sendImagesWithLogoViaEmail = (array, zipname, email) => {
    this.setState({ openEmailDialog: true });
    console.log(array);
    var lineOfFiles = '';
    for (var i = 0; i < array.length; i++) {
      var path = '';
      console.log(array[i]);
      path = array[i].replace('https://storage.googleapis.com/wesnapp/', '');
      path.replace('/', '-');
      if (lineOfFiles == '') {
        lineOfFiles = path;
      } else {
        lineOfFiles = lineOfFiles + ',' + path;
      }
    }
    // this.setState({downloadPercenteges: 1, prepearingImages: true})
    var config = {
      responseType: 'blob',
      onDownloadProgress: this.uploadDownloadProgress(),
      timeout: 2000000000000000000000000,
    };
    var filesOld = lineOfFiles.split(',');
    var files = [];
    var folder = filesOld[0].split(
      '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
    )[0];
    for (var j = 0; j < filesOld.length; j++) {
      files.push(filesOld[j].split(folder + '/')[1]);
    }
    folder = folder.replace('edited', 'edited/compressed');
    console.log('folder');
    console.log(folder);
    console.log(files);
    axios
      .post(
        'https://py.wesnapp.co.il/server/email-logo-images',
        {
          folder: folder,
          files: files,
          zipname: zipname,
          email: email,
          address:
            this.state.requestData.request.ShootingStreet__c +
            ', ' +
            this.state.requestData.request.ShootingCity__c,
          overlay_path:
            'watermarks/' +
            this.state.requestData.contact.AccountId +
            '/overlay.png',
          opacity: this.state.logo_preferences.opacity,
          size: this.state.logo_preferences.logoSize,
          position: this.state.logo_preferences.logoPosition,
        },
        config
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  downloadZipFile = (array, zipname) => {
    console.log(array);
    if (this.state.firstSelection) {
      this.setState({ openReviewDialog: true });
    }
    if (this.state.downloadPercenteges == 0) {
      var lineOfFiles = '';
      for (var i = 0; i < array.length; i++) {
        var path = '';
        console.log(array[i]);
        path = array[i].replace('https://storage.googleapis.com/wesnapp/', '');
        path.replace('/', '-');
        if (lineOfFiles == '') {
          lineOfFiles = path;
        } else {
          lineOfFiles = lineOfFiles + ',' + path;
        }
      }
      this.setState({ downloadPercenteges: 1, prepearingImages: true });
      var config = {
        responseType: 'blob',
        onDownloadProgress: this.uploadDownloadProgress(),
        timeout: 2000000000000000000000000,
      };
      var filesOld = lineOfFiles.split(',');
      var files = [];
      var folder = filesOld[0].split(
        '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
      )[0];
      for (var j = 0; j < filesOld.length; j++) {
        files.push(
          filesOld[j].replace('_watermarked', '').split(folder + '/')[1]
        );
      }
      console.log('folder');
      console.log(folder);
      console.log(files);
      // axios.post('https://py.wesnapp.co.il/server/zipfiles-images',{folder: folder, files: files, zipname: zipname}, config).then((response) => {
      axios
        .post(
          'https://py.wesnapp.co.il/server/zipfiles-images',
          { folder: folder, files: files, zipname: zipname },
          config
        )
        .then((response) => {
          console.log(response.data);
          var headers = response.headers;
          var blob = new Blob([response.data], {
            type: headers['content-type'],
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = zipname;
          link.click();
          link.remove();
          this.setState({ downloadPercenteges: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  uploadDownloadProgress = () => {
    return (progress) => {
      console.log(progress.loaded);
      console.log(progress.total);
      var percentCompleted = Math.round(
        (progress.loaded * 100) / progress.total
      );
      this.setState(
        { downloadPercenteges: percentCompleted, prepearingImages: false },
        () => {}
      );
    };
  };

  getNewSelectedImages = async () => {
    try {
      var data = { id: this.state.requestId };
      const response = await axios.post(
        'https://wesnapp-node-server.appspot.com/api/request/get-full-data',
        data
      );
      console.log('@@#!@#');
      console.log(response.data);
      var selectedImages = [];
      var selectedInThePastString = [];
      var selectedInThePast = [];
      if (response.data.request.Selected_Images__c != null) {
        selectedInThePastString =
          response.data.request.Selected_Images__c.replace(
            new RegExp('<p>', 'g'),
            ''
          )
            .replace(new RegExp('</p>', 'g'), '')
            .replace(new RegExp('<br>', 'g'), '');
        // while (selectedInThePastString.includes('1.')) {
        //   selectedInThePastString = selectedInThePastString
        // }
        selectedInThePast = selectedInThePastString.split(',');
        selectedImages = selectedInThePastString.split(',');
        this.setState({ selected: selectedImages.length });
      }
      // pricePerExtra: response.data.product.Price_Per_Extra_Product__c
      this.setState(
        {
          selectedImages: selectedImages,
          selectedInThePast: selectedInThePast,
          confirmSelect: false,
        },
        () => {
          this.getEditedImages();
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  getPreviewStyle = () => {
    var width = '15%';
    if (this.state.logo_preferences.logoSize == 66) {
      width = '20%';
    } else if (this.state.logo_preferences.logoSize == 100) {
      width = '25%';
    }
    if (this.state.logo_preferences.logoPosition == 'top_left') {
      return {
        width: width,
        height: 'auto',
        position: 'absolute',
        left: 10,
        top: 10,
        opacity: this.state.logo_preferences.opacity / 100,
      };
    } else if (this.state.logo_preferences.logoPosition == 'top_right') {
      return {
        width: width,
        height: 'auto',
        position: 'absolute',
        right: 10,
        top: 10,
        opacity: this.state.logo_preferences.opacity / 100,
      };
    } else if (this.state.logo_preferences.logoPosition == 'bottom_left') {
      return {
        width: width,
        height: 'auto',
        position: 'absolute',
        left: 10,
        bottom: 10,
        opacity: this.state.logo_preferences.opacity / 100,
      };
    } else if (this.state.logo_preferences.logoPosition == 'bottom_right') {
      return {
        width: width,
        height: 'auto',
        position: 'absolute',
        right: 10,
        bottom: 10,
        opacity: this.state.logo_preferences.opacity / 100,
      };
    }
  };

  selectAll = () => {
    var originals = this.state.editedImages;
    var images = [];
    for (var i = 0; i < originals.imagesForGallery.length; i++) {
      images.push(
        originals.imagesForGallery[i].src
          .replace('watermarked/', '')
          .replace('_reduced_watermarked', '')
      );
      originals.imagesForGallery[i].isSelected = true;
    }
    this.setState({
      selectedImages: images,
      editedImages: originals,
      selected: images.length,
      confirmSelect: true,
    });
  };

  removeDraft = (url) => {
    return url.replace('watermarked/', '').replace('_reduced_watermarked', '');
  };

  getNumberOfExtraImagesToPay = () => {
    var imagesInPackage = this.state.imagesInPackage;
    if (this.state.freeImages != null) {
      var imagesInPackage = this.state.imagesInPackage + this.state.freeImages;
    }
    if (this.state.selectedInThePast.length > imagesInPackage) {
      return (
        this.state.selectedImages.length - this.state.selectedInThePast.length
      );
    } else {
      return this.state.selectedImages.length - imagesInPackage;
    }
  };

  getHowMuchForFree = () => {
    var imagesInPackage = this.state.imagesInPackage;
    if (this.state.freeImages != null) {
      var imagesInPackage = this.state.imagesInPackage + this.state.freeImages;
    }
    if (this.state.selectedInThePast.length > imagesInPackage) {
      return this.state.selectedInThePast.length;
    } else {
      if (this.state.selectedImages.length > imagesInPackage) {
        return imagesInPackage;
      } else {
        return this.state.selectedImages.length;
      }
    }
  };

  handleSuccess = () => {
    // window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    // this.props.enqueueSnackbar("The company logo was successfully uploaded",
    //   { variant: "success",color:'white', autoHideDuration: 9999999999999999, resumeHideDuration: 99999999999999,
    //   action: key => (
    //                 <Button color="secondary" style={{color: 'white'}} size="small" onClick={() => this.props.closeSnackbar(key)}>
    //                     Dismiss
    //                 </Button>
    //         )}
    //   )
  };

  getStyle = (i) => {
    if (mobile) {
      return { display: 'inline-block', width: '100%', marginBottom: 10 };
    } else {
      if ((i + 2) % 3 == 0) {
        return {
          display: 'inline-block',
          width: '32.33%',
          marginBottom: 20,
          marginRight: '1.5%',
          marginLeft: '1.5%',
        };
      } else {
        return {
          display: 'inline-block',
          width: '32.33%',
          marginBottom: 20,
          marginRight: '0%',
        };
      }
    }
  };

  getSelectedImagesToPayFor = () => {
    let freeImages = 0;
    let selectedImages = this.state.selectedImages.length;
    if (this.state.requestData.request.Free_Extra_Images__c !== null) {
      freeImages = this.state.requestData.request.Free_Extra_Images__c;
    }
    let numberOfImagesIncluded =
      this.state.requestData.request.Number_of_Images_for_Customer__c +
      freeImages;
    if (numberOfImagesIncluded < selectedImages) {
      return selectedImages - numberOfImagesIncluded;
    } else {
      return 0;
    }
  };

  getCostForExtraProduct = () => {
    let discount = this.state.requestData.request.Extra_Product_Discount__c;
    let pricePerImage =
      this.state.requestData.product.Price_Per_Extra_Product__c;
    if (discount !== null) {
      return (pricePerImage * (100 - discount)) / 100;
    }
    return pricePerImage;
  };

  getTotalAmountToPayFor = () => {
    let forProduct = this.state.requestData.request.Total_Cost__c;
    let extraCost = this.state.requestData.request.Extra_Cost__c;
    let toPayFor = 0;
    if (this.state.requestPaid == false) {
      toPayFor += forProduct;
    }
    // if (extraCost !== null) {
    //   toPayFor += extraCost
    // }
    let forExtra = parseFloat(
      (
        this.getSelectedImagesToPayFor() * this.getCostForExtraProduct()
      ).toFixed(2)
    );
    console.log('for product:', forProduct);
    console.log('for extra:', forExtra);
    if (forExtra !== 0 && forExtra !== null && forExtra !== undefined) {
      if (this.state.couponIsValid) {
        if (this.state.couponType === 'cash') {
          return (toPayFor + forExtra - this.state.couponDiscount).toFixed(2);
        } else {
          return (
            (toPayFor + forExtra) *
            (100 - this.state.couponDiscount)
          ).toFixed(2);
        }
      } else {
        return (toPayFor + forExtra).toFixed(2);
      }
    } else {
      if (this.state.couponIsValid) {
        if (this.state.couponType === 'cash') {
          return (toPayFor - this.state.couponDiscount).toFixed(2);
        } else {
          return (toPayFor * (100 - this.state.couponDiscount)).toFixed(2);
        }
      } else {
        return toPayFor.toFixed(2);
      }
    }
  };

  getTotalAmountToPayForWithVAT = () => {
    let forProduct = this.state.requestData.request.Total_Cost__c;
    let extraCost = this.state.requestData.request.Extra_Cost__c;
    let toPayFor = 0;
    if (this.state.requestPaid == false) {
      toPayFor += forProduct;
    }
    // if (extraCost !== null) {
    //   toPayFor += extraCost
    // }
    let forExtra = parseFloat(
      (
        this.getSelectedImagesToPayFor() * this.getCostForExtraProduct()
      ).toFixed(2)
    );
    console.log('for product:', forProduct);
    console.log('for extra:', forExtra);
    if (forExtra !== 0 && forExtra !== null && forExtra !== undefined) {
      return ((toPayFor + forExtra) * 1.17).toFixed(2);
    } else {
      return (toPayFor * 1.17).toFixed(2);
    }
  };

  checkIfCouponIsValid = () => {
    this.setState({ checkingCoupon: true, couponIsValid: false });
    var data = {
      coupon: this.state.coupon,
      accountId: this.state.accountId,
      requestId: this.state.requestId,
      productId: this.state.productId,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/coupons/check-coupon',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.allowed) {
          let type = '';
          let amount = 0;
          if (response.data.discountAmount !== null) {
            type = 'cash';
            amount = response.data.discountAmount;
          } else {
            type = 'percentages';
            amount = response.data.amount;
          }
          this.setState({
            checkingCoupon: false,
            couponIsValid: true,
            errorCoupon: false,
            canApply: false,
            errorCouponText: 'Coupon is valid',
            couponType: type,
            couponDiscount: amount,
          });
        } else {
          this.setState({
            checkingCoupon: false,
            couponIsValid: false,
            errorCoupon: true,
            canApply: false,
            errorCouponText: 'Coupon is not valid',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChangeCoupon = (event) => {
    let newValue = event.target.value;
    if (newValue === '') {
      console.log('xx');
      this.setState({
        errorCoupon: '',
        errorCouponText: '',
        coupon: newValue,
        canApply: false,
      });
    } else {
      console.log('yy');
      this.setState({ coupon: newValue, canApply: true });
    }
  };

  applyCoupon = () => {};

  render() {
    const { classes, theme } = this.props;
    return (
      <div
        className={classes.root}
        style={{
          borderRadius: 20,
          backgroundColor: 'white',
          minHeight: '100%',
          // height: '100%',
          // position: 'fixed', top: 55, left: 0, whiteSpace: 'wrap',
          // overflowX: 'hidden',
          maxWidth: '100vw',
          width: '100vw',
          padding: mobile ? 10 : 0,
          paddingLeft: 0,
          paddingTop: mobile ? 30 : 0,
        }}
      >
        <CssBaseline />
        <SendByEmailDialog main={this} />
        {this.state.requestData != null && <EmailDialog main={this} />}
        <Dialog
          open={this.state.openPaymentDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClosePaymentDialog}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {this.state.paymentWithProduct
              ? 'Photo Shoot + Additional Media'
              : 'Additional media'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {this.state.paymentWithProduct
                ? "Photo shoot not paid. Additionally you've just selected additional media. You will get charged for those. If would you like to proceed with the download, please complete the payment proccess."
                : "You've just selected additional media. You will get charged for those. If would you like to proceed with the download, please complete the payment proccess."}
            </DialogContentText>
            <div style={{ marginTop: 20, width: '100%', height: 5 }}></div>
            {this.state.paymentWithProduct ? (
              <div>
                {this.state.openPaymentDialog && (
                  <iframe
                    style={{
                      width: '100%',
                      height: 290,
                      marginTop: 0,
                      frameBorder: 0,
                      borderRadius: 0,
                      border: 0,
                    }}
                    src={
                      'https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&cred_type=1&u71=1&trButtonColor=000000&buttonLabel=Payment&type=chargeForProductAndExtra&nologo=1&sum=' +
                      this.getTotalAmountToPayForWithVAT() +
                      '&price=' +
                      this.getTotalAmountToPayFor() +
                      '&accountId=' +
                      this.state.requestData.contact.AccountId +
                      '&orderId=' +
                      this.state.requestData.request.WS_Order__c +
                      '&mobile=' +
                      this.state.requestData.contact.MobilePhone +
                      '&name=' +
                      this.state.requestData.contact.Name +
                      '&vatType=' +
                      '0' +
                      '&email=' +
                      this.state.requestData.contact.Email +
                      '&companyNumber=' +
                      String(this.state.requestData.request.Total_Cost__c) +
                      '&address=' +
                      this.state.requestData.request.ShootingStreet__c +
                      ', ' +
                      this.state.requestData.request.ShootingCity__c +
                      '&city=' +
                      this.state.requestData.request.ShootingCity__c +
                      '&productName=' +
                      this.state.requestData.product.Public_Product_Name__c +
                      '&countryCode=' +
                      'IL' +
                      '&currencyCode=' +
                      this.state.extraImagesData +
                      '&contact=' +
                      this.state.requestData.contact.AccountId +
                      '&pdesc=' +
                      this.state.selectedImagesString +
                      '&remarks=' +
                      this.state.requestData.request.Id +
                      '&company=' +
                      this.state.imagesToPayFor
                    }
                  ></iframe>
                )}
              </div>
            ) : (
              <div>
                {this.state.openPaymentDialog && (
                  <iframe
                    style={{
                      width: '100%',
                      height: 290,
                      marginTop: 0,
                      frameBorder: 0,
                      borderRadius: 0,
                      border: 0,
                    }}
                    src={
                      'https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&cred_type=1&u71=1&trButtonColor=000000&buttonLabel=Payment&type=chargeForExtra&nologo=1&sum=' +
                      this.getTotalAmountToPayForWithVAT() +
                      '&price=' +
                      this.getTotalAmountToPayFor() +
                      '&accountId=' +
                      this.state.requestData.contact.AccountId +
                      '&orderId=' +
                      this.state.requestData.request.WS_Order__c +
                      '&mobile=' +
                      this.state.requestData.contact.MobilePhone +
                      '&name=' +
                      this.state.requestData.contact.Name +
                      '&vatType=' +
                      '0' +
                      '&email=' +
                      this.state.requestData.contact.Email +
                      '&companyNumber=' +
                      '' +
                      '&address=' +
                      this.state.requestData.request.ShootingStreet__c +
                      ', ' +
                      this.state.requestData.request.ShootingCity__c +
                      '&city=' +
                      this.state.requestData.request.ShootingCity__c +
                      '&productName=' +
                      this.state.requestData.product.Public_Product_Name__c +
                      '&countryCode=' +
                      'IL' +
                      '&currencyCode=' +
                      this.state.extraImagesData +
                      '&contact=' +
                      this.state.requestData.contact.AccountId +
                      '&pdesc=' +
                      this.state.selectedImagesString +
                      '&remarks=' +
                      this.state.requestData.request.Id +
                      '&company=' +
                      this.state.imagesToPayFor
                    }
                  ></iframe>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClosePaymentDialog} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openConfirmDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseConfirmDialog}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>Select Images</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              <b>Note</b> - Once you select photos, you won't be able to change
              your selection.
            </DialogContentText>
            <div style={{ marginTop: 10, width: '100%', height: 2 }}></div>
            <center>
              {this.state.addingImagesToSalesforce ? (
                <div style={{ marginTop: 25, height: 48 }}>
                  <CircularProgress size={40} />
                </div>
              ) : (
                <Button
                  onClick={() => this.addImagesToSelection()}
                  variant='contained'
                  type='submit'
                  elevation={0}
                  style={{
                    color: 'white',
                    marginTop: 25,
                    height: 48,
                    borderRadius: 24,
                    background:
                      'linear-gradient(to right , rgb(94,44,237), #814bfa)',
                    boxShadow: '0px 10px 20px -10px rgba(0,0,0,0.30)',
                  }}
                >
                  Confirm Selection
                </Button>
              )}
            </center>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseConfirmDialog} color='primary'>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openReviewDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseReviewDialog}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            Write us a review!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              <b>
                We want to hear you! Please share with us how was the
                photographer and what do you think about the photos.
              </b>
            </DialogContentText>
            <Box component='fieldset' mb={1} borderColor='transparent'>
              <Typography component='legend1'>Rate the photographer</Typography>
              <Rating
                name='simple-controlled1'
                value={this.state.photographerRate}
                onChange={(event, newValue) => {
                  this.setRatingForPhotographer(newValue);
                }}
              />
            </Box>
            <Box
              component='fieldset'
              mb={1}
              borderColor='transparent'
              style={{ marginTop: -10 }}
            >
              <Typography component='legend2'>Rate the photos</Typography>
              <Rating
                name='simple-controlled2'
                value={this.state.photosRate}
                onChange={(event, newValue) => {
                  this.setRatingForPhotos(newValue);
                }}
              />
            </Box>
            <div style={{ marginTop: -12, width: '100%', height: 2 }}></div>
            <TextField
              id='outlined-multiline-static'
              label=''
              multiline
              rows='4'
              value={this.state.userComment}
              style={{ width: '100%' }}
              onChange={this.handleChange('userComment')}
              margin='normal'
              variant='outlined'
            />
          </DialogContent>
          <DialogActions>
            {this.state.addingComment ? (
              <div style={{ marginTop: 15, height: 38 }}>
                <CircularProgress size={30} />
              </div>
            ) : (
              <Button onClick={() => this.addReview()} color='primary'>
                Add Review
              </Button>
            )}
            <Button onClick={this.handleCloseReviewDialog} color='primary'>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.galleryIsOpen && (
          <Lightbox
            mainSrc={
              this.state.editedImages.imagesForGallery[this.state.galleryIndex]
                .src
            }
            nextSrc={
              this.state.editedImages.imagesForGallery[
                (this.state.galleryIndex + 1) %
                  this.state.editedImages.imagesForGallery.length
              ].src
            }
            prevSrc={
              this.state.editedImages.imagesForGallery[
                (this.state.galleryIndex +
                  this.state.editedImages.imagesForGallery.length -
                  1) %
                  this.state.editedImages.imagesForGallery.length
              ].src
            }
            onCloseRequest={() => {
              this.setState({ galleryIsOpen: false });
              this.setState({ open: mobile ? false : true });
            }}
            onMovePrevRequest={() =>
              this.setState({
                galleryIndex:
                  (this.state.galleryIndex +
                    this.state.editedImages.imagesForGallery.length -
                    1) %
                  this.state.editedImages.imagesForGallery.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                galleryIndex:
                  (this.state.galleryIndex + 1) %
                  this.state.editedImages.imagesForGallery.length,
              })
            }
          />
        )}
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: mobile ? false : this.state.open,
          })}
        >
          <SnackbarProvider maxSnack={10}>
            <Grid
              container
              spacing={2}
              dir='ltr'
              justify='center'
              style={{ width: '92%' }}
            >
              <Grid item xs={12} sm={11} md={8} xl={8}>
                <div style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
                  {mobile ? (
                    <div>
                      <div
                        style={{
                          position: 'absolute',
                          top: 4,
                          right: 15,
                          zIndex: 0,
                        }}
                      >
                        <IconButton
                          color='white'
                          aria-label='open drawer'
                          edge='end'
                          onClick={this.handleDrawerOpen}
                          style={{ height: 50, color: 'white' }}
                          className={clsx(this.state.open && classes.hide)}
                        >
                          <MenuIcon style={{ color: 'white' }} />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div
                    style={{
                      fontSize: 24,
                      color: 'rgb(40,40,53)',
                      textAlign: 'left',
                      fontFamily: "'Montserrat', sans-serif",
                      marginTop: mobile ? 0 : 0,
                      marginBottom: mobile ? 0 : 30,
                    }}
                  >
                    <b>Photo Shoot</b> #{this.state.requestName}
                  </div>
                  <div style={{ marginTop: 0 }}>
                    {!mobile && (
                      <div
                        style={{
                          width: '100%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          borderRadius: 30,
                          height: 200,
                          position: 'relative',
                        }}
                      >
                        <Map google={window.google} lat={lat} lng={lng} />
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginTop: mobile ? 15 : 30, textAlign: 'left' }}
                  ></div>
                  {mobile ? (
                    <div
                      style={{
                        color: '#6C65FF',
                        width: mobile ? 'calc(100vw - 40px)' : '100%',
                        overflow: 'scroll',
                        whiteSpace: 'nowrap',
                        backgroundColor: 'white',
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          display: 'inline-block',
                          marginRight: mobile ? 10 : '2.66%',
                          marginLeft: mobile ? 10 : 0,
                          width: mobile ? '80%' : '23%',
                          height: 100,
                          backgroundColor: 'rgb(239,242,249)',
                          borderRadius: 20,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            marginTop: 25,
                            marginLeft: 15,
                            float: 'left',
                            borderRadius: 15,
                          }}
                        >
                          <PersonIcon
                            size='25'
                            style={{ color: 'rgb(94,44,237)', marginTop: 12.5 }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            textAlign: 'left',
                            height: 50,
                            marginTop: 20,
                            marginLeft: 10,
                            float: 'left',
                            borderRadius: 15,
                            fontSize: 16,
                          }}
                        >
                          <div style={{ marginTop: 4, maxWidth: '80%' }}>
                            <b>Photographer</b>
                          </div>
                          <div style={{ marginTop: 8 }}>
                            {this.state.photographerName}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          display: 'inline-block',
                          marginRight: mobile ? 10 : '2.66%',
                          width: mobile ? '80%' : '23%',
                          height: 100,
                          backgroundColor: 'rgb(239,242,249)',
                          borderRadius: 20,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            marginTop: 25,
                            marginLeft: 15,
                            float: 'left',
                            borderRadius: 15,
                          }}
                        >
                          <PhotoCameraIcon
                            size='25'
                            style={{ color: 'rgb(94,44,237)', marginTop: 12.5 }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            textAlign: 'left',
                            height: 50,
                            marginTop: 20,
                            marginLeft: 10,
                            float: 'left',
                            borderRadius: 15,
                            fontSize: 16,
                          }}
                        >
                          <div style={{ marginTop: 4 }}>
                            <b>Product</b>
                          </div>
                          <div style={{ marginTop: 8 }}>
                            {this.state.productName}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          display: 'inline-block',
                          marginRight: mobile ? 10 : '2.66%',
                          width: mobile ? '80%' : '23%',
                          height: 100,
                          backgroundColor: 'rgb(239,242,249)',
                          borderRadius: 20,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            marginTop: 25,
                            marginLeft: 15,
                            float: 'left',
                            borderRadius: 15,
                          }}
                        >
                          <LocationCityIcon
                            size='25'
                            style={{ color: 'rgb(94,44,237)', marginTop: 12.5 }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            textAlign: 'left',
                            maxWidth: 'calc(100% - 85px)',
                            marginLeft: 10,
                            height: 50,
                            marginTop: 20,
                            float: 'left',
                            borderRadius: 15,
                            fontSize: 16,
                          }}
                        >
                          <div style={{ marginTop: 4 }}>
                            <b>Location</b>
                          </div>
                          <div style={{ marginTop: 8 }}>
                            {this.state.shootingLocation}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          display: 'inline-block',
                          marginRight: mobile ? 10 : '0',
                          width: mobile ? '80%' : '23%',
                          height: 100,
                          backgroundColor: 'rgb(239,242,249)',
                          borderRadius: 20,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            marginTop: 25,
                            marginLeft: 15,
                            float: 'left',
                            borderRadius: 15,
                          }}
                        >
                          <CalendarTodayIcon
                            size='25'
                            style={{ color: 'rgb(94,44,237)', marginTop: 12.5 }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            textAlign: 'left',
                            height: 50,
                            marginTop: 20,
                            marginLeft: 10,
                            float: 'left',
                            borderRadius: 15,
                            fontSize: 16,
                          }}
                        >
                          <div style={{ marginTop: 4 }}>
                            <b>Date & Time</b>
                          </div>
                          <div style={{ marginTop: 8 }}>
                            {this.state.dateAndTime}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ color: '#6C65FF', width: '100%' }}>
                      <Grid container spacing={2} dir='ltr' justify='center'>
                        <Grid item xs={12} sm={12} md={6} xl={3}>
                          <div
                            style={{
                              fontFamily: "'Montserrat', sans-serif",
                              display: 'inline-block',
                              width: '100%',
                              height: 100,
                              backgroundColor: 'rgb(239,242,249)',
                              borderRadius: 20,
                            }}
                          >
                            <div
                              style={{
                                display: 'inline-block',
                                width: 50,
                                height: 50,
                                backgroundColor: 'white',
                                marginTop: 25,
                                marginLeft: 15,
                                float: 'left',
                                borderRadius: 15,
                              }}
                            >
                              <PersonIcon
                                size='25'
                                style={{
                                  color: 'rgb(94,44,237)',
                                  marginTop: 12.5,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: 'inline-block',
                                textAlign: 'left',
                                height: 50,
                                marginTop: 20,
                                marginLeft: 10,
                                float: 'left',
                                borderRadius: 15,
                                fontSize: 16,
                              }}
                            >
                              <div style={{ marginTop: 4, maxWidth: '80%' }}>
                                <b>Photographer</b>
                              </div>
                              <div style={{ marginTop: 8 }}>
                                {this.state.photographerName}
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={3}>
                          <div
                            style={{
                              fontFamily: "'Montserrat', sans-serif",
                              display: 'inline-block',
                              width: '100%',
                              height: 100,
                              backgroundColor: 'rgb(239,242,249)',
                              borderRadius: 20,
                            }}
                          >
                            <div
                              style={{
                                display: 'inline-block',
                                width: 50,
                                height: 50,
                                backgroundColor: 'white',
                                marginTop: 25,
                                marginLeft: 15,
                                float: 'left',
                                borderRadius: 15,
                              }}
                            >
                              <PhotoCameraIcon
                                size='25'
                                style={{
                                  color: 'rgb(94,44,237)',
                                  marginTop: 12.5,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: 'inline-block',
                                textAlign: 'left',
                                height: 50,
                                marginTop: 20,
                                marginLeft: 10,
                                float: 'left',
                                borderRadius: 15,
                                fontSize: 16,
                              }}
                            >
                              <div style={{ marginTop: 4 }}>
                                <b>Product</b>
                              </div>
                              <div style={{ marginTop: 8 }}>
                                {this.state.productName}
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={3}>
                          <div
                            style={{
                              fontFamily: "'Montserrat', sans-serif",
                              display: 'inline-block',
                              width: '100%',
                              height: 100,
                              backgroundColor: 'rgb(239,242,249)',
                              borderRadius: 20,
                            }}
                          >
                            <div
                              style={{
                                display: 'inline-block',
                                width: 50,
                                height: 50,
                                backgroundColor: 'white',
                                marginTop: 25,
                                marginLeft: 15,
                                float: 'left',
                                borderRadius: 15,
                              }}
                            >
                              <LocationCityIcon
                                size='25'
                                style={{
                                  color: 'rgb(94,44,237)',
                                  marginTop: 12.5,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: 'inline-block',
                                textAlign: 'left',
                                maxWidth: 'calc(100% - 85px)',
                                marginLeft: 10,
                                height: 50,
                                marginTop: 20,
                                float: 'left',
                                borderRadius: 15,
                                fontSize: 16,
                              }}
                            >
                              <div style={{ marginTop: 4 }}>
                                <b>Location</b>
                              </div>
                              <div style={{ marginTop: 8 }}>
                                {this.state.shootingLocation}
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={3}>
                          <div
                            style={{
                              fontFamily: "'Montserrat', sans-serif",
                              display: 'inline-block',
                              width: '100%',
                              height: 100,
                              backgroundColor: 'rgb(239,242,249)',
                              borderRadius: 20,
                            }}
                          >
                            <div
                              style={{
                                display: 'inline-block',
                                width: 50,
                                height: 50,
                                backgroundColor: 'white',
                                marginTop: 25,
                                marginLeft: 15,
                                float: 'left',
                                borderRadius: 15,
                              }}
                            >
                              <CalendarTodayIcon
                                size='25'
                                style={{
                                  color: 'rgb(94,44,237)',
                                  marginTop: 12.5,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: 'inline-block',
                                textAlign: 'left',
                                height: 50,
                                marginTop: 20,
                                marginLeft: 10,
                                float: 'left',
                                borderRadius: 15,
                                fontSize: 16,
                              }}
                            >
                              <div style={{ marginTop: 4 }}>
                                <b>Date & Time</b>
                              </div>
                              <div style={{ marginTop: 8 }}>
                                {this.state.dateAndTime}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  <div style={{ width: '100%', marginBottom: 0 }}>
                    <div
                      style={{
                        width: '100%',
                        marginBottom: 0,
                        height: mobile ? 0 : 0,
                      }}
                    ></div>
                    {this.state.downloadPercenteges != 100 &&
                      this.state.downloadPercenteges != 0 && (
                        <div
                          style={{
                            width: '100%',
                            marginTop: 10,
                            textAlign: 'left',
                            marginBottom: 10,
                            height: 40,
                          }}
                        >
                          <div style={{ marginTop: 0 }}>
                            {this.state.prepearingImages ? (
                              <div
                                style={{
                                  width: '100%',
                                  textAlign: 'left',
                                  color: 'black',
                                  marginBottom: 10,
                                }}
                              >
                                Prepearing images.. Download will start in few
                                seconds
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: '100%',
                                  textAlign: 'left',
                                  color: 'black',
                                  marginBottom: 10,
                                }}
                              >
                                Download in progress -{' '}
                                {this.state.downloadPercenteges}%
                              </div>
                            )}
                            <BorderLinearProgress
                              variant='determinate'
                              value={this.state.downloadPercenteges}
                            />
                          </div>
                        </div>
                      )}
                    <div
                      style={{
                        display: 'inline-block',
                        float: 'left',
                        fontSize: 24,
                        color: 'black',
                        color: 'rgb(40,40,53)',
                        textAlign: 'left',
                        fontFamily: "'Montserrat', sans-serif",
                        marginTop: mobile ? 15 : 34,
                        marginBottom: mobile ? 15 : 30,
                      }}
                    >
                      <b>Choose</b> Photos
                    </div>
                    <div
                      style={{
                        marginBottom: mobile ? 15 : 0,
                        height: '100%',
                        marginTop: mobile ? 0 : 30,
                        overflow: 'scroll',
                        whiteSpace: 'nowrap',
                        maxWidth: 'calc(100vw - 50px)',
                        float: mobile ? 'left' : 'right',
                      }}
                    >
                      {this.state.selectedInThePast.length !=
                        this.state.editedImages.imagesForGallery.length &&
                        this.state.selectedImages.length !=
                          this.state.editedImages.imagesForGallery.length &&
                        !mobile && (
                          <div
                            style={{
                              display: 'inline-block',
                              fontWeight: 'bold',
                              float: mobile ? 'center' : 'right',
                              fontFamily: "'Montserrat', sans-serif",
                              fontSize: 20,
                              textAlign: 'right',
                              color: '#333',
                              marginBottom: 0,
                              marginTop: mobile ? 0 : 0,
                              marginRight: 15,
                              width: mobile ? 'auto%' : 'auto',
                            }}
                          >
                            <Button
                              onClick={() => this.selectAll()}
                              variant='contained'
                              style={{
                                border: '1px solid rgb(94,44,237)',
                                boxShadow:
                                  '0px 10px 71px -10px rgba(0,0,0,0.10)',
                                borderRadius: 20,
                                borderColor: 'rgb(94,44,237)',
                                cursor: 'pointer',
                                color: 'rgb(94,44,237)',
                                backgroundColor: 'white',
                                height: 40,
                                border: '1px solid rgb(94,44,237)',
                              }}
                            >
                              Select All
                            </Button>
                          </div>
                        )}
                    </div>
                  </div>
                  {this.state.editedImages.imagesForGallery.length > 0 && (
                    <div>
                      {console.log('edited i')}
                      {console.log(this.state.editedImages.imagesForGallery)}
                      <div
                        style={{
                          display: 'inline-block',
                          width: 'calc(100% - 0px)',
                          overflow: 'scroll',
                          verticalAlign: 'top',
                        }}
                      >
                        {this.state.editedImages.imagesForGallery.map(
                          (item, i) => (
                            <div
                              style={
                                this.props.main.state.deviceType == 'desktop'
                                  ? this.getStyle(i)
                                  : {
                                      display: 'inline-block',
                                      width: '100%',
                                      marginBottom: 10,
                                    }
                              }
                            >
                              <div className='imgcontainer'>
                                <img
                                  src={item.src}
                                  alt='Avatar'
                                  className='image'
                                  style={{ width: '100%' }}
                                />
                                <div className='middle'>
                                  <img
                                    src={zoomInIcon}
                                    alt='Avatar'
                                    className='text'
                                    width='80px'
                                    height='80px'
                                    onClick={() => {
                                      this.setState({
                                        galleryIsOpen: true,
                                        galleryIndex: i,
                                      });
                                      this.setState({ open: false });
                                    }}
                                  />
                                </div>
                                <div className='boxinimage'>
                                  <div style={{ height: 75 }}>
                                    <div
                                      style={{
                                        display: 'inline-block',
                                        fontSize: '19px',
                                        marginTop: 28,
                                        float: 'left',
                                        marginLeft: 12,
                                      }}
                                    >
                                      Photo #{i + 1}
                                    </div>
                                    {this.state.selectedInThePast.includes(
                                      this.getLinkAfterReplacement(item.src)
                                    ) ? (
                                      <Tooltip
                                        title={'Selected'}
                                        placement='top'
                                      >
                                        <button
                                          style={{
                                            display: 'inline-block',
                                            cursor: 'pointer',
                                            height: 36,
                                            marginTop: 24,
                                            color: 'white',
                                            float: 'right',
                                            border: 'none',
                                            outline: 'none',
                                            background: 'transparent',
                                          }}
                                          onClick={() => this.onSelectImage(i)}
                                        >
                                          <FavoriteIcon
                                            style={{
                                              width: 25,
                                              height: 25,
                                              color: '#33D7A0',
                                            }}
                                          />
                                        </button>
                                      </Tooltip>
                                    ) : (
                                      <button
                                        style={{
                                          display: 'inline-block',
                                          cursor: 'pointer',
                                          height: 36,
                                          marginTop: 24,
                                          color: 'white',
                                          float: 'right',
                                          border: 'none',
                                          outline: 'none',
                                          background: 'transparent',
                                          marginRight: 12,
                                        }}
                                        onClick={() => this.onSelectImage(i)}
                                      >
                                        {item.isSelected && (
                                          <FavoriteIcon
                                            style={{
                                              width: 25,
                                              height: 25,
                                              color: '#33D7A0',
                                            }}
                                          />
                                        )}
                                        {!item.isSelected && (
                                          <FavoriteBorderIcon
                                            style={{
                                              width: 25,
                                              height: 25,
                                              color: '#33D7A0',
                                            }}
                                          />
                                        )}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className={classes.drawerHeader} />
              </Grid>
            </Grid>
          </SnackbarProvider>
        </main>
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor={mobile ? 'bottom' : 'right'}
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            {mobile ? (
              <IconButton
                onClick={this.handleDrawerClose}
                style={{ marginTop: 38 }}
              >
                <ArrowDown />
              </IconButton>
            ) : (
              <div>
                {/* <IconButton onClick={this.handleDrawerClose} style={{marginTop: 38}}>
                  {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton> */}
              </div>
            )}
          </div>
          <div>
            {
              //
            }
            <div
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                color: '#6C65FF',
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              <Paper
                square
                style={{ backgroundColor: 'rgb(238,242,249)' }}
                elevation={0}
              >
                <Tabs
                  value={this.state.sideMenuTab}
                  indicatorColor='primary'
                  textColor='primary'
                  onChange={this.handleChangeSideMenuTab}
                  aria-label='disabled tabs example'
                  style={{ backgroundColor: 'rgb(238,242,249)' }}
                >
                  <Tab label='Selection' />
                  <Tab label='Logo' />
                </Tabs>
              </Paper>
              {this.state.sideMenuTab == 0 &&
                this.state.requestData !== null && (
                  <div style={{ zIndex: 999999999 }}>
                    {/* Paid or Not */}
                    <div style={{ height: 12.5, width: '100%' }}></div>
                    <div
                      style={{
                        display: 'inline-block',
                        float: 'left',
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: 16,
                        color: '#333',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        marginBottom: 0,
                        marginTop: 16,
                        color: 'rgb(40,40,53)',
                      }}
                    >
                      Payment Status
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        float: 'right',
                        marginTop: 16,
                        fontSize: 16,
                        color: 'rgba(0,0,0,0.6)',
                      }}
                    >
                      {this.state.requestData != null && (
                        <div>
                          {this.state.requestPaid ? (
                            <span style={{ color: '#6C65FF' }}>Paid</span>
                          ) : (
                            <span style={{ color: 'red' }}>Not Paid</span>
                          )}
                        </div>
                      )}
                    </div>
                    <div style={{ height: 50, width: '100%' }}></div>
                    {/* Images in pack */}
                    <div style={{ height: 12.5, width: '100%' }}></div>

                    {this.state.requestData.request.Discount_Percentage__c !==
                    null ? (
                      <div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'left',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginBottom: 0,
                            marginTop: 16,
                            color: 'rgb(40,40,53)',
                          }}
                        >
                          Shooting price
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(0,0,0,0.6)',
                          }}
                        >
                          {this.state.requestData.request.Shooting_Cost__c}
                          {
                            this.state.requestData.request.CurrencyIsoCode
                          } +{' '}
                          {
                            this.state.requestData.request
                              .Discount_Percentage__c
                          }
                          % Discount
                        </div>
                        <div style={{ height: 50, width: '100%' }}></div>
                        <Divider
                          style={{
                            color: 'rgb(226,225,240)',
                            backgroundColor: 'rgb(226,225,240)',
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'left',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginBottom: 0,
                            marginTop: 16,
                            color: 'rgb(40,40,53)',
                          }}
                        >
                          Shooting price
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(0,0,0,0.6)',
                          }}
                        >
                          {this.state.requestData.request.Shooting_Cost__c}{' '}
                          {this.state.requestData.request.CurrencyIsoCode}
                        </div>
                        <div style={{ height: 50, width: '100%' }}></div>
                        <Divider
                          style={{
                            color: 'rgb(226,225,240)',
                            backgroundColor: 'rgb(226,225,240)',
                          }}
                        />
                      </div>
                    )}
                    {this.state.requestData.request.Extra_Cost__c !== null && (
                      <div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'left',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginBottom: 0,
                            marginTop: 16,
                            color: 'rgb(40,40,53)',
                          }}
                        >
                          Extra cost
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(0,0,0,0.6)',
                          }}
                        >
                          {this.state.requestData.request.Extra_Cost__c}
                          {this.state.requestData.request.CurrencyIsoCode}
                        </div>
                        <div style={{ height: 50, width: '100%' }}></div>
                        <Divider
                          style={{
                            color: 'rgb(226,225,240)',
                            backgroundColor: 'rgb(226,225,240)',
                          }}
                        />
                      </div>
                    )}
                    {this.state.requestData.request.Discount_Amount__c !==
                      null && (
                      <div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'left',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginBottom: 0,
                            marginTop: 16,
                            color: 'rgb(40,40,53)',
                          }}
                        >
                          Discount amount
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(0,0,0,0.6)',
                          }}
                        >
                          {this.state.requestData.request.Discount_Amount__c}{' '}
                          {this.state.requestData.request.CurrencyIsoCode}
                        </div>
                        <div style={{ height: 50, width: '100%' }}></div>
                        <Divider
                          style={{
                            color: 'rgb(226,225,240)',
                            backgroundColor: 'rgb(226,225,240)',
                          }}
                        />
                      </div>
                    )}
                    {/* Free extra images */}
                    {this.state.requestData.request.Free_Extra_Images__c !==
                    null ? (
                      <div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'left',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginBottom: 0,
                            marginTop: 16,
                            color: 'rgb(40,40,53)',
                          }}
                        >
                          Selected images
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(0,0,0,0.6)',
                          }}
                        >
                          {this.state.extraImagePrice != 0 && (
                            <div>
                              {this.state.selectedImages.length} /{' '}
                              {
                                this.state.requestData.request
                                  .Number_of_Images_for_Customer__c
                              }{' '}
                              +{' '}
                              {
                                this.state.requestData.request
                                  .Free_Extra_Images__c
                              }{' '}
                              FREE
                            </div>
                          )}
                        </div>
                        <div style={{ height: 50, width: '100%' }}></div>
                        <Divider
                          style={{
                            color: 'rgb(226,225,240)',
                            backgroundColor: 'rgb(226,225,240)',
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'left',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginBottom: 0,
                            marginTop: 16,
                            color: 'rgb(40,40,53)',
                          }}
                        >
                          Selected images
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(0,0,0,0.6)',
                          }}
                        >
                          {this.state.extraImagePrice != 0 && (
                            <div>
                              {this.state.selectedImages.length} /{' '}
                              {
                                this.state.requestData.request
                                  .Number_of_Images_for_Customer__c
                              }
                            </div>
                          )}
                        </div>
                        <div style={{ height: 50, width: '100%' }}></div>
                        <Divider
                          style={{
                            color: 'rgb(226,225,240)',
                            backgroundColor: 'rgb(226,225,240)',
                          }}
                        />
                      </div>
                    )}

                    {/* Discount in perecentages for extra images */}
                    {this.getSelectedImagesToPayFor() > 0 &&
                    this.state.requestData.request.Extra_Product_Discount__c !==
                      null ? (
                      <div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'left',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginBottom: 0,
                            marginTop: 16,
                            color: 'rgb(40,40,53)',
                          }}
                        >
                          Extra image price
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(0,0,0,0.6)',
                          }}
                        >
                          {
                            this.state.requestData.product
                              .Price_Per_Extra_Product__c
                          }
                          ILS +{' '}
                          {
                            this.state.requestData.request
                              .Extra_Product_Discount__c
                          }
                          % Discount
                        </div>
                        <div style={{ height: 50, width: '100%' }}></div>
                      </div>
                    ) : (
                      <div>
                        {this.getSelectedImagesToPayFor() > 0 && (
                          <div>
                            <div
                              style={{
                                display: 'inline-block',
                                float: 'left',
                                fontFamily: "'Montserrat', sans-serif",
                                fontSize: 16,
                                color: '#333',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                marginBottom: 0,
                                marginTop: 16,
                                color: 'rgb(40,40,53)',
                              }}
                            >
                              Extra image price
                            </div>
                            <div
                              style={{
                                display: 'inline-block',
                                float: 'right',
                                marginTop: 16,
                                fontSize: 16,
                                color: 'rgba(0,0,0,0.6)',
                              }}
                            >
                              {
                                this.state.requestData.product
                                  .Price_Per_Extra_Product__c
                              }
                            </div>
                            <div style={{ height: 50, width: '100%' }}></div>
                          </div>
                        )}
                      </div>
                    )}
                    {/* Selected Images */}
                    <Divider
                      style={{
                        color: 'rgb(226,225,240)',
                        backgroundColor: 'rgb(226,225,240)',
                      }}
                    />

                    {/* Extra images selected */}
                    {this.getSelectedImagesToPayFor() > 0 && (
                      <div>
                        {/* <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/> */}
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'left',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginBottom: 0,
                            marginTop: 16,
                            color: 'rgb(40,40,53)',
                          }}
                        >
                          Extra images selected
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(0,0,0,0.6)',
                          }}
                        >
                          {String(this.getSelectedImagesToPayFor())} X{' '}
                          {String(this.getCostForExtraProduct())}ILS
                        </div>
                        <div style={{ height: 50, width: '100%' }}></div>
                        <Divider
                          style={{
                            color: 'rgb(226,225,240)',
                            backgroundColor: 'rgb(226,225,240)',
                          }}
                        />
                      </div>
                    )}

                    {this.state.couponIsValid && (
                      <div>
                        {/* <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/> */}
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'left',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: 16,
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            marginBottom: 0,
                            marginTop: 16,
                            color: 'rgb(40,40,53)',
                          }}
                        >
                          Coupon Discount
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginTop: 16,
                            fontSize: 16,
                            color: 'rgba(0,0,0,0.6)',
                          }}
                        >
                          {this.state.couponType === 'cash' ? (
                            <span>{this.state.couponDiscount} ILS</span>
                          ) : (
                            <span>{this.state.couponDiscount} %</span>
                          )}
                        </div>
                        <div style={{ height: 50, width: '100%' }}></div>
                        <Divider
                          style={{
                            color: 'rgb(226,225,240)',
                            backgroundColor: 'rgb(226,225,240)',
                          }}
                        />
                      </div>
                    )}
                    {/* Extra images selected 
            {this.getSelectedImagesToPayFor() > 0 && (
              <div>
                  <div style={{display: 'inline-block',float: 'left', fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Cost for extra images</div>
                  <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, color: 'rgba(0,0,0,0.6)'}}>{String((this.getSelectedImagesToPayFor() * this.getCostForExtraProduct()).toFixed(2))} {this.state.requestData.request.CurrencyIsoCode}</div>
                  <div style={{height: 50, width: '100%'}}></div>
                  <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
              </div>
            )}*/}
                    {/* Total Amount */}
                    <div style={{ height: 12.5, width: '100%' }}></div>
                    <div
                      style={{
                        display: 'inline-block',
                        float: 'left',
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: 16,
                        color: '#333',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        marginBottom: 0,
                        marginTop: 16,
                        color: 'rgb(40,40,53)',
                      }}
                    >
                      Total Amount
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        float: 'right',
                        marginTop: 16,
                        fontSize: 16,
                        color: 'rgba(0,0,0,0.6)',
                      }}
                    >
                      {this.getTotalAmountToPayFor()}{' '}
                      {this.state.requestData.request.CurrencyIsoCode} + VAT
                    </div>
                    <div style={{ height: 50, width: '100%' }}></div>

                    <div style={{ height: 12.5, width: '100%' }}></div>

                    <div
                      style={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: 16,
                        color: '#333',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        marginBottom: 0,
                        color: 'rgb(40,40,53)',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'bottom',
                          width: 'calc(100% - 80px',
                        }}
                      >
                        <TextField
                          id='standard-basic'
                          label='Coupon'
                          error={this.state.errorCoupon}
                          onChange={this.handleChangeCoupon}
                          fullWidth
                        />
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'bottom',
                        }}
                      >
                        {this.state.checkingCoupon ? (
                          <CircularProgress
                            size={40}
                            style={{ marginLeft: 15 }}
                          />
                        ) : (
                          <Button
                            helper
                            helperText='Error coupon'
                            variant='primary'
                            style={{
                              marginLeft: 10,
                              width: 70,
                              height: 40,
                              backgroundColor: '#6C65FF',
                              color: 'white',
                              textTransform: 'none',
                              fontWeight: 'bold',
                              borderRadius: 18,
                              opacity: this.state.canApply ? 1 : 0.5,
                            }}
                            disabled={!this.state.canApply}
                            onClick={() => this.checkIfCouponIsValid()}
                          >
                            Apply
                          </Button>
                        )}
                      </div>
                    </div>
                    {this.state.errorCoupon && (
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: 14,
                          color: '#333',
                          marginTop: 4,
                          textAlign: 'left',
                          marginBottom: 0,
                          color: 'red',
                          width: '100%',
                        }}
                      >
                        {this.state.errorCouponText}
                      </div>
                    )}
                    {this.state.errorCouponText === 'Coupon is valid' && (
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: 14,
                          color: '#333',
                          marginTop: 4,
                          textAlign: 'left',
                          marginBottom: 0,
                          color: 'green',
                          width: '100%',
                        }}
                      >
                        {this.state.errorCouponText}
                      </div>
                    )}

                    <div style={{ height: 62.5, width: '100%' }}></div>

                    {/* <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
             <div style={{display: 'inline-block',float: 'left', fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Extra photo price</div>
             <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.state.extraImagePrice != 0 && (<div>{this.state.requestData.product.Price_Per_Extra_Product__c} ILS</div>)}</div>
             <div style={{height: 50, width: '100%'}}></div>
             {this.state.extraImageDiscount != null && (
               <div style={{width: '100%'}}>
                <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
                <div style={{display: 'inline-block',float: 'left', fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Discount</div>
                <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.state.extraImagePrice != 0 && (<div>{this.state.extraImageDiscount}%</div>)}</div>
               </div>
             )}
             <div style={{height: 50, width: '100%'}}></div>
             <div style={{fontWeight: 'bold', fontFamily: "'Montserrat', sans-serif", fontSize: 20, color: '#333', textAlign: 'left', marginBottom: 20, marginTop: 16, color: 'rgb(40,40,53)'}}>My Selection ({this.state.selectedImages.length} / {this.state.editedImages.imagesForGallery.length})</div>
             <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
             <div>
               <div style={{display: 'inline-block',float: 'left', fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Selected Images</div>
               <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.getHowMuchForFree()} x 0 ILS</div>
               <div style={{height: 50, width: '100%'}}></div>
             </div> */}
                    {/* <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/> */}
                    {/* {this.state.selectedImages.length > this.getImagesInPackage() && (
               <div>
                 <div>
                 <div style={{display: 'inline-block',float: 'left',  fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Extra Images</div>
                  <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.getNumberOfExtraImagesToPay()} x {this.state.requestData.product.Price_Per_Extra_Product__c} ILS</div>
                  <div style={{height: 50, width: '100%'}}></div>
                 </div>
                 {this.state.extraImageDiscount != null && (
                   <div>
                    <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
                     <div style={{display: 'inline-block',float: 'left',  fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Extra Image Discount</div>
                     <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>-{( (this.getNumberOfExtraImagesToPay()) * (this.state.requestData.product.Price_Per_Extra_Product__c - this.state.extraImagePrice)).toFixed(2)} ILS</div>
                     <div style={{height: 50, width: '100%'}}></div>
                   </div>
                 )}
                </div>
              )} */}
                    {/* {this.state.requestData != null && (
                <div>
                 {this.state.requestData.request.Paid__c == false && (
                   <div>
                    <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
                    <div style={{display: 'inline-block',float: 'left',  fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>{this.state.requestData.product.Public_Product_Name__c}</div>
                    <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.state.requestData.request.Total_Cost__c} ILS</div>
                    <div style={{height: 50, width: '100%'}}></div>
                   </div>
                  )}
                </div>
              )} */}
                    {/* {(this.state.selectedImages.length > this.getImagesInPackage()) || (this.state.requestPaid == false) ? (
                <div>
                 <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
                 <div style={{display: 'inline-block',float: 'left', fontWeight: 'bold', fontFamily: "'Montserrat', sans-serif", fontSize: 16, color: '#333', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Order Totals</div>
                 {this.state.requestPaid == true ? (
                   <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, color: 'rgb(94,44,237)', fontWeight: 'bold'}}>{((this.getNumberOfExtraImagesToPay()) * this.state.extraImagePrice).toFixed(2)} ILS + VAT</div>
                 ) : (
                   <div>
                    {this.state.selectedImages.length > this.getImagesInPackage() ? (
                      <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, color: 'rgb(94,44,237)', fontWeight: 'bold'}}>{((this.getNumberOfExtraImagesToPay()) * this.state.extraImagePrice + this.state.requestData.request.Total_Cost__c).toFixed(2)} ILS + VAT</div>
                    ) : (
                      <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, color: 'rgb(94,44,237)', fontWeight: 'bold'}}>{(this.state.requestData.request.Total_Cost__c).toFixed(2)} ILS + VAT</div>
                    )}
                   </div>
                 )}
                 <div style={{height: 50, width: '100%'}}></div>
               </div>
             ) : (<div></div>)}
             <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/> */}
                    <div
                      style={{
                        width: '100%',
                        marginBottom: 10,
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: 'thin',
                      }}
                    >
                      <div style={{ marginTop: 15 }}>
                        <div style={{ width: '100%' }}>
                          <div style={{ height: 0, width: '100%' }}></div>
                          {this.state.confirmSelect && (
                            <div>
                              <div style={{ textAlign: 'left' }}>
                                <Button
                                  onClick={() => this.confirmSelection()}
                                  variant='contained'
                                  type='submit'
                                  elevation={0}
                                  style={{
                                    color: 'white',
                                    marginTop: 10,
                                    marginBottom: 5,
                                    height: 48,
                                    borderRadius: 24,
                                    background: '#33D7A0',
                                    boxShadow:
                                      '0px 10px 40px -10px rgba(0,0,0,0.60)',
                                  }}
                                  className='flash-button'
                                >
                                  Confirm Selection
                                </Button>
                              </div>
                              <div style={{ height: 15, width: '100%' }}></div>
                            </div>
                          )}
                          {
                            //
                          }
                          <div style={{ textAlign: 'left' }}>
                            {this.state.requestData && (
                              <>
                                {this.state.requestData.request
                                  .Matterport_Link__c !== null &&
                                  this.state.requestData.request.Paid__c ===
                                    true && (
                                    <Button
                                      onClick={() =>
                                        window.open(
                                          this.state.requestData.request
                                            .Matterport_Link__c,
                                          '_blank'
                                        )
                                      }
                                      variant='contained'
                                      type='submit'
                                      elevation={0}
                                      style={{
                                        color: 'white',
                                        marginTop: 10,
                                        marginBottom: 5,
                                        height: 48,
                                        borderRadius: 24,
                                        background: 'red',
                                        boxShadow:
                                          '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                      }}
                                      className='flash-button'
                                      style={{
                                        color: 'white',
                                        marginTop: 10,
                                        marginBottom: 10,
                                        height: 48,
                                        borderRadius: 24,
                                        background: '#33D7A0',
                                        boxShadow:
                                          '0px 10px 40px -10px rgba(0,0,0,0.60)',
                                      }}
                                    >
                                      <PlayCircleFilledIcon
                                        style={{ marginRight: 8 }}
                                      />{' '}
                                      Matterport Link
                                    </Button>
                                  )}
                              </>
                            )}
                            {this.state.requestData && (
                              <>
                                {console.log(
                                  this.state.requestData.request
                                    .Matterport_Video_Link__c
                                )}
                                {console.log(
                                  this.state.requestData.request.Paid__c
                                )}
                                {this.state.requestData.request
                                  .Matterport_Video_Link__c !== null &&
                                  this.state.requestData.request.Paid__c ===
                                    true && (
                                    <Button
                                      onClick={() =>
                                        window.open(
                                          this.state.requestData.request
                                            .Matterport_Video_Link__c,
                                          '_blank'
                                        )
                                      }
                                      variant='contained'
                                      type='submit'
                                      elevation={0}
                                      style={{
                                        color: 'white',
                                        marginTop: 10,
                                        marginBottom: 5,
                                        height: 48,
                                        borderRadius: 24,
                                        background: 'red',
                                        boxShadow:
                                          '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                      }}
                                      className='flash-button'
                                      style={{
                                        color: 'white',
                                        marginTop: 10,
                                        marginBottom: 10,
                                        height: 48,
                                        borderRadius: 24,
                                        background: '#33D7A0',
                                        boxShadow:
                                          '0px 10px 40px -10px rgba(0,0,0,0.60)',
                                      }}
                                    >
                                      <VideocamRoundedIcon
                                        style={{ marginRight: 8 }}
                                      />{' '}
                                      Matterport Video Link
                                    </Button>
                                  )}
                              </>
                            )}
                            <Tooltip
                              title={
                                this.state.selectedInThePast.length == 0
                                  ? 'Select Images'
                                  : 'Download Zip'
                              }
                              placement='top'
                            >
                              {this.state.selectedInThePast.length ? (
                                <Button
                                  onClick={() =>
                                    this.downloadZipFile(
                                      this.state.selectedInThePast,
                                      'WeSnapp_' +
                                        this.state.requestName +
                                        '_edited.zip'
                                    )
                                  }
                                  variant='contained'
                                  type='submit'
                                  elevation={0}
                                  style={{
                                    color: 'white',
                                    marginTop: 10,
                                    marginBottom: 5,
                                    height: 48,
                                    borderRadius: 24,
                                    background: '#33D7A0',
                                    boxShadow:
                                      '0px 10px 40px -10px rgba(0,0,0,0.60)',
                                  }}
                                  className='flash-button'
                                >
                                  <CloudDownload style={{ marginRight: 8 }} />{' '}
                                  Download Images
                                </Button>
                              ) : (
                                <Button
                                  variant='contained'
                                  type='submit'
                                  elevation={0}
                                  style={{
                                    color: 'white',
                                    marginTop: 10,
                                    marginBottom: 5,
                                    height: 48,
                                    borderRadius: 24,
                                    background: 'red',
                                    boxShadow:
                                      '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                  }}
                                  className='flash-button'
                                  style={{
                                    boxShadow:
                                      '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                    borderRadius: 24,
                                    borderColor: 'rgb(94,44,237)',
                                    color: '#6C65FF',
                                    backgroundColor: 'white',
                                    cursor: 'pointer',
                                    height: 48,
                                    border: '1px solid #6C65FF',
                                  }}
                                >
                                  <CloudDownload style={{ marginRight: 8 }} />{' '}
                                  Download Images
                                </Button>
                              )}
                            </Tooltip>
                          </div>
                          <div style={{ height: 15, width: '100%' }}></div>
                          {
                            //
                          }
                          <div style={{ textAlign: 'left' }}>
                            <Tooltip
                              title={
                                this.state.selectedInThePast.length > 0 &&
                                this.state.logo_preferences.url != ''
                                  ? 'Download Zip'
                                  : 'Select Logo & Images'
                              }
                              placement='top'
                            >
                              {this.state.selectedInThePast.length > 0 &&
                              this.state.logo_preferences.url != '' ? (
                                <Button
                                  onClick={() =>
                                    this.downloadZipFileWatermark(
                                      this.state.selectedInThePast,
                                      'WeSnapp_' +
                                        this.state.requestName +
                                        '_edited.zip'
                                    )
                                  }
                                  variant='contained'
                                  type='submit'
                                  elevation={0}
                                  style={{
                                    color: 'white',
                                    marginTop: 10,
                                    marginBottom: 5,
                                    height: 48,
                                    borderRadius: 24,
                                    background: '#6C65FF',
                                    boxShadow:
                                      '0px 10px 40px -10px rgba(0,0,0,0.60)',
                                  }}
                                  className='flash-button'
                                >
                                  <CloudDownload style={{ marginRight: 8 }} />{' '}
                                  Download With Logo
                                </Button>
                              ) : (
                                <Button
                                  variant='contained'
                                  type='submit'
                                  elevation={0}
                                  style={{
                                    color: 'white',
                                    marginTop: 10,
                                    marginBottom: 5,
                                    height: 48,
                                    borderRadius: 24,
                                    background: 'red',
                                    boxShadow:
                                      '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                  }}
                                  className='flash-button'
                                  style={{
                                    boxShadow:
                                      '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                    borderRadius: 24,
                                    borderColor: 'rgb(94,44,237)',
                                    color: '#6C65FF',
                                    backgroundColor: 'white',
                                    cursor: 'pointer',
                                    height: 48,
                                    border: '1px solid #6C65FF',
                                  }}
                                >
                                  <CloudDownload style={{ marginRight: 8 }} />{' '}
                                  Download with logo
                                </Button>
                              )}
                            </Tooltip>
                          </div>
                          <div style={{ height: 15, width: '100%' }}></div>

                          <div style={{ textAlign: 'left' }}>
                            <Tooltip
                              title={
                                this.state.selectedInThePast.length == 0
                                  ? 'Select Images'
                                  : 'Mail Images'
                              }
                              placement='top'
                            >
                              {this.state.selectedInThePast.length ? (
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      openEmailInputDialog: true,
                                      emailType: 'regular',
                                    })
                                  }
                                  variant='contained'
                                  type='submit'
                                  elevation={0}
                                  style={{
                                    color: 'white',
                                    marginTop: 10,
                                    marginBottom: 5,
                                    height: 48,
                                    borderRadius: 24,
                                    background: '#5D2CED',
                                    boxShadow:
                                      '0px 10px 40px -10px rgba(0,0,0,0.60)',
                                  }}
                                  className='flash-button'
                                >
                                  <EmailIcon style={{ marginRight: 8 }} /> Send
                                  to my mail
                                </Button>
                              ) : (
                                <Button
                                  variant='contained'
                                  type='submit'
                                  elevation={0}
                                  style={{
                                    color: 'white',
                                    marginTop: 0,
                                    marginBottom: 5,
                                    height: 48,
                                    borderRadius: 24,
                                    background: 'red',
                                    boxShadow:
                                      '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                  }}
                                  className='flash-button'
                                  style={{
                                    boxShadow:
                                      '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                    borderRadius: 24,
                                    borderColor: 'rgb(94,44,237)',
                                    color: '#6C65FF',
                                    backgroundColor: 'white',
                                    cursor: 'pointer',
                                    height: 48,
                                    border: '1px solid #6C65FF',
                                  }}
                                >
                                  <EmailIcon style={{ marginRight: 8 }} /> Send
                                  to my mail
                                </Button>
                              )}
                            </Tooltip>
                          </div>
                          <div style={{ height: 15, width: '100%' }}></div>

                          <div style={{ textAlign: 'left' }}>
                            <Tooltip
                              title={
                                this.state.selectedInThePast.length > 0 &&
                                this.state.logo_preferences.url != ''
                                  ? 'Mail Images'
                                  : 'Select Logo & Images'
                              }
                              placement='top'
                            >
                              {this.state.selectedInThePast.length > 0 &&
                              this.state.logo_preferences.url != '' ? (
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      openEmailInputDialog: true,
                                      emailType: 'logo',
                                    })
                                  }
                                  variant='contained'
                                  type='submit'
                                  elevation={0}
                                  style={{
                                    color: 'white',
                                    marginTop: 10,
                                    marginBottom: 5,
                                    height: 48,
                                    borderRadius: 24,
                                    background: '#5D2CED',
                                    boxShadow:
                                      '0px 10px 40px -10px rgba(0,0,0,0.60)',
                                  }}
                                  className='flash-button'
                                >
                                  <EmailIcon style={{ marginRight: 8 }} /> Send
                                  with logo to my mail
                                </Button>
                              ) : (
                                <Button
                                  variant='contained'
                                  type='submit'
                                  elevation={0}
                                  style={{
                                    color: 'white',
                                    marginTop: 10,
                                    marginBottom: 5,
                                    height: 48,
                                    borderRadius: 24,
                                    background: 'red',
                                    boxShadow:
                                      '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                  }}
                                  className='flash-button'
                                  style={{
                                    boxShadow:
                                      '0px 5px 31px -5px rgba(0,0,0,0.2)',
                                    borderRadius: 24,
                                    borderColor: 'rgb(94,44,237)',
                                    color: '#6C65FF',
                                    backgroundColor: 'white',
                                    cursor: 'pointer',
                                    height: 48,
                                    border: '1px solid #6C65FF',
                                  }}
                                >
                                  <EmailIcon style={{ marginRight: 8 }} /> Send
                                  with logo to my mail
                                </Button>
                              )}
                            </Tooltip>
                          </div>
                          <div style={{ height: 15, width: '100%' }}></div>
                          <div style={{ height: 20, width: '100%' }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {this.state.sideMenuTab == 1 && (
                <div>
                  {mobile ? (
                    <div
                      style={{
                        fontWeight: 'bold',
                        width: '100%',
                        fontSize: 18,
                        fontFamily: "'Montserrat', sans-serif",
                        textAlign: 'center',
                        marginTop: 50,
                      }}
                    >
                      Oops.. <br />
                      <br />
                      Available only from desktop
                    </div>
                  ) : (
                    <div>
                      {
                        // <div style={{fontWeight: 'bold', fontFamily: "'Montserrat', sans-serif", fontSize: 20, color: '#333', textAlign: 'left', marginBottom: 20, marginTop: 25, color: 'rgb(40,40,53)'}}>Insert Your Logo</div>
                        // <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
                      }
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: 18,
                          color: '#333',
                          fontWeight: 'bold',
                          textAlign: 'left',
                          marginBottom: 15,
                          marginTop: 15,
                          color: 'rgb(40,40,53)',
                        }}
                      >
                        Upload your logo
                      </div>
                      <form onSubmit={this.onFormSubmit}>
                        {
                          //<img src={this.state.logoUrl} alt="Avatar" style={{width: 30, height: 'auto', display: 'inline-block'}}/>
                        }
                        <input
                          type='file'
                          name='myImage'
                          onChange={this.onChangeLogo}
                          style={{
                            display: 'inline-block',
                            textAlign: 'left',
                            width: '100%',
                          }}
                        />
                        {this.state.uploadingWatermark && (
                          <div
                            style={{ marginTop: 0, display: 'inline-block' }}
                          >
                            <CircularProgress
                              size={20}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                        )}
                      </form>
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: 18,
                          color: '#333',
                          fontWeight: 'bold',
                          textAlign: 'left',
                          marginBottom: 15,
                          marginTop: 15,
                          width: '100%',
                          color: 'rgb(40,40,53)',
                        }}
                      >
                        Choose position
                      </div>
                      <div>
                        <RadioGroup
                          aria-label='gender'
                          name='gender2'
                          value={this.state.logo_preferences.logoPosition}
                          onChange={this.handleChangeLogoPosition}
                        >
                          <FormControlLabel
                            value='top_right'
                            control={<Radio color='primary' />}
                            label='Top Right'
                            labelPlacement='end'
                          />
                          <FormControlLabel
                            value='bottom_right'
                            control={<Radio color='primary' />}
                            label='Bottom Right'
                            labelPlacement='end'
                          />
                          <FormControlLabel
                            value='top_left'
                            control={<Radio color='primary' />}
                            label='Top Left'
                            labelPlacement='end'
                          />
                          <FormControlLabel
                            value='bottom_left'
                            control={<Radio color='primary' />}
                            label='Bottom Left'
                            labelPlacement='end'
                          />
                        </RadioGroup>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: 18,
                          color: '#333',
                          fontWeight: 'bold',
                          textAlign: 'left',
                          marginBottom: 15,
                          marginTop: 15,
                          color: 'rgb(40,40,53)',
                        }}
                      >
                        Choose opacity
                      </div>
                      <div style={{ marginTop: 25 }}>
                        <Slider
                          value={this.state.logo_preferences.opacity}
                          defaultValue={this.state.logo_preferences.opacity}
                          getAriaValueText={() => this.handleChangeOpacity}
                          aria-labelledby='discrete-slider-always'
                          onChange={this.handleChangeOpacity}
                          step={10}
                          marks={[]}
                          valueLabelDisplay='auto'
                        />
                      </div>
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: 18,
                          color: '#333',
                          fontWeight: 'bold',
                          textAlign: 'left',
                          marginBottom: 15,
                          marginTop: 15,
                          color: 'rgb(40,40,53)',
                        }}
                      >
                        Choose size
                      </div>
                      <Slider
                        value={this.state.logo_preferences.logoSize}
                        defaultValue={this.state.logo_preferences.logoSize}
                        aria-labelledby='discrete-slider-restrict'
                        step={null}
                        onChange={this.handleChangeSize}
                        valueLabelDisplay='auto'
                        marks={[
                          { value: 33, label: 'small' },
                          { value: 66, label: 'medium' },
                          { value: 100, label: 'large' },
                        ]}
                      />
                      <div
                        style={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: 18,
                          color: '#333',
                          fontWeight: 'bold',
                          textAlign: 'left',
                          marginBottom: 20,
                          marginTop: 25,
                          color: 'rgb(40,40,53)',
                        }}
                      >
                        Preview
                      </div>
                      {this.state.editedImages.imagesForGallery.length > 0 && (
                        <div
                          style={{
                            position: 'relative',
                            textAlign: 'center',
                            color: 'white',
                          }}
                        >
                          <img
                            src={
                              this.state.editedImages.imagesForGallery[0].src
                            }
                            alt='Avatar'
                            className='text'
                            width='80px'
                            height='80px'
                            style={{
                              width: '100%',
                              height: 'auto',
                              borderRadius: 8,
                            }}
                          />
                          {this.state.logo_preferences.url != '' &&
                          this.state.updateInProgress == false ? (
                            <img
                              src={`${this.state.logo_preferences.url}?${this.state.imageHash}`}
                              alt='Avatar'
                              className='text'
                              width='80px'
                              height='80px'
                              style={this.getPreviewStyle()}
                            />
                          ) : (
                            <div>
                              {this.state.updateInProgress == true ? (
                                <div></div>
                              ) : (
                                <img
                                  src={black_logo}
                                  alt='Avatar'
                                  className='text'
                                  width='80px'
                                  height='80px'
                                  style={this.getPreviewStyle()}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Drawer>
        {mobile && this.state.confirmSelect && (
          <Button
            onClick={() =>
              this.downloadZipFile(
                this.state.selectedInThePast,
                'WeSnapp_' + this.state.requestName + '_edited.zip'
              )
            }
            variant='contained'
            type='submit'
            elevation={0}
            style={{
              color: 'white',
              height: 40,
              borderRadius: 20,
              backgroundColor: 'rgb(0,230,18)',
              boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.60)',
            }}
            style={{
              // border: '1px solid rgb(94,44,237)',
              boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.10)',
              borderRadius: 20,
              borderColor: 'rgb(94,44,237)',
              color: 'rgb(94,44,237)',
              backgroundColor: 'rgb(14,209,179)',
              cursor: 'pointer',
              color: 'white',
              height: 40,
              // border: '1px solid rgb(94,44,237)',
              position: 'fixed',
              bottom: 20,
              right: 20,
              zIndex: 0,
            }}
          >
            Confirm selection
          </Button>
        )}
      </div>
    );
  }
}

// ShootingPage.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };
//
// export default withStyles(styles, { withTheme: true })(ShootingPage);

ShootingPage.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ShootingPage);
