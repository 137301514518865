import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';

import BillingIcon from '@material-ui/icons/CreditCard';
import MembersIcon from '@material-ui/icons/AccountTree';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Location from './Location';

import EquipmentsCamerasAndLenses from '../../../Login/Photographer/EquipmentsCamerasAndLenses';
import Equipments3D from '../../../Login/Photographer/Equipments3D';
import EquipmentsDrones from '../../../Login/Photographer/EquipmentsDrones';
import EquipmentsCategory from '../../../Login/Photographer/EquipmentsCategory';
import { TextField } from '@material-ui/core';

const saleBanner = require('../../../../Assets/sale-banner.png');
var mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const cameraIcon = require('../../../../Assets/cameraTableIcon.png');

const noImage = require('../../../../Assets/no_image.png');

const dateIcon = require('../../../../Assets/dateIcon.png');
const locationIcon = require('../../../../Assets/locationIcon.png');
const userIcon = require('../../../../Assets/userIcon.png');
const imageIcon = require('../../../../Assets/imageIcon.png');

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white',
  },
  button: {
    color: 'white',
    height: 50,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 32,
    paddingRight: 32,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: `0 4px 10px ${theme.palette.primary.main}40`,
    borderRadius: 25,
  },
});

class PhotoShoots extends React.Component {
  state = {
    isLoading: false,
    eqType: 0,
    cameras: [],
    isLoading: false,
    drones: [],
    lenses: [],
    trippleD: [],
    updating: false,
  };

  componentWillMount() {}

  componentDidMount = () => {
    var contactId = localStorage.getItem('contactId');
    var cameras = this.props.cameras;
    var lenses = this.props.lenses;
    var drones = this.props.drones;
    var trippleD = this.props.trippleD;
    console.log(cameras);
    console.log(lenses);
    console.log(drones);
    console.log(trippleD);
    this.setState({
      trippleD,
      drones,
      cameras,
      lenses,
    });
  };

  handleChangeText = (name) => (event) => {
    // console.log("@@");
    // console.log( event.target.value);
    this.setState({ [name]: event.target.value });
  };

  handleChangeLocation = (value) => {
    // console.log(value.description);
    this.setState({ address: value.description });
  };

  save = () => {
    var equipmentToTransfer = this.state.cameras
      .concat(this.state.lenses)
      .concat(this.state.drones)
      .concat(this.state.trippleD);
    var equipment = [];
    for (var i = 0; i < equipmentToTransfer.length; i++) {
      equipment.push(equipmentToTransfer[i].value);
    }
    this.saveNewDataInDB(equipment);
  };

  saveNewDataInDB = (equipment) => {
    this.setState({ updating: true });
    var data = {
      id: localStorage.getItem('accountId'),
      equipment: equipment,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/photographer/update-eq',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        this.setState({ updating: false });

        if (this.props.popup) {
          this.props.openSuccess();
          this.props.nextStep();
        } else {
          this.props.main.setState({ openSuccess: true });
        }

        var text =
          '⚠️ The photographer <' +
          'https://wesnapp.lightning.force.com/lightning/r/Account/' +
          localStorage.getItem('accountId') +
          '/view' +
          '|' +
          this.props.userData.Name +
          '> changed his details in equipment settings';
        axios
          .post(
            'https://wesnapp-node-server.appspot.com/api/slack/photographer-notification',
            { text: text },
            { headers: { 'Content-Type': 'application/json' } }
          )
          .then((response) => {})
          .catch((error) => {});
        // Set last update date and time
        axios
          .post(
            'https://us-central1-wesnapp-d136a.cloudfunctions.net/updateContact',
            {
              id: localStorage.getItem('accountId'),
              data: { Last_Confirmed_Settings__c: new Date().toISOString() },
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
          .then((response) => {})
          .catch((error) => {});

        this.props.getUserDetails();
      })
      .catch((error) => {});
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '23px',
          boxShadow: '0px 4px 10px #6334FF10',
          marginTop: 0,
          width: '100%',
          padding: 24,
          marginBottom: 24,
        }}
      >
        <Grid
          container
          spacing={0}
          style={{ padding: 0, paddingTop: 0 }}
          justifyContent='center'
          justify='center'
        >
          <Grid item xs={12} sm={10} md={8} xl={7}>
            <div
              style={{
                fontSize: 20,
                color: 'rgb(40,40,53)',
                textAlign: 'left',
                marginLeft: 0,
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              <b>Equipment Details</b>
            </div>
          </Grid>
          <Grid item xs={12} sm={10} md={8} xl={7}>
            <Grid container spacing={2} dir='ltr'>
              {/* <Grid item xs={3} sm={3} md={3} xl={3}></Grid> */}
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <EquipmentsCategory
                  main={this}
                  style={{ width: '100%' }}
                  settingsPage
                />
              </Grid>
              {/* <Grid item xs={3} sm={3} md={3} xl={3}></Grid> */}
              {this.state.eqType == 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ width: '100%' }}>
                    <EquipmentsCamerasAndLenses
                      style={{ marginTop: 0, width: '100%' }}
                      main={this}
                    />
                  </div>
                </Grid>
              )}
              {this.state.eqType == 1 && (
                <Grid item xs={12} sm={12} md={12}>
                  <EquipmentsDrones style={{ marginTop: 0 }} main={this} />
                </Grid>
              )}
              {this.state.eqType == 2 && (
                <Grid item xs={12} sm={12} md={12}>
                  <Equipments3D style={{ marginTop: 0 }} main={this} />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  label='Comment'
                  id={'extraEquipment_ph'}
                  type={'text'}
                  onChange={this.handleChangeText('comment')}
                  placeholder='Add equipment which is not in the list'
                  style={{ marginLeft: 0 }}
                />
                <div
                  style={{
                    marginLeft: 12,
                    float: 'left',
                    textAlign: 'left',
                    color: 'red',
                    marginTop: 3,
                    textAlign: 'left',
                  }}
                >
                  {this.state.error_comment}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {this.state.updating ? (
                  <div style={{ width: '100%', marginTop: 5 }}>
                    <center>
                      <CircularProgress size={40} />
                    </center>
                  </div>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <center>
                      <Button
                        id={'register_ph'}
                        onClick={() => this.save()}
                        elevation={0}
                        className={classes.button}
                      >
                        <b>{this.props.popup ? 'Done!' : 'Save'}</b>
                      </Button>
                    </center>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
