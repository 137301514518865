import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Switch from '@material-ui/core/Switch';
// import EquipmentsCamerasAndLenses from './EquipmentsCamerasAndLenses';
// import Equipments3D from './Equipments3D';
// import EquipmentsDrones from './EquipmentsDrones';
// import EquipmentsCategory from './EquipmentsCategory'
import SmsDialog from './Photographer/SmsDialog';
import Location from './Photographer/Location';
import Autocomplete from 'react-google-autocomplete';
import uuid from 'react-native-uuid';
import { phone } from 'phone';

// import '../../App.css';
import { BrowserRouter as Router, Link } from 'react-router-dom';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    width: '100%',
    // overflowY: 'scroll',
    // overflowX: 'hidden',
    // maxWidth: '100vw',
    // height: '100%',
    // paddingBottom: 70
    // overflowX: 'hidden',
    // maxWidth: '100vw',

    // overflowY: 'scroll',
    // position: 'fixed',
    //  left: 0
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    height: 50,
    marginTop: 30,
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#ECF1FF',
    },
  },
});

class NewsPage extends React.Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    companyName: '',
    companyNumber: '',
    portfolio: '',
    companyType: '0',
    salutation: '',
    comment: '',
    ip: '',
    eqType: 0,
    isGroup: true,
    acceptTerms: false,
    promoCode: '',
    cameras: [],
    isLoading: false,
    drones: [],
    lenses: [],
    trippleD: [],
    error_firstName: '',
    error_lastName: '',
    error_address: '',
    error_group: '',
    error_companyName: '',
    error_companyNumber: '',
    error_companyType: '',
    error_comment: '',
    error_acceptTerms: '',
    error_phone: '',
    phone: '',
    notInList: false,
    smsDialog: false,
    countryCode: '972',
    inputCode: '',
    errorCode: false,
    code: '',
    formattedAddress: '',
    lat: '',
    lng: '',
    registrationStatus: '',
  };

  startLoading = () => {
    this.setState({ isLoading: true });
  };

  stopLoading = () => {
    this.setState({ isLoading: false });
  };

  componentDidMount() {
    this.getUserIP();
    // this.props.startLoading()
  }

  validateMobile = (phone) => {
    console.log(phone);
    var substring1 = ' 9725';
    var substring2 = ' 97205';
    if (phone.includes(substring1) || phone.includes(substring2)) {
      // console.log("@@");
      if (phone.indexOf(substring1) !== -1) {
        // console.log(phone);
        phone = phone.replace(' 9725', '05');
        phone = phone.replace('-', '');
        if (
          phone[0] == '0' &&
          phone[1] == '5' &&
          (phone[2] == '2' ||
            phone[2] == '0' ||
            phone[2] == '3' ||
            phone[2] == '4' ||
            phone[2] == '5' ||
            phone[2] == '8' ||
            phone[2] == '3')
        ) {
          if (phone.length == 10 && /^\d+$/.test(phone)) {
            this.setState({ phone: phone });
            return true;
          }
        } else {
          return false;
        }
      } else {
        phone = phone.replace(' 97205', '05');
        phone = phone.replace('-', '');
        // console.log(phone);
        if (
          phone[0] == '0' &&
          phone[1] == '5' &&
          (phone[2] == '2' ||
            phone[2] == '0' ||
            phone[2] == '3' ||
            phone[2] == '4' ||
            phone[2] == '5' ||
            phone[2] == '8' ||
            phone[2] == '3')
        ) {
          if (phone.length == 10 && /^\d+$/.test(phone)) {
            this.setState({ phone: phone });
            return true;
          }
        } else {
          return false;
        }
      }
    } else {
      phone = phone.replace('-', '');
      if (
        phone[0] == '0' &&
        phone[1] == '5' &&
        (phone[2] == '2' ||
          phone[2] == '0' ||
          phone[2] == '3' ||
          phone[2] == '4' ||
          phone[2] == '5' ||
          phone[2] == '8' ||
          phone[2] == '3')
      ) {
        if (phone.length == 10 && /^\d+$/.test(phone)) {
          this.setState({ phone: phone });
          return true;
        }
      } else {
        return false;
      }
    }
  };

  handleChangeSwitch = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  getUserIP = () => {
    axios
      .get(
        'https://httpbin.org/ip',
        {},
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // console.log("IP");
        var ip = response.data.origin.split(',')[0];
        // console.log(ip);
        this.setState({ ip: ip });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  handleChangeText = (name) => (event) => {
    // console.log("@@");
    // console.log( event.target.value);
    this.setState({ [name]: event.target.value });
  };

  handleChangeLocation = (value) => {
    // console.log(value.description);
    this.setState({ address: value.description });
  };

  handleChangeSalutation = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  registerFinished = (accountId, contactId) => {
    this.props.main.setState({ completed: true });
    console.log(accountId);
    console.log(contactId);
    localStorage.setItem('accountId', accountId);
    localStorage.setItem('contactId', contactId);
    var token = uuid.v1();
    this.updateToken(token, accountId);
  };

  registerFailed = (data) => {
    this.props.handleOpenAlert('Oops, something went wrong!');
  };

  // saveUUID = (token, accountId) => {
  //   var data = {"id": accountId}
  //   axios.post('https://wesnapp-node-server.appspot.com/api/login/save-token',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
  //     const cookies = new Cookies();
  //     cookies.set('wesnapp-token', response.data.token, { path: '/' });
  //     window.location.reload();
  //   })
  //   .catch( (error) => {
  //     window.location.reload();
  //     //console.log(error);
  //   });
  // }

  updateToken = (token, accountId) => {
    var data = { token: token, accountId: accountId };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/contact/update-token',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        if (response.data === true) {
          localStorage.setItem('wesnapp-token', token);
          console.log('reroute');
          this.props.main.setState({ auth: true }, () => {
            this.stopLoading();
            this.props.history.push('/c/dashboard/photo-shoots');
            window.location.reload();
          });
        } else {
          // this.setState({auth: false})
        }
      })
      .catch((error) => {});
  };

  checkEquipment = () => {
    var allow = true;
    if (this.state.cameras.length > 0) {
      if (this.state.comment !== '' || this.state.lenses.length > 0) {
        return true;
      } else {
        if (
          this.state.drones.length !== 0 ||
          this.state.trippleD.length !== 0
        ) {
          return true;
        } else {
          if (this.state.comment !== '') {
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      if (this.state.drones.length > 0 || this.state.trippleD.length > 0) {
        return true;
      } else {
        if (this.state.comment !== '') {
          return true;
        } else {
          return false;
        }
      }
    }
    // if (this.state.cameras.length == 0 && this.state.lenses.length == 0) {
    //   if (this.state.comment == "") {
    //     return true
    //   } else {
    //     return false
    //   }
    // } else {
    //   return false
    // }
  };

  checkGroup = () => {
    if (this.state.isGroup) {
      if (
        this.state.companyName == '' ||
        this.state.companyNumber == '' ||
        this.state.companyType == '0'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  registerClicked = () => {
    // this.props.startLoading()
    var allowToContinue = true;
    this.setState({
      error_phone: '',
      error_firstName: '',
      error_lastName: '',
      error_email: '',
      error_address: '',
      error_group: '',
      error_acceptTerms: '',
      error_comment: '',
    });
    if (this.state.firstName == '') {
      this.setState({ error_firstName: 'First name empty' });
      console.log('first name');
      allowToContinue = false;
    }
    if (this.state.lastName == '') {
      console.log('last name');
      this.setState({ error_lastName: 'Last name empty' });
      allowToContinue = false;
    }
    if (this.state.email == '') {
      console.log('email');
      this.setState({ error_email: 'Email empty' });
      allowToContinue = false;
    } else {
      if (!this.validateEmail(this.state.email)) {
        this.setState({ error_email: 'Email is not valid' });
        console.log('email2');
        allowToContinue = false;
      }
    }
    if (!this.validateMobile(this.state.phone)) {
      this.setState({ error_phone: 'Error phone number' });
      console.log('mobile');
      allowToContinue = false;
    }
    if (
      this.state.formattedAddress === '' ||
      this.state.lat === '' ||
      this.state.lng === ''
    ) {
      this.setState({
        error_address: "We wasn't able to find the locations. Check again",
      });
      console.log('address');
      allowToContinue = false;
    }
    if (this.state.acceptTerms == false) {
      console.log('terms');
      this.setState({ error_acceptTerms: 'Accept the Terms & Conditions' });
      allowToContinue = false;
    }
    if (allowToContinue) {
      this.continueToVerification();
    } else {
      // alert('no')
    }
  };

  getCompanyType = (id) => {
    switch (id) {
      case '0':
        return 'Ltd';
        break;
      case '1':
        return 'SOHO';
        break;
      case '2':
        return 'Society';
        break;
      default:
        return 'Society';
    }
  };

  registerPhotographer = () => {
    this.startLoading();
    var companyType = '';
    if (!this.props.campaign) {
      if (this.state.isGroup == true) {
        companyType = this.getCompanyType(this.state.companyType);
      }
    }
    var equipmentsToTransfer = this.state.cameras
      .concat(this.state.lenses)
      .concat(this.state.drones)
      .concat(this.state.trippleD);
    var equipments = [];
    for (var i = 0; i < equipmentsToTransfer.length; i++) {
      equipments.push(equipmentsToTransfer[i].value);
    }
    var data = {
      salutation: this.state.salutation,
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      name: this.state.firstName + ' ' + this.state.lastName,
      email: this.state.email,
      mobile: this.state.phone,
      country: this.state.country,
      city: this.state.city,
      street: this.state.street,
      another_equipment: this.state.comment,
      company_legal_name: this.state.companyName,
      company_registration_number: this.state.companyNumber,
      lat: this.state.lat,
      lng: this.state.lng,
      //  company_type: companyType,
      recruit_status: 'Registered',
      equipments: equipments,
      portfolio_link: this.state.portfolio,
      //  isGroup: this.state.isGroup,
      isGroup: false,
      acceptTerms: this.state.acceptTerms,
      ip: this.state.ip,
    };
    console.log(data);
    ////console.log(
    // axios.post('https://wesnapp-node-server.appspot.com/api/client/campaign-register',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    // axios.post('http://localhost:8080/api/client/campaign-register',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/client/campaign-register',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log('result');
        console.log(response.data);

        if (response.data.constructor === Object) {
          // console.log("inside");
          if (response.data.success === 'registeration completed.') {
            this.props.main.setState({ registrationStatus: 'ok' });
            if (window.location.pathname.includes('signup/client')) {
              // alert('x')
              this.registerFinished(
                response.data.accountId,
                response.data.contactId
              );
            } else {
              this.stopLoading();
            }
          } else {
            // this.registerFailed(response.data)
            this.stopLoading();
            this.props.main.setState({ registrationStatus: 'not-ok' });
          }
        } else {
          // this.registerFailed(response.data)
          this.stopLoading();
          this.props.main.setState({ registrationStatus: 'not-ok' });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  generateRandomCode = () => {
    var code = Math.floor(1000 + Math.random() * 9000);
    return code;
  };

  continueToVerification = () => {
    // alert('x')
    this.setState({ smsDialog: true });
    this.setState({ code: this.generateRandomCode() }, () => {
      this.sendVerificationCode();
    });
  };

  reverseString = (str) => {
    // Step 1. Use the split() method to return a new array
    var splitString = str.split(''); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    var joinArray = reverseArray.join(''); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    //Step 4. Return the reversed string
    return joinArray; // "olleh"
  };

  verifyCode = () => {
    this.setState({ errorCode: false });
    console.log(String(this.state.code));
    console.log(String(this.state.inputCode));
    if (
      String(this.state.code) === String(this.state.inputCode) ||
      String(this.state.code) ===
        this.reverseString(String(this.state.inputCode))
    ) {
      this.setState({ smsDialog: false }, () => {
        this.registerPhotographer();
      });
    } else {
      this.setState({ errorCode: true });
    }
  };

  sendVerificationCode = () => {
    var data = {
      phone: '+' + this.state.countryCode + this.state.phone,
      text: 'WeSnapp verification code: ' + this.state.code,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/sms/send-message',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  handleGroupOrNot = (e) => {
    this.setState({ isGroup: e.target.checked });
  };

  handleChangeTypeOfCompany = (e) => {
    this.setState({ companyType: e.target.value });
  };

  acceptTermsAndConditions = (e) => {
    this.setState({ acceptTerms: e.target.checked });
  };

  getCoordinates = async (address) => {
    // alert(address)

    try {
      var link =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        encodeURI(address) +
        '&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s';
      const response = await axios.get(link);
      console.log(response.data.results[0]);
      if (response.data.results.length > 0) {
        if (response.data.results[0].types.includes('street_address')) {
          var address = response.data.results[0].formatted_address.split(', ');
          var street = address[0];
          var city = address[1];
          var country = address[2];
          console.log(address);

          this.setState(
            {
              formattedAddress: address,
              error_address: '',
              lat: response.data.results[0].geometry.location.lat,
              lng: response.data.results[0].geometry.location.lng,
              city: city,
              street: street,
              country: country,
            },
            () => {}
          );
        } else {
          this.setState({
            error_address:
              'Please choose specific address (including street number)',
          });
        }
      } else {
        this.setState({
          error_address: "We wasn't able to find the locations. Check again",
        });
      }
    } catch (err) {
      this.setState({
        error_address: "We wasn't able to find the locations. Check again",
      });
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <SmsDialog main={this} />
        {this.state.isLoading === true && (
          <div
            className='loading'
            style={{
              backgroundColor: 'rgba(255,255,255,0.8)',
              minWidth: '100vw',
              height: '100vh',
              backdropFilter: 'blur(4px)',
            }}
          >
            <CircularProgress style={{ marginTop: 'calc(50vh - 50px)' }} />
            <div>
              <div
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: mobile ? 25 : 20,
                  padding: mobile ? 20 : 0,
                  color: 'black',
                  marginTop: 20,
                  fontWeight: 'bold',
                }}
              >
                Please wait, the process may take a few seconds
              </div>
            </div>
          </div>
        )}
        <Grid container spacing={2} dir='ltr'>
          <Grid item xs={6} sm={6} md={6}>
            <input
              id={'firstName_ph'}
              type={'text'}
              className='regInput'
              onChange={this.handleChangeText('firstName')}
              placeholder='First name*'
              style={{ float: 'right' }}
            />
            <div
              style={{
                marginLeft: 12,
                float: 'left',
                color: 'red',
                marginTop: 3,
                textAlign: 'left',
              }}
            >
              {this.state.error_firstName}
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <input
              id={'lastName_ph'}
              type={'text'}
              className='regInput'
              onChange={this.handleChangeText('lastName')}
              placeholder='Last name*'
              style={{ float: 'right' }}
            />
            <div
              style={{
                marginLeft: 12,
                float: 'left',
                color: 'red',
                marginTop: 3,
                textAlign: 'left',
              }}
            >
              {this.state.error_lastName}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <input
              id={'email_ph'}
              type={'email'}
              className='regInput'
              onChange={this.handleChangeText('email')}
              placeholder='Email*'
              style={{ float: 'right' }}
            />
            <div
              style={{
                marginLeft: 12,
                float: 'left',
                color: 'red',
                marginTop: 3,
                textAlign: 'left',
              }}
            >
              {this.state.error_email}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <input
              id={'phone_ph'}
              type={'tel'}
              className='regInput'
              onChange={this.handleChangeText('phone')}
              placeholder='Phone*'
              style={{ float: 'right' }}
            />
            <div
              style={{
                marginLeft: 12,
                float: 'left',
                color: 'red',
                marginTop: 3,
                textAlign: 'left',
              }}
            >
              {this.state.error_phone}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Location main={this} />
            <div
              style={{
                marginLeft: 12,
                float: 'left',
                color: 'red',
                marginTop: 3,
                textAlign: 'left',
              }}
            >
              {this.state.error_address}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
            <label class='container'>
              I accept the{' '}
              <a
                target='_blank'
                href='https://www.wesnapp.io/en/terms-conditions/'
              >
                <b style={{ color: 'rgb(135,142,225)' }}>
                  Terms and Conditions
                </b>
              </a>{' '}
              and the{' '}
              <a
                target='_blank'
                href='https://www.wesnapp.io/en/privacy-policy-2/'
              >
                <b style={{ color: 'rgb(135,142,225)' }}>Privacy Policy</b>
              </a>
              <input
                id={'accept_terms_and_conditions_ph'}
                type='checkbox'
                checked={this.state.acceptTerms}
                onChange={this.acceptTermsAndConditions}
              />
              <span class='checkmark'></span>
            </label>
            <div
              style={{
                marginLeft: 0,
                float: 'left',
                color: 'red',
                marginTop: 3,
                textAlign: 'left',
              }}
            >
              {this.state.error_acceptTerms}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              id={'register_ph'}
              onClick={() => this.registerClicked()}
              elevation={0}
              style={{
                textTransform: 'none',
                marginTop: 4,
                marginBottom: 0,
                height: 50,
                width: 150,
                borderRadius: 25,
                float: 'left',
                textColor: 'white',
                color: 'white',
                background: 'linear-gradient(to right , #6334FF, #6334FF)',
              }}
              className={classes.button}
            >
              <b>Register</b>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <Link to='/login'>
                <div
                  color='primary'
                  style={{
                    marginTop: 30,
                    marginBottom: 70,
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontSize: 16,
                    width: '100%',
                    textAlign: 'left',
                  }}
                >
                  <u>Already have an account? Sign in now!</u>
                </div>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NewsPage);
