import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import SmsDialog from './SmsDialog';
import TypeDialog from './TypeDialog';
import { AuthContext } from '../../contexts/Auth';
import { CircularProgress, Container, Hidden } from '@material-ui/core';
import history from '../../utils/history';

import MuiPhoneNumber from 'material-ui-phone-number';
import { phone } from 'phone';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 28,
      borderColor: '#DAE2FD',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#ecf1fe',
      borderRadius: 28,
    },
  },
  grow: {
    flexGrow: 1,
    fontSize: '20px',
    textAlign: 'left',
  },
  margin: {
    margin: 4,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  loginComponent: {
    display: 'flex',
    background: '#F9F8FD',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  button: {
    height: 50,
    marginTop: 60,
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
  },
});

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
class MainLogin extends React.Component {
  static contextType = AuthContext;

  state = {
    errorPhoneNumber: '',
    phone: '',
    smsDialog: false,
    errorCode: false,
    inputCode: '',
    code: '',
    countryCode: '972',
    auth: false,
    isLoading: false,
    adminClicks: 0,
    adminOpen: false,
    contactId: '',
    errorPhoneNumberNotExists: false,
    openTypeDialog: false,
  };

  sendVerificationCode = () => {
    var data = {
      phone: phone(this.state.phone, { validateMobilePrefix: false })
        .phoneNumber,
      text: 'WeSnapp verification code: ' + this.state.code,
    };
    // alert(this.state.code)

    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/sms/send-message',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  checkIfUserValid = () => {
    var data = {
      phone: phone(this.state.phone, { validateMobilePrefix: false })
        .phoneNumber,
    };
    //
    this.setState({ isLoading: true });
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/contact/find-account-by-phone-number',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          console.log(response.data[0]);
          // localStorage.setItem("contactId", String(response.data[0].Id));
          // localStorage.setItem("accountId", String(response.data[0].AccountId));
          localStorage.setItem(
            'contactId',
            String(response.data[0].PersonContactId)
          );
          localStorage.setItem('accountId', String(response.data[0].Id));
          console.log(response.data[0].RecordTypeId);
          console.log(response.data[0].PersonContactId);
          console.log(response.data[0].Id);
          // Photographer 0121t000000QmPoAAK
          // Client       0121t000000Qm7GAAS
          const RecordTypeId = response.data[0].RecordTypeId;

          this.setState(
            {
              recordTypeId: RecordTypeId,
              isLoading: false,
              contactId: response.data[0].PersonContactId,
              accountId: response.data[0].Id,
            },
            () => {
              this.setState(
                {
                  errorPhone: false,
                  smsDialog: true,
                  code: this.generateRandomCode(),
                },
                () => {
                  if (this.state.adminOpen) {
                    var token = this.generateToken(64);
                    this.updateToken(token);
                  } else {
                    this.sendVerificationCode();
                  }
                }
              );
            }
          );
        } else {
          console.log('not exists');
          this.setState({ isLoading: false, errorPhoneNumberNotExists: true });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  verifyCode = () => {
    this.setState({ errorCode: false });
    if (String(this.state.code) === String(this.state.inputCode)) {
      this.setState({ smsDialog: false });
      var token = this.generateToken(64);
      this.updateToken(token);
    } else {
      this.setState({ errorCode: true });
    }
  };

  updateToken = (token) => {
    var data = { token: token, accountId: this.state.accountId };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/contact/update-token',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        if (response.data === true) {
          localStorage.setItem('wesnapp-token', token);
          console.log('reroute');
          this.context.setIsLoggedIn(true);
          if (this.state.recordTypeId === '0121t000000Qm7GAAS') {
            this.context.setAccountType('client');
            this.props.history.push('/c/dashboard/photo-shoots');
          } else {
            this.context.setAccountType('photographer');
            this.props.history.push('/p/dashboard/photo-shoots');
          }
        } else {
          this.setState({ auth: false });
        }
      })
      .catch((error) => {});
  };

  generateRandomCode = () => {
    var code = Math.floor(1000 + Math.random() * 9000);
    return code;
  };

  authUser = () => {
    this.setState({ errorPhoneNumberNotExists: false, errorPhoneNumber: '' });
    if (this.validateMobile(this.state.phone)) {
      // Check if in system
      this.checkIfUserValid();
    } else {
      this.setState({ errorPhoneNumber: 'Phone number is not valid' });
    }
  };

  generateToken = (n) => {
    var chars =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var token = '';
    for (var i = 0; i < n; i++) {
      token += chars[Math.floor(Math.random() * chars.length)];
    }
    return token;
  };

  validateMobile = (phonee) => {
    return phone(this.state.phone, { validateMobilePrefix: false }).isValid;
  };

  componentDidMount() {
    console.log(this.context.isLoggedIn);
  }

  handleAdminOnClick = () => {
    var adminClicks = this.state.adminClicks;
    adminClicks++;
    if (adminClicks >= 8) {
      this.setState({ adminOpen: true, adminClicks: adminClicks });
      alert('Welcome admin ;)');
    } else {
      this.setState({ adminClicks: adminClicks });
    }
  };

  handleChangeCode = (event) => {
    this.setState({ countryCode: event.target.value });
  };

  handleChangeText = (name) => (event) => {
    if (name === 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  getHelperText = () => {
    if (this.state.errorPhoneNumber)
      return 'Check country code and phone number';
    else if (this.state.errorPhoneNumberNotExists)
      return 'The given phone number does not exist in the system';
    return '';
  };

  handleChangePhone = (value) => {
    if (value) {
      this.setState({
        phone: value
          .replace(/-/g, '')
          .replace('(', '')
          .replace(')', '')
          .replace(/ /g, ''),
      });
      // this.setState({ phone: value.replace(/-/g, '').replace('(', '').replace(')', '').replace(/ /g, '').replace('+97205', '9725') });
    }
  };

  setOpenTypeDialog = (value) => {
    this.setState({ openTypeDialog: value });
  };

  validatePhoneForE164 = () => {
    if (phone(this.state.phone, { validateMobilePrefix: false }).isValid) {
      return true;
    }
    return false;
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <SmsDialog main={this} />
        <TypeDialog
          setOpen={this.setOpenTypeDialog}
          open={this.state.openTypeDialog}
          history={history}
        />
        <Grid container spacing={0}>
          <Hidden smDown>
            <Grid
              item
              xs={0}
              sm={0}
              md={4}
              xl={4}
              style={{ background: '#F9F8FD' }}
            >
              <div style={{ width: '100%', backgroundColor: '#ECF1FF' }}>
                <img
                  alt=''
                  src={require('../../Assets/photographerImage.jpg')}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    height: '100vh',
                  }}
                />
              </div>
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            xl={8}
            className={classes.loginComponent}
          >
            <Container maxWidth={'sm'} disableGutters={true}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: mobile ? 16 : 32,
                }}
              >
                <Hidden mdUp>
                  <img
                    alt=''
                    src={require('../../Assets/logo.png')}
                    style={{
                      height: mobile ? '24px' : '48px',
                      position: 'fixed',
                      top: 16,
                      left: 16,
                    }}
                  />
                  <div
                    style={{
                      fontSize: mobile ? '36px' : '42px',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      color: '#3f4148',
                    }}
                    onClick={() => this.handleAdminOnClick()}
                  >
                    <div style={{ marginTop: 18, color: '#303030' }}>
                      Welcome back
                    </div>
                  </div>
                </Hidden>
                <Hidden smDown>
                  <div
                    style={{
                      fontSize: mobile ? '28px' : '44px',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      color: '#3f4148',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => this.handleAdminOnClick()}
                  >
                    <div style={{ marginRight: 12, color: '#303030' }}>
                      Welcome to
                    </div>
                    <img
                      alt=''
                      src={require('../../Assets/logo.png')}
                      style={{ height: mobile ? '32px' : '48px' }}
                    />
                  </div>
                </Hidden>
                <MuiPhoneNumber
                  style={{ width: '100%', marginTop: 42 }}
                  name='phone'
                  disableAreaCodes={true}
                  label='Phone Number'
                  data-cy='user-phone'
                  onlyCountries={['il', 'us', 'fr', 'gb']}
                  defaultCountry={'il'}
                  value={this.state.phone}
                  onChange={this.handleChangePhone}
                />
                {/* {(!this.validatePhoneForE164() && this.state.phone) && (
                  <div className={classes.errorMessage}>Phone number is not valid</div>
                )} */}
                {!this.validatePhoneForE164() && this.state.phone && (
                  <div
                    style={{
                      marginTop: 16,
                      textDecoration: 'none',
                      cursor: 'pointer',
                      fontSize: 16,
                      width: '100%',
                      textAlign: 'left',
                      color: 'red',
                    }}
                  >
                    Phone number is not valid
                  </div>
                )}
                {this.getHelperText() !== '' && (
                  <div
                    style={{
                      marginTop: 16,
                      textDecoration: 'none',
                      cursor: 'pointer',
                      fontSize: 16,
                      width: '100%',
                      textAlign: 'left',
                      color: 'red',
                    }}
                  >
                    {this.getHelperText()}
                  </div>
                )}
                <div style={{ width: '100%', textAlign: 'left' }}>
                  {this.state.isLoading ? (
                    <CircularProgress size={50} style={{ marginTop: 60 }} />
                  ) : (
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.button}
                      disabled={!this.validatePhoneForE164()}
                      style={{
                        textAlign: 'left',
                        background:
                          'linear-gradient(256.79deg, #6852ED 40.49%, #BA43FC 100%)',
                        boxShadow: 'rgba(114, 82, 240, 0.6) 0px 8px 20px 0px',
                      }}
                      onClick={() => this.authUser()}
                    >
                      {this.state.isLoading ? (
                        <CircularProgress size={50} />
                      ) : (
                        <div>Send Me Code</div>
                      )}
                    </Button>
                  )}
                </div>
                <div>
                  {/* <Link to="/typeof"> */}
                  <div
                    onClick={() => this.setState({ openTypeDialog: true })}
                    color='primary'
                    style={{
                      marginTop: 30,
                      cursor: 'pointer',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      fontSize: 16,
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    <u style={{ color: 'black' }}>
                      Don't have an account? Join in now!
                    </u>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MainLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(MainLogin));
