import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.green,
        borderColor: theme.palette.primary.green,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 28,
      borderColor: '#DAE2FD',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#ecf1fe',
      borderRadius: 28,
    },
  },
}));

export default function AlertDialogSlide(props) {
  const classes = useStyles();
  const [errorPhoneNumber, setErrorPhoneNumber] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [state, setState] = React.useState({
    canWatch: false,
    canSelect: false,
    accounter: false,
    canOrder: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClose = () => {
    props.main.setState({ openAddNew: false });
  };

  const addNewMember = async () => {
    setErrorPhoneNumber('');
    console.log(validateMobile(phone));
    if (validateMobile(phone)) {
      setLoading(true);
      let userArray = await axios.post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/find-person-account-by-mobile',
        { mobile: phone },
        { headers: { 'Content-Type': 'application/json' } }
      );
      console.log(userArray.data);
      if (userArray.data.length > 0) {
        // alert('exists')
        addNewRelationship(userArray.data[0].PersonContactId);
      } else {
        setLoading(false);
        setErrorPhoneNumber(
          'Sorry, We can find this phone number in our system'
        );
      }
    } else {
      setErrorPhoneNumber('Mobile phone number is not valid');
    }
    // props.main.setState({openAddNew: false})
  };

  const getDateForSalesForce = () => {
    var dateObj = new Date();
    var m = String(dateObj.getDate());
    var d = String(dateObj.getMonth() + 1);
    var y = String(dateObj.getFullYear());
    if (m.length == 1) {
      m = '0' + m;
    }
    if (d.length == 1) {
      d = '0' + d;
    }
    var newDate = y + '-' + d + '-' + m;
    var newTime = String(dateObj.getHours() + ':' + dateObj.getMinutes());
    var dateAndTime = newDate + 'T' + newTime + ':00.000+0200';
    return dateAndTime;
  };

  const addNewRelationship = async (contactId) => {
    var data = {
      AccountId: props.main.state.bAccountId,
      ContactId: contactId,
      IsActive: true,
      Relationship_Status__c: 'Pending',
      StartDate: getDateForSalesForce(),
    };
    if (state.canWatch) {
      data['Watch_Team_Shootings__c'] = true;
    } else {
      data['Watch_Team_Shootings__c'] = false;
    }
    if (state.canSelect) {
      data['Select_Images__c'] = true;
    } else {
      data['Select_Images__c'] = false;
    }
    if (state.accounter) {
      data['Accounta__c'] = true;
    } else {
      data['Accounta__c'] = false;
    }
    if (state.canOrder) {
      data['Order_From_Business_Package__c'] = true;
    } else {
      data['Order_From_Business_Package__c'] = false;
    }
    console.log(data);
    let result = await axios.post(
      'https://wesnapp-node-server.appspot.com' +
        '/api/account/add-new-relationship',
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
    console.log(result);
    var requestUrl = 'https://my.wesnapp.co.il/request/' + result.data[0].id;
    var smsData = {
      phone: '+972' + phone,
      code: '+972',
      text:
        'WeSnapp: You got a new invitation to join as a memeber. ' + requestUrl,
    };
    let smsResult = await axios.post(
      'https://wesnapp-node-server.appspot.com' + '/api/sms/send-message',
      smsData,
      { headers: { 'Content-Type': 'application/json' } }
    );
    setLoading(false);
    props.main.getBusinessAccount();
    handleClose();
  };

  const handleChangeText = (name) => (event) => {
    setPhone(event.target.value.replace('-', ''));
  };

  const validateMobile = (phone) => {
    console.log(phone);
    if (phone.length < 10) return false;
    var substring1 = ' 9725';
    var substring2 = ' 97205';
    if (phone.includes(substring1) || phone.includes(substring2)) {
      console.log('@@');
      if (phone.indexOf(substring1) !== -1) {
        console.log(phone);
        phone = phone.replace(' 9725', '05');
        phone = phone.replace('-', '');
        if (
          phone[0] === '0' &&
          phone[1] === '5' &&
          (phone[2] === '2' ||
            phone[2] === '0' ||
            phone[2] === '4' ||
            phone[2] === '5' ||
            phone[2] === '3' ||
            phone[2] === '8')
        ) {
          if (phone.length === 10 && /^\d+$/.test(phone)) {
            setPhone(phone);
            return true;
          }
        } else {
          return false;
        }
      } else {
        phone = phone.replace(' 97205', '05');
        phone = phone.replace('-', '');
        console.log(phone);
        if (
          phone[0] === '0' &&
          phone[1] === '5' &&
          (phone[2] === '2' ||
            phone[2] === '0' ||
            phone[2] === '4' ||
            phone[2] === '3' ||
            phone[2] === '5' ||
            phone[2] === '8')
        ) {
          if (phone.length === 10 && /^\d+$/.test(phone)) {
            setPhone(phone);
            return true;
          }
        } else {
          return false;
        }
      }
    } else {
      phone = phone.replace('-', '');
      if (
        phone[0] === '0' &&
        phone[1] === '5' &&
        (phone[2] === '2' ||
          phone[2] === '0' ||
          phone[2] === '4' ||
          phone[2] === '5' ||
          phone[2] === '3' ||
          phone[2] === '8')
      ) {
        if (phone.length === 10 && /^\d+$/.test(phone)) {
          setPhone(phone);
          return true;
        }
      } else {
        return false;
      }
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={props.main.state.openAddNew}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {'Add new member'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Here you can add new member to your business and set up uniqe
            permission. Add his phone number and set the permissions.
          </DialogContentText>
          <div style={{ marginTop: 10, display: 'inline-block', width: '70%' }}>
            <TextField
              id='outlined-basic'
              className={classes.root}
              label='Phone number'
              fullWidth
              variant='outlined'
              onChange={handleChangeText('phone')}
              value={phone}
            />
          </div>
          <div style={{ height: 30 }}>
            {errorPhoneNumber !== '' && (
              <div
                style={{
                  color: 'red',
                  fontSize: 15,
                  fontFamily: "'Montserrat', sans-serif",
                  marginTop: 8,
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                {errorPhoneNumber}
              </div>
            )}
          </div>
          <div>
            Can watch business projects
            <AntSwitch
              checked={state.canWatch}
              onChange={handleChange}
              name='canWatch'
            />
          </div>
          <div style={{ marginTop: 10 }}>
            Can user business packages
            <AntSwitch
              checked={state.canOrder}
              onChange={handleChange}
              name='canOrder'
            />
          </div>
          <div style={{ marginTop: 10 }}>
            Can select images
            <AntSwitch
              checked={state.canSelect}
              onChange={handleChange}
              name='canSelect'
            />
          </div>
          <div style={{ marginTop: 10 }}>
            Accounter
            <AntSwitch
              checked={state.accounter}
              onChange={handleChange}
              name='accounter'
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={addNewMember} color='primary'>
            {loading ? <CircularProgress size={30} /> : <div>Add</div>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
