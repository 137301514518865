import React, { useEffect, useState, useContext } from 'react';
import SideMenu from './SideMenu';
import MobileSideMenu from './MobileSideMenu';
import View from './View';
import AppBar from './AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import axios from 'axios';
import NewAppBar from './NewAppBar';
import StyleContextProvider from '../../../contexts/Style.js';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../../contexts/Auth';
import history from '../../../utils/history';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    // width: '100vw',
    // height: '100vh',
    // backgroundColor: '#6334FF',
    // background: 'linear-gradient(90deg, #6334FF 50%, #FFF 50%)',
    // position: 'fixed',
    // top: 0, left: 0,
  },
}));

const Template = () => {
  const classes = useStyles();

  const { setAccountData } = useContext(AuthContext);

  const [deviceType, setDeviceType] = useState('desktop');
  const [page, setPage] = useState('');
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [shootPage, setShootPage] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [isBusinessAccount, setIsBusinessAccount] = useState(false);
  const [businessAccountId, setBusinessAccountId] = useState(null);

  useEffect(() => {
    getUserDetails();
    checkIfBusinessExists();
  }, []);

  const checkIfBusinessExists = () => {
    var contactId = localStorage.getItem('contactId');
    var data = { contactId: contactId };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/get-business-account',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          setIsBusinessAccount(true);
          setBusinessAccountId(response.data[0].AccountId);
        } else {
          setIsBusinessAccount(false);
        }
      })
      .catch((error) => {});
  };

  const getUserDetails = () => {
    var data = { id: localStorage.getItem('accountId') };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/account/get-one',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data[0]);
        var firstName = response.data[0].FirstName;
        var lastName = response.data[0].LastName;
        setAvatar(firstName[0] + lastName[0]);
        setAccountData(response.data[0]);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const goToOrder = () => {
    // history.push('/c/dashboard/order');
    // window.location.reload()
  };

  const updateShootPage = (value) => {
    setShootPage(value);
  };

  return (
    <div className={classes.root}>
      <StyleContextProvider>
        <Container maxWidth={'lg'}>
          <AppBar />
          <View
            updateShootPage={updateShootPage}
            history={history}
            isBusinessAccount={isBusinessAccount}
            businessAccountId={businessAccountId}
            updateShootPage={updateShootPage}
          />
        </Container>
        {/* {deviceType !== 'desktop' && (
          <AppBar style={{position: 'fixed', top: 0, left: 120}} />
        )}
        {deviceType === 'desktop' && (
          <NewAppBar avatar={avatar} goToOrder={goToOrder} history={history} shootPage={shootPage}/>
        )}
        <div>
            <div style={{display: 'inline-block'}}>
                {deviceType === 'desktop' ? (
                    <SideMenu setShootPage={setShootPage} history={history}/>
                ) : (
                    <MobileSideMenu page={page} isBusinessAccount={isBusinessAccount} history={history}/>
                )}
                <View updateShootPage={updateShootPage} history={history} isBusinessAccount={isBusinessAccount} businessAccountId={businessAccountId} updateShootPage={updateShootPage}/>
            </div>
        </div> */}
      </StyleContextProvider>
    </div>
  );
};

export default Template;
