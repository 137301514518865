import React, { useState, useEffect, useContext } from 'react';
import {
  CircularProgress,
  Grid,
  Button,
  TextField,
  Radio,
  FormGroup,
  FormControlLabel,
  Switch,
  Toolbar,
  IconButton,
  AppBar,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import MuiStyleFunction from './PhotoShoot.style';
import axios from 'axios';
import PhotoShootDetails from './PhotoShootDetails';
import Drawer from './Drawer';
import Image from './Image';
import moment from 'moment';
import Skeletons from './PhotoShoot.skeleton.js';
import Lightbox from 'react-image-lightbox';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ConfirmSelectionDialog from './ConfirmSelectionDialog';
import EmailImagesDialog from './EmailImagesDialog';
import PaymentDialog from './PaymentDialog';
import DownloadDialog from './DownloadDialog';
import SendByEmailDialog from './SendByEmailDialog';
import EmailDialog from './EmailDialog';
import { StyleContext } from '../../../../../contexts/Style';
import PhotoShootTitle from './PhotoShootTitle';
import PhotoShootImagesTitle from './PhotoShootImagesTitle';

const useStyles = makeStyles(MuiStyleFunction);
// const GET_EDITED_ORGENIZED_IMAGES = 'http://localhost:8080/server/get-edited-images-orgenized/'
const GET_EDITED_ORGENIZED_IMAGES =
  'https://py.wesnapp.co.il/server/get-edited-images-orgenized/';
const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const PhotoShoot = (props) => {
  const classes = useStyles();

  const { isDrawerOpen, setIsDrawerOpen } = useContext(StyleContext);

  const [loading, setLoading] = useState(true);
  const [requestId, setRequestId] = useState(null);
  const [dateAndTime, setDateAndTime] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [logoPreference, setLogoPreference] = useState({
    opacity: 100,
    logoPosition: 'top_right',
    url: '',
    logoSize: 66,
  });
  const [extraImagePrice, setExtraImagePrice] = useState(null);
  const [extraImageDiscount, setExtraImageDiscount] = useState(null);
  const [paid, setPaid] = useState(false);
  const [dataAndTime, setDataAndTime] = useState('');
  const [requestData, setRequestData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [shootingLocation, setShootingLocation] = useState(null);

  const [selectedNumberOfImages, setSelectedNumberOfImages] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedInThePast, setSelectedInThePast] = useState([]);
  const [confirmSelect, setConfirmSelect] = useState(false);

  const [couponType, setCouponType] = useState(null);
  const [couponIsValid, setCouponIsValid] = useState(false);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [coupon, setCoupon] = useState('');

  const [errorCoupon, setErrorCoupon] = useState(false);
  const [canApply, setCanApply] = useState(true);
  const [errorCouponText, setErrorCouponText] = useState('');
  const [checkingCoupon, setCheckingCoupon] = useState(false);

  const [galleryIsOpen, setGalleryIsOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const [openVideoDialog, setOpenVideoDialog] = useState(false);

  const [editedImages, setEditedImages] = useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    watermarked: [],
    loading: true,
    imagesForGallery: [],
  });

  // WATERMARK
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [uploadingWatermark, setUploadingWatermark] = useState(false);
  const [imageHash, setImageHash] = useState(Date.now());
  const [file, setFile] = useState(null);

  const [selectedImagesString, setSelectedImagesString] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [paymentWithProduct, setPaymentWithProduct] = useState(false);
  const [amountToCharge, setAmountToCharge] = useState('');
  const [addingImagesToSalesforce, setAddingImagesToSalesforce] =
    useState(false);

  const [snackBarStatus, setSnackBarStatus] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');

  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);

  const [paymentLink, setPaymentLink] = React.useState('');
  const [paymentData, setPaymentData] = React.useState(null);

  // DOWNLOAD
  const [downloadPercenteges, setDownloadPercenteges] = React.useState(0);
  const [prepearingImages, setPrepearingImages] = React.useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = React.useState(false);

  const [openSentEmailDialog, setSentOpenEmailDialog] = React.useState(false);
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  const [openCreateVideoDialog, setOpenCreateVideoDialog] =
    React.useState(false);
  const [mailType, setMailType] = React.useState(null);

  useEffect(() => {
    if (selectedInThePast.length !== 0) {
      if (
        requestData.Video_Link__c === null ||
        requestData.Video_Link__c === ''
      ) {
        setOpenCreateVideoDialog(true);
      }
    }
  }, [selectedInThePast]);

  useEffect(() => {
    if (paymentLink !== '') {
      setOpenPaymentDialog(true);
    }
  }, [paymentLink]);

  useEffect(() => {
    if (openPaymentDialog === false) {
      setPaymentLink('');
      getNewSelectedImages();
    }
  }, [openPaymentDialog]);

  useEffect(() => {
    if (loading === false) {
      setIsDrawerOpen(true);
    }
  }, [loading]);

  useEffect(() => {
    getRequestData();
  }, []);

  useEffect(() => {
    if (logoPreference.url !== '') {
      updatelogoPreference();
    }
  }, [logoPreference]);

  useEffect(() => {
    if (snackBarMessage !== '') {
      handleOpenSnackBar();
    }
  }, [snackBarMessage]);

  const downloadZipFile = (array, zipname) => {
    if (downloadPercenteges == 0) {
      var lineOfFiles = '';
      for (var i = 0; i < array.length; i++) {
        var path = '';
        path = array[i].replace('https://storage.googleapis.com/wesnapp/', '');
        path.replace('/', '-');
        if (lineOfFiles == '') {
          lineOfFiles = path;
        } else {
          lineOfFiles = lineOfFiles + ',' + path;
        }
      }
      setDownloadPercenteges(1);
      setPrepearingImages(true);
      setOpenDownloadDialog(true);
      var config = {
        responseType: 'blob',
        onDownloadProgress: uploadDownloadProgress(),
        timeout: 2000000000000000000000000,
      };
      var filesOld = lineOfFiles.split(',');
      var files = [];
      var folder = filesOld[0].split(
        '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
      )[0];
      for (var j = 0; j < filesOld.length; j++) {
        files.push(
          filesOld[j].replace('_watermarked', '').split(folder + '/')[1]
        );
      }
      axios
        .post(
          'https://py.wesnapp.co.il/server/zipfiles-images',
          { folder: folder, files: files, zipname: zipname },
          config
        )
        .then((response) => {
          console.log(response.data);
          var headers = response.headers;
          var blob = new Blob([response.data], {
            type: headers['content-type'],
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = zipname;
          link.click();
          link.remove();
          setDownloadPercenteges(0);
          setOpenDownloadDialog(false);
          createSnackbar('success', 'Images downloaded successfully');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const downloadZipFileWatermark = (array, zipname) => {
    if (downloadPercenteges == 0) {
      var lineOfFiles = '';
      for (var i = 0; i < array.length; i++) {
        var path = '';
        path = array[i].replace('https://storage.googleapis.com/wesnapp/', '');
        path.replace('/', '-');
        if (lineOfFiles == '') {
          lineOfFiles = path;
        } else {
          lineOfFiles = lineOfFiles + ',' + path;
        }
      }
      setDownloadPercenteges(1);
      setPrepearingImages(true);
      setOpenDownloadDialog(true);
      var config = {
        responseType: 'blob',
        onDownloadProgress: uploadDownloadProgress(),
        timeout: 2000000000000000000000000,
      };
      var filesOld = lineOfFiles.split(',');
      var files = [];
      var folder = filesOld[0].split(
        '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
      )[0];
      for (var j = 0; j < filesOld.length; j++) {
        files.push(filesOld[j].split(folder + '/')[1]);
      }
      var data = {
        folder: folder,
        files: files,
        zipname: zipname,
        overlay_path: 'watermarks/' + contactData.AccountId + '/overlay.png',
        opacity: logoPreference.opacity,
        size: logoPreference.logoSize,
        position: logoPreference.logoPosition,
      };
      axios
        .post(
          'https://py.wesnapp.co.il/server/zipfiles-watermarks',
          data,
          config
        )
        .then((response) => {
          console.log(response.data);
          var headers = response.headers;
          var blob = new Blob([response.data], {
            type: headers['content-type'],
          });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = zipname;
          link.click();
          link.remove();
          setDownloadPercenteges(0);
          setOpenDownloadDialog(false);
          createSnackbar('success', 'Images downloaded successfully');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const uploadDownloadProgress = () => {
    return (progress) => {
      var percentCompleted = Math.round(
        (progress.loaded * 100) / progress.total
      );
      setDownloadPercenteges(percentCompleted);
      setPrepearingImages(false);
    };
  };

  const createSnackbar = (type, message) => {
    setSeverity(type);
    setSnackBarMessage(message);
  };

  const handleOpenSnackBar = () => {
    setSnackBarStatus(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarStatus(false);
    setSnackBarMessage('');
  };

  const updatelogoPreference = () => {
    var formData = {
      accountId: accountId,
      data: {
        Logo_Preference__c:
          '{"url": "' +
          logoPreference.url +
          '", "logoSize": ' +
          logoPreference.logoSize +
          ', "logoPosition": "' +
          logoPreference.logoPosition +
          '", "opacity": ' +
          logoPreference.opacity +
          '}',
      },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/account/update-account',
        formData,
        {}
      )
      .then((response) => {
        setUpdateInProgress(false);
        setImageHash(Date.now());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeLogo = (e) => {
    let currentFile = e.target.files[0];
    setFile(currentFile);
    setUpdateInProgress(true);
    setUploadingWatermark(true);
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .post(
        'https://py.wesnapp.co.il/server/uploadwatermark/' + accountId,
        formData,
        config
      )
      .then((response) => {
        setUploadingWatermark(false);
        setLogoPreference({
          opacity: logoPreference.opacity,
          logoSize: logoPreference.logoSize,
          logoPosition: logoPreference.logoPosition,
          url: response.data.imageUrl,
        });
        createSnackbar('success', 'Company logo uploaded successfully');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeLogoPosition = (event) => {
    setLogoPreference({
      opacity: logoPreference.opacity,
      logoSize: logoPreference.logoSize,
      logoPosition: event.target.value,
      url: logoPreference.url,
    });
  };

  const handleChangeOpacity = (event, newValue) => {
    setLogoPreference({
      opacity: newValue,
      logoSize: logoPreference.logoSize,
      logoPosition: logoPreference.logoPosition,
      url: logoPreference.url,
    });
  };

  const handleChangeSize = (event, newValue) => {
    setLogoPreference({
      opacity: logoPreference.opacity,
      logoSize: newValue,
      logoPosition: logoPreference.logoPosition,
      url: logoPreference.url,
    });
  };

  const getAccountData = async (id) => {
    var data = { id: id };
    const account_response = await axios.post(
      'https://wesnapp-node-server.appspot.com/api/account/get-one-account',
      { id: id }
    );
    return account_response.data[0];
  };

  const getlogoPreference = (accountData) => {
    console.log(accountData.Logo_Preference__c);
    console.log(JSON.parse(accountData.Logo_Preference__c));
    let preferences = JSON.parse(accountData.Logo_Preference__c);
    if (preferences != null && preferences != '' && preferences !== undefined) {
      setLogoPreference(preferences);
    }
  };

  const checkForExtraImageDiscountAndSetExtraImagePrice = (
    Price_Per_Extra_Product__c,
    Extra_Product_Discount__c
  ) => {
    const extraImagePriceData = Price_Per_Extra_Product__c;
    setExtraImagePrice(extraImagePriceData);
    var extraImageDiscountData = Extra_Product_Discount__c;
    setExtraImageDiscount(extraImagePriceData);
    if (extraImageDiscountData != null) {
      var old = Price_Per_Extra_Product__c;
      var discount = 100 - extraImageDiscountData;
      var extraImagePriceAfterDiscount = (old * discount) / 100;
      setExtraImagePrice(extraImagePriceAfterDiscount);
    }
  };

  const checkIfPaid = (request) => {
    const requestWasPaid = request.Paid__c;
    if (request.Order_Record_Type__c === '0121t0000015F5WAAU') {
      requestWasPaid = true;
    }
    setPaid(requestWasPaid);
  };

  useEffect(() => {
    console.log('props.requestId', props.requestId);
  }, []);

  const getRequestData = async () => {
    try {
      console.log('props.requestId', props.requestId);
      var id = props.requestId;
      setRequestId(id);
      if (id != null && id != undefined && id != '') {
        const response = await axios.post(
          'https://wesnapp-node-server.appspot.com/api/request/get-full-data',
          { id: id }
        );
        setDateAndTime(
          moment(response.data.request.Shooting_Date_Time__c)
            .subtract(1, 'days')
            .calendar()
        );
        getCoordinates(
          response.data.request.ShootingStreet__c +
            ' ' +
            response.data.request.ShootingCity__c
        );
        const accountData = await getAccountData(
          response.data.contact.AccountId
        );
        setAccountId(response.data.contact.AccountId);
        getlogoPreference(accountData);
        // checkForExtraImageDiscountAndSetExtraImagePrice(response.data.product.Price_Per_Extra_Product__c, accountData.Extra_Product_Discount__c)
        checkIfPaid(response.data.request);
        setRequestData(response.data.request);
        setContactData(response.data.contact);
        setProductData(response.data.product);

        // setShootingLocation(response.data.request.ShootingStreet__c + " " + response.data.request.ShootingCity__c)

        let selectedImages = [];
        let selectedInThePastString = [];
        let selectedInThePast = [];

        if (response.data.request.Selected_Images__c != null) {
          selectedInThePastString =
            response.data.request.Selected_Images__c.replace(
              new RegExp('<p>', 'g'),
              ''
            )
              .replace(new RegExp('</p>', 'g'), '')
              .replace(new RegExp('<br>', 'g'), '');
          selectedInThePast = selectedInThePastString.split(',');
          selectedImages = selectedInThePastString.split(',');
          if (selectedImages.length == 0) {
            // setState({firstSelection: true})
          }
          setSelectedNumberOfImages(selectedImages.length);
        }

        setSelectedImages(selectedImages);
        setSelectedInThePast(selectedInThePast);
        setConfirmSelect(false);
        getEditedImages(id, selectedInThePast);
        setLoading(false);
      } else {
        alert('Shooting ID is not valid.');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getCoordinates = async (address) => {
    try {
      var link =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        encodeURI(address) +
        '&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s';
      let response = await axios.get(link);
      let latNumber = response.data.results[0].geometry.location.lat;
      let lngNumber = response.data.results[0].geometry.location.lng;
      setLat(latNumber);
      setLng(lngNumber);
    } catch (err) {
      console.error(err);
    }
  };

  const getEditedImages = async (requestId, selectedInThePast) => {
    const response = await axios.get(
      GET_EDITED_ORGENIZED_IMAGES + requestId + '-AT-infused',
      {},
      { headers: { 'Content-Type': 'application/json' } }
    );
    var data = response.data;
    console.log('response', response.data);
    var imagesForGallery = [];
    var counter = 0;
    for (var i = 0; i < data.watermarked.length; i++) {
      //   if (data.watermarked[i].includes('compressed') || data.watermarked[i].includes('thumbnail')) continue
      if (selectedInThePast.includes(data.watermarked[i])) {
        counter += 1;
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth: 320,
          thumbnailHeight: 174,
          isSelected: false,
          caption: 'Image ' + String(i),
        });
      } else {
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth: 320,
          thumbnailHeight: 174,
          isSelected: false,
          caption: 'Image ' + String(i),
        });
      }
    }
    data['loading'] = false;
    data['imagesForGallery'] = imagesForGallery;
    setEditedImages(data);
    setOpenConfirmDialog(false);
    setAddingImagesToSalesforce(false);
  };

  const onSelectImage = (index) => {
    let inThePast = selectedInThePast;
    let inThePastSave = selectedInThePast;

    var images = editedImages;
    let img = images.imagesForGallery[index];

    let tempSelectedImages = selectedImages;

    if (!inThePast.includes(removeDraft(img.src))) {
      if (img.hasOwnProperty('isSelected')) {
        if (
          !inThePast.includes(removeDraft(images.imagesForGallery[index].src))
        ) {
          images.imagesForGallery[index].isSelected = !img.isSelected;
          if (img.isSelected == true) {
            tempSelectedImages.push(
              removeDraft(images.imagesForGallery[index].src)
            );
          } else {
            var index = tempSelectedImages.indexOf(
              removeDraft(images.imagesForGallery[index].src)
            );
            if (index > -1) {
              tempSelectedImages.splice(index, 1);
            }
          }
          if (tempSelectedImages.length > selectedInThePast.length) {
            setSelectedImages(tempSelectedImages);
            var newImages = { ...images };
            setEditedImages(newImages);
            setConfirmSelect(true);
            setSelectedImages(tempSelectedImages);
          } else {
            setSelectedImages(tempSelectedImages);
            var newImages = { ...images };
            setEditedImages(newImages);
            setConfirmSelect(false);
            setSelectedImages(tempSelectedImages);
          }
        } else {
        }
      } else {
      }
    } else {
    }
  };

  const selectAll = () => {
    var originals = editedImages;
    var images = [];
    for (var i = 0; i < originals.imagesForGallery.length; i++) {
      images.push(
        originals.imagesForGallery[i].src
          .replace('watermarked/', '')
          .replace('_reduced_watermarked', '')
      );
      originals.imagesForGallery[i].isSelected = true;
    }
    var newImages = { ...originals };
    setEditedImages(newImages);
    setSelectedImages(images);
    setConfirmSelect(true);
  };

  const removeDraft = (url) => {
    return url.replace('watermarked/', '').replace('_reduced_watermarked', '');
  };

  const getSelectedImagesToPayFor = () => {
    let freeImages = 0;
    let tempSelectedImages = selectedImages.length;
    if (requestData.Free_Extra_Images__c !== null) {
      freeImages = requestData.Free_Extra_Images__c;
    }
    let numberOfImagesIncluded =
      requestData.Number_of_Images_for_Customer__c + freeImages;
    if (selectedInThePast.length > numberOfImagesIncluded) {
      numberOfImagesIncluded = selectedInThePast.length;
    }
    if (numberOfImagesIncluded < tempSelectedImages) {
      return tempSelectedImages - numberOfImagesIncluded;
    } else {
      return 0;
    }
  };

  const getCostForExtraProduct = () => {
    return productData.Auto_Edit_Image_Cost__c;
    // let discount = requestData.Extra_Product_Discount__c
    // let pricePerImage = productData.Price_Per_Extra_Product__c
    // if (discount !== null) {
    //   return (pricePerImage * (100 - discount)) / 100
    // }
    // return pricePerImage
  };

  const getTotalAmountToPayFor = () => {
    let forProduct = 0;
    if (!requestData.Paid__c) {
      forProduct += requestData.Total_Cost__c;
    }
    let toPayFor = forProduct;
    let forExtra = parseFloat(
      (getSelectedImagesToPayFor() * getCostForExtraProduct()).toFixed(2)
    );
    if (forExtra !== 0 && forExtra !== null && forExtra !== undefined) {
      if (couponIsValid) {
        if (couponType === 'cash') {
          return (toPayFor + forExtra - couponDiscount).toFixed(2);
        } else {
          return ((toPayFor * (100 - couponDiscount)) / 100 + forExtra).toFixed(
            2
          );
        }
      } else {
        return (toPayFor + forExtra).toFixed(2);
      }
    } else {
      if (couponIsValid) {
        if (couponType === 'cash') {
          return (toPayFor - couponDiscount).toFixed(2);
        } else {
          return ((toPayFor * (100 - couponDiscount)) / 100).toFixed(2);
        }
      } else {
        return toPayFor.toFixed(2);
      }
    }
  };

  const checkIfCouponIsValid = () => {
    setCheckingCoupon(true);
    setCouponIsValid(false);
    var data = {
      coupon: coupon,
      accountId: accountId,
      requestId: requestId,
      productId: productData.Id,
    };
    console.log(data);
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/coupons/check-coupon',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.allowed) {
          let type = '';
          let amount = 0;
          if (response.data.discountAmount !== null) {
            type = 'cash';
            amount = response.data.discountAmount;
          } else {
            type = 'percentages';
            amount = response.data.amount;
          }
          setCheckingCoupon(false);
          setCouponIsValid(true);
          setErrorCoupon(false);
          setCanApply(false);
          setErrorCouponText('Coupon is valid');
          setCouponType(type);
          setCouponDiscount(amount);
        } else {
          setCheckingCoupon(false);
          setCouponIsValid(false);
          setErrorCoupon(true);
          setCanApply(false);
          setErrorCouponText(response.data.reason);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeCoupon = (event) => {
    let newValue = event.target.value;
    if (newValue === '') {
      setErrorCoupon('');
      setErrorCouponText('');
      setCoupon(newValue);
      setCanApply(false);
    } else {
      setCoupon(newValue);
      setCanApply(true);
    }
  };

  const getLinkAfterReplacement = (link) => {
    link = link.replace('_reduced_watermarked', '');
    link = link.replace('watermarked/', '');
    return link;
  };

  const openGallery = (index) => {
    setGalleryIndex(index);
    setGalleryIsOpen(true);
  };

  const confirmSelection = () => {
    var images = selectedImages;
    var imagesString = '';
    var amountToCharge = 0;

    if (images.length >= 1) {
      imagesString = images[0]
        .replace('watermarked/', '')
        .replace('_reduced_watermarked', '');
      if (images.length > 1) {
        for (var i = 1; i < images.length; i++) {
          imagesString +=
            ',' +
            images[i]
              .replace('watermarked/', '')
              .replace('_reduced_watermarked', '');
        }
      }
    }

    var paymentWithProduct = true;

    var imagesInPackage = requestData.Number_of_Images_for_Customer__c;
    if (requestData.Free_Extra_Images__c != null) {
      var imagesInPackage =
        requestData.Number_of_Images_for_Customer__c +
        requestData.Free_Extra_Images__c;
    }
    if (selectedImages.length > imagesInPackage || !requestData.Paid__c) {
      var imagesToPayFor = '';
      if (selectedImages.length > imagesInPackage) {
        var counter = selectedImages.length - imagesInPackage;
        for (var i = 0; i < selectedImages.length; i++) {
          if (
            !selectedInThePast.includes(
              selectedImages[i]
                .replace('watermarked/', '')
                .replace('_reduced_watermarked', '')
            )
          ) {
            if (counter != 0) {
              if (imagesToPayFor == '') {
                imagesToPayFor = selectedImages[i]
                  .replace('watermarked/', '')
                  .replace('_reduced_watermarked', '');
              } else {
                var imageString = selectedImages[i]
                  .replace('watermarked/', '')
                  .replace('_reduced_watermarked', '');
                imagesToPayFor = imagesToPayFor + ',' + imageString;
              }
              counter -= 1;
            }
          }
        }
        amountToCharge += imagesToPayFor.split(',').length * extraImagePrice;
        // setExtraImagesData
        // this.setState({extraImagesData: String(imagesToPayFor.split(',').length) + '-' + String(extraImagePrice) + '-' + String(this.state.requestData.product.Price_Per_Extra_Product__c)}, () => {
        // })
      }
      if (requestData.Paid__c == false) {
        paymentWithProduct = true;
        amountToCharge += requestData.Total_Cost__c;
      }
      if (getSelectedImagesToPayFor() === 0 && requestData.Paid__c) {
        setSelectedImagesString(imagesString);
        setOpenConfirmDialog(true);
      } else {
        setAmountToCharge(amountToCharge);
        setPaymentWithProduct(paymentWithProduct);
        // Create payment request
        createPaymentLink(paymentWithProduct, imagesString);
      }
    } else {
      setSelectedImagesString(imagesString);
      setOpenConfirmDialog(true);
    }
  };

  const createPaymentLink = async (paymentWithProduct, imagesString) => {
    var paymentFor = 8;
    // if (paymentWithProduct) {
    //     if (requestData.Paid__c) {
    //         paymentFor = 2
    //     } else {
    //         paymentFor = 3
    //     }
    // } else {
    //     paymentFor = 1
    // }

    var link =
      'https://wesnapp-node-server.appspot.com/api/payment/get-payment-url';
    let data = {
      // Account
      contactId: contactData.Id,
      accountId: accountId,
      // Product
      productId: productData.Id,
      // Request and Order
      requestId: requestId,
      orderId: requestData.WS_Order__c,
      // Amount
      productTotalAmount: productData.Total_Cost__c, // Before coupon discount
      totalAmountToPay: parseFloat(getTotalAmountToPayFor() * 1.17).toFixed(2),
      // Coupon
      couponCode: coupon,
      couponType: couponType,
      couponAmount: couponDiscount,
      // Payment For
      paymentFor: paymentFor,
      // paymentType
      paymentType: '1',
      saveToken: true,
    };
    if (paymentWithProduct) {
      data['numberOfExtraImages'] = getSelectedImagesToPayFor();
      data['priceForExtraImage'] = getCostForExtraProduct();
    }
    if (paymentWithProduct) {
      data['selectedImages'] = imagesString;
    }
    console.log('data', data);
    const response = await axios.post(link, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log('response', response);
    setPaymentData(data);
    setPaymentLink(response.data);
  };

  const addImagesToSelection = () => {
    setAddingImagesToSalesforce(true);
    var data = {
      requestId: requestId,
      data: {
        Selected_Images__c: selectedImagesString,
        Status__c: 'Process Completed',
      },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/request/update-request',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        setOpenConfirmDialog(false);
        setAddingImagesToSalesforce(false);
        getNewSelectedImages();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNewSelectedImages = async () => {
    try {
      var data = { id: requestId };
      const response = await axios.post(
        'https://wesnapp-node-server.appspot.com/api/request/get-full-data',
        data
      );
      console.log(response.data);
      var selectedImages = [];
      var selectedInThePastString = [];
      var tempSelectedInThePast = [];
      if (response.data.request.Selected_Images__c != null) {
        selectedInThePastString =
          response.data.request.Selected_Images__c.replace(
            new RegExp('<p>', 'g'),
            ''
          )
            .replace(new RegExp('</p>', 'g'), '')
            .replace(new RegExp('<br>', 'g'), '');
        tempSelectedInThePast = selectedInThePastString.split(',');
        selectedImages = selectedInThePastString.split(',');
      }
      checkIfPaid(response.data.request);
      setRequestData(response.data.request);
      if (tempSelectedInThePast.length > selectedInThePast.length) {
        setConfirmSelect(false);
        setSelectedImages(selectedImages);
        setSelectedInThePast(tempSelectedInThePast);
        getEditedImages(requestId, tempSelectedInThePast);
        createSnackbar('success', 'Images selected successfully');
        setCouponType(null);
        setCouponIsValid(false);
        setCouponDiscount(0);
        setCoupon('');
        setErrorCoupon(false);
        setCanApply(true);
        setCheckingCoupon(false);
        changeRequestStatusToCompleted();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const changeRequestStatusToCompleted = () => {
    var data = {
      requestId: requestId,
      data: { Status__c: 'Process Completed' },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/request/update-request',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendToMyEmail = (type) => {
    setMailType(type);
    setOpenEmailDialog(true);
  };

  const sendImagesViaEmail = (array, zipname, email) => {
    console.log(array);
    var lineOfFiles = '';
    for (var i = 0; i < array.length; i++) {
      var path = '';
      console.log(array[i]);
      path = array[i].replace('https://storage.googleapis.com/wesnapp/', '');
      path.replace('/', '-');
      if (lineOfFiles == '') {
        lineOfFiles = path;
      } else {
        lineOfFiles = lineOfFiles + ',' + path;
      }
    }
    var config = {
      responseType: 'blob',
      onDownloadProgress: uploadDownloadProgress(),
      timeout: 2000000000000000000000000,
    };
    var filesOld = lineOfFiles.split(',');
    var files = [];
    var folder = filesOld[0].split(
      '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
    )[0];
    for (var j = 0; j < filesOld.length; j++) {
      files.push(filesOld[j].split(folder + '/')[1]);
    }
    folder = folder.replace('edited', 'edited/compressed');
    axios
      .post(
        'https://py.wesnapp.co.il/server/email-images',
        {
          folder: folder,
          files: files,
          zipname: zipname,
          email: email,
          address:
            requestData.ShootingStreet__c + ', ' + requestData.ShootingCity__c,
        },
        config
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setSentOpenEmailDialog(true);
  };

  const sendImagesWithLogoViaEmail = (array, zipname, email) => {
    console.log(array);
    var lineOfFiles = '';
    for (var i = 0; i < array.length; i++) {
      var path = '';
      console.log(array[i]);
      path = array[i].replace('https://storage.googleapis.com/wesnapp/', '');
      path.replace('/', '-');
      if (lineOfFiles == '') {
        lineOfFiles = path;
      } else {
        lineOfFiles = lineOfFiles + ',' + path;
      }
    }
    var config = {
      responseType: 'blob',
      onDownloadProgress: uploadDownloadProgress(),
      timeout: 2000000000000000000000000,
    };
    var filesOld = lineOfFiles.split(',');
    var files = [];
    var folder = filesOld[0].split(
      '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
    )[0];
    for (var j = 0; j < filesOld.length; j++) {
      files.push(filesOld[j].split(folder + '/')[1]);
    }
    folder = folder.replace('edited', 'edited/compressed');
    axios
      .post(
        'https://py.wesnapp.co.il/server/email-logo-images',
        {
          folder: folder,
          files: files,
          zipname: zipname,
          email: email,
          address:
            requestData.ShootingStreet__c + ', ' + requestData.ShootingCity__c,
          overlay_path: 'watermarks/' + contactData.AccountId + '/overlay.png',
          opacity: logoPreference.opacity,
          size: logoPreference.logoSize,
          position: logoPreference.logoPosition,
        },
        config
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setSentOpenEmailDialog(true);
  };

  return (
    <div className={classes.root}>
      {openEmailDialog && (
        <EmailDialog
          open={openEmailDialog}
          setOpenEmailDialog={setOpenEmailDialog}
          sendImagesWithLogoViaEmail={sendImagesWithLogoViaEmail}
          sendImagesViaEmail={sendImagesViaEmail}
          selectedInThePast={selectedInThePast}
          emailType={mailType}
          contactData={contactData}
          requestData={requestData}
        />
      )}
      <SendByEmailDialog
        open={openSentEmailDialog}
        setOpenEmailDialog={setSentOpenEmailDialog}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={severity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
      {galleryIsOpen && (
        <Lightbox
          mainSrc={editedImages.imagesForGallery[galleryIndex].src}
          nextSrc={
            editedImages.imagesForGallery[
              (galleryIndex + 1) % editedImages.imagesForGallery.length
            ].src
          }
          prevSrc={
            editedImages.imagesForGallery[
              (galleryIndex + editedImages.imagesForGallery.length - 1) %
                editedImages.imagesForGallery.length
            ].src
          }
          onCloseRequest={() => {
            setGalleryIsOpen(false);
          }}
          onMovePrevRequest={() =>
            setGalleryIndex(
              (galleryIndex + editedImages.imagesForGallery.length - 1) %
                editedImages.imagesForGallery.length
            )
          }
          onMoveNextRequest={() =>
            setGalleryIndex(
              (galleryIndex + 1) % editedImages.imagesForGallery.length
            )
          }
        />
      )}
      <div className={classes.content}>
        {/* <Grid container spacing={4} dir="ltr" justify="center">
                    <Grid item xs={12} sm={12} md={10} xl={8}> */}
        {loading === false ? (
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                {/* <PhotoShootTitle id={requestData.Name} /> */}
                {/* <PhotoShootDetails location={shootingLocation} dateTime={dateAndTime} product={productData.Public_Product_Name__c} photographer={requestData.Photographer_Name__c} /> */}
                <PhotoShootImagesTitle
                  selectAll={selectAll}
                  showSelectAll={
                    selectedImages.length !==
                    editedImages.imagesForGallery.length
                  }
                  requestData={requestData}
                  selectedImages={selectedImages}
                  extraImageDiscount={extraImageDiscount}
                  extraImagePrice={extraImagePrice}
                  couponIsValid={couponIsValid}
                  couponDiscount={couponDiscount}
                  couponType={couponType}
                  productData={productData}
                  createSnackbar={createSnackbar}
                  getSelectedImagesToPayFor={getSelectedImagesToPayFor}
                  getCostForExtraProduct={getCostForExtraProduct}
                  errorCoupon={errorCoupon}
                  canApply={canApply}
                  errorCouponText={errorCouponText}
                  checkingCoupon={checkingCoupon}
                  downloadZipFileWatermark={downloadZipFileWatermark}
                  checkIfCouponIsValid={checkIfCouponIsValid}
                  handleChangeCoupon={handleChangeCoupon}
                  getTotalAmountToPayFor={getTotalAmountToPayFor}
                  onFormSubmit={() => console.log('')}
                  onChangeLogo={onChangeLogo}
                  uploadingWatermark={uploadingWatermark}
                  logo_preferences={logoPreference}
                  handleChangeLogoPosition={handleChangeLogoPosition}
                  handleChangeOpacity={handleChangeOpacity}
                  handleChangeSize={handleChangeSize}
                  editedImages={editedImages}
                  updateInProgress={updateInProgress}
                  imageHash={imageHash}
                  requestData={requestData}
                  selectedInThePast={selectedInThePast}
                  confirmSelection={confirmSelection}
                  confirmSelect={confirmSelect}
                  downloadZipFile={downloadZipFile}
                  sendToMyEmail={sendToMyEmail}
                  setOpenVideoDialog={setOpenVideoDialog}
                />
                <Grid container spacing={2}>
                  {editedImages.imagesForGallery.map((item, i) => (
                    <Image
                      item={item}
                      createSnackbar={createSnackbar}
                      i={i}
                      onSelectImage={onSelectImage}
                      key={i}
                      selectedInThePast={selectedInThePast}
                      getLinkAfterReplacement={getLinkAfterReplacement}
                      openGallery={openGallery}
                    />
                  ))}
                </Grid>
              </div>
              <div style={{ minWidth: 400 }}>
                {loading === false && (
                  <Drawer
                    requestData={requestData}
                    selectedImages={selectedImages}
                    extraImageDiscount={extraImageDiscount}
                    extraImagePrice={extraImagePrice}
                    couponIsValid={couponIsValid}
                    couponDiscount={couponDiscount}
                    couponType={couponType}
                    productData={productData}
                    createSnackbar={createSnackbar}
                    getSelectedImagesToPayFor={getSelectedImagesToPayFor}
                    getCostForExtraProduct={getCostForExtraProduct}
                    errorCoupon={errorCoupon}
                    canApply={canApply}
                    errorCouponText={errorCouponText}
                    checkingCoupon={checkingCoupon}
                    downloadZipFileWatermark={downloadZipFileWatermark}
                    checkIfCouponIsValid={checkIfCouponIsValid}
                    handleChangeCoupon={handleChangeCoupon}
                    getTotalAmountToPayFor={getTotalAmountToPayFor}
                    onFormSubmit={() => console.log('')}
                    onChangeLogo={onChangeLogo}
                    uploadingWatermark={uploadingWatermark}
                    logo_preferences={logoPreference}
                    handleChangeLogoPosition={handleChangeLogoPosition}
                    handleChangeOpacity={handleChangeOpacity}
                    handleChangeSize={handleChangeSize}
                    editedImages={editedImages}
                    updateInProgress={updateInProgress}
                    imageHash={imageHash}
                    requestData={requestData}
                    selectedInThePast={selectedInThePast}
                    confirmSelection={confirmSelection}
                    confirmSelect={confirmSelect}
                    downloadZipFile={downloadZipFile}
                    sendToMyEmail={sendToMyEmail}
                    setOpenVideoDialog={setOpenVideoDialog}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <Skeletons />
        )}
        {/* </Grid>
                </Grid> */}
      </div>
      <ConfirmSelectionDialog
        open={openConfirmDialog}
        setDialogStatus={setOpenConfirmDialog}
        addingImagesToSalesforce={addingImagesToSalesforce}
        addImagesToSelection={addImagesToSelection}
      />
      <PaymentDialog
        open={openPaymentDialog}
        setDialogStatus={setOpenPaymentDialog}
        paymentWithProduct={paymentWithProduct}
        paymentLink={paymentLink}
        paymentData={paymentData}
      />
      <DownloadDialog
        open={openDownloadDialog}
        setDialogStatus={setOpenDownloadDialog}
        downloadPercenteges={downloadPercenteges}
        prepearingImages={prepearingImages}
      />
    </div>
  );
};

export default PhotoShoot;
