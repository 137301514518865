import React, { useContext } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  ButtonBase,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/CloseRounded';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Gallery from 'react-photo-gallery';
import Photo from './Photo';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardReturnRounded } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FilledInput from '@material-ui/core/FilledInput';
import Input from '@material-ui/core/Input';
import html2canvas from 'html2canvas';
import HomeIcon from '@material-ui/icons/Home';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ColorPicker from './ColorPicker';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import Room from '../../../../Assets/Room.js';
import Type from '../../../../Assets/Type.js';
import Size from '../../../../Assets/Size.js';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix='₪'
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function getSteps() {
  return ['Asset Details', 'Agent Details & Design', 'Select Images'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`;
    case 1:
      return 'An ad group contains one or more ads which target a shared set of keywords.';
    case 2:
      return `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`;
    default:
      return 'Unknown step';
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 18,
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  createVideoButton: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 12,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 18,
    textTransform: 'none',
  },
}));

export default function ScrollDialog({
  open,
  setOpen,
  selectedInThePast,
  logoPreferences,
  accountId,
  orderId,
  requestId,
  contactId,
  createSnackbar,
  requestData,
  uploadingWatermark,
  onChangeLogo,
  onFormSubmit,
}) {
  const [scroll, setScroll] = React.useState('paper');
  const [images, setImages] = React.useState([]);

  const [price, setPrice] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [size, setSize] = React.useState('');
  const [propertyType, setPropertyType] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [rooms, setRooms] = React.useState('');
  const [agentName, setAgentName] = React.useState('');

  const [gender, setGender] = React.useState('סוכן');
  const [dealType, setDealType] = React.useState('למכירה');

  const [loading, setLoading] = React.useState(false);

  const [color, setColor] = React.useState('#575DC5');

  const [profileImage, setProfileImage] = React.useState(null);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const onChangeProfileImage = (e) => {
    let currentFile = e.target.files[0];

    var reader = new FileReader();

    reader.onload = function (event) {
      setProfileImage(event.target.result);
    };

    reader.readAsDataURL(currentFile);
  };

  const getSignedUrl = async (fileName, contentType) => {
    let response = await axios.post(
      'https://wesnapp-node-server.appspot.com/api/storage/get-signed-url',
      { fileName: fileName, contentType: contentType },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    return response.data;
  };

  const toDataURL = async (url, base64) => {
    const blob = await fetch(base64).then((res) => res.blob());

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.onload = () => {
      const status = xhr.status;
      const data = xhr.data;
      return status;
    };

    xhr.onerror = (e) => {
      console.log('Something went wrong', e);
    };
    xhr.setRequestHeader('Content-Type', 'image/png');
    xhr.send(blob);
  };

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const uploadImageFunction = async (imgData) => {
    let randomName = `${uuidv4()}.png`;
    let overlayLink = `https://storage.googleapis.com/wesnapp/watermarks/video/${randomName}`;
    console.log('randomName', randomName);
    let directory = `watermarks/video/`;

    let signedUrl = await getSignedUrl(directory + randomName, 'image/png');

    await toDataURL(signedUrl, imgData);

    return overlayLink;
  };

  // const test = async () => {
  //   document.querySelector('#test').style.letterSpacing = '0.1px';
  //   let overlayLinkCanva = await html2canvas(document.querySelector("#test"), {useCORS: true, backgroundColor: null})
  //   let overlayLink = await uploadImageFunction(overlayLinkCanva.toDataURL('image/png'))
  //   console.log('overlayLink', overlayLink)
  // }

  const handleRenderVideo = async () => {
    document.querySelector('#test').style.letterSpacing = '0.1px';

    if (
      price === '' ||
      address === '' ||
      size === '' ||
      propertyType === '' ||
      phone === '' ||
      rooms === '' ||
      agentName === ''
    ) {
      alert('Please fill all the fields');
      return;
    }

    setLoading(true);

    let overlayLinkCanva = await html2canvas(document.querySelector('#test'), {
      useCORS: true,
      backgroundColor: null,
    });
    let overlayLink = await uploadImageFunction(
      overlayLinkCanva.toDataURL('image/png')
    );

    let closingOverlayCanva = await html2canvas(
      document.querySelector('#closingScreen'),
      { useCORS: true, backgroundColor: null }
    );
    let closingOverlayLink = await uploadImageFunction(
      closingOverlayCanva.toDataURL('image/png')
    );

    console.log('overlayLink', overlayLink);

    let finalImages = [];
    images.forEach((image) => {
      if (image.selected) finalImages.push(getSmallerImage(image.image));
    });

    var data = JSON.stringify({
      price: '₪' + numberWithCommas(price),
      phone: phone,
      address: address,
      rooms: rooms + ' חדרים',
      propertyType: propertyType,
      size: size + ' מ״ר',
      images: finalImages,
      accountId: accountId,
      contactId: contactId,
      orderId: orderId,
      requestId: requestId,
      watermarkLink: overlayLink,
      closingOverlay: closingOverlayLink,
    });

    // console.log('data', data)
    // return

    var config = {
      method: 'post',
      url: 'https://wesnapp-node-server.appspot.com/api/video/create',
      // url: 'http://localhost:8080/api/video/create',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setLoading(false);
        createSnackbar(
          'success',
          'Video in progress. We will notify you when it ready'
        );
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangeText = (e) => {
    switch (e.target.name) {
      case 'price':
        setPrice(e.target.value);
        break;
      case 'address':
        setAddress(e.target.value);
        break;
      case 'size':
        setSize(e.target.value);
        break;
      case 'propertyType':
        setPropertyType(e.target.value);
        break;
      case 'rooms':
        setRooms(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'agentName':
        setAgentName(e.target.value);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    let arr = [];
    selectedInThePast.forEach((link, index) => {
      arr.push({ id: index, image: link, selected: true });
    });
    setImages(arr);
  }, []);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getSmallerImage = (link) => {
    let imageName = link.split('/').pop();
    let surffix = link.split('.').pop();
    let reducedLink = link.replace(
      imageName,
      'compressed/' + imageName.replace('.' + surffix, '_reduced.' + surffix)
    );
    return reducedLink;
  };

  const removeImageByIndex = (index) => {
    let tempImages = images;
    console.log('x', tempImages);
    tempImages[index].selected = !tempImages[index].selected;
    console.log('y', tempImages);
    setImages([...tempImages]);
  };

  const SortableItem = SortableElement((props) => (
    <div
      style={{
        width: 160,
        height: 160,
        cursor: 'grab',
        position: 'relative',
        zIndex: 999999999999,
        borderRadius: 8,
        position: 'relative',
        boxShadow: '0 6px 10px #00000040',
        left: 0,
        top: 0,
        margin: 12,
      }}
    >
      <img
        src={getSmallerImage(props.image.image)}
        alt=''
        style={{ objectFit: 'cover', width: 160, height: 160, borderRadius: 8 }}
      />
      {props.image.selected ? (
        <button
          onClick={() => removeImageByIndex(props.value)}
          style={{
            fontSize: 20,
            cursor: 'pointer',
            outline: 'none',
            border: 0,
            color: 'white',
            backgroundColor: '#33D7A0',
            boxShadow: '0px 6px 10px #33D7A050',
            height: 24,
            width: 24,
            position: 'absolute',
            zIndex: 9999999999999,
            borderRadius: 12,
            right: -8,
            top: -8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CheckRoundedIcon style={{ color: 'white', width: 20, height: 20 }} />
        </button>
      ) : (
        <button
          onClick={() => removeImageByIndex(props.value)}
          style={{
            fontSize: 16,
            cursor: 'pointer',
            outline: 'none',
            border: 0,
            color: 'white',
            backgroundColor: '#9a9a9a',
            boxShadow: '0px 6px 10px #9a9a9a50',
            height: 24,
            width: 24,
            position: 'absolute',
            zIndex: 9999999999999,
            borderRadius: 12,
            right: -8,
            top: -8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CheckRoundedIcon style={{ color: 'white', width: 20, height: 20 }} />
        </button>
      )}
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'wrap',
          justifyContent: 'left',
          marginTop: 12,
        }}
      >
        {images.map((image, index) => (
          <SortableItem
            key={`item-${index}`}
            image={image}
            index={index}
            value={index}
          />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setImages([...arrayMove(images, oldIndex, newIndex)]);
  };

  const SortablePhoto = SortableElement((item) => (
    <Photo {...item} src={item.image} />
  ));

  const SortableGallery = SortableContainer(({ items }) => (
    <Gallery
      photos={items}
      renderImage={(props) => <SortablePhoto {...props} />}
    />
  ));

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleChangeDealType = (event) => {
    setDealType(event.target.value);
  };

  //   const numberWithCommas = (x) => {
  //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  const getStep1 = () => {
    return (
      <>
        <FormControl fullWidth component='fieldset' style={{ marginTop: 12 }}>
          <FormLabel component='legend'>Deal type</FormLabel>
          <RadioGroup
            aria-label='dealType'
            name='dealType'
            value={dealType}
            onChange={handleChangeDealType}
          >
            <FormControlLabel value='למכירה' control={<Radio />} label='Sale' />
            <FormControlLabel value='להשכרה' control={<Radio />} label='Rent' />
          </RadioGroup>
        </FormControl>
        <TextField
          name='propertyType'
          fullWidth
          onChange={handleChangeText}
          label={'Property Type'}
          style={{ marginTop: 8 }}
        />
        <TextField
          name='address'
          fullWidth
          onChange={handleChangeText}
          label={'Address'}
          style={{ marginTop: 12 }}
        />
        <TextField
          name='rooms'
          fullWidth
          onChange={handleChangeText}
          label={'Rooms'}
          style={{ marginTop: 12 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>rooms</InputAdornment>
            ),
          }}
        />
        <TextField
          name='size'
          fullWidth
          onChange={handleChangeText}
          label={'Size in squared metters'}
          style={{ marginTop: 12 }}
          InputProps={{
            endAdornment: <InputAdornment position='start'>sqm</InputAdornment>,
          }}
        />
        <TextField
          fullWidth
          name='phone'
          onChange={handleChangeText}
          label={'Phone'}
          style={{ marginTop: 12 }}
        />
        <TextField
          label='Price'
          style={{ marginTop: 12 }}
          fullWidth
          name='price'
          onChange={handleChangeText}
          id='formatted-numberformat-input'
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
      </>
    );
  };

  const getStep2 = () => {
    return (
      <>
        <div
          style={{
            fontFamily: "'Alef', sans-serif",
            fontSize: 14,
            color: '#000000',
            fontWeight: '500',
            textAlign: 'left',
            marginBottom: 12,
            marginTop: 12,
            color: 'rgb(40,40,53)',
          }}
        >
          Upload your logo
        </div>
        <form onSubmit={onFormSubmit}>
          <input
            type='file'
            name='myImage'
            onChange={onChangeLogo}
            style={{
              display: 'inline-block',
              textAlign: 'left',
              width: '100%',
            }}
          />
          {uploadingWatermark && (
            <div style={{ marginTop: 0, display: 'inline-block' }}>
              <CircularProgress size={20} style={{ marginTop: 0 }} />
            </div>
          )}
        </form>
        <div
          style={{
            fontFamily: "'Alef', sans-serif",
            fontSize: 14,
            color: '#000000',
            fontWeight: '500',
            textAlign: 'left',
            marginBottom: 12,
            marginTop: 12,
            color: 'rgb(40,40,53)',
          }}
        >
          Upload agent image
        </div>
        <form onSubmit={onFormSubmit}>
          <input
            type='file'
            name='profileImage'
            onChange={onChangeProfileImage}
            style={{
              display: 'inline-block',
              textAlign: 'left',
              width: '100%',
            }}
          />
        </form>
        <FormControl fullWidth component='fieldset' style={{ marginTop: 12 }}>
          <FormLabel component='legend'>Pick main color</FormLabel>
          <ColorPicker setColor={setColor} />
        </FormControl>
        <TextField
          name='agentName'
          fullWidth
          onChange={handleChangeText}
          label={'Agent Name'}
          style={{ marginTop: 12 }}
        />
        <FormControl fullWidth component='fieldset' style={{ marginTop: 12 }}>
          <FormLabel component='legend'>Agent gender</FormLabel>
          <RadioGroup
            aria-label='gender'
            name='gender'
            value={gender}
            onChange={handleChangeGender}
          >
            <FormControlLabel
              value='סוכנת'
              control={<Radio />}
              label='Female'
            />
            <FormControlLabel value='סוכן' control={<Radio />} label='Male' />
          </RadioGroup>
        </FormControl>
      </>
    );
  };

  const getStep3 = () => {
    return (
      <>
        <Typography style={{ marginTop: 12, fontWeight: 400, fontSize: 16 }}>
          Feel free to change the order of the images for the video by dragging
          them to the place you want. You can also choose which images to remove
          from the video by clicking on the - (Minus) icon.
        </Typography>
        <SortableList
          distance={1}
          axis={'xy'}
          items={images}
          onSortEnd={onSortEnd}
        />
      </>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        fullWidth
        maxWidth={'lg'}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>Create Video</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <div id='test2'></div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <Stepper activeStep={activeStep} orientation='vertical'>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <Typography
                      style={{
                        marginTop: -8,
                        marginBottom: 0,
                        fontWeight: 600,
                        fontSize: 24,
                        cursor: 'pointer',
                      }}
                      onClick={() => setActiveStep(index)}
                    >
                      {label}
                    </Typography>
                    <StepContent>
                      {index === 0 && getStep1()}
                      {index === 1 && getStep2()}
                      {index === 2 && getStep3()}
                      {index !== 2 && (
                        <div className={classes.actionsContainer}>
                          <div>
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                            >
                              Back
                            </Button>
                            <Button
                              variant='contained'
                              color='primary'
                              onClick={handleNext}
                              className={classes.button}
                            >
                              {activeStep === steps.length - 1
                                ? 'Finish'
                                : 'Next'}
                            </Button>
                          </div>
                        </div>
                      )}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button>
                </Paper>
              )}
            </div>
            <div style={{ minWidth: 420, height: 720, marginLeft: 24 }}>
              <Typography
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                  fontWeight: 600,
                  fontSize: 24,
                }}
              >
                Overlay Preview
              </Typography>
              <div style={{ minWidth: 420, height: 720, position: 'relative' }}>
                <img
                  alt=''
                  src={images[0] ? images[0].image : ''}
                  style={{
                    width: 420,
                    height: 720,
                    objectFit: 'cover',
                    objectPosition: 'right',
                  }}
                />
                <div
                  id='test'
                  style={{
                    fontFamily: "'Alef', sans-serif",
                    width: 350,
                    height: 720,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    background: 'rgba(255,255,255,0.7)',
                    textAlign: 'right',
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                  }}
                >
                  {logoPreferences.url && logoPreferences.url !== '' && (
                    <img
                      alt=''
                      src={logoPreferences.url}
                      style={{
                        height: 60,
                        marginTop: 18,
                        marginBottom: 12,
                        width: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  )}
                  <div
                    style={{
                      fontWeight: 400,
                      color: '#000000',
                      fontSize: 22,
                      textAlign: 'center',
                      direction: 'rtl',
                    }}
                  >{`${dealType} ב${address}`}</div>
                  <div style={{ width: '100%' }}>
                    {propertyType !== '' && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row-reverse',
                          marginTop: 14,
                        }}
                      >
                        <div
                          style={{ margin: 12, marginTop: 8, marginBottom: 8 }}
                        >
                          <Type color={color} size={'42px'} />
                        </div>
                        <div
                          style={{
                            fontWeight: 400,
                            color: '#000000',
                            fontSize: 28,
                            direction: 'rtl',
                          }}
                        >
                          {propertyType}
                        </div>
                      </div>
                    )}
                    {rooms !== '' && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row-reverse',
                        }}
                      >
                        <div
                          style={{ margin: 12, marginTop: 8, marginBottom: 8 }}
                        >
                          <Room color={color} size={'42px'} />
                        </div>
                        <div
                          style={{
                            fontWeight: 400,
                            color: '#000000',
                            fontSize: 28,
                            direction: 'rtl',
                          }}
                        >
                          <span
                            style={{ fontFamily: "'Open Sans', sans-serif" }}
                          >
                            {rooms}
                          </span>{' '}
                          חדרים
                        </div>
                      </div>
                    )}
                    {size !== '' && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row-reverse',
                        }}
                      >
                        <div
                          style={{ margin: 12, marginTop: 8, marginBottom: 8 }}
                        >
                          <Size color={color} size={'42px'} />
                        </div>
                        <div
                          style={{
                            fontWeight: 400,
                            color: '#000000',
                            fontSize: 28,
                            direction: 'rtl',
                          }}
                        >
                          <span
                            style={{ fontFamily: "'Open Sans', sans-serif" }}
                          >
                            {size}
                          </span>{' '}
                          מ״ר
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        width: 'calc(100% - 48px)',
                        color: '#000000',
                        marginTop: 18,
                        marginLeft: 24,
                        textAlign: 'center',
                        fontWeight: 900,
                        fontSize: 32,
                        marginBottom: 18,
                        borderTop: `2px solid ${color}`,
                        borderBottom: `2px solid ${color}`,
                        fontFamily: "'Open Sans', sans-serif",
                      }}
                    >
                      ₪{numberWithCommas(price)}
                    </div>
                    {profileImage && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          style={{
                            width: 80,
                            height: 80,
                            background: 'white',
                            borderRadius: 40,
                            objectFit: 'contain',
                          }}
                          alt=''
                          src={profileImage}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        marginTop: 12,
                        color: '#000000',
                        textAlign: 'center',
                        fontSize: 26,
                        fontWeight: 600,
                      }}
                    >
                      {agentName}
                    </div>
                    <div
                      style={{
                        marginTop: 6,
                        color: '#000000',
                        textAlign: 'center',
                        fontSize: 22,
                        fontWeight: 400,
                        direction: 'rtl',
                      }}
                    >
                      {`${gender} נדל״ן`}
                    </div>
                    <div
                      style={{
                        marginTop: 12,
                        color: '#000000',
                        textAlign: 'center',
                        fontSize: 26,
                        fontWeight: 400,
                        fontFamily: "'Open Sans', sans-serif",
                      }}
                    >
                      {phone}
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: 75,
                        background: color,
                        color: 'white',
                        fontSize: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        fontWeight: 'bold',
                      }}
                    >
                      {dealType}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <div style={{ position: 'fixed', top: -2000, left: 0 }}>
          <div
            style={{
              width: 1280,
              height: 720,
              position: 'relative',
              fontFamily: "'Alef', sans-serif",
            }}
          >
            <img
              alt=''
              src={images[0] ? images[images.length - 1].image : ''}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'right',
              }}
            />
            <div
              id='closingScreen'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                flexFlow: 'column',
                display: 'flex',
                alignItems: 'center',
                background: 'rgba(255,255,255,0.6)',
              }}
            >
              {logoPreferences.url && logoPreferences.url !== '' && (
                <img
                  alt=''
                  src={logoPreferences.url}
                  style={{
                    height: 90,
                    marginTop: 120,
                    width: 'auto',
                    objectFit: 'cover',
                  }}
                />
              )}
              <div
                style={{
                  marginTop: 32,
                  color: '#000000',
                  textAlign: 'center',
                  fontSize: 100,
                  fontWeight: 900,
                }}
              >
                {agentName}
              </div>
              <div
                style={{
                  color: '#000000',
                  textAlign: 'center',
                  fontSize: 70,
                  fontWeight: 400,
                  marginTop: 0,
                  fontFamily: "'Open Sans', sans-serif",
                }}
              >
                {phone}
              </div>
              <div
                style={{
                  height: 10,
                  width: 700,
                  backgroundColor: color,
                  marginTop: 20,
                }}
              ></div>
            </div>
          </div>
        </div>
        <DialogActions>
          {/* <Button onClick={test} color="primary" style={{textTransform: 'none'}}>
            Cancel
          </Button> */}
          <Button
            onClick={handleClose}
            color='primary'
            style={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          {activeStep === 2 && (
            <Button
              onClick={handleRenderVideo}
              color='primary'
              disabled={loading}
              className={classes.createVideoButton}
            >
              {loading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Create Video'
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
