import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  Button,
  makeStyles,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import {
  MuiThemeProvider,
  createMuiTheme,
  StylesProvider,
  jssPreset,
} from '@material-ui/core/styles';
import PageStyle from './Bmby.style.js';
import ImageGallery from './ImageGallery';
import CustomizedProgressBars from './ProgressBar.js';
import Location from './Location';
import MuiThemeStyle from './MuiThemeStyle.js';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { TimePicker, DatePicker } from 'antd';
import moment from 'moment';
import '../../../../node_modules/antd/dist/antd.css';
import axios from 'axios';
import history from '../../../utils/history';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ReactPixel from 'react-facebook-pixel';
import { Email } from '@material-ui/icons';
import SmsDialog from './SmsDialog';
import MuiPhoneNumber from 'material-ui-phone-number';
import { phone as phoneValidator } from 'phone';

const useStyle = makeStyles(PageStyle);
const theme = createMuiTheme(MuiThemeStyle);
// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

function encode_utf8(s) {
  return s;
  // return decodeURI(s)
  // return decodeURIComponent( s );
}

const text = {
  welcomeTitle: 'ברוך הבא ל',
  mobileWelcomeMessage: 'ברוך הבא',
  welcomeText1:
    'מאות הצלמים שלנו פרוסים בכל הארץ כדי להעניק לכם חווית צילום שלא הכרתם.',
  welcomeText2: 'הזמנה בקליק ללא כאבי ראש, בכל זמן ובכל מקום שתרצו.',
  point1: 'וויסנאפ לוקחת את הצילום לקצה עם מיטב הצלמים והטכנולוגיה.',
  point2:
    'הנכס המצולם יכול להיות פשוט אבל הצילום חייב להיראות מקצועי! זה מה שעושה את הבידול.',
  point3: 'תמונה מקצועית תמקסם את החשיפה שלך פי 8!',
  point4:
    'מחקרים אומרים כי בעזרת צילום מקצועי תוכל למכור/להשכיר את הנכס 50% מהר יותר!',
  point5: 'הציעו ללקוחות שלכם 3 חוזקות. איכות מקצועיות ואחידות.',
  // welcomeText: 'אנו חברת הצילום הגדולה בישראל. אנו נספק לך חווית צילום מקצועי שלא הכרת! עם צלמים בפריסה ארצית שזמינים עבורך מתי שרק נוח לך.',
  startButton: 'בואו נתחיל!',
  next: 'הבא',
  previous: 'הקודם',
  getAddressTitle: 'היכן תרצה שיתבצע הצילום?',
  getDateAndTimeTitle: 'באיזה מועד יתבצע הצילום?',
  productTitle: 'בחר מוצר',
  paymentWaitingMessage:
    'תהליך עיבוד התשלום עשוי לקחת מספר שניות. אנא המתן בסבלנות',
  images: 'תמונות',
  summary: 'סיכום הזמנה',
  where: 'מיקום',
  when: 'יום ושעה',
  numberOfImages: 'מספר תמונות',
  shootingCost: 'מחיר',
};

const BmbyOrderPage = (props) => {
  const classes = useStyle();

  const [auth, setAuth] = useState(false);

  const [step, setStep] = useState(0);
  const [location, setLocation] = useState(null);
  const [datetime, setDatetime] = useState(null);
  const [errorLocation, setErrorLocation] = useState(false);
  const [errorDateTime, setErrorDateTime] = useState(false);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accountId, setAccountId] = useState(null);
  const [isNewUser, setIsNewUser] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [paymentURL, setPaymentURL] = useState(null);

  const [couponType, setCouponType] = useState('cash');
  // const [couponCode, setCouponCode] = useState('BMBY99')
  const [couponCode, setCouponCode] = useState(null);
  const [couponAmount, setCouponAmount] = useState(259);
  const [couponIsValid, setCouponIsValid] = useState(false);
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const [canApply, setCanApply] = useState(false);
  const [errorCoupon, setErrorCoupon] = useState(false);
  const [errorCouponReason, setErrorCouponReason] = useState('');
  const [couponResultText, setCouponResultText] = useState('');

  const [packageId, setPackageId] = useState(null);
  const [packageOrderId, setPackageOrderId] = useState(null);

  const [checkIfUsed1plus1, setCheckIfUsed1plus1] = useState(false);
  const [anotherBasicForFree, setAnotherBasicForFree] = useState(false);

  const [askForEmail, setAskForEmail] = useState(false);
  const [askForPhone, setAskForPhone] = useState(false);

  const [skipStep1, setSkipStep1] = useState(false);

  const [userCode, setUserCode] = useState('');
  const [errorCode, setErrorCode] = useState(false);
  const [code, setCode] = useState('');
  const [smsDialogStatus, setSmsDialogStatus] = useState(false);
  const [partnerUserId, setPartnerUserId] = useState(null);
  const [remarketing, setRemarketing] = useState(false);
  const [partnerCompanyId, setPartnerCompanyId] = useState(null);
  const [partnerCompanyName, setPartnerCompanyName] = useState(null);

  const [askForName, setAskForName] = useState(false);

  const setupPixel = () => {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init('717379229167457', advancedMatching, options);
  };

  useEffect(() => {
    setupPixel();
    // console.log('window.location.href', window.location.href)
    // console.log('window.location.search', window.location.search);
    // console.log('decodeURI(window.location.href)', decodeURIComponent(window.location.href));
    // console.log('decodeURI(window.location.search)', decodeURI(window.location.search));
    let params = new URLSearchParams(
      decodeURIComponent(window.location.search)
    );
    // check key
    if (
      params.get('key') === null ||
      params.get('key') !== '8cb9c926c55f44f3aaa82d3fd8db4c61'
    ) {
      // console.log('redirect', params.get('key'))
      window.location.replace('https://my.wesnapp.co.il/order');
    }

    let tempFirstName = null;
    let tempLastName = null;
    let tempEmail = null;
    let tempOfficeId = null;
    let tempOfficeName = null;
    // let tempLName = null

    if (
      params.get('firstname') === null ||
      params.get('lastname') === null ||
      params.get('phone') === null
    ) {
      setRemarketing(true);
      setAskForEmail(true);
      setLoading(false);
    } else {
      // First Name
      console.log('firstName', params.get('firstname'));
      tempFirstName = encode_utf8(params.get('firstname'));
      console.log('firstName decoded', tempFirstName);
      setFirstName(tempFirstName);
      // Last Name
      tempLastName = encode_utf8(params.get('lastname'));
      setLastName(tempLastName);
      // Agent Id
      setPartnerUserId(params.get('agentid'));
      // Email
      if (params.get('email') === null) {
        setAskForEmail(true);
      } else {
        tempEmail = params.get('email');
        if (!validateEmail(params.get('email'))) setAskForEmail(true);
      }
      // Office Id
      if (params.get('entitytype')) {
        if (params.get('entitytype') === 'company') {
          if (params.get('officeid')) {
            tempOfficeId = encode_utf8(params.get('officeid').split('-')[0]);
            setPartnerCompanyId(tempOfficeId);
          }
          if (params.get('officename')) {
            tempOfficeName = encode_utf8(params.get('officename'));
            setPartnerCompanyName(tempOfficeName);
          }
        }
      }

      if (params.get('phone')) {
        if (
          checkIfPhoneIsValidWithoutChangingTheState(params.get('phone')).valid
        ) {
          findClientUsingPhoneNumberOrAgentId(
            checkIfPhoneIsValidWithoutChangingTheState(params.get('phone'))
              .phone,
            params.get('agentid'),
            tempFirstName,
            tempLastName,
            tempEmail,
            tempOfficeId,
            tempOfficeName
          );
          setPhone(
            checkIfPhoneIsValidWithoutChangingTheState(params.get('phone'))
              .phone
          );
        } else {
          findClientUsingPhoneNumberOrAgentId(
            checkIfPhoneIsValidWithoutChangingTheState(params.get('phone'))
              .phone,
            params.get('agentid'),
            tempFirstName,
            tempLastName,
            tempEmail,
            tempOfficeId,
            tempOfficeName
          );
        }
      } else {
        setRemarketing(true);
        setLoading(false);
      }
    }
    console.log('v1.0.2');
  }, []);

  useEffect(() => {
    if (code !== '') {
      setSmsDialogStatus(true);
      sendVerificationCode();
    }
  }, [code]);

  useEffect(() => {
    if (step === 0) {
      history.push(
        '/bmby/welcome' +
          window.location.search.replace(
            '&key=8cb9c926c55f44f3aaa82d3fd8db4c61',
            ''
          )
      );
      // history.push('/bmby/welcome' + window.location.search)
    } else if (step === 1) {
      history.push('/bmby/phone' + window.location.search);
    } else if (step === 2) {
      history.push('/bmby/location' + window.location.search);
    } else if (step === 3) {
      history.push('/bmby/datetime' + window.location.search);
    } else if (step === 4) {
      history.push('/bmby/product' + window.location.search);
    } else if (step === 5) {
      history.push('/bmby/email' + window.location.search);
    } else if (step === 6) {
      history.push('/bmby/completed' + window.location.search);
    }
    ReactPixel.pageView();
  }, [step]);

  useEffect(() => {
    if (location) {
      getCoordinates(location.description);
    }
  }, [location]);

  useEffect(() => {
    // console.log('partnerUserId', partnerUserId)
  }, [partnerUserId]);

  useEffect(() => {
    if (contactId) {
      checkIfUserCanUseCoupon();
    }
  }, [contactId]);

  const verifyCode = () => {
    setErrorCode(false);
    if (String(userCode) === String(code) && userCode !== '') {
      setSmsDialogStatus(false);
      updateUserValidated();
      updateAccountWithMobilePhoneNumber(phone);
    } else {
      setErrorCode(true);
    }
  };

  const checkIfUserCanUseCoupon = () => {
    var data = { id: contactId };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/request/get-by-contact',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(async (response) => {
        if (response.data.length === 0) {
          setCouponIsValid(true);
        }
      });
  };

  const getCoordinates = async (address) => {
    try {
      var link =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        encodeURI(address) +
        '&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s';
      const response = await axios.get(link);
      if (response.data.results.length > 0) {
        if (
          response.data.results[0].types.includes('street_address') ||
          response.data.results[0].types.includes('premise')
        ) {
          setLat(response.data.results[0].geometry.location.lat);
          setLng(response.data.results[0].geometry.location.lng);
          setErrorLocation(false);
        } else {
          setErrorLocation(true);
        }
      } else {
        setErrorLocation(true);
      }
    } catch (err) {
      setErrorLocation(true);
    }
  };

  const checkIfPhoneIsValidWithoutChangingTheState = (tempPhone) => {
    let phoneValidatorObject = phoneValidator('+972' + tempPhone, {
      validateMobilePrefix: false,
    });
    return {
      phone: phoneValidatorObject.phoneNumber,
      valid: phoneValidatorObject.isValid,
    };
  };

  const checkIfPhoneIsValid = (tempPhone) => {
    let phoneValidatorObject = phoneValidator('+972' + tempPhone, {
      validateMobilePrefix: false,
    });
    setPhone(phoneValidatorObject.phoneNumber);
    return phoneValidatorObject.isValid;
  };

  const findClientUsingPhoneNumberOrAgentId = async (
    mobilePhoneNumber,
    clientAgentId,
    firstName,
    lastName,
    email,
    tempOfficeId,
    tempOfficeName
  ) => {
    console.log('mobile', mobilePhoneNumber, 'agentId', clientAgentId);
    let phoneResponse = await axios.post(
      'https://wesnapp-node-server.appspot.com' +
        '/api/contact/find-account-by-phone-number',
      { phone: mobilePhoneNumber },
      { headers: { 'Content-Type': 'application/json' } }
    );
    let agentIdResponse = await axios.post(
      'https://wesnapp-node-server.appspot.com' +
        '/api/account/find-person-account-by-partner-id',
      { clientAgentId: clientAgentId },
      { headers: { 'Content-Type': 'application/json' } }
    );
    let accountsFromPhone = phoneResponse.data;
    let accountsFromAgentId = agentIdResponse.data;
    // console.log('accountsFromPhone', accountsFromPhone)
    // console.log('accountsFromAgentId', accountsFromAgentId)
    let account = null;
    if (accountsFromPhone.length !== 0) {
      account = accountsFromPhone[0];
    }
    // else if (accountsFromAgentId.length !== 0) {
    //     account = accountsFromAgentId[0]
    // }
    // console.log('account', account)
    if (account) {
      // user exist
      setAccountId(account.Id);
      setContactId(account.PersonContactId);
      if (clientAgentId) {
        updateAccountWithPartnerUserId(
          account.Id,
          clientAgentId === 'xxxxxxxx' ? null : clientAgentId,
          tempOfficeId,
          tempOfficeName
        );
      }
      if (account.User_Validated__c) {
        setSkipStep1(true);
      }
      setLoading(false);
    } else {
      // create a user
      // console.log('new user')
      setSkipStep1(false);
      setIsNewUser(true);
      var accountData = {
        mobile: mobilePhoneNumber,
        formattedMobile: mobilePhoneNumber,
        lastName: lastName ? lastName : 'temp',
        firstName: firstName ? firstName : 'temp',
        email: email
          ? validateEmail(email)
            ? email
            : 'temp@wesnapp.io'
          : 'temp@wesnapp.io',
        leadSource: remarketing ? 'Bmby Remarketing' : 'Bmby Dashboard',
      };
      axios
        .post(
          'https://wesnapp-node-server.appspot.com' +
            '/api/account/create-new-client-person-account',
          accountData,
          { headers: { 'Content-Type': 'application/json' } }
        )
        .then((response) => {
          // console.log('partnerUserId 2', partnerUserId)
          if (clientAgentId) {
            updateAccountWithPartnerUserId(
              response.data.accountId,
              clientAgentId === 'xxxxxxxx' ? null : clientAgentId,
              tempOfficeId,
              tempOfficeName
            );
          }
          setAccountId(response.data.accountId);
          setContactId(response.data.contactId);
          setLoading(false);
        });
    }
  };

  const findClientUsingPhoneNumber = async () => {
    setLoading(true);
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/find-person-account-by-mobile',
        { mobile: phone },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(async (response) => {
        // console.log(response);
        var accounts = response.data;
        if (accounts.length !== 0) {
          var accountData = accounts[0];
          setAccountId(accountData.Id);
          setContactId(accountData.PersonContactId);
          setLoading(false);
          if (accountData.User_Validated__c) {
            setSkipStep1(true);
          }
        } else {
          setSkipStep1(false);
          setIsNewUser(true);
          var accountData = {
            mobile: phone,
            lastName: lastName ? lastName : 'temp',
            firstName: firstName ? firstName : 'temp',
            email: email
              ? validateEmail(email)
                ? email
                : 'temp@wesnapp.io'
              : 'temp@wesnapp.io',
          };
          axios
            .post(
              'https://wesnapp-node-server.appspot.com' +
                '/api/account/create-new-client-person-account',
              accountData,
              { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
              // console.log('new account data', response.data)
              if (partnerUserId) {
                updateAccountWithPartnerUserId(response.data.accountId);
              }
              setAccountId(response.data.accountId);
              setContactId(response.data.contactId);
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        // console.log('error', error)
      });
  };

  const updateAccountWithPartnerUserId = (
    newUserAccountId,
    clientAgentId,
    tempOfficeId,
    tempOfficeName
  ) => {
    // console.log('newUserAccountId', newUserAccountId, 'clientAgentId', clientAgentId, 'tempOfficeId', tempOfficeId, 'tempOfficeName', tempOfficeName)
    var data = {
      accountId: newUserAccountId,
      data: {
        Partner_User_Id__c: clientAgentId,
      },
    };
    if (tempOfficeId) {
      data.Partner_Company_Id__c = tempOfficeId;
    }
    if (tempOfficeName) {
      data.Partner_Company_Name__c = tempOfficeName;
    }
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/update-account',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // console.log(response.data)
      })
      .catch((error) => {});
  };

  const updateAccountWithMobilePhoneNumber = (phone) => {
    var data = {
      accountId: accountId,
      data: {
        Mobile_Number__c: phone,
        PersonMobilePhone: phone.replace('0', '+972'),
      },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/update-account',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // console.log(response.data)
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const updateUserWithName = () => {
    setLoading(true);
    var data = {
      accountId: accountId,
      data: {
        FirstName: firstName,
        LastName: lastName,
      },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/update-account',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // console.log(response.data)
        setLoading(false);
        setStep(2);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const onchangeDateTime = (e) => {
    // console.log(e._d)
    setDatetime(e);
    // handleDateTime(e._d)
  };

  const handleDateTime = (event) => {
    setErrorDateTime(false);
    setDatetime(event);
  };

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days').endOf('day');
  };

  const createRequest = async () => {
    setLoading(true);
    var nameForInvoice = firstName + ' ' + lastName;
    var data = {
      firstName: firstName,
      lastName: lastName,
      mobile: phone,
      email: email ? email : 'temp@wesnapp.io',
      selectedProduct: !selectedProduct
        ? 'a016N000000sz64QAA'
        : selectedProduct,
      reference: remarketing ? 'bmby remarketing' : 'bmby dashboard',
      datetime: datetime ? moment(datetime._d).format() : null,
      whoWillBeOnSiteName: firstName + ' ' + lastName,
      whoWillBeOnSitePhone: phone,
      officeName: nameForInvoice,
      city: null,
      street: null,
      address: null,
      country: null,
      lng: null,
      lat: null,
      isPackage: false,
      connectToBusiness: false,
      businessAccountId: '',
      useNow: true,
      onlyOrder: false,
      accountId: accountId,
      contactId: contactId,
      status: 'Basic details',
    };
    if (location) {
      var address = location.description.split(', ');
      var street = address[0];
      var city = address[1];
      var country = address[2];
      data.city = city;
      data.street = street;
      data.lat = lat.toString();
      data.lng = lng.toString();
      data.address = location.description;
      data.country = country;
    }
    console.log('request data: ', data);
    let url = 'https://wesnapp-node-server.appspot.com';
    axios
      .post(url + '/api/action/create-request', data, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(async (response) => {
        setLoading(false);
        console.log('response.data', response.data);
        setRequestId(response.data.requestId);
        setStep(3);
      })
      .catch((error) => {});
  };

  const createOrder = async () => {
    setLoading(true);
    let data = {
      accountId: accountId,
      contactId: contactId,
      requestId: requestId,
      selectedProduct: selectedProduct,
    };
    // console.log(data);
    let url =
      'https://wesnapp-node-server.appspot.com' +
      '/api/action/create-regular-order';
    if (selectedProduct === 'a016N000000sz5zQAA') {
      url =
        'https://wesnapp-node-server.appspot.com' +
        '/api/action/create-package-order';
    } else if (selectedProduct === 'freebasic') {
      // console.log('freebasic freebasic freebasic freebasic freebasic')
      url =
        'https://wesnapp-node-server.appspot.com' +
        '/api/action/' +
        'connect-request-to-package-order';
      data.orderId = packageOrderId;
    }
    axios
      .post(url, data, { headers: { 'Content-Type': 'application/json' } })
      .then(async (response) => {
        // console.log(response.data);
        setOrderId(response.data.orderId);
        let paymentURL = await getPaymentURL(response.data.orderId);
        setLoading(false);
        setPaymentURL(paymentURL);
        // if (step !== 3) {
        // if (askForEmail) {
        //     setStep(4)
        // } else {
        //     setStep(4)
        // }
        // }
      })
      .catch((error) => {});
  };

  const updateUserEmail = async () => {
    setLoading(true);
    var data = {
      accountId: accountId,
      data: {
        PersonEmail: email,
      },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/update-account',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // console.log(response.data)
        setLoading(false);
        setStep(6);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const updateUserValidated = async () => {
    setLoading(true);
    var data = {
      accountId: accountId,
      data: {
        User_Validated__c: true,
        Mobile_Number__c: phone,
      },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/update-account',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // console.log(response.data)
        setLoading(false);
        // console.log('askForName', askForName)
        if (askForName) {
          setStep(1.5);
        } else {
          setStep(2);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const updateRequest = async () => {
    let selectedProductId = 'a016N000000sz64QAA';
    let status = null;
    if (selectedProduct) {
      selectedProductId = selectedProduct;
      status = 'Full details';
    }
    if (selectedProduct === 'a016N000000sz5zQAA') {
      // get signle product id
      selectedProductId = 'a016N000000szX2QAI';
    }
    // console.log('update request', selectedProductId)
    setLoading(true);
    var nameForInvoice = firstName + ' ' + lastName;
    var data = {
      firstName: firstName,
      lastName: lastName,
      mobile: phone,
      email: email ? email : 'temp@wesnapp.io',
      reference: remarketing ? 'bmby remarketing' : 'bmby dashboard',
      datetime: datetime && datetime._d ? moment(datetime._d).format() : null,
      whoWillBeOnSiteName: firstName + ' ' + lastName,
      whoWillBeOnSitePhone: phone,
      city: null,
      street: null,
      address: null,
      country: null,
      lng: null,
      lat: null,
      officeName: nameForInvoice,
      isPackage: false,
      connectToBusiness: false,
      businessAccountId: '',
      useNow: true,
      selectedProduct: selectedProductId,
      onlyOrder: false,
      requestId: requestId,
    };
    if (status) {
      data.status = status;
    }
    if (location) {
      var address = location.description.split(', ');
      var street = address[0];
      var city = address[1];
      var country = address[2];
      data.city = city;
      data.street = street;
      data.lat = lat.toString();
      data.lng = lng.toString();
      data.address = location.description;
      data.country = country;
    }
    // console.log(data);
    if (!requestId) {
      // Can't update if request is null
      alert('Error: Request Id cannot be equal to null.');
    }
    let url = 'https://wesnapp-node-server.appspot.com';
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/action/bmby-update-request',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(async (response) => {
        setLoading(false);
        // console.log('askForEmail', askForEmail)
        if (step === 4 && askForEmail) {
          setStep(5);
        } else if (step === 4 && !askForEmail) {
          setStep(6);
        } else {
          setStep(step + 1);
        }
      })
      .catch((error) => {});
  };

  const checkForOpenPackages = () => {
    var data = { id: accountId };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/order/check-if-bmby-client-can-use-1-plus-1',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        if (response.data === false) {
          setCheckIfUsed1plus1(true);
          axios
            .post(
              'https://wesnapp-node-server.appspot.com' +
                '/api/order/get-open-orders',
              data,
              { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
              for (var i = 0; i < response.data.length; i++) {
                if (response.data[i].singleProductId === 'a016N000000szX2') {
                  setAnotherBasicForFree(true);
                  setPackageOrderId(response.data[i].orderId);
                }
              }
            });
        } else {
          setCheckIfUsed1plus1(false);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (accountId) {
      checkForOpenPackages();
    }
  }, [accountId]);

  // const getPaymentURL = async () => {

  //     var maxpay = "2"
  //     var sum = getTotalCost()
  //     var cred_type = null
  //     var paymentFor = null
  //     if (sum >= 1000 && sum < 2000) {
  //       maxpay = "2"
  //     } else if (sum >= 2000 && sum < 3000) {
  //       maxpay = "3"
  //     } else if (sum >= 3000 && sum < 4000) {
  //       maxpay = "4"
  //     } if (sum >= 5000) {
  //       maxpay = "5"
  //     }
  //     if (selectedProduct.Product_Type__c === 'Single') {
  //         paymentFor = 1
  //     } else if (selectedProduct.Product_Type__c === 'Package') {
  //         paymentFor = 5
  //     }
  //     var link = "https://wesnapp-node-server.appspot.com/api/payment/get-payment-url"
  //     let data = {
  //       contactId: contactId,
  //       accountId: accountId,
  //       productId: selectedProduct.Id,
  //       requestId: requestId,
  //       orderId: orderId,
  //       productTotalAmount: (selectedProduct.Total_Cost__c * 1.17).toFixed(2), // Before coupon discount
  //       totalAmountToPay: getTotalCost(),
  //       couponCode: couponCode,
  //       couponType: couponType,
  //       couponAmount: couponAmount,
  //       paymentFor: paymentFor,
  //       paymentType: 1,
  //       maxPayments: "2"

  //     }
  //     console.log('data', data)
  //     const response = await axios.post(link, data, {headers: {'Content-Type': 'application/json'}})
  //     console.log('response', response)
  //     setPaymentURL(response.data)
  //     return response.data
  // }

  const getPaymentURL = async (order) => {
    console.log('get payment url');
    var maxpay = '2';
    var sum = getTotalCost();
    var cred_type = null;
    var paymentFor = null;
    if (sum >= 1000 && sum < 2000) {
      maxpay = '2';
    } else if (sum >= 2000 && sum < 3000) {
      maxpay = '3';
    } else if (sum >= 3000 && sum < 4000) {
      maxpay = '4';
    }
    if (sum >= 5000) {
      maxpay = '5';
    }
    if (selectedProduct === 'a016N000000sz5zQAA') {
      paymentFor = 5;
    } else {
      paymentFor = 1;
    }
    var link =
      'https://wesnapp-node-server.appspot.com/api/payment/get-payment-url';
    let data = {
      contactId: contactId,
      accountId: accountId,
      productId: selectedProduct,
      requestId: requestId,
      orderId: order,
      productTotalAmount: getTotalCost(), // Before coupon discount
      totalAmountToPay: getTotalCost(),
      couponCode: couponCode,
      couponType: couponType,
      couponAmount: couponAmount,
      paymentFor: paymentFor,
      paymentType: 1,
      maxPayments: '2',
    };
    // console.log('data', data)
    const response = await axios.post(link, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    // console.log('response', response)
    setPaymentURL(response.data);
    return response.data;
  };

  // a0c6N000000soSDQAY
  const getTotalCost = () => {
    let toPayFor = getProducCost();
    return (toPayFor * 1.17).toFixed(2);
    // let toPayFor = selectedProduct.Total_Cost__c
    // let total = 0
    // if ((selectedProduct.Total_Cost__c === 350 && couponIsValid)) {
    //   if (couponType === 'cash') {
    //     total = toPayFor - couponAmount
    //   } else {
    //     total = (toPayFor * ((100 - couponAmount) / 100))
    //   }
    // } else {
    //   total = toPayFor
    // }
    // return (total * 1.17).toFixed(2)
  };

  const getProductName = () => {
    if (selectedProduct === 'a016N000000sz5zQAA') {
      return 'צילום מקצועי 12 תמונות 1 + 1';
    } else if (selectedProduct === 'a016N000000szX2QAI') {
      return 'צילום מקצועי 12 תמונות';
    } else if (selectedProduct === 'a011t00000FMZOBAA5') {
      return '3D Matterport';
    }
  };

  const getProducCost = () => {
    if (selectedProduct === 'a016N000000sz5zQAA') {
      return 269;
    } else if (selectedProduct === 'a016N000000szX2QAI') {
      return 269;
    } else if (selectedProduct === 'a011t00000FMZOBAA5') {
      return 600;
    }
  };

  const startMatchingAlgorithm = (requestId) => {
    var data = { requestId: requestId };
    axios
      .post(
        'https://us-central1-wesnapp-d136a.cloudfunctions.net/startMatching',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {})
      .catch((error) => {});
  };

  const nextStep = () => {
    if (step === 0) {
      ReactPixel.track('bmby_start_order', {});
    }
    if (step === 1.5) {
      updateUserWithName();
      return;
    }
    if (step === 2 && requestId === null) {
      createRequest();
    } else if (step === 2 && requestId === null) {
      updateRequest();
    } else if (step === 5) {
      updateUserEmail();
    } else {
      if (requestId !== null && step === 4) {
        createOrder();
      }
      if (requestId !== null) {
        updateRequest();
      }
      // setStep(step + 1)
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChangeEmail = (e) => {
    let userInput = e.target.value;
    setEmail(userInput);
  };

  const handleChangePhone = (value) => {
    let userInput = value
      .replace(/-/g, '')
      .replace('(', '')
      .replace(')', '')
      .replace(/ /g, '');
    setPhone(userInput);
  };

  const handleChangeFirstName = (e) => {
    let userInput = e.target.value;
    setFirstName(userInput);
  };

  const handleChangeLastName = (e) => {
    let userInput = e.target.value;
    setLastName(userInput);
  };

  const generateRandomCode = () => {
    var code = Math.floor(1000 + Math.random() * 9000);
    return code;
  };

  const sendVerificationCode = () => {
    var data = {
      phone: '+972' + phone,
      text: 'WeSnapp verification code: ' + code,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/sms/send-message',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response, code);
      })
      .catch((error) => {});
  };

  function openBeforeAndAfter() {
    var win = window.open(
      'https://wesnapp.io/wesnapp-vs-smartphone/',
      '_blank'
    );
    win.focus();
  }

  const checkIfUserExistAndPassValidation = async () => {
    setLoading(true);
    let response = await axios.post(
      'https://wesnapp-node-server.appspot.com' +
        '/api/account/find-person-account-by-mobile',
      { mobile: phone },
      { headers: { 'Content-Type': 'application/json' } }
    );
    var accounts = response.data;
    console.log('accounts', accounts);
    setLoading(false);
    if (accounts.length !== 0) {
      if (accounts[0].User_Validated__c) {
        return 'user validated';
      } else {
        return 'user not validated';
      }
    } else {
      return 'user not found';
    }
  };

  const validatePhone = async () => {
    console.log('remarketing', remarketing);
    if (checkIfPhoneIsValid(phone)) {
      if (remarketing) {
        let sendCode = await checkIfUserExistAndPassValidation(phone);
        findClientUsingPhoneNumberOrAgentId(
          phone,
          'xxxxxxxx',
          null,
          null,
          null,
          null
        );
        console.log('sendCode', sendCode);
        if (sendCode === 'user validated') {
          setStep(step + 1);
        } else if (sendCode == 'user not validated') {
          setCode(generateRandomCode());
        } else {
          setCode(generateRandomCode());
          setAskForName(true);
          console.log('setAskForName set to true');
        }
      } else {
        setCode(generateRandomCode());
      }
    } else {
      alert('phone is not valid');
    }
  };

  const nextButtonDisabled = () => {
    if (step === 1.5) {
      if (firstName === '' || lastName === '') {
        return true;
      }
    }
    return (
      (step === 2 && !location) ||
      (step === 3 && !datetime) ||
      (step === 4 && !selectedProduct) ||
      (step === 5 && !validateEmail(email))
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <SmsDialog
          setUserCode={setUserCode}
          errorCode={errorCode}
          open={smsDialogStatus}
          setClose={setSmsDialogStatus}
          verifyCode={verifyCode}
        />
        <div className={classes.root}>
          {step == 0 && (
            <div
              style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                paddingBottom: 90,
                top: 0,
                left: 0,
                backgroundColor: 'white',
                overflow: 'scroll',
              }}
            >
              {!mobile && (
                <img
                  src={require('../../../Assets/photographerImage.jpg')}
                  style={{
                    height: '100%',
                    width: '50%',
                    right: 0,
                    position: 'fixed',
                    objectFit: 'cover',
                  }}
                  alt={''}
                />
              )}
              {!mobile && (
                <div
                  style={{
                    height: '100%',
                    width: '50%',
                    position: 'fixed',
                    objectFit: 'cover',
                    backgroundColor: 'rgba(104, 82, 237, 0.0)',
                    backgroundColor: 'rgba(50, 24, 127,0.0)',
                    Left: 0,
                    top: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column',
                    padding: mobile ? 16 : 64,
                  }}
                  alt={''}
                >
                  <img
                    src={require('../../../Assets/1plus1Sticker.png')}
                    style={{
                      height: 'auto',
                      width: '20vw',
                      right: 24,
                      top: 24,
                      position: 'fixed',
                      objectFit: 'cover',
                    }}
                    alt={''}
                  />
                  <img
                    src={require('../../../Assets/backgroundEffectBmby.png')}
                    style={{
                      height: 'calc(100vh - 22vw)',
                      width: 'auto',
                      right: 0,
                      bottom: 0,
                      position: 'fixed',
                      objectFit: 'cover',
                      zIndex: 99,
                    }}
                    alt={''}
                  />
                  <img
                    src={require('../../../Assets/bmbyText.png')}
                    style={{
                      height: 'auto',
                      width: '45%',
                      right: '2.5%',
                      top: '25vw',
                      position: 'fixed',
                      objectFit: 'cover',
                      zIndex: 999,
                    }}
                    alt={''}
                  />
                  <div
                    style={{
                      height: 80,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '50%',
                      backgroundColor: 'white',
                      right: 0,
                      bottom: 0,
                      position: 'fixed',
                      objectFit: 'cover',
                      zIndex: 9,
                    }}
                    alt={''}
                  >
                    <div style={{ marginRight: 140 }}>
                      <div>* הזמן צילום אחד והצילום הבא ללא עלות</div>
                      <div>* ההטבה לזמן מוגבל</div>
                      <div>* המחיר לא כולל מע״מ</div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={
                  mobile
                    ? {
                        objectFit: 'cover',
                        paddingBottom: 90,
                        backgroundColor: 'rgba(255,255,255,1)',
                        left: 0,
                        right: 0,
                        top: 0,
                        display: 'block',
                        display: 'flex',
                        justifyContent: 'center',
                        flexFlow: 'column',
                        padding: mobile ? 12 : 64,
                        overflow: 'scroll',
                      }
                    : {
                        height: '100%',
                        width: mobile ? '100%' : '50%',
                        position: 'fixed',
                        objectFit: 'cover',
                        backgroundColor: 'rgba(255,255,255,1)',
                        left: 0,
                        right: 0,
                        top: 0,
                        display: 'block',
                        display: 'flex',
                        justifyContent: 'center',
                        flexFlow: 'column',
                        padding: mobile ? 16 : 64,
                        overflow: 'scroll',
                      }
                }
                alt={''}
              >
                {mobile && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 24,
                    }}
                  >
                    <div style={{ height: 24 }}></div>
                    <img
                      src={require('../../../Assets/logo.png')}
                      style={{ height: 30 }}
                      alt={''}
                      style={{
                        display: 'flex',
                        zindex: 999999,
                        height: 30,
                        marginTop: 12,
                      }}
                    />
                  </div>
                )}
                {mobile && (
                  <div
                    style={{
                      height: '300px',
                      width: '100%',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      objectFit: 'cover',
                      backgroundColor: 'rgba(104, 82, 237, 0.0)',
                      backgroundColor: 'rgba(50, 24, 127,0.0)',
                      Left: 0,
                      top: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexFlow: 'column',
                      padding: mobile ? 16 : 64,
                    }}
                    alt={''}
                  >
                    <img
                      src={require('../../../Assets/photographerImage.jpg')}
                      style={{
                        height: '100%',
                        width: '100%',
                        right: 0,
                        position: 'absolute',
                        objectFit: 'cover',
                      }}
                      alt={''}
                    />
                    <img
                      src={require('../../../Assets/1plus1Sticker.png')}
                      style={{
                        height: 'auto',
                        zIndex: 9999999,
                        width: '30%',
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        objectFit: 'cover',
                      }}
                      alt={''}
                    />
                    <img
                      src={require('../../../Assets/backgroundEffectBmby.png')}
                      style={{
                        height: 'auto',
                        maxHeight: '100%',
                        width: '60%',
                        bottom: 0,
                        position: 'absolute',
                        right: 0,
                        objectFit: 'cover',
                        zIndex: 99,
                      }}
                      alt={''}
                    />
                    <img
                      src={require('../../../Assets/bmbyText.png')}
                      style={{
                        height: 'auto',
                        width: '90%',
                        marginTop: 42,
                        objectFit: 'cover',
                        zIndex: 999,
                      }}
                      alt={''}
                    />
                  </div>
                )}
                {!mobile && (
                  <div
                    style={{
                      fontSize: 35,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                      marginTop: 32,
                      marginBottom: 32,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={require('../../../Assets/logo.png')}
                      style={{ height: 54 }}
                      alt={''}
                    />
                  </div>
                )}
                <div
                  style={{
                    fontSize: 18,
                    marginTop: 12,
                    color: '#3F3F3F',
                    opacity: 1,
                  }}
                >
                  {text.welcomeText1}
                </div>
                <div
                  style={{
                    fontSize: 18,
                    marginTop: 8,
                    color: '#3F3F3F',
                    opacity: 1,
                  }}
                >
                  {text.welcomeText2}
                </div>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: mobile ? 32 : 64,
                    color: '#3F3F3F',
                    opacity: 1,
                    display: 'flex',
                  }}
                >
                  <div style={{ marginLeft: 12 }}>
                    <VerifiedUserIcon
                      size={24}
                      style={{ color: '#61CE70', width: 24, height: 24 }}
                    />
                  </div>
                  <div
                    onClick={openBeforeAndAfter}
                    style={{
                      textDecoration: 'underline',
                      textDecorationColor: '#6433FF',
                      cursor: 'pointer',
                    }}
                  >
                    להשוואה בין צילום מקצועי שלנו לצילום חובבני לחץ כאן
                  </div>
                </div>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: 16,
                    color: '#3F3F3F',
                    opacity: 1,
                    display: 'flex',
                  }}
                >
                  <div style={{ marginLeft: 12 }}>
                    <VerifiedUserIcon
                      size={26}
                      style={{ color: '#61CE70', width: 24, height: 24 }}
                    />
                  </div>
                  <div>{text.point1}</div>
                </div>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: 16,
                    color: '#3F3F3F',
                    opacity: 1,
                    display: 'flex',
                  }}
                >
                  <div style={{ marginLeft: 12 }}>
                    <VerifiedUserIcon
                      size={24}
                      style={{ color: '#61CE70', width: 24, height: 24 }}
                    />
                  </div>
                  <div>{text.point2}</div>
                </div>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: 16,
                    color: '#3F3F3F',
                    opacity: 1,
                    display: 'flex',
                  }}
                >
                  <div style={{ marginLeft: 12 }}>
                    <VerifiedUserIcon
                      size={24}
                      style={{ color: '#61CE70', width: 24, height: 24 }}
                    />
                  </div>
                  <div>{text.point3}</div>
                </div>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: 16,
                    color: '#3F3F3F',
                    opacity: 1,
                    display: 'flex',
                  }}
                >
                  <div style={{ marginLeft: 12 }}>
                    <VerifiedUserIcon
                      size={24}
                      style={{ color: '#61CE70', width: 24, height: 24 }}
                    />
                  </div>
                  <div>{text.point4}</div>
                </div>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: 16,
                    marginBottom: mobile ? 0 : 64,
                    color: '#3F3F3F',
                    opacity: 1,
                    display: 'flex',
                  }}
                >
                  <div style={{ marginLeft: 12 }}>
                    <VerifiedUserIcon
                      size={24}
                      style={{ color: '#61CE70', width: 24, height: 24 }}
                    />
                  </div>
                  <div>{text.point5}</div>
                </div>
                {mobile && (
                  <div style={{ marginRight: 0, marginTop: 14 }}>
                    <div>* הזמן צילום אחד והצילום הבא ללא עלות</div>
                    <div>* ההטבה לזמן מוגבל</div>
                    <div>* המחיר לא כולל מע״מ</div>
                  </div>
                )}
                {mobile ? (
                  <div
                    style={{
                      height: 100,
                      paddingLeft: 16,
                      paddingRight: 16,
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      backgroundColor: 'white',
                      right: 0,
                      bottom: 0,
                      position: 'fixed',
                      objectFit: 'cover',
                      zIndex: 9,
                    }}
                    alt={''}
                  >
                    <div
                      style={{
                        marginTop: 20,
                        display: 'flex',
                        flexDirection: 'row-reverse',
                      }}
                    >
                      <Button
                        id={'bmby_start_order'}
                        onClick={() => setStep(skipStep1 ? 2 : 1)}
                        style={{
                          paddingLeft: 60,
                          paddingRight: 60,
                          height: 60,
                          width: mobile ? '100%' : 'auto',
                          fontSize: 20,
                          fontWeight: 'bold',
                          borderRadius: 12,
                          color: 'white',
                          background:
                            'linear-gradient(256.79deg, #6852ED 40.49%, #BA43FC 100%)',
                          boxShadow: 'rgba(114, 82, 240, 0.6) 0px 8px 20px 0px',
                        }}
                      >
                        {text.startButton}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: 0,
                      display: 'flex',
                      flexDirection: 'row-reverse',
                    }}
                  >
                    <Button
                      id={'bmby_start_order'}
                      onClick={() => setStep(skipStep1 ? 2 : 1)}
                      style={{
                        paddingLeft: 60,
                        paddingRight: 60,
                        height: 60,
                        width: mobile ? '100%' : 'auto',
                        fontSize: 20,
                        fontWeight: 'bold',
                        borderRadius: 12,
                        color: 'white',
                        background:
                          'linear-gradient(256.79deg, #6852ED 40.49%, #BA43FC 100%)',
                        boxShadow: 'rgba(114, 82, 240, 0.6) 0px 8px 20px 0px',
                      }}
                    >
                      {text.startButton}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
          {step === 1 && (
            <Grid container spacing={0} dir='rtl' justify='center'>
              <Grid item xs={12} sm={10} md={8} xl={7} lg={7}>
                <CustomizedProgressBars step={step} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexFlow: 'column',
                    textAlign: 'right',
                    width: '100%',
                    padding: 16,
                    marginTop: mobile ? 24 : '7vh',
                  }}
                >
                  <div
                    style={{
                      fontSize: mobile ? 24 : 45,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                    }}
                  >
                    <span style={{ opacity: 1 }}>הזן מספר טלפון נייד</span>
                  </div>
                  <div style={{ marginTop: 58, width: '100%', maxWidth: 400 }}>
                    {/* <TextField fullWidth label='טלפון נייד' value={phone} onChange={handleChangePhone} /> */}
                    <MuiPhoneNumber
                      style={{ width: '100%', direction: 'ltr' }}
                      name='phone'
                      disableAreaCodes={true}
                      label='טלפון נייד'
                      data-cy='user-phone'
                      onlyCountries={['il', 'us', 'fr', 'gb']}
                      defaultCountry={'il'}
                      value={phone}
                      onChange={handleChangePhone}
                    />
                  </div>
                  {/* <div style={{marginTop: 12, width: '100%', maxWidth: 400}}>
                                        <TextField fullWidth label='שם פרטי' value={firstName} onChange={handleChangePhone} />
                                    </div>
                                    <div style={{marginTop: 12, width: '100%', maxWidth: 400}}>
                                        <TextField fullWidth label='שם משפחה' value={lastName} onChange={handleChangePhone} />
                                    </div> */}
                </div>
              </Grid>
            </Grid>
          )}
          {step === 1.5 && (
            <Grid container spacing={0} dir='rtl' justify='center'>
              <Grid item xs={12} sm={10} md={8} xl={7} lg={7}>
                <CustomizedProgressBars step={step} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexFlow: 'column',
                    textAlign: 'right',
                    width: '100%',
                    padding: 16,
                    marginTop: mobile ? 24 : '7vh',
                  }}
                >
                  <div
                    style={{
                      fontSize: mobile ? 24 : 45,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                    }}
                  >
                    <span style={{ opacity: 1 }}>הזן שם פרטי ומשפחה</span>
                  </div>
                  <div style={{ marginTop: 12, width: '100%', maxWidth: 400 }}>
                    <TextField
                      fullWidth
                      label='שם פרטי'
                      value={firstName}
                      onChange={handleChangeFirstName}
                    />
                  </div>
                  <div style={{ marginTop: 12, width: '100%', maxWidth: 400 }}>
                    <TextField
                      fullWidth
                      label='שם משפחה'
                      value={lastName}
                      onChange={handleChangeLastName}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          {step === 2 && (
            <Grid container spacing={0} dir='rtl' justify='center'>
              <Grid item xs={12} sm={10} md={8} xl={7} lg={7}>
                <CustomizedProgressBars step={step} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexFlow: 'column',
                    textAlign: 'right',
                    width: '100%',
                    padding: 16,
                    marginTop: mobile ? 24 : '7vh',
                  }}
                >
                  <div
                    style={{
                      fontSize: mobile ? 24 : 45,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                    }}
                  >
                    <span style={{ opacity: 1 }}>{text.getAddressTitle}</span>
                  </div>
                  <div style={{ marginTop: 58, width: '100%', maxWidth: 400 }}>
                    <Location
                      style={{ width: '100%', maxWidth: 400 }}
                      setErrorLocation={setErrorLocation}
                      setLocation={setLocation}
                      errorLocation={errorLocation}
                      location={location}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          {step === 3 && (
            <Grid container spacing={0} dir='rtl' justify='center'>
              <Grid item xs={12} sm={10} md={8} xl={7} lg={7}>
                <CustomizedProgressBars step={step} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexFlow: 'column',
                    textAlign: 'right',
                    width: '100%',
                    padding: 16,
                    marginTop: mobile ? 24 : '7vh',
                  }}
                >
                  <div
                    style={{
                      fontSize: mobile ? 24 : 45,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                    }}
                  >
                    <span style={{ opacity: 1 }}>
                      {text.getDateAndTimeTitle}
                    </span>
                  </div>
                  <div style={{ marginTop: 58, width: '100%', maxWidth: 400 }}>
                    <div
                      style={{
                        fontSize: 12,
                        color: errorDateTime ? '#f44336' : 'gray',
                        width: '100%',
                        textAlign: 'right',
                        fontFamily: 'Arial',
                      }}
                    >
                      אנא בחר יום ושעה
                    </div>
                    <DatePicker
                      renderExtraFooter={() => (
                        <div style={{ height: 0 }}>
                          <div
                            style={{
                              backgroundColor: 'white',
                              width: '50%',
                              position: 'relative',
                              top: 10,
                              height: 50,
                            }}
                          ></div>
                        </div>
                      )}
                      minuteStep={15}
                      format={'DD/MM/YYYY HH:mm'}
                      hideDisabledOptions={true}
                      placeholder=''
                      value={datetime}
                      onChange={onchangeDateTime}
                      clearIcon={<div></div>}
                      disabledDate={disabledDate}
                      showToday={false}
                      showNow={false}
                      id={'bmby_datetime'}
                      disabledHours={() => [0, 1, 2, 3, 4, 5]}
                      style={{
                        width: '100%',
                        maxWidth: 400,
                        height: 32,
                        background: 'transparent',
                        'antd-input:hover:focus:select': {
                          borderBottom: '2px solid black',
                          outline: 'none',
                        },
                        outline: 'none',
                        border: 'none',
                        borderBottom: errorDateTime
                          ? '2px solid #f44336'
                          : '1px solid gray',
                        color: 'black',
                        padding: '4px 5px 4px 0px',
                      }}
                      showTime
                    />
                    {errorDateTime && (
                      <div
                        style={{
                          fontSize: 12,
                          marginTop: 3,
                          color: '#f44336',
                          width: '100%',
                          textAlign: 'left',
                          fontFamily: 'Arial',
                          marginTop: 4,
                        }}
                      >
                        Error date and time
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          {step === 4 && (
            <Grid container spacing={0} dir='rtl' justify='center'>
              <Grid item xs={12} sm={10} md={8} xl={7} lg={7}>
                <CustomizedProgressBars step={step} />
                <div
                  style={{
                    display: 'flex',
                    overflow: 'scroll',
                    justifyContent: 'center',
                    flexFlow: 'column',
                    textAlign: 'right',
                    width: '100%',
                    padding: 16,
                    marginTop: mobile ? 24 : '7vh',
                  }}
                >
                  <div
                    style={{
                      fontSize: mobile ? 24 : 45,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                    }}
                  >
                    <span style={{ opacity: 1 }}>{text.productTitle}</span>
                  </div>
                  <div
                    style={{
                      marginTop: mobile ? 24 : 58,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-around',
                      flexFlow: 'wrap',
                    }}
                  >
                    {checkIfUsed1plus1 === false && (
                      <div
                        id={'bmby_1+1_product_12_images'}
                        className={classes.productBox}
                        onClick={() => setSelectedProduct('a016N000000sz5zQAA')}
                        style={{
                          cursor: 'pointer',
                          backgroundColor:
                            selectedProduct &&
                            'a016N000000sz5zQAA' === selectedProduct
                              ? '#6852ED'
                              : 'white',
                        }}
                        key={'7'}
                      >
                        <div
                          style={{
                            fontSize: mobile ? 18 : 42,
                            fontWeight: 'semi-bold',
                            position: 'absolute',
                            top: 10,
                            color:
                              selectedProduct &&
                              'a016N000000sz5zQAA' === selectedProduct
                                ? 'white'
                                : '#32187F',
                          }}
                        >
                          1 + 1
                        </div>
                        <div
                          style={{
                            fontSize: mobile ? 18 : 24,
                            marginTop: mobile ? 12 : 0,
                            fontWeight: 'semi-bold',
                            color:
                              selectedProduct &&
                              'a016N000000sz5zQAA' === selectedProduct
                                ? 'white'
                                : '#32187F',
                          }}
                        >
                          צילום מקצועי
                        </div>
                        <div
                          style={{
                            fontSize: mobile ? 18 : 20,
                            fontWeight: 'semi-bold',
                            color:
                              selectedProduct &&
                              'a016N000000sz5zQAA' === selectedProduct
                                ? 'white'
                                : '#32187F',
                          }}
                        >
                          {12} {text.images}
                        </div>
                        <div
                          style={{
                            fontSize: mobile ? 18 : 24,
                            marginTop: mobile ? 18 : 28,
                            color:
                              selectedProduct &&
                              'a016N000000sz5zQAA' === selectedProduct
                                ? 'white'
                                : '#6852ED',
                          }}
                        >
                          <div>
                            <span style={{ textDecoration: 'line-through' }}>
                              {538}₪
                            </span>{' '}
                            <span>269₪</span>
                          </div>
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            bottom: 12,
                            fontSize: 13,
                            color:
                              selectedProduct &&
                              'a016N000000sz5zQAA' === selectedProduct
                                ? 'white'
                                : '#7A7A7A',
                            opacity:
                              selectedProduct &&
                              'a016N000000sz5zQAA' === selectedProduct
                                ? 1
                                : 0.5,
                          }}
                        >
                          כ{45} דק׳ צילום
                        </div>
                      </div>
                    )}
                    {anotherBasicForFree ? (
                      <div
                        id={'bmby_free_product_12_images'}
                        className={classes.productBox}
                        onClick={() => setSelectedProduct('freebasic')}
                        style={{
                          cursor: 'pointer',
                          backgroundColor:
                            selectedProduct && 'freebasic' === selectedProduct
                              ? '#6852ED'
                              : 'white',
                        }}
                        key={'3'}
                      >
                        <div
                          style={{
                            fontSize: mobile ? 18 : 24,
                            fontWeight: 'semi-bold',
                            color:
                              selectedProduct && 'freebasic' === selectedProduct
                                ? 'white'
                                : '#32187F',
                          }}
                        >
                          צילום מקצועי
                        </div>
                        <div
                          style={{
                            fontSize: mobile ? 18 : 20,
                            fontWeight: 'semi-bold',
                            color:
                              selectedProduct && 'freebasic' === selectedProduct
                                ? 'white'
                                : '#32187F',
                          }}
                        >
                          {12} {text.images}
                        </div>
                        <div
                          style={{
                            fontSize: mobile ? 18 : 24,
                            marginTop: mobile ? 18 : 28,
                            color:
                              selectedProduct && 'freebasic' === selectedProduct
                                ? 'white'
                                : '#6852ED',
                          }}
                        >
                          <div>
                            <span>0₪ - שולם</span>
                          </div>
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            bottom: 12,
                            fontSize: 13,
                            color:
                              selectedProduct && 'freebasic' === selectedProduct
                                ? 'white'
                                : '#7A7A7A',
                            opacity:
                              selectedProduct && 'freebasic' === selectedProduct
                                ? 1
                                : 0.5,
                          }}
                        >
                          כ{45} דק׳ צילום
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      id={'bmby_basic_product_12_images'}
                      className={classes.productBox}
                      onClick={() => setSelectedProduct('a016N000000szX2QAI')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectedProduct &&
                          'a016N000000szX2QAI' === selectedProduct
                            ? '#6852ED'
                            : 'white',
                      }}
                      key={'0'}
                    >
                      <div
                        style={{
                          fontSize: mobile ? 18 : 24,
                          fontWeight: 'semi-bold',
                          color:
                            selectedProduct &&
                            'a016N000000szX2QAI' === selectedProduct
                              ? 'white'
                              : '#32187F',
                        }}
                      >
                        צילום מקצועי
                      </div>
                      <div
                        style={{
                          fontSize: mobile ? 18 : 20,
                          fontWeight: 'semi-bold',
                          color:
                            selectedProduct &&
                            'a016N000000szX2QAI' === selectedProduct
                              ? 'white'
                              : '#32187F',
                        }}
                      >
                        {12} {text.images}
                      </div>
                      <div
                        style={{
                          fontSize: mobile ? 18 : 24,
                          marginTop: mobile ? 18 : 28,
                          color:
                            selectedProduct &&
                            'a016N000000szX2QAI' === selectedProduct
                              ? 'white'
                              : '#6852ED',
                        }}
                      >
                        <div>
                          <span>269₪</span>
                        </div>
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 12,
                          fontSize: 13,
                          color:
                            selectedProduct &&
                            'a016N000000szX2QAI' === selectedProduct
                              ? 'white'
                              : '#7A7A7A',
                          opacity:
                            selectedProduct &&
                            'a016N000000szX2QAI' === selectedProduct
                              ? 1
                              : 0.5,
                        }}
                      >
                        כ{45} דק׳ צילום
                      </div>
                    </div>
                    <div
                      id={'bmby_product_matterport'}
                      className={classes.productBox}
                      onClick={() => setSelectedProduct('a011t00000FMZOBAA5')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectedProduct &&
                          'a011t00000FMZOBAA5' === selectedProduct
                            ? '#6852ED'
                            : 'white',
                      }}
                      key={'1'}
                    >
                      <div
                        style={{
                          fontSize: mobile ? 18 : 24,
                          fontWeight: 'semi-bold',
                          color:
                            selectedProduct &&
                            'a011t00000FMZOBAA5' === selectedProduct
                              ? 'white'
                              : '#32187F',
                        }}
                      >
                        3D Matterport
                      </div>
                      <div
                        style={{
                          fontSize: mobile ? 18 : 20,
                          fontWeight: 'semi-bold',
                          color:
                            selectedProduct &&
                            'a011t00000FMZOBAA5' === selectedProduct
                              ? 'white'
                              : '#32187F',
                        }}
                      >
                        מחיר ל 150 מטר
                      </div>
                      <div
                        style={{
                          fontSize: mobile ? 18 : 24,
                          marginTop: mobile ? 18 : 28,
                          color:
                            selectedProduct &&
                            'a011t00000FMZOBAA5' === selectedProduct
                              ? 'white'
                              : '#6852ED',
                        }}
                      >
                        <div>
                          <span>מ 600₪</span>
                        </div>
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 12,
                          fontSize: 13,
                          color:
                            selectedProduct &&
                            'a011t00000FMZOBAA5' === selectedProduct
                              ? 'white'
                              : '#7A7A7A',
                          opacity:
                            selectedProduct &&
                            'a011t00000FMZOBAA5' === selectedProduct
                              ? 1
                              : 0.5,
                        }}
                      >
                        כ{90} דק׳ צילום
                      </div>
                    </div>
                    {/* {products.map((item, index) => (
                                            <div id={'bmby_product_' + item.Id} className={classes.productBox} onClick={()=> setSelectedProduct(item)} style={{cursor: 'pointer', backgroundColor: selectedProduct && (item.Id === selectedProduct.Id) ? '#6852ED' : 'white'}} key={index}>
                                                <div style={{fontSize: mobile ? 32 : 42, fontWeight: 'semi-bold', color: selectedProduct && (item.Id === selectedProduct.Id) ? 'white' : '#32187F'}}>
                                                    {item.Number_of_Images_for_Customer__c} {text.images}
                                                </div>
                                                <div style={{fontSize: mobile ? 20 : 24, color: selectedProduct && (item.Id === selectedProduct.Id) ? 'white' : '#6852ED'}}>
                                                    {(item.Total_Cost__c === 350 && couponIsValid) ? (
                                                        <div>
                                                            <span style={{textDecoration: 'line-through'}}>{item.Total_Cost__c}₪</span> <span>99₪</span>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <span>{item.Total_Cost__c}₪</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div style={{position: 'absolute', bottom: 12, fontSize: 13, color: selectedProduct && (item.Id === selectedProduct.Id) ? 'white' : '#7A7A7A', opacity: selectedProduct && (item.Id === selectedProduct.Id) ? 1 : 0.5}}>
                                                    כ{item.Shooting_Duration__c} דק׳ צילום
                                                </div>
                                            </div>
                                        ))} */}
                  </div>
                  <div
                    style={{
                      marginTop: 12,
                      marginBottom: 12,
                      width: '100%',
                      textAlign: 'right',
                      fontSize: 16,
                      paddingRight: 16,
                    }}
                  >
                    * המחירי הינם לפני מע״מ
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          {step === 5 && (
            <Grid container spacing={0} dir='rtl' justify='center'>
              <Grid item xs={12} sm={10} md={8} xl={7} lg={7}>
                <CustomizedProgressBars step={step} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexFlow: 'column',
                    textAlign: 'right',
                    width: '100%',
                    padding: 16,
                    marginTop: mobile ? 24 : '7vh',
                  }}
                >
                  <div
                    style={{
                      fontSize: mobile ? 24 : 45,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                    }}
                  >
                    <span style={{ opacity: 1 }}>מהי כתובת המייל שלך?</span>
                  </div>
                  <div style={{ marginTop: 58, width: '100%', maxWidth: 400 }}>
                    <TextField
                      fullWidth
                      label='כתובת מייל'
                      value={email}
                      onChange={handleChangeEmail}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          {step === 6 && (
            <Grid container spacing={0} dir='rtl' justify='center'>
              <Grid item xs={12} sm={10} md={8} xl={7} lg={7}>
                <CustomizedProgressBars step={step} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexFlow: 'column',
                    textAlign: 'right',
                    width: '100%',
                    padding: 16,
                    marginTop: mobile ? 24 : '7vh',
                  }}
                >
                  <div
                    style={{
                      fontSize: mobile ? 20 : 28,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                    }}
                  >
                    <span style={{ opacity: 1 }}>{text.summary}</span>
                  </div>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                      marginTop: 12,
                    }}
                  >
                    <span style={{ opacity: 1 }}>
                      {text.where}: {location.description}
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                      marginTop: 12,
                    }}
                  >
                    <span style={{ opacity: 1 }}>
                      {text.when}: {moment(datetime._d).format('LLL')}
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: 'semi-bold',
                      color: '#3F3F3F',
                      marginTop: 12,
                    }}
                  >
                    {/* <span style={{opacity: 1}}>{text.numberOfImages}: {selectedProduct.Number_of_Images_for_Customer__c}</span> */}
                    <span style={{ opacity: 1 }}>
                      {'המוצר שנבחר'}: {getProductName()}
                    </span>
                  </div>
                  {selectedProduct !== 'freebasic' ? (
                    <>
                      <div
                        style={{
                          fontSize: 24,
                          fontWeight: 'semi-bold',
                          color: '#3F3F3F',
                          marginTop: 12,
                        }}
                      >
                        <span style={{ opacity: 1 }}>
                          {text.shootingCost}:{' '}
                          {(getTotalCost() / 1.17).toFixed(2)}₪ + מע״מ
                        </span>
                      </div>
                      {paymentURL ? (
                        <div style={{ width: '100%' }}>
                          <iframe
                            style={{
                              width: '100%',
                              height: 460,
                              marginTop: 24,
                              frameBorder: 0,
                              borderRadius: 0,
                              border: 0,
                            }}
                            src={paymentURL}
                          ></iframe>
                          <div
                            style={{
                              marginTop: 12,
                              marginBottom: 12,
                              width: '100%',
                              textAlign: 'center',
                              fontSize: 16,
                            }}
                          >
                            {text.paymentWaitingMessage}
                          </div>
                        </div>
                      ) : (
                        <center>
                          <CircularProgress
                            size={50}
                            style={{ marginTop: 32 }}
                          />
                        </center>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        fontSize: 24,
                        fontWeight: 'semi-bold',
                        color: '#3F3F3F',
                        marginTop: 12,
                      }}
                    >
                      <span style={{ opacity: 1 }}>
                        {text.shootingCost}: חינם - מימוש הטבה 1+1
                      </span>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
          {(step === 1 ||
            step === 1.5 ||
            step === 2 ||
            step === 3 ||
            step === 4 ||
            step === 5) && (
            <div
              style={{
                width: '100%',
                position: 'fixed',
                height: mobile ? 66 : 100,
                bottom: 0,
                background: '#F9F8FD',
              }}
            >
              <Grid container spacing={0} dir='rtl' justify='center'>
                <Grid item xs={12} sm={10} md={8} xl={7} lg={7}>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <Button
                      id={'bmby_back'}
                      onClick={() => setStep(step - 1)}
                      style={{
                        paddingLeft: mobile ? 30 : 60,
                        marginRight: 16,
                        paddingRight: mobile ? 30 : 60,
                        height: mobile ? 50 : 60,
                        fontSize: 20,
                        fontWeight: 'bold',
                        borderRadius: 12,
                        color: 'white',
                        background: 'rgba(104,82,237,0.5)',
                      }}
                    >
                      {text.previous}
                    </Button>
                    {loading ? (
                      <CircularProgress
                        style={{
                          marginLeft: 16,
                          height: mobile ? 50 : 60,
                          width: mobile ? 50 : 60,
                          color: '#6852ED',
                        }}
                      />
                    ) : (
                      <Button
                        id={'bmby_next_' + String(step)}
                        disabled={nextButtonDisabled()}
                        onClick={step === 1 ? validatePhone : nextStep}
                        style={{
                          paddingLeft: mobile ? 30 : 60,
                          marginLeft: 16,
                          paddingRight: mobile ? 30 : 60,
                          height: mobile ? 50 : 60,
                          fontSize: 20,
                          fontWeight: 'bold',
                          borderRadius: 12,
                          color: 'white',
                          background:
                            'linear-gradient(256.79deg, #6852ED 40.49%, #BA43FC 100%)',
                          boxShadow: 'rgba(114, 82, 240, 0.6) 0px 8px 20px 0px',
                          opacity: nextButtonDisabled() ? 0.5 : 1,
                        }}
                      >
                        {text.next}
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </StylesProvider>
    </MuiThemeProvider>
  );
};

export default BmbyOrderPage;
