import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import SideMenu from './SideMenu';
import MobileSideMenu from './MobileSideMenu';
import View from './View';
import AppBar from './AppBar';
import NewAppBar from './NewAppBar';
import axios from 'axios';
import { Container } from '@material-ui/core';
import StyleContextProvider from '../../../contexts/Style.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

var mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    // overflow: 'hidden',
    // background: 'linear-gradient(90deg, #6C65FF 50%, #FFF 50%)',
    // background: "linear-gradient(to right , #6334FF, #6334FF)",
    // position: 'fixed',
    // backgroundColor: '#6334ff',
    // background: '#6334ff',
    // top: 0, left: 0
  },
});

class Template extends React.Component {
  state = {
    deviceType: 'desktop',
    page: '',
    sideMenuOpen: false,
    shootPage: false,
    avatar: '',
    userData: null,
    equipment: [],
    cameras: [],
    lenses: [],
    trippleD: [],
    drones: [],
    finishedToLoadUserData: false,
  };

  componentDidMount() {
    var page = this.getParameterByName('page');
    this.setState({ page: page });
    if (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    ) {
      this.setState({ deviceType: 'mobile' });
    } else {
      this.setState({ deviceType: 'desktop' });
    }

    this.getUserDetails();
  }

  getPhotographerEquipment = (account) => {
    var data = { accountId: localStorage.getItem('accountId') };
    var trippleD = [];
    var cameras = [];
    var lenses = [];
    var drones = [];
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/equipment/get-equipment-of-photographer',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data[0]);
        this.setState({ equipment: response.data });
        for (let index = 0; index < response.data.length; index++) {
          const element = response.data[index];
          if (element.Equipment_Type__c == 'Lens') {
            lenses.push({
              value: element.Equipment__c,
              label: element.Brand__c + ' ' + element.Product_Model__c,
            });
          } else if (element.Equipment_Type__c == 'Camera') {
            cameras.push({
              value: element.Equipment__c,
              label: element.Brand__c + ' ' + element.Product_Model__c,
            });
          } else if (element.Equipment_Type__c == 'Drone') {
            drones.push({
              value: element.Equipment__c,
              label: element.Brand__c + ' ' + element.Product_Model__c,
            });
          } else if (element.Equipment_Type__c == '3D') {
            trippleD.push({
              value: element.Equipment__c,
              label: element.Brand__c + ' ' + element.Product_Model__c,
            });
          }
        }
        this.setState({
          cameras,
          lenses,
          trippleD,
          drones,
          finishedToLoadUserData: true,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  getUserDetails = async () => {
    var data = { id: localStorage.getItem('accountId') };
    let response = await axios.post(
      'https://wesnapp-node-server.appspot.com' + '/api/account/get-one',
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
    console.log(response.data[0]);
    var firstName = response.data[0].FirstName;
    var lastName = response.data[0].LastName;
    this.setState(
      { avatar: firstName[0] + lastName[0], userData: response.data[0] },
      () => {
        this.getPhotographerEquipment();
      }
    );
  };

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  getPageStyle = () => {
    if (!mobile) {
      return {
        width: 'calc(100vw - 120px)',
        backgroundColor: 'white',
        display: 'inline-block',
        borderRadius: '0px 0px 0px 20px',
        verticalAlign: 'top',
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: '100%',
        left: 120,
        position: 'fixed',
        top: 94,
        paddingBottom: 70,
      };
      // return {width: 'calc(100vw - 0px)', minHeight: '100vh', backgroundColor: 'white', display: 'inline-block', borderRadius: '35px 0px 0px 35px', verticalAlign:'top', overflowY: 'scroll', height: '100vh', left: '0px', position: 'sticky', position: '-webkit-sticky', position: 'absolute', top: 0}
    } else {
      return {
        marginTop: 0,
        width: '100vw',
        height: '100vh',
        paddingBottom: 50,
        backgroundColor: 'white',
        display: 'inline-block',
        verticalAlign: 'top',
        overflowY: 'scroll',
        overflowX: 'hidden',
        paddingLeft: 0,
        paddingRight: 0,
        position: 'fixed',
        left: 0,
        top: 55,
        backgroundColor: 'linear-gradient(to right , #6334FF, #6334FF)',
      };
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <StyleContextProvider>
          <Container maxWidth={'lg'}>
            <AppBar />
            <View
              main={this}
              accountData={this.state.userData}
              getUserDetails={this.getUserDetails}
              updateShootPage={this.updateShootPage}
              history={history}
              isBusinessAccount={this.state.isBusinessAccount}
              businessAccountId={this.state.businessAccountId}
              updateShootPage={this.updateShootPage}
            />
          </Container>
        </StyleContextProvider>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);

// import React, {useEffect, useState, useContext} from 'react';
// import SideMenu from './SideMenu';
// import MobileSideMenu from './MobileSideMenu';
// import View from './View';
// import AppBar from './AppBar';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import {
//   BrowserRouter as Router,
//   Switch
// } from "react-router-dom";
// import axios from 'axios'
// import NewAppBar from './NewAppBar'
// import StyleContextProvider from '../../../contexts/Style.js'
// import { makeStyles } from '@material-ui/core/styles';
// import { AuthContext } from '../../../contexts/Auth';
// import history from '../../../utils/history'
// import { Container } from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({
//   list: {
//     width: 250,
//   },
//   fullList: {
//     width: 'auto',
//   },
//   root: {
//     // width: '100vw',
//     // height: '100vh',
//     // backgroundColor: '#6334FF',
//     // background: 'linear-gradient(90deg, #6334FF 50%, #FFF 50%)',
//     // position: 'fixed',
//     // top: 0, left: 0,
//   }
// }));

// const Template = () => {

//   const classes = useStyles()

//   const {setAccountData, accountData} = useContext(AuthContext)

//   const [deviceType, setDeviceType] = useState('desktop')
//   const [page, setPage] = useState('')
//   const [sideMenuOpen, setSideMenuOpen] = useState(false)
//   const [shootPage, setShootPage] = useState(false)
//   const [avatar, setAvatar] = useState('')
//   const [isBusinessAccount, setIsBusinessAccount] = useState(false)
//   const [businessAccountId, setBusinessAccountId] = useState(null)

//   useEffect(() => {
//     getUserDetails()
//     checkIfBusinessExists()
//   }, [])

//   const checkIfBusinessExists = () => {
//     var contactId = localStorage.getItem("contactId")
//     var data = {contactId: contactId}
//     axios.post('https://wesnapp-node-server.appspot.com' + '/api/account/get-business-account',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
//         console.log(response.data)
//         if (response.data.length > 0) {
//             setIsBusinessAccount(true)
//             setBusinessAccountId(response.data[0].AccountId)
//         } else {
//           setIsBusinessAccount(false)
//         }
//     })
//     .catch( (error) => {
//     });
//   }

//   const getUserDetails = () => {
//     var data = {id: localStorage.getItem("accountId")}
//     axios.post('https://wesnapp-node-server.appspot.com' + '/api/account/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
//         console.log(response.data[0])
//         var firstName = response.data[0].FirstName
//         var lastName = response.data[0].LastName
//         setAvatar(firstName[0] + lastName[0])
//         setAccountData(response.data[0])
//     })
//     .catch( (error) => {
//       //console.log(error);
//     });
//   }

//   const getParameterByName = (name, url) => {
//     if (!url) url = window.location.href;
//     name = name.replace(/[\[\]]/g, '\\$&');
//     var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//         results = regex.exec(url);
//     if (!results) return null;
//     if (!results[2]) return '';
//     return decodeURIComponent(results[2].replace(/\+/g, ' '));
//   }

//   const goToOrder = () => {
//     // history.push('/c/dashboard/order');
//     // window.location.reload()
//   }

//   const updateShootPage = (value) => {
//     setShootPage(value)
//   }

//   return (
//     <div className={classes.root}>
//       <StyleContextProvider>
//         <Container>
//             <AppBar />
//             <View accountData={accountData} getUserDetails={getUserDetails} updateShootPage={updateShootPage} history={history} isBusinessAccount={isBusinessAccount} businessAccountId={businessAccountId} updateShootPage={updateShootPage}/>
//         </Container>
//       </StyleContextProvider>
//     </div>
//   );
// }

// export default Template;
