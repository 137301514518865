import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MuiStyleFunction from './PhotoShoot.style';
import axios from 'axios';
import PhotoShootDetails from './PhotoShootDetails';
import Map from '../Map';
import Image from './Image';
import moment from 'moment';
import Skeletons from './PhotoShoot.skeleton.js';
import Lightbox from 'react-image-lightbox';
import { Snackbar, Tabs, Tab, AppBar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DownloadDialog from './DownloadDialog';
import { useParams } from 'react-router-dom';
import UploadedStations from './UploadedStations';
import UploadStations from './UploadStations';
import UploadedImages from './UploadedImages';
import UploadImages from './UploadImages';
import CommentsDialog from './CommentsDialog.js';
import Original from './Images/Original/Original';

const useStyles = makeStyles(MuiStyleFunction);
const GET_EDITED_ORGENIZED_IMAGES =
  'https://py.wesnapp.co.il/server/get-edited-images-orgenized/';
const GET_BRACKETS_ORGENIZED_IMAGES =
  'https://py.wesnapp.co.il/server/get-images-orgenized/';
const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const PhotoShoot = (props) => {
  const classes = useStyles();

  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [dateAndTime, setDateAndTime] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [productData, setProductData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [shootingLocation, setShootingLocation] = useState(null);
  const [photographerName, setPhotographerName] = useState(null);

  const [galleryIsOpen, setGalleryIsOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const [editedImages, setEditedImages] = useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    watermarked: [],
    loading: true,
    imagesForGallery: [],
  });

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [paymentWithProduct, setPaymentWithProduct] = useState(false);
  const [addingImagesToSalesforce, setAddingImagesToSalesforce] =
    useState(false);

  const [displayGallery, setDisplayGallery] = React.useState(false);

  const [snackBarStatus, setSnackBarStatus] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');

  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);

  const [commentDialogOpen, setCommentDialogOpen] = React.useState(false);

  const [uploadStation, setUploadStation] = React.useState('Raws');

  const [requestName, setRequestName] = React.useState('');

  const [uploadAndLastUpload, setUploadAndLastUpload] =
    React.useState('Upload');

  const [addingCommentInProgress, setAddingCommentInProgress] = useState(false);
  const [comment, setComment] = useState('');

  const [exposures4, setExposures4] = useState(false);
  const [exposures5, setExposures5] = useState(false);
  const [exposures6, setExposures6] = useState(false);
  const [exposures7, setExposures7] = useState(false);
  const [exposures8, setExposures8] = useState(false);
  const [exposures9, setExposures9] = useState(false);

  const [uploadedExposures, setUploadedExposures] = useState('');

  // DOWNLOAD
  const [downloadPercenteges, setDownloadPercenteges] = React.useState(0);
  const [prepearingImages, setPrepearingImages] = React.useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = React.useState(false);

  const [images1Exposures, setImages1Exposures] = React.useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    loading: true,
    imagesForGallery: [],
  });
  const [images3Exposures, setImages3Exposures] = React.useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    loading: true,
    imagesForGallery: [],
  });
  const [images4Exposures, setImages4Exposures] = React.useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    loading: true,
    imagesForGallery: [],
  });
  const [images5Exposures, setImages5Exposures] = React.useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    loading: true,
    imagesForGallery: [],
  });
  const [images6Exposures, setImages6Exposures] = React.useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    loading: true,
    imagesForGallery: [],
  });
  const [images7Exposures, setImages7Exposures] = React.useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    loading: true,
    imagesForGallery: [],
  });
  const [images8Exposures, setImages8Exposures] = React.useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    loading: true,
    imagesForGallery: [],
  });
  const [images9Exposures, setImages9Exposures] = React.useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    loading: true,
    imagesForGallery: [],
  });
  const [imagesRawExposures, setImagesRawExposures] = React.useState({
    thumbnail: [],
    originals: [],
    compressed: [],
    loading: true,
    imagesForGallery: [],
  });

  useEffect(() => {
    getRequestData();
  }, []);

  useEffect(() => {
    if (snackBarMessage !== '') {
      handleOpenSnackBar();
    }
  }, [snackBarMessage]);

  const getLinkAfterReplacement = (link) => {
    link = link.replace('_reduced_watermarked', '');
    link = link.replace('watermarked/', '');
    return link;
  };

  const openGallery = (index) => {
    setGalleryIndex(index);
    setGalleryIsOpen(true);
  };

  const uploadDownloadProgress = () => {
    return (progress) => {
      var percentCompleted = Math.round(
        (progress.loaded * 100) / progress.total
      );
      setDownloadPercenteges(percentCompleted);
      setPrepearingImages(false);
    };
  };

  const createSnackbar = (type, message) => {
    setSeverity(type);
    setSnackBarMessage(message);
  };

  const handleOpenSnackBar = () => {
    setSnackBarStatus(true);
  };

  const commentAddedSuccessfully = () => {
    createSnackbar('success', 'The comment was successfully added');
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarStatus(false);
    setSnackBarMessage('');
  };

  const getAccountData = async (id) => {
    var data = { id: id };
    const account_response = await axios.post(
      'https://wesnapp-node-server.appspot.com/api/account/get-one-account',
      { id: id }
    );
    return account_response.data[0];
  };

  const handleChangeDisplayGallery = (name) => (event) => {
    setDisplayGallery(event.target.checked);
  };

  const getImages = async (exposures) => {
    let response = null;
    if (exposures === 'raw') {
      response = await axios.get(
        `${GET_BRACKETS_ORGENIZED_IMAGES + id}-originals-RAWS`,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );
    } else {
      response = await axios.get(
        `${
          GET_BRACKETS_ORGENIZED_IMAGES + id
        }-originals-bracketing-${exposures}Exposures`,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );
    }
    var data = response.data;
    var imagesForGallery = [];
    for (var i = 0; i < data.compressed.length; i++) {
      imagesForGallery.push({
        src: data.compressed[i],
        thumbnail: data.thumbnail[i],
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        caption: 'Image ' + String(i),
      });
    }
    data['loading'] = false;
    data['imagesForGallery'] = imagesForGallery;
    if (exposures === '1') {
      setImages1Exposures(data);
    } else if (exposures === '3') {
      setImages3Exposures(data);
    } else if (exposures === '4') {
      setImages4Exposures(data);
    } else if (exposures === '5') {
      setImages5Exposures(data);
    } else if (exposures === '6') {
      setImages6Exposures(data);
    } else if (exposures === '7') {
      setImages7Exposures(data);
    } else if (exposures === '8') {
      setImages8Exposures(data);
    } else if (exposures === '9') {
      setImages9Exposures(data);
    } else if (exposures === 'raw') {
      setImagesRawExposures(data);
    }
    return 'finished';
  };

  const getRequestData = async () => {
    try {
      setRequestId(id);
      if (id != null && id != undefined && id != '') {
        const response = await axios.post(
          'https://wesnapp-node-server.appspot.com/api/request/get-full-data',
          { id: id }
        );
        setDateAndTime(
          moment(response.data.request.Shooting_Date_Time__c)
            .subtract(1, 'days')
            .calendar()
        );
        getCoordinates(
          response.data.request.ShootingStreet__c +
            ' ' +
            response.data.request.ShootingCity__c
        );
        const accountData = await getAccountData(
          response.data.contact.AccountId
        );
        setAccountId(response.data.contact.AccountId);
        setRequestData(response.data.request);
        setContactData(response.data.contact);
        setProductData(response.data.product);
        setShootingLocation(
          response.data.request.ShootingStreet__c +
            ' ' +
            response.data.request.ShootingCity__c
        );
        setRequestName(response.data.request.Name);
        setPhotographerName(response.data.request.Photographer_Name__c);

        if (response.data.request.Type_Of_Upload__c != null) {
          setUploadedExposures(response.data.request.Type_Of_Upload__c);
        }

        // getAllImages()

        setLoading(false);
      } else {
        alert('Shooting ID is not valid.');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getAllImages = async () => {
    let getImagesPromise = [
      getImages('1'),
      getImages('3'),
      getImages('4'),
      getImages('5'),
      getImages('6'),
      getImages('7'),
      getImages('8'),
      getImages('9'),
      getImages('raw'),
    ];
    await Promise.all(getImagesPromise);
    console.log('finished all');
  };

  const getCoordinates = async (address) => {
    try {
      var link =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        encodeURI(address) +
        '&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s';
      let response = await axios.get(link);
      let latNumber = response.data.results[0].geometry.location.lat;
      let lngNumber = response.data.results[0].geometry.location.lng;
      setLat(latNumber);
      setLng(lngNumber);
    } catch (err) {
      console.error(err);
    }
  };

  const getEditedImages = async (requestId, selectedInThePast) => {
    const response = await axios.get(
      GET_EDITED_ORGENIZED_IMAGES + requestId + '-edited',
      {},
      { headers: { 'Content-Type': 'application/json' } }
    );
    var data = response.data;
    var imagesForGallery = [];
    var counter = 0;
    for (var i = 0; i < data.watermarked.length; i++) {
      if (selectedInThePast.includes(data.originals[i])) {
        counter += 1;
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth: 320,
          thumbnailHeight: 174,
          isSelected: false,
          caption: 'Image ' + String(i),
        });
      } else {
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth: 320,
          thumbnailHeight: 174,
          isSelected: false,
          caption: 'Image ' + String(i),
        });
      }
    }
    data['loading'] = false;
    data['imagesForGallery'] = imagesForGallery;
    setEditedImages(data);
    setOpenConfirmDialog(false);
    setAddingImagesToSalesforce(false);
  };

  const getUploadStationStyle = (i) => {
    if (i == '+') {
      return {
        color: '#544cf8',
        fontSize: 21,
        borderColor: '#544cf8',
        borderWidth: '2px',
        marginBottom: 20,
        height: 50,
        borderRadius: 25,
        backgroundColor: 'rgb(249,250,252)',
        borderStyle: 'dashed',
        boxShadow: '0px 10px 15px -10px rgba(0,0,0,0.00)',
      };
    }
    if (uploadStation == i) {
      return {
        color: 'white',
        marginBottom: 20,
        height: 50,
        borderRadius: 25,
        background:
          'linear-gradient(to right , rgb(94,44,237), rgb(94,44,237))',
        boxShadow: '0px 10px 15px -10px rgba(0,0,0,0.0)',
      };
    } else {
      return {
        color: 'rgb(94,44,237)',
        marginBottom: 20,
        height: 50,
        borderRadius: 25,
        background: 'linear-gradient(to right , #EEF2F9, #EEF2F9)',
        boxShadow: '0px 10px 15px -10px rgba(0,0,0,0.0)',
      };
    }
  };

  const downloadZipFileByType = (type, zipname) => {
    var array = [];
    if (type == 'Raws') {
      array = imagesRawExposures.originals;
    }
    if (type == '1 Exposures') {
      array = images1Exposures.originals;
    }
    if (type == '3 Exposures') {
      array = images3Exposures.originals;
    }
    if (type == '4 Exposures') {
      array = images4Exposures.originals;
    }
    if (type == '5 Exposures') {
      array = images5Exposures.originals;
    }
    if (type == '6 Exposures') {
      array = images6Exposures.originals;
    }
    if (type == '7 Exposures') {
      array = images7Exposures.originals;
    }
    if (type == '8 Exposures') {
      array = images8Exposures.originals;
    }
    if (type == '9 Exposures') {
      array = images9Exposures.originals;
    }
    var lineOfFiles = '';
    for (var i = 0; i < array.length; i++) {
      var path = '';
      path = array[i].replace('https://storage.googleapis.com/wesnapp/', '');
      path.replace('/', '-');
      if (lineOfFiles == '') {
        lineOfFiles = path;
      } else {
        lineOfFiles = lineOfFiles + ',' + path;
      }
    }
    setDownloadPercenteges(1);
    setPrepearingImages(true);
    setOpenDownloadDialog(true);
    var config = {
      responseType: 'blob',
      onDownloadProgress: uploadDownloadProgress(),
    };
    var filesOld = lineOfFiles.split(',');
    var files = [];
    var folder = filesOld[0].split(
      '/' + filesOld[0].split('/')[filesOld[0].split('/').length - 1]
    )[0];
    for (var j = 0; j < filesOld.length; j++) {
      files.push(filesOld[j].split(folder + '/')[1]);
    }
    axios
      .post(
        'https://py.wesnapp.co.il/server/zipfiles-images',
        { folder: folder, files: files, zipname: zipname },
        config
      )
      .then((response) => {
        var headers = response.headers;
        var blob = new Blob([response.data], { type: headers['content-type'] });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = zipname;
        link.click();
        link.remove();
        setDownloadPercenteges(0);
        setOpenDownloadDialog(false);
        createSnackbar('success', 'Images downloaded successfully');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const returnTabStyle = (index) => {
    if (uploadAndLastUpload != index) {
      return {
        fontWeight: 'bold',
        height: '100%',
        width: '50.0%',
        textTransform: 'none',
        borderRadius: 25,
      };
    } else {
      return {
        fontWeight: 'bold',
        height: '100%',
        width: '50.0%',
        textTransform: 'none',
        borderRadius: 25,
        color: 'white',
        background: 'linear-gradient(to right , #544cf8, #814bfa)',
        marginLeft: 2,
      };
    }
  };

  const handleChangeUploadAndLastUpload = (event, value) => {
    setUploadAndLastUpload(value);
    getAllImages();
  };

  const editImages = (type) => {
    var data = { requestId: requestId, exposures: parseInt(type) };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/editingRequest/create',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addComment = () => {
    setAddingCommentInProgress(true);
    var data = {
      requestId: requestId,
      data: { Photographer_Comments__c: comment },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/request/update-request',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        setAddingCommentInProgress(true);
        setCommentDialogOpen(false);
        commentAddedSuccessfully();
      })
      .catch((error) => {});
  };

  return (
    <div className={classes.root}>
      <CommentsDialog
        addingCommentInProgress={addingCommentInProgress}
        setComment={setComment}
        commentDialogOpen={commentDialogOpen}
        setCommentDialogOpen={setCommentDialogOpen}
        addComment={addComment}
        comment={comment}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={severity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
      {galleryIsOpen && (
        <Lightbox
          mainSrc={editedImages.imagesForGallery[galleryIndex].src}
          nextSrc={
            editedImages.imagesForGallery[
              (galleryIndex + 1) % editedImages.imagesForGallery.length
            ].src
          }
          prevSrc={
            editedImages.imagesForGallery[
              (galleryIndex + editedImages.imagesForGallery.length - 1) %
                editedImages.imagesForGallery.length
            ].src
          }
          onCloseRequest={() => {
            setGalleryIsOpen(false);
          }}
          onMovePrevRequest={() =>
            setGalleryIndex(
              (galleryIndex + editedImages.imagesForGallery.length - 1) %
                editedImages.imagesForGallery.length
            )
          }
          onMoveNextRequest={() =>
            setGalleryIndex(
              (galleryIndex + 1) % editedImages.imagesForGallery.length
            )
          }
        />
      )}
      <div className={classes.content}>
        {loading === false ? (
          <>
            <PhotoShootDetails
              location={shootingLocation}
              dateTime={dateAndTime}
              product={productData.Public_Product_Name__c}
              photographer={requestData.Photographer_Name__c}
            />
            <Original request={requestData} />
          </>
        ) : (
          <Skeletons />
        )}
      </div>
      <DownloadDialog
        open={openDownloadDialog}
        setDialogStatus={setOpenDownloadDialog}
        downloadPercenteges={downloadPercenteges}
        prepearingImages={prepearingImages}
      />
    </div>
  );
};

export default PhotoShoot;
