import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import AppBar from './AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@material-ui/core';

const history = createBrowserHistory();

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#FFF',
    // background: 'linear-gradient(90deg, #6C65FF 50%, #FFF 50%)',
    position: 'fixed',
    top: 0,
    left: 0,
  },
});

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

class Template extends React.Component {
  state = {
    approving: false,
    rejecting: false,
    rejected: false,
    approved: false,
    pending: false,
    groupName: '',
  };

  componentDidMount() {
    console.log(this.props.match.params.id);
    this.getRequestData(this.props.match.params.id);
  }

  getRequestData = (id) => {
    var data = { id: id };
    console.log(data);
    this.setState({ id: id });
    // axios.post('https://wesnapp-node-server.appspot.com' + '/api/accountcontactrelation/get-one',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/accountcontactrelation/get-one',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        var relationship = response.data[0];
        var status = relationship.Relationship_Status__c;
        if (status === null || status === 'Pending') {
          this.setState({ pending: true });
        } else if (status === 'Approved') {
          this.setState({ approved: true });
        } else if (status === 'Refused') {
          this.setState({ rejected: true });
        }
        this.setState({ groupName: relationship.Account_Name__c });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  approve = () => {
    this.setState({ approving: true });
    var data = {
      id: this.state.id,
      data: { Relationship_Status__c: 'Approved' },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/update-relationship',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        this.setState({ approving: false, approved: true, pending: false });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  reject = () => {
    this.setState({ rejecting: true });
    var data = {
      id: this.state.id,
      data: { Relationship_Status__c: 'Refused' },
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/update-relationship',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        this.setState({ rejecting: false, rejected: true, pending: false });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <AppBar />
        <Grid
          container
          spacing={1}
          style={{ padding: 20, paddingTop: 40 }}
          justifyContent='center'
          justify='center'
        >
          <Grid item xs={12} sm={12} md={7} xl={7}>
            <div
              style={{
                width: '100%',
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'left',
                marginTop: 'calc(50vh - 50px - 170px)',
              }}
            >
              New Invitation!
            </div>
            <div
              style={{
                width: '100%',
                fontSize: 18,
                marginTop: 20,
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              You got a new invitation to be a member of {this.state.groupName}{' '}
              group.
            </div>
          </Grid>
          {/* <div style={{position: 'fixed', bottom: 20, width: '100%'}}> */}
          {/* <Grid container spacing={1} style={{padding: 20, paddingTop: 40}} justifyContent="center" justify="center"> */}
          {this.state.pending && !this.state.rejecting && (
            <Grid item xs={6} sm={6} md={6} xl={6}>
              <Button
                onClick={() => this.reject()}
                style={{
                  width: '100%',
                  maxWidth: 200,
                  height: 50,
                  marginTop: 30,
                  float: 'left',
                  borderRadius: 8,
                  backgroundColor: '#FF3366',
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Reject
              </Button>
            </Grid>
          )}
          {this.state.pending && this.state.rejecting && (
            <Grid item xs={6} sm={6} md={6} xl={6}>
              <CircularProgress
                style={{
                  width: 40,
                  height: 40,
                  marginTop: 5,
                  color: '#FF3366',
                  marginTop: 35,
                }}
              />
            </Grid>
          )}

          {this.state.pending && !this.state.approving && (
            <Grid item xs={6} sm={6} md={6} xl={6}>
              <Button
                onClick={() => this.approve()}
                style={{
                  width: '100%',
                  maxWidth: 200,
                  height: 50,
                  marginTop: 30,
                  float: 'right',
                  borderRadius: 8,
                  backgroundColor: '#23CE6B',
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Approve
              </Button>
            </Grid>
          )}
          {this.state.pending && this.state.approving && (
            <Grid item xs={6} sm={6} md={6} xl={6}>
              <CircularProgress
                style={{
                  width: 40,
                  height: 40,
                  marginTop: 5,
                  color: '#23CE6B',
                  marginTop: 35,
                }}
              />
            </Grid>
          )}

          {this.state.approved && !this.state.approving && (
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Button
                style={{
                  width: '100%',
                  height: 50,
                  marginTop: 30,
                  float: 'right',
                  borderRadius: 8,
                  backgroundColor: '#23CE6B',
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Approved
              </Button>
            </Grid>
          )}
          {this.state.rejected && (
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Button
                style={{
                  width: '100%',
                  height: 50,
                  marginTop: 30,
                  float: 'right',
                  borderRadius: 8,
                  backgroundColor: '#FF3366',
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Rejected
              </Button>
            </Grid>
          )}
          {/* </Grid> */}
          {/* </div> */}
        </Grid>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
