import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SmsDialog from './SmsDialog';
import OrderStepper from './OrderStepper';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

var mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    backgroundColor: '#6C65FF',
    backgroundColor: 'white',
  },
});

class Template extends React.Component {
  state = {
    lat: 32,
    lng: 32,
    smsDialog: false,
    willBeOnSite: true,
    connectToBusiness: false,
    isBusinessAccount: false,
    businessAccountId: null,
    errorFirstName: false,
    errorLastName: false,
    errorPhoneNumber: false,
    errorAddress: false,
    errorDate: false,
    errorCode: false,
    errorTime: false,
    errorOfficeName: false,
    errorWillBeOnSiteName: false,
    errorWillBeOnSitePhone: false,
    errorSelectProduct: false,
    errorEmail: false,

    formatedDate: null,
    formatedTime: null,

    isLoading: false,

    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    officeName: '',
    code: '',
    inputCode: '',

    formattedAddress: '',
    whoWillBeOnSiteName: '',
    whoWillBeOnSitePhone: '',
    date: '',
    time: '',

    products: [],
    selectedProduct: '-1',
    selectedProductData: null,
    countryCode: '972',

    openPackages: [],
    productsFromPackages: [],
    searchForOpenPackages: true,
    isPackage: false,
    selectedPackage: null,

    requestId: null,

    useNow: null,

    nameForInvoice: '',

    activeStep: 1,

    startMatch: true,
    admin: null,

    coupon: '',
    checkingCoupon: '',
    couponIsValid: false,
    appliedCoupon: false,
    canApply: false,
    errorCoupon: false,
    errorCouponText: '',
    couponType: '',
    couponDiscount: '',
  };

  validateMobile = (phone) => {
    console.log(phone);
    var substring1 = ' 9725';
    var substring2 = ' 97205';
    if (phone.includes(substring1) || phone.includes(substring2)) {
      console.log('@@');
      if (phone.indexOf(substring1) !== -1) {
        console.log(phone);
        phone = phone.replace(' 9725', '05');
        phone = phone.replace('-', '');
        if (
          phone[0] == '0' &&
          phone[1] == '5' &&
          (phone[2] == '2' ||
            phone[2] == '3' ||
            phone[2] == '0' ||
            phone[2] == '4' ||
            phone[2] == '5' ||
            phone[2] == '8')
        ) {
          if (phone.length == 10 && /^\d+$/.test(phone)) {
            this.setState({ phone: phone });
            return true;
          }
        } else {
          return false;
        }
      } else {
        phone = phone.replace(' 97205', '05');
        phone = phone.replace('-', '');
        console.log(phone);
        if (
          phone[0] == '0' &&
          phone[1] == '5' &&
          (phone[2] == '2' ||
            phone[2] == '3' ||
            phone[2] == '0' ||
            phone[2] == '4' ||
            phone[2] == '5' ||
            phone[2] == '8')
        ) {
          if (phone.length == 10 && /^\d+$/.test(phone)) {
            this.setState({ phone: phone });
            return true;
          }
        } else {
          return false;
        }
      }
    } else {
      phone = phone.replace('-', '');
      if (
        phone[0] == '0' &&
        phone[1] == '5' &&
        (phone[2] == '2' ||
          phone[2] == '3' ||
          phone[2] == '0' ||
          phone[2] == '4' ||
          phone[2] == '5' ||
          phone[2] == '8')
      ) {
        if (phone.length == 10 && /^\d+$/.test(phone)) {
          this.setState({ phone: phone });
          return true;
        }
      } else {
        return false;
      }
    }
  };

  validateMobileWithoutUpdate = (phone) => {
    console.log(phone);
    var substring1 = ' 9725';
    var substring2 = ' 97205';
    if (phone.includes(substring1) || phone.includes(substring2)) {
      console.log('@@');
      if (phone.indexOf(substring1) !== -1) {
        console.log(phone);
        phone = phone.replace(' 9725', '05');
        phone = phone.replace('-', '');
        if (
          phone[0] == '0' &&
          phone[1] == '5' &&
          (phone[2] == '2' ||
            phone[2] == '3' ||
            phone[2] == '0' ||
            phone[2] == '4' ||
            phone[2] == '5' ||
            phone[2] == '8')
        ) {
          if (phone.length == 10 && /^\d+$/.test(phone)) {
            // this.setState({phone: phone})
            return true;
          }
        } else {
          return false;
        }
      } else {
        phone = phone.replace(' 97205', '05');
        phone = phone.replace('-', '');
        console.log(phone);
        if (
          phone[0] == '0' &&
          phone[1] == '5' &&
          (phone[2] == '2' ||
            phone[2] == '3' ||
            phone[2] == '0' ||
            phone[2] == '4' ||
            phone[2] == '5' ||
            phone[2] == '8')
        ) {
          if (phone.length == 10 && /^\d+$/.test(phone)) {
            // this.setState({phone: phone})
            return true;
          }
        } else {
          return false;
        }
      }
    } else {
      phone = phone.replace('-', '');
      if (
        phone[0] == '0' &&
        phone[1] == '5' &&
        (phone[2] == '2' ||
          phone[2] == '3' ||
          phone[2] == '0' ||
          phone[2] == '4' ||
          phone[2] == '5' ||
          phone[2] == '8')
      ) {
        if (phone.length == 10 && /^\d+$/.test(phone)) {
          // this.setState({phone: phone})
          return true;
        }
      } else {
        return false;
      }
    }
  };

  validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  resetErrors = () => {
    this.setState({
      errorFirstName: false,
      errorLastName: false,
      errorPhoneNumber: false,
      errorAddress: false,
      errorDate: false,
      errorCode: false,
      errorOfficeName: false,
      errorTime: false,
      errorWillBeOnSiteName: false,
      errorWillBeOnSitePhone: false,
      errorSelectProduct: false,
      errorEmail: false,
      // admin: false
    });
  };

  getCoordinates = async (address) => {
    try {
      var link =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        encodeURI(address) +
        '&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s';
      const response = await axios.get(link);
      // console.log(response.data.results[0]);
      if (response.data.results.length > 0) {
        if (
          response.data.results[0].types.includes('street_address') ||
          response.data.results[0].types.includes('premise')
        ) {
          console.log('yes');
          this.setState(
            {
              errorAddress: false,
              lat: response.data.results[0].geometry.location.lat,
              lng: response.data.results[0].geometry.location.lng,
              formattedAddress: response.data.results[0].formatted_address,
            },
            () => {
              return true;
            }
          );
        } else {
          console.log('no');
          console.log(response.data.results[0]);
          this.setState({ errorAddress: true });
          return false;
        }
      } else {
        this.setState({ errorAddress: true });
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  nextStep = async () => {
    this.resetErrors();
    var step = this.state.activeStep;
    if (step === 0) {
      var allowToContinue = true;
      if (this.state.firstName.length <= 1) {
        this.setState({ errorFirstName: true });
        allowToContinue = false;
      }
      if (this.state.lastName.length <= 1) {
        this.setState({ errorLastName: true });
        allowToContinue = false;
      }
      if (!this.validateEmail(this.state.email)) {
        this.setState({ errorEmail: true });
        allowToContinue = false;
      }
      if (!this.validateMobile(this.state.phone)) {
        this.setState({ errorPhoneNumber: true });
        allowToContinue = false;
      }
      if (allowToContinue) {
        this.continueStepOne();
      }
    } else if (step == 1) {
      var allowToContinue = true;
      if (this.state.useNow) {
        var location = await this.getCoordinates(this.state.formattedAddress);
        console.log(location);
        if (location === false) {
          this.setState({ errorAddress: true });
          allowToContinue = false;
        }
        if (!this.state.willBeOnSite) {
          if (this.state.whoWillBeOnSiteName.length <= 1) {
            this.setState({ errorWillBeOnSiteName: true });
            allowToContinue = false;
          }
          if (
            !this.validateMobileWithoutUpdate(this.state.whoWillBeOnSitePhone)
          ) {
            this.setState({ errorWillBeOnSitePhone: true });
            allowToContinue = false;
          }
        }
        if (this.state.date == '') {
          this.setState({ errorDate: true });
          allowToContinue = false;
        }
        if (this.state.selectedProduct === '-1') {
          this.setState({ errorSelectProduct: true });
          allowToContinue = false;
        }
        if (this.state.time == '') {
          this.setState({ errorTime: true });
          allowToContinue = false;
        } else {
          var min = this.state.time.split(':')[1];
          console.log(min);
          console.log(min);
          if (
            min === '0' ||
            min === '00' ||
            min === '15' ||
            min === '30' ||
            min === '45'
          ) {
            // time is ok
          } else {
            this.setState({ errorDate: true });
            allowToContinue = false;
          }
        }
        if (this.state.selectedProduct == 0) {
          this.setState({ errorSelectProduct: true });
          allowToContinue = false;
        }
        if (allowToContinue) {
          this.continueStepTwo();
        }
      } else {
        // use after
        this.setState({ formattedAddress: 'Waiting for scheduled' });
        this.continueStepTwoWithoutUse();
      }
    }
  };

  getParameterByName = (name) => {
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  componentDidMount() {
    if (this.props.landingPage) {
      this.setState({ activeStep: 0 });
    }
    var admin = this.getParameterByName('admin');
    if (admin != null && admin != undefined && admin != '') {
      if (admin === 'wesnapp2020') {
        this.setState({ admin: true }, () => {
          console.log(this.state.admin);
        });
      } else {
        this.setState({ admin: false }, () => {
          console.log(this.state.admin);
        });
      }
    }
    var firstName = this.getParameterByName('firstName');
    if (firstName != null && firstName != undefined && firstName != '') {
      this.setState({ firstName: firstName });
    }

    var lastName = this.getParameterByName('lastName');
    if (lastName != null && lastName != undefined && lastName != '') {
      this.setState({ lastName: lastName });
    }

    var phone = this.getParameterByName('mobile');
    if (phone != null && phone != undefined && phone != '') {
      this.setState({ phone: phone });
    }

    var email = this.getParameterByName('email');
    if (email != null && email != undefined && email != '') {
      this.setState({ email: email });
    }

    var officeName = this.getParameterByName('officeName');
    if (officeName != null && officeName != undefined && officeName != '') {
      this.setState({ officeName: officeName });
    }

    var reference = this.getParameterByName('reference');
    if (reference != null && reference != undefined && reference != '') {
      this.setState({ reference: reference });
    }

    console.log(this.props.landingPage);
    if (!this.props.landingPage) {
      this.getUserDetails();
      this.checkForOpenPackages(localStorage.getItem('accountId'));
      this.getSpecialProducts(localStorage.getItem('accountId'));
      this.checkIfBusinessExists();
    } else {
      // this.setState({activeStep: 1})
    }
  }

  componenetDidUpdate() {
    // this.checkIfBusinessExists()
  }

  checkIfBusinessExists() {
    if (this.props.isBusinessAccount) {
      this.setState({
        isBusinessAccount: true,
        businessAccountId: this.props.businessAccountId,
      });
    }
  }

  getUserDetails = () => {
    var data = { id: localStorage.getItem('accountId') };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/account/get-one',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data[0]);
        var nameForInvoice = response.data[0].Company_Name_for_Invoices__c;
        if (nameForInvoice === null || nameForInvoice === '') {
          nameForInvoice =
            response.data[0].FirstName + ' ' + response.data[0].LastName;
        }
        this.setState({
          phone: response.data[0].Mobile_Number__c,
          firstName: response.data[0].FirstName,
          lastName: response.data[0].LastName,
          email: response.data[0].PersonEmail,
          nameForInvoice: nameForInvoice,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  loading = (flag) => {
    this.setState({ isLoading: flag });
  };

  generateRandomCode = () => {
    var code = Math.floor(1000 + Math.random() * 9000);
    return code;
  };

  sendVerificationCode = () => {
    var data = {
      phone: '+' + this.state.countryCode + this.state.phone,
      text: 'WeSnapp verification code: ' + this.state.code,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/sms/send-message',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  findClientUsingPhoneNumber = () => {
    var data = {
      mobile: this.state.phone,
    };
    console.log(data);
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/account/find-person-account-by-mobile',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response);
        var accounts = response.data;
        if (accounts.length !== 0) {
          var accountData = accounts[0];
          // .Id .PersonContactId
          // alert(toString(accountData))
          // alert(accountData.Id)
          this.checkForOpenPackages(accountData.Id);
          this.getSpecialProducts(accountData.Id);
          this.setState({ activeStep: 1 });
        } else {
          // Create new client
          this.setState({ isLoading: true });
          var accountData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            mobile: this.state.phone,
          };
          axios
            .post(
              'https://wesnapp-node-server.appspot.com' +
                '/api/account/create-new-client-person-account',
              accountData,
              { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
              // axios.post('http://localhost:8080' + '/api/account/create-new-client-person-account',accountData, {headers: {'Content-Type': 'application/json'}}).then((response) => {
              console.log('new account data');
              console.log(response.data.accountId);
              console.log(response.data.contactId);
              this.setState({ isLoading: false, activeStep: 1 });
            });
          // Get public products
          axios
            .post(
              'https://wesnapp-node-server.appspot.com' +
                '/api/product/get-public',
              data,
              { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
              console.log('public products');
              console.log(response.data);
              if (response.data.length > 0) {
                var publicProducts = [];
                response.data.forEach((product) => {
                  publicProducts.push(product.Id);
                });
                this.getProducts(publicProducts);
              } else {
              }
            });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  checkIfCouponIsValid = () => {
    this.setState({ checkingCoupon: true, couponIsValid: false });
    var data = {
      coupon: this.state.coupon,
      accountId: this.state.accountId,
      requestId: this.state.requestId,
      productId: this.state.productId,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/coupons/check-coupon',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.allowed) {
          let type = '';
          let amount = 0;
          if (response.data.discountAmount !== null) {
            type = 'cash';
            amount = response.data.discountAmount;
          } else {
            type = 'percentages';
            amount = response.data.amount;
          }
          this.setState({
            checkingCoupon: false,
            couponIsValid: true,
            errorCoupon: false,
            canApply: false,
            errorCouponText: 'Coupon is valid',
            couponType: type,
            couponDiscount: amount,
          });
        } else {
          this.setState({
            checkingCoupon: false,
            couponIsValid: false,
            errorCoupon: true,
            canApply: false,
            errorCouponText: 'Coupon is not valid',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChangeCoupon = (event) => {
    let newValue = event.target.value;
    if (newValue === '') {
      console.log('xx');
      this.setState({
        errorCoupon: '',
        errorCouponText: '',
        coupon: newValue,
        canApply: false,
      });
    } else {
      console.log('yy');
      this.setState({ coupon: newValue, canApply: true });
    }
  };

  applyCoupon = () => {};

  reverseString = (str) => {
    // Step 1. Use the split() method to return a new array
    var splitString = str.split(''); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    var joinArray = reverseArray.join(''); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    //Step 4. Return the reversed string
    return joinArray; // "olleh"
  };

  verifyCode = () => {
    // console.log(this.state.code)
    // console.log(this.state.inputCode)
    this.setState({ errorCode: false });
    if (
      String(this.state.code) === String(this.state.inputCode) ||
      String(this.state.code) ===
        this.reverseString(String(this.state.inputCode))
    ) {
      this.findClientUsingPhoneNumber();
      this.setState({ smsDialog: false });
    } else {
      this.setState({ errorCode: true });
    }
  };

  continueStepOne = () => {
    if (this.state.admin) {
      this.findClientUsingPhoneNumber();
      this.setState({ activeStep: 1 });
    } else {
      this.setState({ smsDialog: true });
      this.setState({ code: this.generateRandomCode() }, () => {
        this.sendVerificationCode();
      });
    }
  };

  continueStepTwoWithoutUse = () => {
    // if (this.state.requestId !== null) {
    //   this.updateRequest()
    // } else {
    if (this.state.selectedProductData.Product_Type__c === 'Single') {
      this.createRequestWithoutUse();
    } else {
      this.createRequestWithoutUse();
    }
    // }
  };

  continueStepTwo = () => {
    if (this.state.requestId !== null) {
      this.updateRequest();
    } else {
      if (this.state.selectedProductData.Product_Type__c === 'Single') {
        this.createRequest();
      } else {
        this.createRequest();
      }
    }
  };

  getSpecialProducts = (accountId) => {
    var data = {
      accountId: accountId,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/product/get-special',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log('special products');
        console.log(response.data);
        if (response.data.length > 0) {
          var specialProducts = [];
          response.data.forEach((product) => {
            if (product.Active__c) {
              specialProducts.push(product.WS_Product__c);
            }
          });
          console.log(specialProducts);
          // Get public products
          axios
            .post(
              'https://wesnapp-node-server.appspot.com' +
                '/api/product/get-public',
              data,
              { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
              console.log('public products');
              console.log(response.data);
              if (response.data.length > 0) {
                var publicProducts = [];
                response.data.forEach((product) => {
                  publicProducts.push(product.Id);
                });
                this.getProducts(publicProducts.concat(specialProducts));
              } else {
              }
            });
          // this.getProducts(["a011t0000030DKCAA2","a011t0000030Gu5AAE","a011t0000030GuAAAU"].concat(specialProducts))
          // this.getProducts(specialProducts)
        } else {
          // No special products
          // Get public products
          axios
            .post(
              'https://wesnapp-node-server.appspot.com' +
                '/api/product/get-public',
              data,
              { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
              console.log('public products');
              console.log(response.data);
              if (response.data.length > 0) {
                var publicProducts = [];
                response.data.forEach((product) => {
                  publicProducts.push(product.Id);
                });
                this.getProducts(publicProducts);
              } else {
              }
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProducts = (products) => {
    console.log('!@#');
    var data = {
      products: products,
    };
    console.log(data);
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/product/get-many',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          var products = response.data;
          products.sort((a, b) => b.Total_Cost__c - a.Total_Cost__c);
          products.reverse();
          this.setState({ products: products });
        } else {
          // this.setState({newUser: true})
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateRequest = () => {
    this.loading(true);
    var address = this.state.formattedAddress.split(', ');
    var street = address[0];
    var city = address[1];
    var country = address[2];
    var officeName = this.state.officeName;
    if (this.state.officeName == '') {
      officeName = this.state.firstName + ' ' + this.state.lastName;
    }
    var data = {
      requestId: this.state.requestId,
      contactId: localStorage.getItem('contactId'),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      mobile: this.state.phone,
      email: this.state.email,
      selectedProduct: this.state.selectedProduct,
      reference: 'dashboard',
      datetime: this.state.date + 'T' + this.state.time + ':00.000+0200',
      address: this.state.formattedAddress,
      country: country,
      whoWillBeOnSiteName: this.state.whoWillBeOnSiteName,
      whoWillBeOnSitePhone: this.state.whoWillBeOnSitePhone,
      city: city,
      street: street,
      officeName: officeName,
      lng: this.state.lng.toString(),
      lat: this.state.lat.toString(),
      isPackage: this.state.isPackage,
      connectToBusiness: this.state.connectToBusiness,
      businessAccountId: this.props.businessAccountId,
    };
    if (this.state.admin) {
      data.reference = 'team';
    }
    if (this.state.isPackage) {
      data['selectedPackage'] = this.state.selectedPackage;
    }
    console.log(data);
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/action/update-request',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        this.loading(false);
        console.log(response.data);
        // this.setState({accountId: response.data.accountId, contactId: response.data.contactId, orderId: response.data.orderId, requestId: response.data.requestId}, () => {
        this.setState({ activeStep: 2 });
        // })
      })
      .catch((error) => {});
  };

  createRequestWithoutUse = () => {
    this.loading(true);

    var officeName = this.state.officeName;
    if (this.state.officeName == '') {
      officeName = this.state.firstName + ' ' + this.state.lastName;
    }
    var data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      mobile: this.state.phone,
      email: this.state.email,
      selectedProduct: this.state.selectedProduct,
      reference: 'dashboard',
      officeName: officeName,
      isPackage: this.state.isPackage,
      connectToBusiness: this.state.connectToBusiness,
      businessAccountId: this.props.businessAccountId,
      useNow: false,
      onlyOrder: this.state.selectedProductData.Product_Type__c === 'Package',
    };
    if (this.state.admin) {
      data.reference = 'team';
    }
    if (this.state.selectedProductData.Product_Type__c === 'Package') {
      data['singleProduct'] =
        this.state.selectedProductData.Link_to_Single_Product__c;
    }
    if (this.state.isPackage) {
      data['selectedPackage'] = this.state.selectedPackage;
    }
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/action/create-order-new',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // axios.post('http://localhost:8080' + '/api/action/create-order-new',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        this.loading(false);
        console.log(response.data);
        this.setState(
          {
            accountId: response.data.accountId,
            contactId: response.data.contactId,
            orderId: response.data.orderId,
            requestId: response.data.requestId,
          },
          () => {
            this.setState({ activeStep: 2 });
          }
        );
      })
      .catch((error) => {});
  };

  createRequest = () => {
    this.loading(true);
    var address = this.state.formattedAddress.split(', ');
    var street = address[0];
    var city = address[1];
    var country = address[2];
    // console.log(city);
    // console.log(country);
    // console.log(street);
    var officeName = this.state.officeName;
    if (this.state.officeName == '') {
      officeName = this.state.firstName + ' ' + this.state.lastName;
    }
    var data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      mobile: this.state.phone,
      email: this.state.email,
      selectedProduct: this.state.selectedProduct,
      reference: 'dashboard',
      datetime: this.state.date + 'T' + this.state.time + ':00.000+0200',
      address: this.state.formattedAddress,
      country: country,
      whoWillBeOnSiteName: this.state.whoWillBeOnSiteName,
      whoWillBeOnSitePhone: this.state.whoWillBeOnSitePhone,
      city: city,
      street: street,
      officeName: officeName,
      lng: this.state.lng.toString(),
      lat: this.state.lat.toString(),
      isPackage: this.state.isPackage,
      connectToBusiness: this.state.connectToBusiness,
      businessAccountId: this.props.businessAccountId,
      useNow: true,
      onlyOrder: this.state.selectedProductData.Product_Type__c === 'Package',
    };
    if (this.state.admin) {
      data.reference = 'team';
    }
    if (this.state.selectedProductData.Product_Type__c === 'Package') {
      data['singleProduct'] =
        this.state.selectedProductData.Link_to_Single_Product__c;
    }
    if (this.state.isPackage) {
      data['selectedPackage'] = this.state.selectedPackage;
    }
    console.log(data);
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/action/create-order-new',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // axios.post('http://localhost:8080' + '/api/action/create-order-new',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        this.loading(false);
        console.log(response.data);
        this.setState(
          {
            accountId: response.data.accountId,
            contactId: response.data.contactId,
            orderId: response.data.orderId,
            requestId: response.data.requestId,
          },
          () => {
            this.setState({ activeStep: 2 });
            // start match or not
            if (this.state.startMatch) {
              console.log('start match');
              var data = { requestId: response.data.requestId };
              axios
                .post(
                  'https://us-central1-wesnapp-d136a.cloudfunctions.net/startMatching',
                  data,
                  { headers: { 'Content-Type': 'application/json' } }
                )
                .then((response) => {
                  console.log(response.data);
                })
                .catch((error) => {
                  //console.log(error);
                });
            }
          }
        );
      })
      .catch((error) => {});
  };

  checkForOpenPackages = (clientId) => {
    var data = {
      id: clientId,
    };
    //https://wesnapp-node-server.appspot.com
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' +
          '/api/order/get-open-orders',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        var products = [];
        for (var i = 0; i < response.data.length; i++) {
          products.push(response.data[i].singleProductId);
        }
        this.setState({ openPackages: response.data });
        this.getProductsFromPackages(products, response.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  getProductsFromPackages = (products, packages) => {
    console.log('!@#');
    var data = {
      products: products,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/product/get-many',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          this.setState({
            productsFromPackages: response.data,
            searchForOpenPackages: false,
          });
        } else {
          this.setState({ searchForOpenPackages: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div
        className={styles.root}
        style={{
          backgroundColor: 'white',
          borderRadius: '35px 35px 0 0px',
          marginTop: 10,
          padding: mobile ? 20 : 0,
          paddingTop: mobile ? 30 : 0,
          minHeight: '100%',
        }}
      >
        <div>
          <SmsDialog main={this} />
          {this.state.isLoading === true && (
            <div
              className='loading'
              style={{
                backgroundColor: 'rgba(255,255,255,0.8)',
                minHeight: '100vh',
                minWidth: '100vw',
                backdropFilter: 'blur(4px)',
              }}
            >
              <CircularProgress style={{ marginTop: 'calc(50vh - 50px)' }} />
              <div>
                <div
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: mobile ? 25 : 20,
                    padding: mobile ? 20 : 0,
                    color: 'black',
                    marginTop: 20,
                    fontWeight: 'bold',
                  }}
                >
                  Please wait, the process may take a few seconds
                </div>
              </div>
            </div>
          )}
          <Grid
            container
            spacing={2}
            style={{ padding: 0, paddingTop: 0 }}
            justifyContent='center'
            justify='center'
          >
            <Grid item xs={12} sm={12} md={10} xl={9}>
              <div
                style={{
                  fontSize: 24,
                  color: 'rgb(40,40,53)',
                  textAlign: 'left',
                  marginLeft: 0,
                  fontFamily: "'Montserrat', sans-serif",
                  marginTop: mobile ? 0 : 15,
                  marginBottom: mobile ? 0 : 30,
                }}
              >
                <b>New Order</b>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              xl={6}
              style={{ marginTop: -20, paddingBottom: 40 }}
            >
              {/* {this.state.admin !== null && ( */}
              <OrderStepper
                landingPage={this.props.landingPage}
                isBusinessAccount={this.props.isBusinessAccount}
                main={this}
                admin={this.state.admin}
              />
              {/* )} */}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
