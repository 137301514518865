import { BrowserRouter } from 'react-router-dom';
import React, { useState, useContext, useEffect } from 'react';
import {
  MuiThemeProvider,
  createMuiTheme,
  useStyles,
  makeStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Cookies from 'universal-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';
import Login from './Components/Login/Login.js';
import SignUpClient from './Components/Login/SignUpClient.js';
import ClientTemplate from './Components/Client/Base/Template.js';
import PhotographerTemplate from './Components/Photographer/Base/Template.js';
import SignUpPhotographer from './Components/Login/SignUpPhotographer.js';
import ConfirmRelation from './Components/Landings/ConfirmRelation.js';
import Unsubscribe from './Components/Landings/Unsubscribe.js';
import NewOffer from './Components/Landings/NewOffer/NewOffer.js';
import Order from './Components/Landings/Order.js';
import OrderMobile from './Components/Landings/OrderMobile/Order.js';
import OrderHomero from './Components/Landings/Homero/Order.js';
import Demo from './Components/Demo/Demo';
import Video from './Components/Video/Video';
import Matching from './Components/Matching/Matching';
import DevelopmentGallery from './Components/DevelopmentGallery/DevelopmentGallery';
import CampaignPhotographers from './Components/Landings/CampaignPhotographers/CampaignPhotographers.js';
import Type from './Components/Login/Type.js';
import { Router, Route, Link, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import MuiThemeStyle from './MuiThemeStyle.js';
import { AuthContext } from './contexts/Auth';
import history from './utils/history';
import BmbyOrderPage from './Components/Landings/Bmby/Bmby.js';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { withRouter } from 'react-router-dom';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;
const API_URL = 'https://wesnapp-node-server.appspot.com/server/';
const theme = createMuiTheme(MuiThemeStyle);

const App = () => {
  // const classes = useStyles()

  useEffect(() => {}, []);

  const {
    setIsLoggedIn,
    setAccountId,
    setContactId,
    setToken,
    setAccountType,
    isLoggedIn,
  } = useContext(AuthContext);

  // const context = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(true);

  const isLanding = () => {
    if (
      history.location.pathname.includes('/new-offer') ||
      history.location.pathname.includes('/job-offer') ||
      history.location.pathname.includes('/request') ||
      history.location.pathname.includes('/membership') ||
      history.location.pathname.includes('/new-order') ||
      history.location.pathname.includes('/order') ||
      history.location.pathname.includes('/bmby') ||
      history.location.pathname.includes('homehero') ||
      history.location.pathname.includes('unsubscribe') ||
      history.location.pathname.includes('video') ||
      history.location.pathname.includes('campaign-photographers')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const loggedIn = (userType) => {
    if (userType === '0121t000000Qm7GAAS') {
      if (
        !isLanding() &&
        !history.location.pathname.includes('/demo') &&
        !history.location.pathname.includes('/billing') &&
        !history.location.pathname.includes('/matching-manually') &&
        !history.location.pathname.includes('/video') &&
        !history.location.pathname.includes('/development/gallery') &&
        !history.location.pathname.includes('/c/dashboard/company') &&
        !history.location.pathname.includes('/c/dashboard/order') &&
        !history.location.pathname.includes('/c/dashboard/account') &&
        !history.location.pathname.includes('/c/dashboard/account') &&
        !history.location.pathname.includes('/c/dashboard/photo-shoot') &&
        !history.location.pathname.includes('/c/dashboard/shoot') &&
        !history.location.pathname.includes('/c/dashboard/order')
      ) {
        history.push('/c/dashboard/photo-shoots');
      }
      setIsLoggedIn(true);
      setIsLoading(false);
      setAccountType('client');
    } else {
      if (
        !isLanding() &&
        !history.location.pathname.includes('/demo') &&
        !history.location.pathname.includes('/matching-manually') &&
        !history.location.pathname.includes('/video') &&
        !history.location.pathname.includes('/development/gallery') &&
        !history.location.pathname.includes('/p/dashboard/photo-shoot') &&
        !history.location.pathname.includes('/p/dashboard/billing') &&
        !history.location.pathname.includes('/p/dashboard/account') &&
        !history.location.pathname.includes('/p/dashboard/auto-edit')
      ) {
        history.push('/p/dashboard/photo-shoots');
      }
      setIsLoggedIn(true);
      setIsLoading(false);
      setAccountType('photographer');
    }
  };

  const notLoggedIn = () => {
    if (isLanding()) {
    } else if (
      !history.location.pathname.includes('/signup/photographer') &&
      !history.location.pathname.includes('/video') &&
      !history.location.pathname.includes('/development/gallery') &&
      !history.location.pathname.includes('/demo') &&
      !history.location.pathname.includes('/matching-manually') &&
      !history.location.pathname.includes('/signup/client') &&
      !history.location.pathname.includes('/typeof')
    ) {
      history.push('/login');
    }
    setIsLoggedIn(false);
    setIsLoading(false);
  };
  // a026N000001OGZO
  const validateUser = async () => {
    const currentToken = localStorage.getItem('wesnapp-token');
    const contactId = localStorage.getItem('contactId');
    const accountId = localStorage.getItem('accountId');
    if (currentToken === null || contactId === null || accountId === null) {
      if (
        !isLanding() &&
        !history.location.pathname.includes('/demo') &&
        !history.location.pathname.includes('/billinng') &&
        !history.location.pathname.includes('/matching-manually') &&
        !history.location.pathname.includes('/video') &&
        !history.location.pathname.includes('/development/gallery') &&
        !history.location.pathname.includes('/signup/photographer') &&
        !history.location.pathname.includes('/signup/client') &&
        !history.location.pathname.includes('/typeof')
      ) {
        history.push('/login');
      }
      setIsLoading(false);
    } else {
      const data = { token: currentToken, accountId: accountId };
      const result = await axios.post(API_URL + 'login/check-token', data, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (result.data.isValid) {
        loggedIn(result.data.userType);
        setAccountId(accountId);
        setContactId(contactId);
        setToken(currentToken);
      } else {
        notLoggedIn();
      }
    }
  };

  useEffect(() => {
    if (isLanding()) {
      // setIsLoading(false)
      validateUser();
    } else {
      validateUser();
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <div
        className='App'
        dir={theme.direction}
        style={{ width: '100vw', top: 0, left: 0, background: '#F9F8FD' }}
      >
        <CssBaseline>
          {isLoading === true ? (
            <div
              className='loading'
              style={{
                backgroundColor: 'white',
                minHeight: '100vh',
                minWidth: '100vw',
              }}
            >
              <CircularProgress style={{ marginTop: 'calc(50vh - 50px)' }} />
              <div>
                <div
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: mobile ? 25 : 20,
                    padding: mobile ? 20 : 0,
                    color: 'black',
                    marginTop: 20,
                    fontWeight: 'bold',
                  }}
                ></div>
              </div>
            </div>
          ) : (
            // <BrowserRouter forceRefresh={true}>
            <Router history={history}>
              <Switch>
                <PublicRoute
                  restricted={false}
                  component={Demo}
                  landingPage={false}
                  history={history}
                  isLoggedIn={isLoggedIn}
                  path='/demo'
                />
                <PublicRoute
                  restricted={false}
                  component={Video}
                  landingPage={false}
                  history={history}
                  isLoggedIn={isLoggedIn}
                  path='/video/:id'
                />
                <PublicRoute
                  restricted={false}
                  component={Matching}
                  landingPage={false}
                  history={history}
                  isLoggedIn={isLoggedIn}
                  path='/matching-manually/:id'
                />
                <PublicRoute
                  restricted={true}
                  component={Login}
                  landingPage={false}
                  history={history}
                  isLoggedIn={isLoggedIn}
                  path='/login'
                />
                <PublicRoute
                  restricted={false}
                  component={ConfirmRelation}
                  history={history}
                  landingPage={false}
                  isLoggedIn={isLoggedIn}
                  path='/membership/:id'
                />
                <PublicRoute
                  restricted={false}
                  component={DevelopmentGallery}
                  history={history}
                  landingPage={false}
                  isLoggedIn={isLoggedIn}
                  path='/development/gallery/:id'
                />
                <PublicRoute
                  restricted={false}
                  component={Unsubscribe}
                  landingPage={false}
                  isLoggedIn={isLoggedIn}
                  path='/unsubscribe/:id'
                />
                <PublicRoute
                  restricted={false}
                  component={NewOffer}
                  history={history}
                  landingPage={false}
                  isLoggedIn={isLoggedIn}
                  path='/job-offer/:id'
                />
                <PublicRoute
                  restricted={false}
                  component={BmbyOrderPage}
                  history={history}
                  landingPage={true}
                  isLoggedIn={isLoggedIn}
                  path='/bmby'
                />
                <PublicRoute
                  restricted={false}
                  component={OrderMobile}
                  history={history}
                  landingPage={true}
                  isLoggedIn={isLoggedIn}
                  path='/order-page'
                />
                <PublicRoute
                  restricted={false}
                  component={OrderMobile}
                  history={history}
                  landingPage={true}
                  isLoggedIn={isLoggedIn}
                  path='/order'
                />
                <PublicRoute
                  restricted={true}
                  component={OrderHomero}
                  history={history}
                  landingPage={false}
                  isLoggedIn={isLoggedIn}
                  path='/homehero/order'
                />
                <PublicRoute
                  restricted={false}
                  component={SignUpPhotographer}
                  history={history}
                  landingPage={false}
                  isLoggedIn={isLoggedIn}
                  path='/signup/photographer'
                />
                <PublicRoute
                  restricted={true}
                  component={SignUpClient}
                  history={history}
                  landingPage={false}
                  isLoggedIn={isLoggedIn}
                  path='/signup/client'
                />
                <PrivateRoute
                  component={<ClientTemplate history={history} />}
                  isLoggedIn={isLoggedIn}
                  path='/c/dashboard'
                />
                <PrivateRoute
                  component={<PhotographerTemplate history={history} />}
                  isLoggedIn={isLoggedIn}
                  path='/p/dashboard'
                />
                {/* <Route path="/" component={() => <Redirect to={isLoggedIn ? '/c/dashboard' : '/login'} />} />  */}
                <Route path='/new-order'>
                  <Redirect to={'/order'} />
                </Route>
                <Route path='/campaign-photographers'>
                  <Redirect to={'/signup/photographer'} />
                </Route>
              </Switch>
            </Router>
            // </BrowserRouter>
          )}
        </CssBaseline>
      </div>
    </MuiThemeProvider>
  );
};

export default App;
