import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import UploadDialog from './UploadDialog';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
});

export default function SimpleTable(props) {
  const classes = useStyles();

  const [openLoader, setOpenLoader] = React.useState(false);
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);
  const [row, setRow] = React.useState(null);

  const getColorByStatus = (status) => {
    if (status === 'Done') {
      return '#36C5AB';
    }
    if (status === 'Not Approved / Error') {
      return '#EEC300';
    }
    return '#FD612B';
  };

  const getBackgroundColorByStatus = (status) => {
    if (status === 'Done') {
      return 'rgba(54, 197, 171, 0.2)';
    }
    if (status === 'Not Approved / Error') {
      return 'rgba(238, 195, 0, 0.2)';
    }
    return 'rgba(253, 97, 43, 0.2)';
  };

  const getSignedUrl = async (fileName, contentType) => {
    let response = await axios.post(
      'https://wesnapp-node-server.appspot.com/api/storage/get-signed-url',
      { fileName: fileName, contentType: contentType },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    return response.data;
  };

  const onChangeFile = async (e, id) => {
    console.log(e.target.files[0]);
    const files = Array.from(e.target.files);
    let file = files[0];

    setOpenLoader(true);

    let directory = `invoices/${localStorage.getItem(
      'contactId'
    )}/${new Date().getTime()}_`;
    // let signedUrl = await getSignedUrl(directory + 'document.' + file.name.substring(file.name.lastIndexOf(".")+1), file.name.substring(file.name.lastIndexOf(".")+1) === 'pdf' ? `appliction/${file.name.substring(file.name.lastIndexOf(".")+1)}` : `image/${file.name.substring(file.name.lastIndexOf(".")+1)}`)
    let signedUrl = await getSignedUrl(
      directory +
        'document.' +
        file.name.substring(file.name.lastIndexOf('.') + 1)
    );
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedUrl, true);
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        console.log('uploadFinished');
        let url = `https://storage.googleapis.com/wesnapp/${
          directory +
          'document.' +
          file.name.substring(file.name.lastIndexOf('.') + 1)
        }`;
        axios
          .post(
            'https://wesnapp-node-server.appspot.com/api/payment-request/update-request',
            { data: { Link_to_Receipt__c: url }, requestId: id },
            { headers: { 'Content-Type': 'application/json' } }
          )
          .then((response) => {
            var contactId = localStorage.getItem('contactId');
            var accountId = localStorage.getItem('accountId');
            if (contactId != null) {
              props.main.getRelatedRequests(contactId, accountId);
              setOpenLoader(false);
            }
          })
          .catch((error) => {
            console.log(
              'שגיאה במהלך העלאת הקובץ. אנה נסה שוב או צור קשר עם צוות התמיכה',
              error
            );
          });
      } else {
        console.log(xhr);
      }
    };
    xhr.onerror = (e) => {
      console.log(
        'שגיאה במהלך העלאת הקובץ. אנה נסה שוב או צור קשר עם צוות התמיכה',
        e
      );
    };
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  };

  return (
    <div style={{ width: '100%' }}>
      <Backdrop style={{ color: 'white', zIndex: 999 }} open={openLoader}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {openUploadDialog && (
        <UploadDialog
          getData={props.main.componentDidMount}
          open={openUploadDialog}
          setOpen={setOpenUploadDialog}
          onChangeFile={onChangeFile}
          row={row}
        />
      )}
      {/* <TableContainer component={Paper} elevation={0} style={{width: '100%'}}> */}
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Payment #</TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align='left'>
              Date
            </TableCell>
            {/* <TableCell style={{fontWeight: 'bold'}} align="left">Amount</TableCell>
                <TableCell style={{fontWeight: 'bold'}} align="left">VAT</TableCell> */}
            <TableCell style={{ fontWeight: 'bold' }} align='left'>
              Total Amount
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align='left'>
              Invoice
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align='left'>
              Receipt
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align='left'>
              Payment Date
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align='left'>
              Status
            </TableCell>
            {/* <TableCell style={{fontWeight: 'bold'}} align="left">More Details</TableCell> */}
            {/* <TableCell style={{fontWeight: 'bold'}} align="left">Comment</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData.map((row) => (
            <TableRow key={row.id}>
              <TableCell
                component='th'
                id={'labelId'}
                scope='row'
                padding='50'
                style={{ cursor: 'pointer' }}
              >
                <div
                  style={{ color: '#6334FF' }}
                  onClick={() =>
                    props.main.setState({
                      openPaymentRequestDetails: true,
                      detailsTableData: row.moreDetails,
                      prID: row.comment.id,
                    })
                  }
                >
                  <u>#{row.id}</u>
                </div>
              </TableCell>
              <TableCell align='left'>{row.requestDate}</TableCell>
              {/* <TableCell align="left">{row.amount}₪</TableCell>
                    <TableCell align="left">{row.vat}%</TableCell> */}
              <TableCell align='left'>{row.totalAmount}₪</TableCell>
              <TableCell align='left'>
                {row.linkToInvoice != null &&
                row.linkToInvoice.includes('http') ? (
                  <div
                    onClick={() => window.open(row.linkToInvoice, '_blank')}
                    className='linkButton'
                  >
                    Open
                  </div>
                ) : (
                  <div elevation={0} className='linkButton'>
                    Empty
                  </div>
                )}
              </TableCell>
              <TableCell align='left'>
                {row.linkToReceipt != null &&
                row.linkToReceipt.includes('http') ? (
                  <div
                    onClick={() => window.open(row.linkToReceipt, '_blank')}
                    className='linkButton'
                  >
                    Open
                  </div>
                ) : (
                  <div>
                    {/* <input
                                    accept="image/*,.pdf"
                                    className={classes.input}
                                    id="contained-button-file"
                                    // multiple
                                    style={{display: 'none'}}
                                    type="file"
                                    onChange={ (e) => onChangeFile(e, row.docId)}
                                /> */}
                    {/* <label htmlFor="contained-button-file"> */}
                    <Button
                      component='span'
                      onClick={() => {
                        setOpenUploadDialog(true);
                        setRow(row);
                      }}
                      elevation={0}
                      style={{
                        marginTop: 0,
                        textTransform: 'none',
                        height: 34,
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: 17,
                        display: 'inline-block',
                        color: 'white',
                        backgroundColor: 'rgba(253, 97, 43, 0.2)',
                      }}
                      className={classes.button}
                    >
                      <PublishRoundedIcon
                        style={{
                          color: 'rgba(253, 97, 43, 1)',
                          verticalAlign: 'middle',
                          display: 'inline-block',
                          width: 20,
                          height: 20,
                          marginRight: 8,
                          marginTop: -2,
                        }}
                      />
                      <div
                        style={{
                          verticalAlign: 'middle',
                          display: 'inline-block',
                          color: 'rgba(253, 97, 43, 1)',
                        }}
                      >
                        <b>Upload</b>
                      </div>
                    </Button>
                    {/* </label> */}
                  </div>
                )}
              </TableCell>
              <TableCell align='left'>{row.paymentDate}</TableCell>
              <TableCell align='left' width={220}>
                {/* <Button elevation={0} style={{  marginTop: 0, textTransform: 'none', height: 34, paddingLeft: 15, paddingRight: 15, borderRadius: 17, display: 'inline-block', color: 'white', backgroundColor: getColorByStatus(row.status) }} className={classes.button}> */}
                {/* <div style={{color: getColorByStatus(row.status)}}>{row.status}</div> */}
                {/* </Button> */}
                {row.linkToReceipt != null &&
                row.linkToReceipt.includes('http') ? (
                  <Button
                    elevation={0}
                    style={{
                      marginTop: 0,
                      textTransform: 'none',
                      height: 34,
                      paddingLeft: 15,
                      paddingRight: 15,
                      borderRadius: 17,
                      display: 'inline-block',
                      color: '#36C5AB',
                      backgroundColor: getBackgroundColorByStatus(row.status),
                      fontWeight: 'bold',
                    }}
                  >
                    <b
                      style={{
                        color: getColorByStatus(row.status),
                        opacity: 1,
                      }}
                    >
                      {row.status === 'Approved / Payment Queue'
                        ? 'Approved'
                        : row.status === 'Receipt Pending for Approval'
                        ? 'Pending for Approval'
                        : row.status}
                    </b>
                  </Button>
                ) : (
                  <Button
                    elevation={0}
                    style={{
                      marginTop: 0,
                      textTransform: 'none',
                      height: 34,
                      paddingLeft: 15,
                      paddingRight: 15,
                      borderRadius: 17,
                      display: 'inline-block',
                      color: '#36C5AB',
                      backgroundColor: getBackgroundColorByStatus(''),
                      fontWeight: 'bold',
                    }}
                  >
                    <b style={{ color: getColorByStatus(''), opacity: 1 }}>
                      Pending
                    </b>
                  </Button>
                )}
              </TableCell>
              {/* <TableCell align="left"> */}
              {/* {row.moreDetails} */}
              {/* {console.log(row.details)} */}
              {/* <Button onClick={() => props.main.setState({openPaymentRequestDetails: true, detailsTableData: row.moreDetails, prID: row.comment.id})} elevation={0} style={{  marginTop: 0, textTransform: 'none', height: 34, paddingLeft: 15, paddingRight: 15, borderRadius: 17, display: 'inline-block', color: 'white', backgroundColor: '#6334FF'}} className={classes.button}>
                            <b>Details</b>
                        </Button> */}
              {/* </TableCell> */}
              {/* <TableCell align="left">
                        <Button onClick={() => props.main.setState({commentText: row.comment.text, commentId: row.comment.id, commentDialogOpen: true})} elevation={0} style={{marginTop: 0, textTransform: 'none', height: 34, paddingLeft: 15, paddingRight: 15, borderRadius: 17, display: 'inline-block', color: 'white', backgroundColor: '#6334FF'}} className={classes.button}>
                            <b>Add Comment</b>
                        </Button>
                    </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* </TableContainer> */}
    </div>
  );
}
