import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Switch from '@material-ui/core/Switch';
import EquipmentsCamerasAndLenses from './EquipmentsCamerasAndLenses';
import Equipments3D from './Equipments3D';
import EquipmentsDrones from './EquipmentsDrones';
import EquipmentsCategory from './EquipmentsCategory';
import SmsDialog from './SmsDialog';
import Location from './Location';
import Autocomplete from 'react-google-autocomplete';
import uuid from 'react-native-uuid';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import MuiPhoneNumber from 'material-ui-phone-number';
import history from '../../../utils/history';
import { withRouter } from 'react-router-dom';
import { AuthContext } from '../../../contexts/Auth';
import { phone } from 'phone';
// import '../../App.css';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F9F8FD',
    width: '100%',
    // overflowY: 'scroll',
    // overflowX: 'hidden',
    // maxWidth: '100vw',
    // height: '100%',
    // paddingBottom: 70
    // overflowX: 'hidden',
    // maxWidth: '100vw',

    // overflowY: 'scroll',
    // position: 'fixed',
    //  left: 0
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    height: 50,
    marginTop: 30,
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#ECF1FF',
    },
  },
});

class NewsPage extends React.Component {
  static contextType = AuthContext;

  state = {
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    companyName: '',
    companyNumber: '',
    portfolio: '',
    companyType: '0',
    salutation: '',
    comment: '',
    ip: '',
    eqType: 0,
    isGroup: true,
    acceptTerms: false,
    promoCode: '',
    cameras: [],
    isLoading: false,
    drones: [],
    lenses: [],
    trippleD: [],
    error_firstName: '',
    error_lastName: '',
    error_address: '',
    error_group: '',
    error_companyName: '',
    error_companyNumber: '',
    error_companyType: '',
    error_comment: '',
    error_acceptTerms: '',
    error_phone: '',
    error_email: '',
    phone: '',
    notInList: false,
    smsDialog: false,
    countryCode: '972',
    inputCode: '',
    errorCode: false,
    code: '',
    formattedAddress: '',
    lat: '',
    lng: '',
    registrationStatus: '',
    accountId: null,
    contactId: null,
  };

  startLoading = () => {
    this.setState({ isLoading: true });
  };

  stopLoading = () => {
    this.setState({ isLoading: false });
  };

  componentDidMount() {
    this.checkIfUserExists();
    this.getUserIP();
    // this.props.startLoading()
  }

  validateMobile = (phonee) => {
    return phone(this.state.phone, { validateMobilePrefix: false }).isValid;
    // var substring1 = " 9725"
    // var substring2 = " 97205"
    // if (phone.includes(substring1) || phone.includes(substring2)) {
    //   // console.log("@@");
    //   if (phone.indexOf(substring1) !== -1) {
    //     // console.log(phone);
    //     phone = phone.replace(" 9725", "05");
    //     phone = phone.replace("-", "");
    //     if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "3" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3")) {
    //       if (phone.length == 10 && (/^\d+$/.test(phone))) {
    //         this.setState({phone: phone})
    //         return true
    //       }
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     phone = phone.replace(" 97205", "05");
    //     phone = phone.replace("-", "");
    //     // console.log(phone);
    //     if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "3" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3")) {
    //       if (phone.length == 10 && (/^\d+$/.test(phone))) {
    //         this.setState({phone: phone})
    //         return true
    //       }
    //     } else {
    //       return false;
    //     }
    //   }
    // } else {
    //   phone = phone.replace("-", "");
    //   if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "3" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3")) {
    //     if (phone.length == 10 && (/^\d+$/.test(phone))) {
    //       this.setState({phone: phone})
    //       return true
    //     }
    //   } else {
    //     return false;
    //   }
    // }
  };

  handleChangeSwitch = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  getUserIP = () => {
    axios
      .get(
        'https://httpbin.org/ip',
        {},
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        // console.log("IP");
        var ip = response.data.origin.split(',')[0];
        // console.log(ip);
        this.setState({ ip: ip });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  handleChangeText = (name) => (event) => {
    // console.log("@@");
    // console.log( event.target.value);
    this.setState({ [name]: event.target.value });
  };

  handleChangeLocation = (value) => {
    // console.log(value.description);
    this.setState({ address: value.description });
  };

  handleChangeSalutation = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  registerFinished = (accountId, contactId) => {
    this.props.main.setState({ completed: true });
    console.log(accountId);
    console.log(contactId);
    localStorage.setItem('accountId', accountId);
    localStorage.setItem('contactId', contactId);
    var token = uuid.v1();
    this.updateToken(token, accountId);
  };

  registerFailed = (data) => {
    this.props.handleOpenAlert('Oops, something went wrong!');
  };

  saveUUID = (token, accountId) => {
    var data = { id: accountId };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/login/save-token',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        const cookies = new Cookies();
        cookies.set('wesnapp-token', response.data.token, { path: '/' });
        window.location.reload();
      })
      .catch((error) => {
        window.location.reload();
        //console.log(error);
      });
  };

  checkEquipment = () => {
    var allow = true;
    if (this.state.cameras.length > 0) {
      if (this.state.comment !== '' || this.state.lenses.length > 0) {
        return true;
      } else {
        if (
          this.state.drones.length !== 0 ||
          this.state.trippleD.length !== 0
        ) {
          return true;
        } else {
          if (this.state.comment !== '') {
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      if (this.state.drones.length > 0 || this.state.trippleD.length > 0) {
        return true;
      } else {
        if (this.state.comment !== '') {
          return true;
        } else {
          return false;
        }
      }
    }
    // if (this.state.cameras.length == 0 && this.state.lenses.length == 0) {
    //   if (this.state.comment == "") {
    //     return true
    //   } else {
    //     return false
    //   }
    // } else {
    //   return false
    // }
  };

  checkGroup = () => {
    if (this.state.isGroup) {
      if (
        this.state.companyName == '' ||
        this.state.companyNumber == '' ||
        this.state.companyType == '0'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  registerClicked = () => {
    // this.props.startLoading()
    var allowToContinue = true;
    this.setState({
      error_phone: '',
      error_firstName: '',
      error_lastName: '',
      error_email: '',
      error_address: '',
      error_group: '',
      error_acceptTerms: '',
      error_comment: '',
    });
    if (this.state.firstName == '') {
      this.setState({ error_firstName: 'First name empty' });
      console.log('first name');
      allowToContinue = false;
    }
    if (this.state.lastName == '') {
      console.log('last name');
      this.setState({ error_lastName: 'Last name empty' });
      allowToContinue = false;
    }
    if (this.state.email == '') {
      console.log('email');
      this.setState({ error_email: 'Email empty' });
      allowToContinue = false;
    } else {
      if (!this.validateEmail(this.state.email)) {
        this.setState({ error_email: 'Email is not valid' });
        console.log('email2');
        allowToContinue = false;
      }
    }
    if (!this.validateMobile(this.state.phone)) {
      this.setState({ error_phone: 'Error phone number' });
      console.log('mobile');
      allowToContinue = false;
    }
    if (
      this.state.formattedAddress === '' ||
      this.state.lat === '' ||
      this.state.lng === ''
    ) {
      this.setState({
        error_address: "We wasn't able to find the locations. Check again",
      });
      console.log('address');
      allowToContinue = false;
    }
    // if (!this.props.campaign) {
    //   if (this.checkGroup()) {
    //     this.setState({error_group: "Please fill all the details about your company"})
    //     allowToContinue = false
    //   }
    // }
    if (!this.checkEquipment()) {
      console.log('eq');
      this.setState({
        error_comment:
          'Please choose a least 1 camera and 1 lens / Drone / 3D Camera or describe your equipment bellow',
      });
      allowToContinue = false;
    }
    if (this.state.acceptTerms == false) {
      console.log('terms');
      this.setState({ error_acceptTerms: 'Accept our Terms & Conditions' });
      allowToContinue = false;
    }
    // if (!this.props.campaign) {
    //   if (this.state.acceptTerms == false) {
    //     this.setState({error_acceptTerms: "Accept the Terms & Conditions"})
    //     allowToContinue = false
    //   }
    // }
    if (allowToContinue) {
      this.continueToVerification();
    } else {
      // alert('no')
    }
  };

  getCompanyType = (id) => {
    switch (id) {
      case '0':
        return 'Ltd';
        break;
      case '1':
        return 'SOHO';
        break;
      case '2':
        return 'Society';
        break;
      default:
        return 'Society';
    }
  };

  getSFDateTime = () => {
    return moment(new Date()).format('YYYY-MM-DDTh:mm:ss') + '.000+0200';
  };

  updateEquipment = async () => {
    var equipmentToTransfer = this.state.cameras
      .concat(this.state.lenses)
      .concat(this.state.drones)
      .concat(this.state.trippleD);
    var equipment = [];
    for (var i = 0; i < equipmentToTransfer.length; i++) {
      equipment.push(equipmentToTransfer[i].value);
    }
    var data = {
      id: this.state.accountId,
      equipment: equipment,
    };
    let response = await axios.post(
      'https://wesnapp-node-server.appspot.com/api/photographer/update-eq',
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
    var text =
      '⚠️ Old photographer <' +
      'https://wesnapp.lightning.force.com/lightning/r/Account/' +
      this.state.accountId +
      '/view' +
      '|' +
      this.state.firstName +
      ' ' +
      this.state.lastName +
      '> changed his equipment in when he tried to signup';
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/slack/photographer-notification',
        { text: text },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {})
      .catch((error) => {});
    return 'finished';
  };

  updateAccountDetails = async () => {
    var data = {
      id: localStorage.getItem('accountId'),
      data: {
        FirstName: this.state.firstName,
        LastName: this.state.lastName,
        PersonEmail: this.state.email,
        Portfolio_link__pc: this.state.portfolio,
        BillingCity: this.state.city,
        BillingStreet: this.state.street,
        BillingCountry: this.state.country,
        Last_Confirmed_Settings__c: this.getSFDateTime(),
      },
    };
    let response = await axios.post(
      'https://wesnapp-node-server.appspot.com/api/photographer/update-profile',
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
    var text =
      '⚠️ Old photographer <' +
      'https://wesnapp.lightning.force.com/lightning/r/Account/' +
      this.state.accountId +
      '/view' +
      '|' +
      this.state.firstName +
      ' ' +
      this.state.lastName +
      '> changed his personal details in when he tried to signup';
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/slack/photographer-notification',
        { text: text },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {})
      .catch((error) => {});
    return 'finished';
  };

  updatePhotographer = async () => {
    this.startLoading();
    await this.updateEquipment();
    await this.updateAccountDetails();
    this.registerFinished(this.state.accountId, this.state.contactId);
  };

  registerPhotographer = () => {
    this.startLoading();
    var companyType = '';
    if (!this.props.campaign) {
      if (this.state.isGroup == true) {
        companyType = this.getCompanyType(this.state.companyType);
      }
    }
    var equipmentsToTransfer = this.state.cameras
      .concat(this.state.lenses)
      .concat(this.state.drones)
      .concat(this.state.trippleD);
    var equipments = [];
    for (var i = 0; i < equipmentsToTransfer.length; i++) {
      equipments.push(equipmentsToTransfer[i].value);
    }
    let phoneNumber = phone(this.state.phone, {
      validateMobilePrefix: false,
    }).phoneNumber;
    phoneNumber = phoneNumber
      .replace(/-/g, '')
      .replace('(', '')
      .replace(')', '')
      .replace(/ /g, '');
    var data = {
      salutation: this.state.salutation,
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      name: this.state.firstName + ' ' + this.state.lastName,
      email: this.state.email,
      mobile: this.state.phone
        .replace('+9725', '050')
        .replace('+97205', '050')
        .replace('+1', '')
        .replace('+44', '')
        .replace('+33', '')
        .replace(/-/g, '')
        .replace('(', '')
        .replace(')', '')
        .replace(/ /g, ''),
      formattedMobile: phoneNumber,
      country: this.state.country,
      city: this.state.city,
      street: this.state.street,
      another_equipment: this.state.comment,
      company_legal_name: this.state.companyName,
      company_registration_number: this.state.companyNumber,
      lat: this.state.lat,
      lng: this.state.lng,
      recruit_status: 'Registered',
      equipments: equipments,
      portfolio_link: this.state.portfolio,
      isGroup: false,
      acceptTerms: false,
      ip: this.state.ip,
      Last_Confirmed_Settings__c: this.getSFDateTime(),
      Welcome_Email_sent_successfully__c: true,
    };
    console.log(data);
    // axios.post('http://localhost:8080/api/photographer/campaign-register',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/photographer/campaign-register',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        if (response.data.constructor === Object) {
          // console.log("inside");
          if (response.data.success === 'registeration completed.') {
            // this.props.history.push(window.location.pathname + '/completed')
            this.props.main.setState({ registrationStatus: 'ok' });
            this.registerFinished(
              response.data.accountId,
              response.data.contactId
            );

            // if (window.location.pathname.includes('signup/photographer')) {
            //   this.registerFinished(response.data.accountId, response.data.contactId)
            // } else {
            //   this.stopLoading()
            // }
          } else {
            // this.stopLoading()
            // // this.registerFailed(response.data)
            // this.props.main.setState({registrationStatus: 'not-ok'})
          }
        } else {
          // this.stopLoading()
          // // this.registerFailed(response.data)
          // this.props.main.setState({registrationStatus: 'not-ok'})
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  generateRandomCode = () => {
    var code = Math.floor(1000 + Math.random() * 9000);
    return code;
  };

  continueToVerification = () => {
    // alert('x')
    this.setState({ smsDialog: true });
    this.setState({ code: this.generateRandomCode() }, () => {
      this.sendVerificationCode();
    });
  };

  reverseString = (str) => {
    // Step 1. Use the split() method to return a new array
    var splitString = str.split(''); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    var joinArray = reverseArray.join(''); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    //Step 4. Return the reversed string
    return joinArray; // "olleh"
  };

  verifyCode = () => {
    this.setState({ errorCode: false });
    console.log(String(this.state.code));
    console.log(String(this.state.inputCode));
    if (
      String(this.state.code) === String(this.state.inputCode) ||
      String(this.state.code) ===
        this.reverseString(String(this.state.inputCode))
    ) {
      this.setState({ smsDialog: false }, () => {
        if (this.state.accountId && this.state.contactId) {
          this.updatePhotographer();
        } else {
          this.registerPhotographer();
        }
      });
    } else {
      this.setState({ errorCode: true });
    }
  };

  sendVerificationCode = () => {
    let phoneNumber = this.state.phone;
    phoneNumber = phoneNumber
      .replace(/-/g, '')
      .replace('(', '')
      .replace(')', '')
      .replace(/ /g, '');
    var data = {
      phone: phoneNumber,
      text: 'WeSnapp verification code: ' + this.state.code,
    };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/sms/send-message',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        //console.log(error);
      });
    // Check if user exists
    this.checkIfUserExists();
  };

  checkIfUserExists = async () => {
    let phoneNumber = phone(this.state.phone, {
      validateMobilePrefix: false,
    }).phoneNumber;
    if (!phoneNumber) phoneNumber = '';
    phoneNumber = phoneNumber
      .replace(/-/g, '')
      .replace('(', '')
      .replace(')', '')
      .replace(/ /g, '');
    let data = {
      phone: phoneNumber,
    };
    console.log('data', data);
    let response = await axios.post(
      'https://wesnapp-node-server.appspot.com' +
        '/api/contact/find-account-by-phone-number',
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
    console.log('response', response);
    if (response.data.length > 0) {
      this.setState({
        accountId: response.data[0].Id,
        contactId: response.data[0].PersonContactId,
      });
      console.log('response.data[0].Id', response.data[0].Id);
      console.log(
        'response.data[0].PersonContactId',
        response.data[0].PersonContactId
      );
    }
  };

  handleGroupOrNot = (e) => {
    this.setState({ isGroup: e.target.checked });
  };

  handleChangeTypeOfCompany = (e) => {
    this.setState({ companyType: e.target.value });
  };

  acceptTermsAndConditions = (e) => {
    this.setState({ acceptTerms: e.target.checked });
  };

  getStreet = (addressComponents) => {
    for (let index = 0; index < addressComponents.length; index++) {
      const addressComponent = addressComponents[index];
      if (addressComponent.types.includes('route')) {
        return addressComponent.long_name;
      }
    }
    return '';
  };

  getCity = (addressComponents) => {
    for (let index = 0; index < addressComponents.length; index++) {
      const addressComponent = addressComponents[index];
      if (addressComponent.types.includes('locality')) {
        return addressComponent.long_name;
      }
    }
    return '';
  };

  getCountry = (addressComponents) => {
    for (let index = 0; index < addressComponents.length; index++) {
      const addressComponent = addressComponents[index];
      if (addressComponent.types.includes('country')) {
        return addressComponent.long_name;
      }
    }
    return '';
  };

  getCoordinates = async (address) => {
    console.log(address);
    try {
      var link =
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        encodeURI(address) +
        '&key=AIzaSyAaiPKKuOKyFcl_Hed_xF_1Jx9oQiKJu_s';
      const response = await axios.get(link);
      console.log(response.data.results[0]);
      if (response.data.results.length > 0) {
        if (
          response.data.results[0].types.includes('street_address') ||
          response.data.results[0].types.includes('route') ||
          response.data.results[0].types.includes('geocode')
        ) {
          var address = response.data.results[0].formatted_address.split(', ');

          let addressComponents = response.data.results[0].address_components;
          var street = this.getStreet(addressComponents);
          var city = this.getCity(addressComponents);
          var country = this.getCountry(addressComponents);
          console.log(address);

          this.setState(
            {
              formattedAddress: address,
              error_address: '',
              lat: response.data.results[0].geometry.location.lat,
              lng: response.data.results[0].geometry.location.lng,
              city: city,
              street: street,
              country: country,
            },
            () => {}
          );
        } else {
          this.setState({
            error_address:
              'Please choose specific address (including street number)',
          });
        }
      } else {
        this.setState({
          error_address:
            "Error 1: We wasn't able to find the locations. Check again",
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        error_address:
          "Error 2: We wasn't able to find the locations. Check again",
      });
    }
  };

  updateToken = (token, accountId) => {
    var data = { token: token, accountId: accountId };
    axios
      .post(
        'https://wesnapp-node-server.appspot.com' + '/api/contact/update-token',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        if (response.data === true) {
          localStorage.setItem('wesnapp-token', token);
          this.context.setIsLoggedIn(true);
          this.context.setAccountType('photographer');
          this.props.main.setState({ auth: true }, () => {
            history.push('/p/dashboard/photo-shoots');
            this.stopLoading();
          });
        } else {
          // this.setState({auth: false})
        }
      })
      .catch((error) => {});
  };

  handleChangePhone = (value) => {
    console.log('phone', value);
    if (value) {
      this.setState({ phone: value });
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <SmsDialog main={this} />
        {this.state.isLoading === true && (
          <div
            className='loading'
            style={{
              backgroundColor: 'rgba(255,255,255,0.8)',
              minWidth: '100vw',
              height: '100vh',
              backdropFilter: 'blur(4px)',
            }}
          >
            <CircularProgress style={{ marginTop: 'calc(50vh - 50px)' }} />
            <div>
              <div
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: mobile ? 25 : 20,
                  padding: mobile ? 20 : 0,
                  color: 'black',
                  marginTop: 20,
                }}
              >
                Please wait, the process may take a few seconds
              </div>
            </div>
          </div>
        )}
        <Grid container spacing={2} dir='ltr'>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              variant='standard'
              label='First Name'
              onChange={this.handleChangeText('firstName')}
              error={this.state.error_firstName !== ''}
              helperText={this.state.error_firstName}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              variant='standard'
              label='Last Name'
              onChange={this.handleChangeText('lastName')}
              error={this.state.error_firstName !== ''}
              helperText={this.state.error_firstName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {console.log('this.state.error_email', this.state.error_email)}
            <TextField
              variant='standard'
              label='Email'
              type={'email'}
              onChange={this.handleChangeText('email')}
              error={this.state.error_email !== ''}
              helperText={this.state.error_email}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MuiPhoneNumber
              style={{ width: '100%' }}
              name='phone'
              disableAreaCodes={true}
              label='Phone Number'
              data-cy='user-phone'
              onlyCountries={['il', 'us', 'fr', 'gb']}
              defaultCountry={'il'}
              value={this.state.phone}
              onChange={this.handleChangePhone}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Location errorLocation={this.state.error_address} main={this} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant='standard'
              label='Portfolio link (Website / Instagram / Facebook)'
              onChange={this.handleChangeText('portfolio')}
              error={this.state.error_firstName !== ''}
              helperText={this.state.error_firstName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <EquipmentsCategory main={this} />
          </Grid>
          {this.state.eqType == 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <EquipmentsCamerasAndLenses
                style={{ marginTop: 0 }}
                main={this}
              />
            </Grid>
          )}
          {this.state.eqType == 1 && (
            <Grid item xs={12} sm={12} md={12}>
              <EquipmentsDrones style={{ marginTop: 0 }} main={this} />
            </Grid>
          )}
          {this.state.eqType == 2 && (
            <Grid item xs={12} sm={12} md={12}>
              <Equipments3D style={{ marginTop: 0 }} main={this} />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant='standard'
              label='Add equipment which is not in the list'
              onChange={this.handleChangeText('comment')}
              error={this.state.error_comment !== ''}
              helperText={this.state.error_comment}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={this.state.acceptTerms}
                onChange={this.acceptTermsAndConditions}
                name='acceptTerms'
                style={{ marginLeft: -9 }}
              />
              <label style={{ marginLeft: 9, fontSize: 14, textAlign: 'left' }}>
                I accept the{' '}
                <a
                  target='_blank'
                  href='https://www.wesnapp.io/en/terms-conditions/'
                >
                  <u style={{ color: '#835CFF' }}>Terms and Conditions</u>
                </a>{' '}
                and the{' '}
                <a
                  target='_blank'
                  href='https://www.wesnapp.io/en/privacy-policy-2/'
                >
                  <u style={{ color: '#835CFF' }}>Privacy Policy</u>
                </a>
              </label>
            </div>
            <div
              style={{
                marginLeft: 0,
                float: 'left',
                color: 'red',
                marginTop: 3,
                textAlign: 'left',
              }}
            >
              {this.state.error_acceptTerms}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              id={'register_ph'}
              onClick={() => this.registerClicked()}
              elevation={0}
              style={{
                textTransform: 'none',
                marginTop: 4,
                marginBottom: 0,
                height: 50,
                width: 150,
                borderRadius: 25,
                float: 'left',
                textColor: 'white',
                color: 'white',
                background: '#835cff',
                background:
                  'linear-gradient(256.79deg, #6852ED 40.49%, #BA43FC 100%)',
                boxShadow: 'rgba(114, 82, 240, 0.6) 0px 8px 20px 0px',
              }}
              className={classes.button}
            >
              <b>Register</b>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <Link to='/login'>
                <div
                  color='primary'
                  style={{
                    marginTop: 24,
                    marginBottom: 16,
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontSize: 16,
                    width: '100%',
                    color: 'black',
                    textAlign: 'left',
                  }}
                >
                  <u>Already have an account? Sign in now!</u>
                </div>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(NewsPage));
