import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import '../../../../../App.css';
import Slide from '@material-ui/core/Slide';
import Dropzone from '../Dropzone/Dropzone';
import LazyLoad from 'react-lazyload';
import 'react-image-lightbox/style.css';
import { withSnackbar } from 'notistack';
import ImageInUpload from './Original/ImageInUpload';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

const BASE_URL = 'https://py.wesnapp.co.il/server/upload/';

const vIcon = require('../../../../../../src/Assets/vIcon.png');

function CircularProgressWithLabel(props) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress
        style={{ width: 180, height: 180, marginTop: 0 }}
        variant='determinate'
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='caption'
          component='div'
          style={{ fontSize: 26, marginTop: 0 }}
          color='textSecondary'
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: 10,
  },
});

class Upload3Exposures extends React.Component {
  state = {
    urls: [],
    files: [],
    finishLoading: false,
    finishUploading: false,
    exposure: parseInt(this.props.exposure),
    showUploadingDialog: false,
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  componentDidMount() {
    console.log(this.getDateAndTime());
  }

  handleChangeFiles(files) {
    if (files.length > 0) {
      this.setState({ finishLoading: false });
      var selectedFiles = this.state.files;
      var counter = 0;
      for (var i = 0; i < files.length; i++) {
        var reader = new FileReader();
        var file = files[i];
        reader.onload = (e) => {
          selectedFiles.push({
            data: e.target.result,
            file: files[counter],
            size: files[counter].size,
            percentCompleted: 0,
            url: '',
          });
          counter += 1;
          if (counter == files.length) {
            if (counter % this.state.exposure != 0) {
            }
            var sortedFiles = selectedFiles.sort(this.naturalCompare);
            this.setState(
              {
                files: sortedFiles,
                finishUploading: false,
              },
              () => {
                console.log('DONE');
                this.uploaderImages();
                this.setState({ finishLoading: false });
              }
            );
          }
        };
        if (!this.checkIfFileExisting(file, selectedFiles)) {
          reader.readAsDataURL(file);
        }
      }
    }
  }

  naturalCompare(a, b) {
    var ax = [],
      bx = [];
    a.file.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      ax.push([$1 || Infinity, $2 || '']);
    });
    b.file.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      bx.push([$1 || Infinity, $2 || '']);
    });
    while (ax.length && bx.length) {
      var an = ax.shift();
      var bn = bx.shift();
      var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
      if (nn) return nn;
    }
    return ax.length - bx.length;
  }

  naturalCompareByUrl(a, b) {
    var ax = [],
      bx = [];
    a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      ax.push([$1 || Infinity, $2 || '']);
    });
    b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      bx.push([$1 || Infinity, $2 || '']);
    });
    while (ax.length && bx.length) {
      var an = ax.shift();
      var bn = bx.shift();
      var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
      if (nn) return nn;
    }
    return ax.length - bx.length;
  }

  checkIfFileExisting = (file, files) => {
    for (var i = 0; i < files.length; i++) {
      if (files[i].file.name == file.name) {
        return true;
      }
    }
    return false;
  };

  getDateAndTime = () => {
    var newdate = '';
    var event = new Date();
    var e = event.toLocaleString('IST', { timeZone: 'Asia/Jerusalem' });
    var newdateForSalesforce = e;
    var date = e.split(',')[0];
    var date_ = date.split('/');
    var month = date_[0];
    var day = date_[1];
    var year = date_[2];
    if (String(month).length < 2) {
      month = '0' + String(month);
    }
    if (String(day).length < 2) {
      day = '0' + String(day);
    }
    newdate = year + '-' + month + '-' + day;
    console.log(newdate);
    var time = e.split(',')[1];
    time = time.replace(' ', '');
    var times = time.split(':');
    var hour = times[0];
    console.log(times[2].includes('PM'));
    if (times[2].includes('PM')) {
      if (hour === '1') {
        hour = '13';
      }
      if (hour === '2') {
        hour = '14';
      }
      if (hour === '3') {
        hour = '15';
      }
      if (hour === '4') {
        hour = '16';
      }
      if (hour === '5') {
        hour = '17';
      }
      if (hour === '6') {
        hour = '18';
      }
      if (hour === '7') {
        hour = '19';
      }
      if (hour === '8') {
        hour = '20';
      }
      if (hour === '9') {
        hour = '21';
      }
      if (hour === '10') {
        hour = '22';
      }
      if (hour === '11') {
        hour = '23';
      }
      if (hour === '12') {
        hour = '12';
      }
    }
    var minutes = times[1];
    var seconds = times[2].replace('AM', '').replace('PM', '').replace(' ', '');
    var time_full = hour + ':' + minutes + ':' + seconds;
    console.log(time_full);

    var newdateForSalesforce = newdate + 'T' + time_full + '+0200';
    return newdateForSalesforce;
  };

  uploaderImages = () => {
    this.setState({ showUploadingDialog: true });
    var counter = 0;
    var files = this.state.files;
    if (files.length % this.state.exposure != 0) {
      // this.handleAlert()
    }
    if (files.length > 0) {
      if (files.length === 1) {
        this.uploadImageFunction(counter);
      } else if (files.length === 2) {
        this.uploadImageFunction(counter);
        this.uploadImageFunction(counter + 1);
      } else if (files.length === 3) {
        this.uploadImageFunction(counter);
        this.uploadImageFunction(counter + 1);
        this.uploadImageFunction(counter + 2);
      } else if (files.length === 4) {
        this.uploadImageFunction(counter);
        this.uploadImageFunction(counter + 1);
        this.uploadImageFunction(counter + 2);
        this.uploadImageFunction(counter + 3);
      } else if (files.length === 5) {
        this.uploadImageFunction(counter);
        this.uploadImageFunction(counter + 1);
        this.uploadImageFunction(counter + 2);
        this.uploadImageFunction(counter + 3);
        this.uploadImageFunction(counter + 4);
      } else if (files.length >= 6) {
        this.uploadImageFunction(counter);
        this.uploadImageFunction(counter + 1);
        this.uploadImageFunction(counter + 2);
        this.uploadImageFunction(counter + 3);
        this.uploadImageFunction(counter + 4);
        this.uploadImageFunction(counter + 5);
      }
    } else {
      alert('All images are uploaded successfully.');
    }
  };

  getSignedUrl = async (fileName, contentType) => {
    let response = await axios.post(
      'https://wesnapp-node-server.appspot.com/api/storage/get-signed-url',
      { fileName: fileName, contentType: contentType },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    return response.data;
  };

  updateRequestStatus = () => {
    let uploadedExposures = this.props.request.Type_Of_Upload__c;
    if (uploadedExposures === null) uploadedExposures = '';
    if (uploadedExposures == '') {
      uploadedExposures = uploadedExposures + '3';
    } else {
      uploadedExposures = uploadedExposures + ';3';
    }
    axios
      .post(
        'https://wesnapp-node-server.appspot.com/api/request/update-request',
        {
          requestId: this.props.request.Id,
          data: {
            Status__c: 'Images Uploaded',
            Original_Image_Upload_Date_Time__c: this.getDateAndTime(),
            Type_Of_Upload__c: uploadedExposures,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
        this.props.fetchImages();
      });
  };

  uploadImageFunction = async (counter) => {
    this.updateRequestStatus();
    var files = this.state.files;
    const file = files[counter];
    let directory = `shootings/${this.props.requestId}/originals/bracketing/${this.props.exposure}Exposures/`;
    let signedUrl = await this.getSignedUrl(
      directory + file.file.name,
      file.file.type
    );
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedUrl, true);
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        console.log('counter', counter);
        console.log('length', files.length);
        if (counter === this.state.files.length - 1) {
          // this.props.getNewImagesAfterUpload()
          // this.props.startAT()
          // this.props.showAlertMessage('success', 'Images successfully uploaded')
        } else {
          if (counter + 6 < files.length) {
            this.uploadImageFunction(counter + 6);
          }
        }
      } else {
        console.log(xhr);
      }
    };

    xhr.onerror = (e) => {
      console.log('Something went wrong', e);
    };
    xhr.setRequestHeader('Content-Type', file.file.type);
    xhr.upload.addEventListener(
      'progress',
      this.uploadProgress(counter),
      false
    );
    xhr.send(file.file);
  };

  getImageName = (image) => {
    return image.file.name;
  };

  uploadProgress = (id) => {
    return (progress) => {
      var arrayOfFiles = this.state.files;
      var percentCompleted = Math.round(
        (progress.loaded * 100) / progress.total
      );
      arrayOfFiles[id].percentCompleted = percentCompleted;
      this.setState({ files: arrayOfFiles }, () => {
        if (this.howMuchUploadedCompleted() === this.state.files.length) {
          setTimeout(() => {
            this.props.getNewImagesAfterUpload();
            this.props.startAT(false);
            this.props.showAlertMessage(
              'success',
              'Images successfully uploaded'
            );
            this.setState({ showUploadingDialog: false });
          }, 12000);
        }
      });
    };
  };

  getNameFromUrl = (item) => {
    var splitedUrl = item.url.split('/');
    return splitedUrl[splitedUrl.length - 1];
  };

  showBackdrop = () => {
    for (let index = 0; index < this.state.files.length; index++) {
      const file = this.state.files[index];
      if (file.percentCompleted !== 100) return true;
    }
    return false;
  };

  howMuchUploadedCompleted = () => {
    let counter = 0;
    let totalPercentages = 0;
    for (let index = 0; index < this.state.files.length; index++) {
      const file = this.state.files[index];
      if (file.percentCompleted === 100) {
        counter += 1;
      }
    }
    return counter;
  };

  howMuchUploaded = () => {
    // let counter = 0
    let totalPercentages = 0;
    for (let index = 0; index < this.state.files.length; index++) {
      const file = this.state.files[index];
      console.log('fff', file.percentCompleted);
      totalPercentages += file.percentCompleted;
      // if (file.percentCompleted === 100) {
      //   counter += 1
      // }
    }
    return (totalPercentages / (this.state.files.length * 100)) * 100;
    // return counter
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        {this.state.files && (
          <Backdrop
            open={this.state.showUploadingDialog}
            style={{ zIndex: 9999 }}
          >
            <div
              style={{
                background: 'white',
                borderRadius: 16,
                padding: '32px 64px',
                position: 'relative',
              }}
            >
              <Typography
                style={{
                  marginBottom: 32,
                  textAlign: 'left',
                  fontSize: 22,
                  width: '100%',
                  position: 'absolute',
                  left: 16,
                }}
              >
                Uploading images {this.howMuchUploadedCompleted()}/
                {this.state.files.length}
              </Typography>
              <LinearProgress
                style={{
                  width: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 82,
                }}
              />
              <div style={{ height: 82, width: '100%' }}></div>
              {this.howMuchUploadedCompleted() === this.state.files.length && (
                <div
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                    color: '#111111',
                    fontSize: 16,
                    marginTop: -12,
                  }}
                >
                  Processing images...
                </div>
              )}
              <CircularProgressWithLabel value={this.howMuchUploaded()} />
            </div>
          </Backdrop>
        )}
        <Grid container spacing={24} dir='ltr'>
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <Dropzone
                onFilesAdded={this.handleChangeFiles.bind(this)}
                exposure={this.props.exposure}
              />
              <div style={{ marginTop: 0, width: '100%', marginLeft: -2.5 }}>
                {this.state.files.length > 0 && (
                  <div>
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: 20,
                        marginBottom: 20,
                        marginTop: 15,
                        textAlign: 'left',
                        color: '#818BA6',
                      }}
                    >
                      {this.state.files.length} New images
                    </div>
                    {this.state.files.map((item, i) => (
                      <ImageInUpload item={item} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Upload3Exposures.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Upload3Exposures);
