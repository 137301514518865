import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { AuthContext } from '../../../../contexts/Auth';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import history from '../../../../utils/history';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'white',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  completeEquipmentButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 18,
    paddingRight: 18,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: '0 4px 10px #6334FF90',
    borderRadius: 24,
    height: 48,
  },
  paperFullWidth: {
    width: 'calc(100% - 64px)',
    height: '-webkit-fill-available',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, setOpen }) {
  const classes = useStyles();

  const [link, setLink] = useState(null);

  const { accountData } = useContext(AuthContext);

  useEffect(() => {
    if (accountData) {
      getSkills();
    }
  }, [accountData]);

  const getSkills = async () => {
    let skills = await axios.post(
      'https://wesnapp-node-server.appspot.com/api/photographerskill/get-by-contact-id',
      { id: localStorage.getItem('contactId') },
      { headers: { 'Content-Type': 'application/json' } }
    );
    let foundRealEstateSkill = false;
    for (let index = 0; index < skills.data.length; index++) {
      const skill = skills.data[index];
      console.log('skill', skill.Skill_Name__c, skill);
      if (skill.Skill_Name__c === 'Stills Photography - Real Estate') {
        foundRealEstateSkill = true;
        console.log('status', skill.Status__c);
        if (skill.Status__c === 'Pending') {
          // Show photo shoot test
          setLink(skill.Skill_Guidelines_Url__c);
          // setLink(accountData.Country_Code__c === "+972" ? "https://wesnapp.io/he/photographers-guidelines-israel" : "https://wesnapp.io/photographers-guidelines-usa")
          // setOpen(true)
        }
      }
    }
    if (!foundRealEstateSkill) {
      // Show equipment dialog
      setLink('https://wesnapp.io/welcome-photographers/');
      // setOpen(true)
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (link !== null) {
      setOpen(true);
    }
  }, [link]);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{ paperFullWidth: classes.paperFullWidth }}
      >
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <Typography
              style={{ fontSize: 18, fontWeight: 'bold', flexGrow: 1 }}
            >
              Shooting test guidlines
            </Typography>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon style={{ color: '#111111' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ height: '100%', minHeight: '90vh' }}>
          {accountData && link && (
            <iframe
              src={link}
              width='100%'
              height='100%'
              frameBorder='0'
              style={{ minHeight: 'calc(90vh - 60px)' }}
            >
              Browser not compatible.
            </iframe>
          )}
        </DialogContent>
        {link && !link.includes('guidelines') && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              height: 100,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              bottom: 36,
              left: 0,
            }}
          >
            <Button
              className={classes.completeEquipmentButton}
              onClick={() => history.push('/p/dashboard/account')}
            >
              Complete Equipment
            </Button>
          </div>
        )}
      </Dialog>
    </div>
  );
}
